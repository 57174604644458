<div class="continer-fluid">
    <div class="new-dashboard-wrapper">

        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner">Loading...</span></div>
            </div>
        </div>
        <app-new-header></app-new-header>
        <app-side-menu (ngoChanged)="ngoChanged($event)"></app-side-menu>

        <ng-container *ngIf="alertMsg.msg !== ''">
            <app-alert [message]="alertMsg"></app-alert>
        </ng-container>

        <div class="grid-wrapper">
            <div class="row no-gutters">
                <div class="colFull">
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <a (click)="redirectChallenges()" href="javascript:void(0)">Challenges</a>
                            </li>
                            <ng-container *ngIf="eventDetailsData?.overall?.event_series_id">
                                <li>
                                    <a *ngIf="eventDetailsData"
                                        [routerLink]="['/events/series', eventDetailsData?.overall?.event_series_id]">
                                        Challenge Series
                                    </a>
                                </li>
                            </ng-container>
                            <li class="active">
                                <a href="javascript:void(0)">Challenge Details</a>
                            </li>
                        </ul>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-12">
                            <div class="heading_wrapper mbTitle">
                                <div class="title-wrapper">
                                    <div class="left-column">
                                        <div class="page-title">{{eventDetailsData?.overall?.event_name}}</div>
                                    </div>
                                    <div class="right-column"
                                        *ngIf="userRole === 'ROLE_SUPER_ADMIN' && eventDetailsData?.overall?.event_name !== 'unknown'">
                                        <div class="btn-box">
                                            <button (click)="editEvent();" class="custom-btn">Edit Challenge</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters mb-4">
                        <div class="col-12 col-lg-5">
                            <a [href]="eventDetailsData?.overall?.fb_group_url" target="_blank">
                                <div class="event-image">
                                    <ng-container *ngIf="eventDetailsData?.overall?.header_image_url == null; else headImage">
                                        <img src="../../../assets/images/bostan.jpg" alt="">
                                        <div class="centered">View Facebook Group<i class="fa fa-arrow-right" aria-hidden="true"></i></div>
                                    </ng-container>
                                    <ng-template #headImage>
                                        <img [src]="eventDetailsData?.overall?.header_image_url" alt="">
                                        <div class="centered">View Facebook Group<i class="fa fa-arrow-right" aria-hidden="true"></i></div>
                                    </ng-template>
                                </div>
                            </a>
                        </div>
                        <div class="col-12 col-lg-7" *ngIf="eventDetailsData">
                            <div class="event-details-box">
                                <p class="total-participants d-inline">
                                    {{eventDetailsData?.overall?.total_participants | number:'1.0-0'}} Participants</p>                                    
                                <ul>
                                    <li><span [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="Start Date">Start Date:</span> {{onlyStartDate}}</li>
                                    <li><span [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="End Date">End Date:</span> {{onlyEndDate}} ({{diffDays}} Days)</li>
                                </ul>
                                <div class="optinLink">
                                    <p><span matTooltip="Opt In">Opt In:</span> <a
                                            [href]="eventDetailsData?.overall?.opt_in_url">{{eventDetailsData?.overall?.opt_in_url}}</a>
                                    </p>
                                </div>
                                <div class="optinLink">
                                    <p><span matTooltip="Fundraiser Creation">Fundraiser Creation:</span> <a
                                            [href]="oneClickLink">{{oneClickLink}}</a>
                                    </p>
                                </div>
                                <div class="stat-row">
                                    <div class="stat-blocks mob1">
                                        <div class="stat-block">
                                            <div class="stat-label-wrap content-end">
                                                <div class="stat-label" [matTooltipClass]="positionBottom.value" matTooltip="Group Members">Group Members</div>
                                            </div>
                                            <div class="stat-number">
                                                <ng-container *ngIf="eventDetailsData?.overall?.group_members_count!=null; else noMember">{{eventDetailsData?.overall?.group_members_count | number:'1.0-0'}}</ng-container>
                                                <ng-template #noMember>--</ng-template>
                                            </div>
                                        </div>
                                        <div class="stat-block">
                                            <div class="stat-label-wrap content-end">
                                                <div class="stat-label" [matTooltipClass]="positionBottom.value" matTooltip="The total amount raised by your Fundraisers within the selected time period.">Total Raised</div>
                                            </div>
                                            <div class="stat-number">
                                                {{ eventDetailsData?.event_detail_graph?.graph_details?.raised | currency:selectedNgo.currency_name:'symbol':'1.0-0' }}/{{ eventDetailsData?.overall?.event_goal | currency:selectedNgo.currency_name:'symbol':'1.0-0' }}
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" [style.width.%]="percentage">
                                                    <span class="sr-only">{{percentage}}% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="stat-block">
                                            <div class="stat-label-wrap content-end">
                                                <div class="stat-label" [matTooltipClass]="positionBottom.value" matTooltip="Total Activity">Total Activity</div>
                                            </div>
                                            <div class="stat-number">
                                                {{ eventDetailsData?.overall?.total_activity | number:'1.0-0' }} 
                                                <ng-container *ngIf="eventDetailsData?.overall?.goal_type!=null; else Miles">{{ eventDetailsData?.overall?.goal_type }}</ng-container><ng-template #Miles> - </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="display-grid">
                        <div class="content-box">
                            <mat-tab-group (selectedTabChange)="onRevenueTabChange($event)" [selectedIndex]="selectedTab.revenue">
                                <mat-tab id="raised" > 
                                    <ng-template mat-tab-label>
                                        <div class="title" [matTooltipClass]="positionBottom.value" matTooltip="The total amount raised by your Fundraisers within the selected time period."> Raised </div>
                                        <div class="total-amount"> {{ eventDetailsData?.event_detail_graph?.graph_details?.raised | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }} </div>
                                        <div class="average" [matTooltipClass]="positionBottom.value" matTooltip="Average amount raised by each of your Fundraisers.">{{ eventDetailsData?.event_detail_graph?.graph_details?.avg_raised | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }}&nbsp;Avg Raised </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab id="fundraisers"> 
                                    <ng-template mat-tab-label>
                                        <div class="title" [matTooltipClass]="positionBottom.value" matTooltip="Total number of Fundraisers within the selected time period."> Fundraisers </div>
                                        <div class="total-amount"> {{ eventDetailsData?.event_detail_graph?.graph_details?.fundraiser | number:'1.0-0' }} </div>
                                        <div class="average" [matTooltipClass]="positionBottom.value" matTooltip="Total number of Donations to a Fundraiser for the selected time period."> {{ eventDetailsData?.event_detail_graph?.graph_details?.avg_fundraiser | number:'1.0-0' }}&nbsp;Avg Donations </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab id="donations"> 
                                    <ng-template mat-tab-label>
                                        <div class="title" [matTooltipClass]="positionBottom.value" matTooltip="Total number of Donations to a Fundraiser for the selected time period."> Donations </div>
                                        <div class="total-amount"> {{ eventDetailsData?.event_detail_graph?.graph_details?.donations | number:'1.0-0' }} </div>
                                        <div class="average"  [matTooltipClass]="positionBottom.value" matTooltip="Average amount of money donated per transaction for the selected time period."> {{ eventDetailsData?.event_detail_graph?.graph_details?.avg_donations | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }}&nbsp;Avg Donation </div>
                                    </ng-template>
                                </mat-tab>
                              </mat-tab-group>
                            <div class="html-embed-9 w-embed w-script mb-3">
                                <div class="row no-gutters">
                                    <div class="col-7">
                                        <ul class="legends frgraphLegend">
                                            <li>
                                                <div class="legendBox" [ngStyle] = "{'cursor':'text'}">
                                                    <div class="lgDisc newBg mr-1"></div>
                                                    <div class="lgText lgFont mr-1">
                                                        New
                                                    </div>
                                                    <div class="lgFont" [ngStyle] = "{'color':'#7F95A1'}">
                                                        
                                                        <ng-container *ngIf = "['total_raised']?.includes(newDataObj?.key)">{{ newDataObj?.val | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }}</ng-container>
                                                        <ng-container *ngIf = "['total_fundraiser','total_donation']?.includes(newDataObj?.key)">{{ newDataObj?.val | number:'1.0-0' }}</ng-container>

                                                        ({{newDataObj?.percentage | number : '1.0-0'}}%)
                                                        
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="legendBox" [ngStyle] = "{'cursor':'text'}">
                                                    <div class="lgDisc prBg mr-1"></div>
                                                    <div class="lgText lgFont mr-1">
                                                        Repeat
                                                    </div>
                                                    <div class="lgFont" [ngStyle] = "{'color':'#7F95A1'}">
                                                        <ng-container *ngIf = "['total_raised']?.includes(repeatDataObj?.key)">{{ repeatDataObj?.val | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }}</ng-container>
                                                        <ng-container *ngIf = "['total_fundraiser','total_donation']?.includes(newDataObj?.key)">{{ repeatDataObj?.val | number:'1.0-0' }}</ng-container>
                                                        
                                                        ({{repeatDataObj?.percentage | number : '1.0-0'}}%)
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                 </div>
                                <canvas id="event_revenue"></canvas>
                            </div>
                        </div>
                        <div class="content-box">
                            <mat-tab-group (selectedTabChange)="onMarketingTabChange($event)" [selectedIndex]="selectedTab.marketing">
                                <mat-tab id="roas" > 
                                    <ng-template mat-tab-label>
                                        <div class="title"> ROAS </div>
                                        <div class="total-amount"> {{ eventDetailsData?.roas_cpl_graph?.graph_details?.roas * 100 | number : '1.0-0' }}%</div>
                                        <div class="average"> Total </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab id="cpl"> 
                                    <ng-template mat-tab-label>
                                        <div class="title"> CPL </div>
                                        <div class="total-amount"> {{ eventDetailsData?.roas_cpl_graph?.graph_details?.cpl | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }} </div>
                                        <div class="average"> Total </div>
                                    </ng-template>
                                </mat-tab>
                              </mat-tab-group>
                            <div class="html-embed-9 w-embed w-script mb-3">
                                <div class="row no-gutters">
                                    <div class="col-7">
                                        <ul class="legends frgraphLegend">
                                            <li>
                                                <div class="legendBox" [ngStyle] = "{'cursor':'text'}">
                                                    <div class="lgDisc newBg mr-1"></div>
                                                    <div class="lgText lgFont mr-1">
                                                        New
                                                    </div>
                                                    <div class="lgFont" [ngStyle] = "{'color':'#7F95A1'}">
                                                        {{ newDataMarketing?.val | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }}
                                                        ({{newDataMarketing?.percentage | number : '1.0-0'}}%)
                                                        
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="legendBox" [ngStyle] = "{'cursor':'text'}">
                                                    <div class="lgDisc prBg mr-1"></div>
                                                    <div class="lgText lgFont mr-1">
                                                        Repeat
                                                    </div>
                                                    <div class="lgFont" [ngStyle] = "{'color':'#7F95A1'}">
                                                        {{ repeatDataMarketing?.val | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }}
                                                        ({{repeatDataMarketing?.percentage | number : '1.0-0'}}%)
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                 </div>
                                <canvas id="event_marketing"></canvas>
                            </div>
                        </div>
                    </div>
                    <div class="content-box">
                        <div class="event-section event-list">
                            <div class="table-responsive">
                                <table
                                    class="table table-borderless"
                                    matSort
                                    (matSortChange)="sortData($event)"
                                    [matSortActive]="sortingField"
                                    [matSortDirection]="orderBy"
                                    matSortDisableClear>
                                    <thead>
                                        <tr>
                                            <td mat-sort-header="attribution_source"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="Source">Source</span></td>
                                            <td mat-sort-header="total_raised"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="Total amount raised by all Fundraisers within that Challenge Series.">Total Raised</span></td>
                                            <td mat-sort-header="participants"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="The number of people who clicked the Facebook Ad and filled out the lead form.">Leads</span></td>
                                            <td mat-sort-header="fundraiser_percentage"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="The amount of people who make up the Challenge series who are also Fundraisers.">Fundraisers(%)</span></td>
                                            <td mat-sort-header="average_fundraiser"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="The average amount raised for each Fundraiser in the Series.">Avg Fundraiser</span></td>
                                            <td mat-sort-header="total_donations"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="The total number of donations received for that Challenge Series">Donations</span></td>
                                            <td mat-sort-header="opt_in_percentage" class="text-left"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="The % of people who opted-in to engage with us on Facebook Messenger">Opt In%</span></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngIf="eventDetailsData?.detailed_data?.length > 0; else NotFound">
                                            <tr *ngFor="let detail of eventDetailsData?.detailed_data">
                                                <td class="fontStyle">
                                                    {{ detail?.attribution_source }}
                                                </td>
                                                <td class="fontStyle">
                                                    {{ detail?.total_raised | currency:selectedNgo.currency_name:'symbol':'1.0-0' }}
                                                </td>
                                                <td class="fontStyle">
                                                    {{ detail?.participants | number:'1.0-0'}}
                                                </td>
                                                <td class="fontStyle">
                                                    {{ detail?.fundraisers | number }}
                                                    ({{detail?.fundraiser_percentage | number : '1.2-2'}}%)
                                                </td>
                                                <td class="fontStyle">
                                                    <ng-container *ngIf="detail?.fundraisers > 0; else NoFundraiser">
                                                        {{detail?.total_raised / detail?.fundraisers | currency:selectedNgo.currency_name:'symbol':'1.0-0'}} 
                                                    </ng-container>
                                                    <ng-template #NoFundraiser>
                                                         {{0 | currency:selectedNgo.currency_name:'symbol':'1.0-0'}}
                                                    </ng-template>
                                                </td>
                                                <td class="fontStyle"> {{ detail?.total_donations | number:'1.0-0'}}
                                                </td>
                                                <td class="fontStyle text-left">
                                                    {{ detail?.opt_in_percentage }}%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fontStyle font-weight-bold">All Sources</td>
                                                <td class="fontStyle font-weight-bold">
                                                    {{ allsourcesRaiseSum | currency:selectedNgo.currency_name:'symbol':'1.0-0' }}</td>
                                                <td class="fontStyle font-weight-bold">
                                                    {{ totalParticipants | number:'1.0-0'}}</td>
                                                <td class="fontStyle font-weight-bold">{{ totalFundraisers}}
                                                    ({{totalFundraiserPercentage | number : '1.2-2'}}%)</td>
                                                <td class="fontStyle font-weight-bold">
                                                    {{ totalAverage_fundraiser | currency:selectedNgo.currency_name:'symbol':'1.0-0' }}</td>
                                                <td class="fontStyle font-weight-bold">
                                                    {{ toalDonations | number:'1.0-0' }}</td>
                                                <td class="fontStyle font-weight-bold text-left">
                                                    {{ totalOptinPercent | number : '1.2-2'}}%</td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #NotFound>
                                            <tr>
                                                <td colspan="7" class="notFound text-center">No data found</td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-240 content-box mt-0" id="subscriberList">
                    <table class="table table-borderless subscribers_heading">
                        <thead>
                            <tr>
                                <th class="theading" [matTooltipClass]="positionBottom.value" matTooltip="Subscriber list which shows your top Subscribers.">&nbsp;&nbsp;Subscribers</th>
                                <th class="view-all"><a [routerLink]="['/subscribers']">View All</a></th>
                            </tr>
                        </thead>
                    </table>
                    <div class="subscribers-wrapper">
                        <div class="overlay__inner custom-overlay" *ngIf="activeSubscriberLoader">
                            <div class="overlay__content">
                                <span class="spinner loadImg"></span>
                            </div>
                        </div>
                        <table class="table table-borderless">                          
                            <tbody>
                                <ng-container
                                    *ngIf="subscriberlistData && subscriberlistData.length > 0; else SNotFound">
                                    <tr class="cursor-pointer" *ngFor="let subscriber of subscriberlistData" (click)="openSubscriberDetail(subscriber?.person_id)">
                                        <td>
                                            <div class="user-profile">
                                                <div class="widget-content-left">
                                                    <ng-container
                                                        *ngIf="subscriber?.profile_image_url != null; else noImage">
                                                        <img [src]="subscriber?.profile_image_url" alt="">
                                                    </ng-container>
                                                    <ng-template #noImage>
                                                        <img src="../../../assets/images/noImage.png" alt="">
                                                    </ng-template>
                                                </div>
                                                <div class="widget-heading" [title]="subscriber.full_name">
                                                    {{subscriber.full_name}}</div>
                                            </div>
                                        </td>
                                        <td class="price">{{currencyName=='USD' ? '$' : '£'}} {{ subscriber.fundraiser_amount }}</td>
                                    </tr>
                                </ng-container>
                                <ng-template #SNotFound>
                                    <tr>
                                        <td colspan="2" class="notFound text-center">No data found</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="shape-bg">
    <div class="c-shape-bg is--full rt-25">
    </div>
</div>