export interface ConversionGraph {
    thank_you_count: number;
    email_percent: number;
    opt_in_percent: number;
    email_count: number;
    opt_in_count: number;
}

export interface DirectConversionGraph {
    email_count_direct: number;
    email_percent_direct: number;
    opt_in_count_direct: number;
    opt_in_percent_direct: number;
    thank_you_count_direct: number;
}

export interface EventConversionGraph {
    thank_you_count_fbc: number;
    opt_in_count_fbc: number;
    email_count_fbc: number;
    email_percent_fbc: number;
    opt_in_percent_fbc: number;
}

export const ChartColor = ['#04C3CB'];
export const ChartColorFbc = ['#F8B630'];
export const ChartColorTotalFunnel = [
    ['#04C3CB', '#04C3CB'],
     '#F8B630',
     '#F8B630'
  ];




