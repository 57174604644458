import { Pipe, PipeTransform} from '@angular/core'
import { DomSanitizer} from '@angular/platform-browser'

@Pipe({
    name: 'highlightSentence'
})
export class HighlightSearchSentence implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}

  transform(value: any, args: any): any {
    if (!args) {
      return value;
    }
    const replacedValue = value.replace(args, "<mark>" + args + "</mark>")
    return replacedValue;
  }
}