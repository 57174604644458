import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FbLoginService {
  public redirectUrl  = environment.baseURL + 'fb-callback';
  // public redirectUrl  = 'http://localhost:4200/fb-callback';

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  validateCode(code: string): Observable<any> {
    const params = { code };

    return this.http.get('login', { params });
  }

  signOutFB(): void {
    localStorage.removeItem(environment.localKey + 'basicInfo');
    this.router.navigate(['/']);
  }

  fbLogin(code: string) {
    const params = {
      redirectUri: this.redirectUrl,
      code
    };

    return this.http.get('login', { params });
  }
}