import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvanceConfiguration {
  constructor(
    private http: HttpClient,
  ) { }

  getNgoList(): Observable<any> {
    return this.http.get('fundraiserPages');
  }

  projectListByNGOID(ngoID: string): Observable<any> {
    const params = { charity_id: ngoID };

    return this.http.get('get_projects', { params });
  }

  createProjects(data): Observable<any> {
    return this.http.post('add_project', data);
  }

  updateProjects(data, charityId = ''): Observable<any> {
    const params = { charity_id: charityId };
    return this.http.post('update_project', data, { params });
  }

  changeAsanaTaskStatus(reqData): Observable<any> {
    return this.http.post('set_asana_task_status?charity_id=' + reqData.charityId, {status: reqData.status});
  }

  getAsanaTaskStatus(charityId): Observable<any> {
    const params = { charity_id: charityId };
    return this.http.get('get_asana_task_status', { params });
  }

  changeFundraserTaskStatus(reqData): Observable<any> {
    return this.http.post('update_american_flag?charity_id=' + reqData.charityId, {american_flag: reqData.american_flag});
  }

  getAmericanTaskStatus(charityId): Observable<any> {
    const params = { charity_id: charityId };
    return this.http.get('get_us_fundraiser_flag', { params });
  }

  getfbcharityStatus(charityId): Observable<any> {
    const params = { charity_id: charityId };
    return this.http.get('get_fb_charity_flag', { params });
  }

  changeCharityStatus(reqData): Observable<any> {
    return this.http.post('change_fb_charity_flag?charity_id=' + reqData.charityId, {fb_charity_flag: reqData.fb_charity_flag});
  }

  updateProjectStatus(reqData): Observable<any> {
    return this.http.post('update_project_names?charity_id=' + reqData, {});
  }

}
