import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Filter } from 'src/app/models/Fundraiser';
import * as momentJs from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FilterSharedService {
  public currentMonth = momentJs().month();
  public filterMonth  = momentJs.monthsShort();
  public months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  public dateFilter: Filter = {
    type: 'daily',
    year: new Date().getFullYear(),
    month: this.filterMonth[this.currentMonth]
  };

  private dateFilterSource    = new BehaviorSubject<Filter>(this.dateFilter);

  public dateFilters$         = this.dateFilterSource.asObservable();

  constructor() { }

  changeDateFilter(data: Filter) {
    this.dateFilterSource.next(data);
  }
}
