<div class="asanaConfirmation">   
    <!-- <div class="title">Edit Asana Status</div> -->
    <mat-dialog-content>
        <div class="closeBtn">
            <button class="btn-dialog-close pull-right" mat-stroked-button (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>  
        <div class="md-dialog-content text-center">
            <div>
                Please make sure to save charity api after this action.
                <div class="button-wrapper">
                    <button mat-raised-button class="btn btn-info" type="button" (click)="onClose(true)">OK</button>&nbsp;&nbsp;
                    <button mat-raised-button class="btn-outline-custom back-button" type="button" (click)="onClose(false)">Cancel</button>
                </div>
            </div>
        </div>        
    </mat-dialog-content>  
</div>