import { Component, OnInit, Inject, Optional, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WordCloudService } from '../services/wordcloud.service';
import { MessageService } from '../services/message.service';


@Component({
  selector: 'sentence-story-detail',
  templateUrl: './sentence-story-detail.component.html',
  styleUrls: ['./sentence-story-detail.component.scss']
})
export class SentenceStoryDetailComponent implements OnInit {

  fromDialog: string;
  questionText: string; 
  charityID: any;
  stories: [];  
  loading = false;
  details: any;
  searchTerm: string;
  qType: any;
  tmpStories:any[] = [];

  constructor(
    public dialogRef: MatDialogRef<SentenceStoryDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
     private _messageService: MessageService
  ) {

     this.questionText = data.question;  
     this.charityID = data.charityId;  
     this.searchTerm = data.question;
     this.qType = data.type;
  }

  ngOnInit() {
    this.getQuestionDetail();    
  }

  truncateStories(str) {
    let formattedMessage = '';  
    str.split(' ').forEach((words, i: number) => {   
      if (i < 20) {          
        formattedMessage += ' ' + words;
      } 
    });
    return formattedMessage;
  }

  ShowMoreButton(index) {  
    for(let i=0;i<this.tmpStories.length;i++){
      if(i == index){
        this.tmpStories[index].isExpanded = true;
      }
    }
  }

  ShowLessButton(index) {  
    for(let i=0;i<this.tmpStories.length;i++){
      if(i == index){
        this.tmpStories[index].isExpanded = false;
      }
    }
  }
  
  getQuestionDetail(){
    this._messageService.getSentenceDetail(this.charityID, this.questionText).subscribe((res : any) =>{
      if(res){
        this.details = res.message;
        let tmpSt = JSON.parse(JSON.stringify(res.message));
        for(let i=0;i<tmpSt.length;i++){
          let tmpObj = tmpSt[i];
          tmpObj["isExpanded"] = false;
          this.tmpStories.push(tmpObj);  
        }
      }
    })
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close', data: this.fromDialog });
  }


}
