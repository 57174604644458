<div class="continer-fluid">
    <div class="new-dashboard-wrapper">
        <!-- app header -->
        <app-new-header></app-new-header>

        <!-- Side Menu -->
        <app-side-menu (ngoChanged)="ngoChanged($event)"></app-side-menu>

        <ng-container *ngIf="alertMsg.msg !== ''">
            <app-alert [message]="alertMsg"></app-alert>
        </ng-container>

        <!-- Main Content -->
        <div class="grid-wrapper">
            <div class="row no-gutters">
                <div class="colFull">
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <a (click)="redirectChallenges()" href="javascript:void(0)">Challenge Series</a>
                            </li>
                            <li class="active" *ngIf="eventSeriesData?.overall_result_dict?.series_name">
                                <a href="javascript:void(0)">Challenges </a>
                            </li>                                   
                        </ul>
                    </div>

                    <div class="heading_wrapper mbTitle">
                        <div class="title-wrapper">
                            <div class="left-column">
                                <div class="page-title" *ngIf="eventSeriesData?.overall_result_dict?.series_name">
                                    {{eventSeriesData?.overall_result_dict?.series_name}}
                                </div>
                            </div>
                            <div class="right-column" *ngIf="userRole === 'ROLE_SUPER_ADMIN'">
                                <div class="btn-box">
                                    <button (click)="newEvent();" class="custom-btn">
                                        Create Challenge
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="common-box-wrapper">
                            <div class="searchBox search-15">
                                <input type="search" class="searchText" #input (input)="searchThis(input.value)"
                                    placeholder="Search">
                                <span class="searchIcon"></span>
                            </div>
                            <div class="filterBox">
                                <div class="filter-wrapper">
                                    <div class="filterText" [matTooltipClass]="positionBottom.value" matTooltip="Provides you with time based boundaries to view your Dashboard results. (E.g. All Time, Year, Month)">
                                        Filters: {{ filterApplied[filterData?.type] }}
                                        {{ filterData?.type === 'daily' ? '(' + filterData?.month  + ' &nbsp;' + filterData?.year + ')' : filterData?.type === 'monthly' ?  '(' + filterData?.year + ')' : '' }}
                                    </div>
                                    <div class="custom-dropdown position-absolute">
                                        <div class="dropdown">
                                            <a class="dropdown-toggle gu-dropdown-toggle" href="#" role="button"
                                                id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                            </a>

                                            <div class="dropdown-menu w-open" aria-labelledby="dropdownMenuLink">
                                                <button class="dropdown-item" [disabled]="filterData?.type === 'all'"
                                                    (click)="filter('all')">
                                                    All Time
                                                </button>

                                                <button class="dropdown-item" data-toggle="modal"
                                                    data-target="#detailedDashboardYear">
                                                    Year
                                                </button>

                                                <button class="dropdown-item" data-toggle="modal"
                                                    data-target="#detailedDashboardMonth">
                                                    Month
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sresult" *ngIf="searchTxt?.length >= 3">
                                <ng-container *ngIf="totalSearchResult > 0;else noResult">
                                    <a (click)="toBottom()" href="javascript:void(0)">
                                        {{totalSearchResult}} Results
                                    </a>
                                </ng-container>
                                <ng-template #noResult>
                                    <a (click)="toBottom()" href="javascript:void(0)">{{searchStatus}}</a>
                                </ng-template>
                            </div>
                        </div>
                    </div>


                    <div class="content-box">
                        <div class="overlay__inner custom-overlay" *ngIf="loading">
                            <div class="overlay__content">
                                <span class="spinner loadImg"></span>
                            </div>
                        </div>
                        <h2 class="common-heading mr-3"><span [matTooltipClass]="positionBottom.value" matTooltip="An adjustable overview of all Fundraiser activity within the selected time period.">Fundraiser Overview</span></h2>

                        <div class="stat-row">
                            <div class="stat-blocks mob1">
                                <div class="stat-block">
                                    <div class="stat-label-wrap content-end">
                                        <div class="stat-label text-left" [matTooltipClass]="positionBottom.value" matTooltip="The total amount raised by your Fundraisers within the selected time period.">Total Raised</div>
                                    </div>
                                    <div class="stat-number">
                                        {{ eventSeriesData?.overall_result_dict?.totalRaised | currency:selectedNgo?.currency_name:'symbol':'1.0-0'}}
                                    </div>
                                    <div>
                                        <div class="stat-sub">
                                            <span [matTooltipClass]="positionBottom.value" matTooltip="Average amount raised by each of your Fundraisers.">Avg Fundraiser&nbsp;</span>
                                            <span class="common-subheading-price">{{ avgCampaign | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="stat-blocks">
                                <div class="stat-block right mob2">
                                    <div class="stat-label-wrap right">
                                        <div class="stat-label" [matTooltipClass]="positionBottom.value" matTooltip="Total number of Donations to a Fundraiser for the selected time period.">Total Donations</div>
                                    </div>
                                    <div class="stat-number">
                                        {{ eventSeriesData?.overall_result_dict?.totalDonation | number:'1.0-0' }}
                                    </div>
                                    <div>
                                        <div class="stat-sub" [matTooltipClass]="positionBottom.value" matTooltip="Average amount of money donated per transaction for the selected time period.">
                                            Avg Donation
                                            <span class="common-subheading-price">{{ avgDonation | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="stat-block right mob3">
                                    <div class="stat-label-wrap right">
                                        <div class="stat-label" [matTooltipClass]="positionBottom.value" matTooltip="Total number of Fundraisers within the selected time period.">Total Campaigns</div>
                                    </div>
                                    <div class="stat-number">
                                        {{ eventSeriesData?.overall_result_dict?.totalCampaign | number:'1.0-0' }}
                                    </div>
                                    <div>
                                        <div class="stat-sub" [matTooltipClass]="positionBottom.value" matTooltip="Average number of Donations to a Fundraiser for the selected time period.">
                                            Avg Donations
                                            <span class="common-subheading-price">{{ avgDonations | number:'1.0-0' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="html-embed-9 w-embed w-script">
                            <div class="row no-gutters">
                                <div class="col-7">
                                    <ng-container *ngIf="eventSeriesData && eventSeriesData.data">
                                        <ul class="legends frgraphLegend">
                                            <li
                                                *ngFor="let graph of eventSeriesData.data; let i = index"
                                                (click)="datasetFlag[graph.title] ? removeDataset(graph.title) : addDataset(graph.title)"
                                                [ngClass]="{'cross-line': !datasetFlag[graph.title]}">
                                                    <ng-container *ngIf="graph.type === 'stack'">
                                                        <div class="legendBox">
                                                            <div class="lgDisc mr-1" [ngStyle]="{'background-color': chartColor[i]}"></div>
                                                            <div class="lgText lgFont mr-1">
                                                                {{ graph.title }}
                                                            </div>
                                                            <div class="lgFont" [ngStyle]="{'color': chartColor[i]}">
                                                                {{ getTotalValue(graph.title) }}
                                                            </div>
                                                        </div>  
                                                    </ng-container>
                                            </li>
                                        </ul>
                                    </ng-container>
                                </div>

                                <div class="col-5">
                                     <ul class="legends frgraphLegendRight">
                                        <li (click)="datasetFlag['Total Campaigns'] ? removeDataset('Total Campaigns') : addDataset('Total Campaigns')" [ngClass]="{'cross-line': !datasetFlag['Total Campaigns']}">
                                        <div class="legendBox">
                                            <div class="mr-2">--</div>
                                            <div class="lgText lgFont mr-1" [matTooltipClass]="positionBottom.value" matTooltip="Total number of Fundraisers within the selected time period.">Campaigns</div>
                                            <div class="lgFont blColor">
                                                {{ eventSeriesData?.overall_result_dict?.totalCampaign | number:'1.0-0' }}
                                                <!-- {{ getTotalValue('Total Campaigns') }} -->
                                            </div>
                                        </div> 
                                        </li>
                                         <li (click)="datasetFlag['Total Donations'] ? removeDataset('Total Donations') : addDataset('Total Donations')" [ngClass]="{'cross-line': !datasetFlag['Total Donations']}">
                                         <div class="legendBox">
                                             <div class="mr-2 Line"></div>
                                             <div class="lgText lgFont mr-1" [matTooltipClass]="positionBottom.value" matTooltip="Total number of Donations to a Fundraiser for the selected time period.">Donations</div>
                                             <div class="lgFont blColor">
                                                 {{ getTotalValue('Total Donations') }}
                                             </div>
                                         </div>  
                                         </li>
                                     </ul>    
                                </div>
                            </div>

                            <canvas id="eventSeriesList"></canvas>
                        </div>
                    </div>



                    <div class="content-box">
                        <div class="overlay__inner custom-overlay" *ngIf="eventSeriesListingLoader">
                            <div class="overlay__content custom">
                                <span class="spinner loadImg"></span>
                            </div>
                        </div>
                        <div class="event-section event-list">
                            <div class="table-responsive">

                                <table
                                class="table table-borderless"
                                matSort
                                (matSortChange)="sortData($event)"
                                [matSortActive]="sortingField"
                                [matSortDirection]="orderBy"
                                matSortDisableClear>
                                <thead>
                                    <tr>
                                        <td mat-sort-header="name"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="This lists each Challenge Series Type. (e.g. 100mi run)">Challenge</span></td>
                                        <td mat-sort-header="start_date"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="This is the start date of the most recent Fundraiser held within that Challenge Series.">Start Date</span></td>                                            
                                        <td mat-sort-header="raised"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="Total amount raised by all Fundraisers within that Challenge Series.">Total Raised</span></td>
                                        <td mat-sort-header="participants"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="The number of people who clicked the Facebook Ad and filled out the lead form.">Leads (Referrals)</span></td>
                                        <td mat-sort-header="group_members_count"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="Group Members">Group Members</span></td>
                                        <td mat-sort-header="fundraisers"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="The amount of people who make up the Challenge series who are also Fundraisers.">Fundraisers(%)</span></td>
                                        <td mat-sort-header="average"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="The average amount raised for each Fundraiser in the Series.">Avg Fundraiser</span></td>
                                        <td mat-sort-header="donations"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="The total number of donations received for that Challenge Series">Donations</span></td>
                                        <td mat-sort-header="opt_in_per" class="text-left"><span [matTooltipClass]="positionTop.value" [matTooltipPosition]="positionTop.value" matTooltip="The % of people who opted-in to engage with us on Facebook Messenger">Opt In %</span></td>
                                    </tr>
                                </thead>
                                <tbody id="event-series-list">
                                    <ng-container *ngIf="eventSeriesListingData; else NotFoundSeries">
                                        <tr (click)="redirect(event)" *ngFor="let event of eventSeriesListingData">
                                            <td class="pl7">
                                                <div class="user-profile">
                                                    <div class="widget-content-left">
                                                        <ng-container *ngIf="event?.image ==' '; else noImage">
                                                            <img src="../../../assets/images/noImage.png" alt="">
                                                        </ng-container>
                                                        <ng-template #noImage>
                                                            <img [src]="event?.image" alt="">
                                                        </ng-template>
                                                    </div>
                                                    <div class="widget-heading">
                                                        <div class="title">{{ event.name }}</div>
                                                        <div class="particepants">
                                                            {{ event.participants | number:'1.0-0' }} Participants
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="fontStyle">
                                                {{ event.start_date }}
                                            </td>                                               
                                            <td class="fontStyle">
                                                {{ event.raised | currency:selectedNgo?.currency_name:'symbol':'1.0-0'}}
                                            </td>
                                            <td class="fontStyle">
                                                {{ event.participants | number:'1.0-0' }}
                                                ({{ event.referal | number:'1.0-0' }})
                                            </td>
                                            <td class="fontStyle">
                                                {{ event.group_members_count | number:'1.0-0'}}
                                            </td>
                                            <td class="fontStyle">
                                                <ng-container *ngIf="event?.fundraisers==''; else Efundraisers"> --
                                                </ng-container>
                                                <ng-template #Efundraisers>{{ event.fundraisers | number }}
                                                    ({{event.fundraiser_percentage | number : '1.2-2'}}%)
                                                </ng-template>
                                            </td>
                                            <td class="fontStyle">
                                                <ng-container *ngIf="event?.average==''; else Eaverage"> --
                                                </ng-container>
                                                <ng-template #Eaverage>
                                                    <ng-container *ngIf="event.fundraisers > 0; else NoAvgFundVal">
                                                        {{ event.raised_per_fund | currency:selectedNgo?.currency_name:'symbol':'1.0-0' }}
                                                    </ng-container>
                                                    <ng-template #NoAvgFundVal>
                                                        {{ 0 | currency:selectedNgo?.currency_name:'symbol':'1.0-0'}}
                                                    </ng-template> 
                                                </ng-template>
                                            </td>
                                            <td class="fontStyle">
                                                <ng-container *ngIf="event?.donations==''; else sdonations"> --
                                                </ng-container>
                                                <ng-template #sdonations>{{ event.donations | number:'1.0-0' }}
                                                </ng-template>
                                            </td>
                                            <td class="fontStyle text-left">
                                                {{ event.opt_in_per }}%
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-template #NotFoundSeries>
                                        <tr>
                                            <td colspan="7" class="notFound text-center">No Data Found</td>
                                        </tr>
                                    </ng-template>
                                </tbody>
                            </table>
                              
                            </div>
                        </div>
                    </div>

                    

                </div>
                <div class="col-240 content-box" id="subscriberList">
                    <table class="table table-borderless subscribers_heading">
                        <thead>
                            <tr>
                                <th class="theading" [matTooltipClass]="positionBottom.value" matTooltip="Subscriber list which shows your top Subscribers.">&nbsp;&nbsp;Subscribers</th>
                                <th class="view-all"><a [routerLink]="['/subscribers']">View All</a></th>
                            </tr>
                        </thead>
                    </table>
                    <div class="subscribers-wrapper">
                        <div class="overlay__inner custom-overlay" *ngIf="activeSubscriberLoader">
                            <div class="overlay__content">
                                <span class="spinner loadImg"></span>
                            </div>
                        </div>
                        <table class="table table-borderless">                           
                            <tbody>
                                <ng-container
                                    *ngIf="subscriberlistData && subscriberlistData.length > 0; else SNotFound">
                                    <tr class="cursor-pointer" *ngFor="let event of subscriberlistData" (click)="openSubscriberDetail(event?.person_id)">
                                        <td>
                                            <div class="user-profile">
                                                <div class="widget-content-left">
                                                    <ng-container
                                                        *ngIf="event?.profile_image_url != null; else SnoImage">
                                                        <img alt="" [src]="event?.profile_image_url">
                                                    </ng-container>
                                                    <ng-template #SnoImage>
                                                        <img src="../../../assets/images/noImage.png" alt="">
                                                    </ng-template>
                                                </div>
                                                <div class="widget-heading" [title]="event?.full_name">
                                                    {{ event.full_name }}</div>
                                            </div>
                                        </td>
                                        <td class="price">{{currencyName=='USD' ? '$' : '£'}} {{ event.fundraiser_amount }}
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #SNotFound>
                                    <tr>
                                        <td colspan="2" class="notFound text-center">No data found</td>
                                    </tr>
                                </ng-template>
                            </tbody>

                        </table>
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
</div>

<div class="shape-bg">
    <div class="c-shape-bg is--full">
    </div>
</div>


<div class="modal above fade" id="detailedDashboardYear" role="dialog">
    <div class="modal-dialog modal-dialog-centered custom-dialog-modal modal-md" role="document">
        <div class="modal-content m-2">
            <div class="modal-header px-4">
                <h6 class="modal-title">Choose the year</h6>
                <button #modalCloseBtnYear type="button" data-dismiss="modal" class="close"><span
                        aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <ul class="yearPicker">
                    <ng-container *ngFor="let year of filterYear">
                        <li [ngClass]="(filterData?.type === 'monthly' && filterData?.year === year) ? 'active' : ''"
                            (click)="filter('monthly', year)">
                            {{ year }}
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="modal above fade" id="detailedDashboardMonth" role="dialog">
    <div class="modal-dialog modal-dialog-centered custom-dialog-modal modal-md" role="document">
        <div class="modal-content m-2">
            <div class="modal-header px-4">
                <h6 class="modal-title">Choose the month</h6>
                <button #modalCloseBtnMonth type="button" data-dismiss="modal" class="close"><span
                        aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="monthPicker" *ngFor="let year of filterYear">
                    <span class="my-1">{{ year }}</span>
                    <ul class="year mx-0 my-1 pl-4 pr-4 pb-4 pt-2">
                        <ng-container *ngFor="let month of filterMonth; let i = index">
                            <ng-container *ngIf="currentYear === year && currentMonth < i; else months">
                                <li class="disabled">
                                    {{ month }}
                                </li>
                            </ng-container>

                            <ng-template #months>
                                <li [ngClass]="(filterData?.type === 'daily' && filterData?.year === year && filterData?.month === month) ? 'active' : ''"
                                    (click)="filter('daily', year, month)">
                                    {{ month }}
                                </li>
                            </ng-template>

                        </ng-container>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>