<footer class="site-footer">
  <div class="footer-inner bg-white">
    <div class="row">
      <div class="col-sm-6">
        Copyright &copy; 2019 goodUnited
      </div>
      <div class="col-sm-6 text-right">
        Designed by <a href="javascript:void(0)">Tatrasdata</a>
      </div>
    </div>
  </div>
</footer>
