import { Component, OnInit, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { Subscription } from 'rxjs';
import { NgoSharedService } from '../../services/ngo-shared.service';
import { SubscriptionGraphService } from '../../../services/subscriptiongraph.service';
import { Filter, SubscriptionGraph, ChartColor } from 'src/app/models/subscription';
import { finalize } from 'rxjs/operators';
import { FilterSharedService } from '../../services/filter-shared.service';
import { ChartsService } from 'src/app/services/charts.service';
import { TooltipPosition } from '@angular/material/tooltip';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-subscription-graph',
  templateUrl: './subscription-graph.component.html',
  styleUrls: ['./subscription-graph.component.scss']
})
export class SubscriptionGraphComponent implements OnInit, OnDestroy {
  public chart;
  public chartColor = ChartColor;
  public chartData: any[] = [];

  public charityId: string;

  public charityIDSubscription: Subscription;
  public subscriptionGraphData: SubscriptionGraph;

  public filterData: Filter;
  public filterDataSubscription: Subscription;
  public allTime: any;

  public subscriptionLoader   = true;

  public datasetFlag = {
    Direct: true,
    Events: true,
    Challenges: true,
  };

  public datasets = {
    Direct: null,
    Events: null,
    Challenges: null,
  };

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionLeft = new UntypedFormControl(this.positionOptions[4]);
  positionTop = new UntypedFormControl(this.positionOptions[2]);
  positionBottom = new UntypedFormControl(this.positionOptions[3]);

  constructor(
    private _ngoSharedService: NgoSharedService,
    private _filterSharedService: FilterSharedService,
    private _chartService: ChartsService,
    private _subscriptionGraphService: SubscriptionGraphService
  ) { }

  ngOnInit() {
    this.getCharityId();
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.charityId = selectedNGO.charityId;
              if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
              this.getDateFilter();
            }
          }
        );
  }

  getDateFilter() {
    this.filterDataSubscription = this._filterSharedService
        .dateFilters$
        .subscribe(
          res => {
            this.filterData = res;
            this.allTime = res.type;
            if (this.chart) this.chart.destroy();
            this.getSubscriptionGraphData();
          }
        );
  }

  getSubscriptionGraphData() {
    this.subscriptionLoader = true;
    this._subscriptionGraphService
        .getSubscriptionGraph(this.charityId, this.filterData)
        .pipe(finalize(() => {
          this.subscriptionLoader = false;
          setTimeout(() => this.createChart(), 100);
        }))
        .subscribe(
          (res: any) => {
            this.subscriptionGraphData = res;
          }
        );
  }

  createChart() {
    this.getDataSets();

    const labels = this.getLabels();

    if (this.chartData.length < 1) return;

    this.chart = new Chart('subscription', {
      type: 'bar',
      data: {
        labels,
        datasets: this.chartData
      },
      options: this._chartService.subscriptionGraphOptions
    });
  }

  getDataSets() {
    this.chartData = [];
    if (!this.subscriptionGraphData || !this.subscriptionGraphData.hasOwnProperty('data')) return;

    this.subscriptionGraphData.data.forEach((val, i) => {
      const data = {
        label: val.title,
        data: val.value,
        backgroundColor: this.chartColor[val.title],
        borderColor: 'rgba(255, 255, 255, 0.9)',
        fill: true,
        barThickness: 6,
      };

      this.chartData.push(data);
    });
  }

  getLabels() {
    if (!this.subscriptionGraphData || !this.subscriptionGraphData.hasOwnProperty('label')) return [];

    const labels = [];
    this.subscriptionGraphData.label.forEach(val => {
      const label = val;
      labels.push(label);
    });

    return labels;
  }

  getTotalValue(title: string) {
    if (!(this.subscriptionGraphData && this.subscriptionGraphData.hasOwnProperty('data'))) return;

    let sum = 0;
    const data: any = this.subscriptionGraphData.data.filter(val => val.title === title);
    if (data.length < 1) return;

    data[0].value.forEach(val => {
      if (!isNaN(val)) sum += parseInt(val, 10);
    });
    return sum > 0 ? ' ' + sum.toLocaleString() : '0';

  }

  removeDataset(label: string) {
    this.datasetFlag[label] = false;
    this.datasets[label] = this.chart.data.datasets.filter((dataset) => (dataset.label === label))[0];
    this.chart.data.datasets = this.chart.data.datasets.filter((dataset) => (dataset.label !== label));
    this.chart.update();
  }

  addDataset(label: string) {
    this.datasetFlag[label] = true;
    this.chart.data.datasets.push(this.datasets[label]);
    this.chart.update();
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
  }

}
