import { Component, OnInit , ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-gudatepicker',
  templateUrl: './gudatepicker.component.html',
  styleUrls: ['./gudatepicker.component.scss']
})
export class GudatepickerComponent implements OnInit, AfterViewInit {
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  
  selected: {start: Date, end: Date};
  alwaysShowCalendars: boolean;

  @ViewChild('inputToFocus') inputElement: ElementRef;

  constructor(public dialogRef: MatDialogRef<GudatepickerComponent>) { this.alwaysShowCalendars = true; }

  ngOnInit() {
  }

  ngAfterViewInit(){
    setTimeout(() => this.openDatepicker(), 0);
  }
  
  openDatepicker() {
    this.pickerDirective.open();
  }

  onClose() {
    this.dialogRef.close({});
  }

}
