import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { IntegrationsComponent } from './integrations/integrations.component'
import { MapAttributesComponent } from './map-attributes/map-attributes.component';
import { CardintegrationsComponent } from './cardintegrations/cardintegrations.component';
import { BlackbaudComponent } from './blackbaud/blackbaud.component';
import { UserRoles } from './shared/global/role';
import { RoleGuardService } from './services/role-guard.service';
import { SentenceClassifierComponent } from './sentence-classifier/sentence-classifier.component';
import { SyncSettingsComponent } from './sync-settings/sync-settings.component';
import { FacebookLoginComponent } from './facebook-login/facebook-login.component';
import { FbCallbackComponent } from './fb-callback/fb-callback.component';
import { RouteRedirectGuard } from './shared/guard/route-redirect.guard';
import { FacebookErrorComponent } from './facebook-error/facebook-error.component';

const routes: Routes = [
  {
    path: 'login',
    component: FacebookLoginComponent
  },
  {
    path: 'fb-error',
    component: FacebookErrorComponent
  },
  { path: 'activation-message/category',
    redirectTo: 'activation-message/category',
    pathMatch: 'full',
    data: { roles: UserRoles.SUPER_ADMIN } },
  {
    path: 'integrations',
    component: IntegrationsComponent,
    canActivate: [RouteRedirectGuard],
    children: [
      {
        path: '',
        component: CardintegrationsComponent
      },
      {
        path: 'blackbaud',
        component: BlackbaudComponent,
      },
      {
        path: 'map-attributes',
        component: MapAttributesComponent
      },
      {
        path: 'sync-settings',
        component: SyncSettingsComponent
      }     
    ]
  },
  { path: 'tactics',
    redirectTo: 'activation-message/tactics',
    pathMatch: 'full',
    data: { roles: UserRoles.SUPER_ADMIN } },
  { path: 'test',
    redirectTo: 'activation-message/current-test',
    pathMatch: 'full',
    data: { roles: UserRoles.SUPER_ADMIN } },
  { path: 'message-overall',
    redirectTo: 'activation-message/messages',
    pathMatch: 'full',
    data: { roles: UserRoles.SUPER_ADMIN } },
  {
    path: 'activation-message',
    loadChildren: () => import('./activation-message/activation-message.module').then(m =>
    m.ActivationMessageModule),
    canActivate: [RoleGuardService, RouteRedirectGuard],
    data: {roles: UserRoles.SUPER_ADMIN}
  },
  {
    path: 'sentence-level-classifier',
    component: SentenceClassifierComponent,
    // canActivate: [RoleGuardService, RouteRedirectGuard],
    // data: {roles: UserRoles.SUPER_ADMIN}
  },
  {
    path: 'fb-callback',
    component: FbCallbackComponent,
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'subscribers',
    loadChildren: () => import('./subscribers/subscribers.module').then(m => m.SubscribersModule),
    canActivate: [RouteRedirectGuard],
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
  },
  {
    path: 'newreports',
    // redirectTo: 'oldreports',
    // pathMatch: 'full',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [RoleGuardService, RouteRedirectGuard],
    // data: {roles: UserRoles.SUPER_ADMIN}
    data:  {roles: [UserRoles.SUPER_ADMIN, UserRoles.NGO_ADMIN]}
  },
  {
    path: 'reports',
    loadChildren: () => import('./oldreports/reports.module').then(m => m.OldReportsModule),
    canActivate: [RouteRedirectGuard],
    //data: {roles: UserRoles.SUPER_ADMIN}
  },
  {
    path: 'organizations',
    loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule),
    canActivate: [RoleGuardService, RouteRedirectGuard],
    data: {roles: UserRoles.SUPER_ADMIN}
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [RoleGuardService, RouteRedirectGuard],
    data: {roles: UserRoles.SUPER_ADMIN}
  },
  // {
  //   path: 'optimization',
  //   loadChildren: () => import('./optimization/optimization.module').then(m => m.OptimizationModule),
  //   canActivate: [RoleGuardService, RouteRedirectGuard],
  //   data: {roles: UserRoles.SUPER_ADMIN}
  // },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
