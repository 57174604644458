<div class="asanaConfirmation">   
    <!-- <div class="title">Edit Asana Status</div> -->
    <mat-dialog-content>
        <div class="closeBtn">
            <button class="btn-dialog-close pull-right" mat-stroked-button (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>  
        <div class="md-dialog-content text-center">            
            <div>
                <ng-container *ngIf="tacticStatus === true">                    
                    Do you really want to disable this tactic? It will disable its usage in any test.
                </ng-container>
                <ng-container *ngIf="tacticStatus === false">
                    Do you really want to enable this tactic? It will enable its usage in any test.
                </ng-container>
                <div class="button-wrapper">
                    <button mat-raised-button class="btn btn-info" type="button" (click)="editTacStatus()">OK</button>&nbsp;&nbsp;
                    <button mat-raised-button class="btn-outline-custom back-button" type="button" (click)="onClose(false)">Cancel</button>
                </div>
            </div> 
        </div>        
    </mat-dialog-content>  
</div>