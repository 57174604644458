import { Component, OnInit } from '@angular/core';
import * as momentJs from 'moment';

@Component({
  selector: 'app-date-month-filter',
  templateUrl: './date-month-filter.component.html',
  styleUrls: ['./date-month-filter.component.scss']
})
export class DateMonthFilterComponent implements OnInit {
  public currentYear  = new Date().getFullYear();
  public currentMonth = momentJs().month();

  public filterYear   = Array.from({length: this.currentYear - 2017}, (v, k) => this.currentYear - k);
  public filterMonth  = momentJs.monthsShort();

  constructor() { }

  ngOnInit() {
  }

  filter(type: string, year: number, month = null) {

  }

}
