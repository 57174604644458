import { Component, OnInit, OnDestroy, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgoSharedService } from '../../services/ngo-shared.service';
import { Subscription, Observable } from 'rxjs';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { CommonService } from 'src/app/services/common.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { map } from 'rxjs/operators';
import { UserSharedService } from '../../services/user-shared.service';

@Component({
  selector: 'app-admin-side-menu',
  templateUrl: './admin-side-menu.component.html',
  styleUrls: ['./admin-side-menu.component.scss']
})
export class AdminSideMenuComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;
  public url: string;
  public charityIDSubscription: Subscription;
  public ngoList$: Observable<SelectedNgo[]>;
  public filteredNgoList$: Observable<SelectedNgo[]>;
  public selectedNgo: SelectedNgo;

  status: any;
  public user: any;
  public roles: any;
  public userRole: any;
  public userSubscription: Subscription;

  @Output() ngoChanged = new EventEmitter<any>();

  constructor(
    private router: Router,
    private _ngoSharedService: NgoSharedService,
    private _commonService: CommonService,
    private _sidenavservice: SidenavService,
    private _userSharedService: UserSharedService,
  ) { }

  ngOnInit() {
    this.url = this.router.url;
    this.getCharityId();
    this.getNgoList();
    this._sidenavservice.currentMessage.subscribe(status => this.status = status);    
  }

  getNgoList(): void {
    this.filteredNgoList$ = this.ngoList$ = this._ngoSharedService.ngoLists;
  }

  getUserinfo() {
    this.userSubscription = this._userSharedService.userData
        .subscribe(
          res => {
            if (Object.keys(res).length > 0) {
               this.user = res;
               this.roles = this.user.roles;
               this.userRole = this.roles[0].role;
            }
          }
        );
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNgo => {
            if (selectedNgo.charityId !== null) 
            this.getUserinfo();
            this.selectedNgo = selectedNgo;
          }
        );
  }

  setPage(ngo: SelectedNgo) {
    this.searchInput.nativeElement.value = '';
    this.getNgoList();

    this._commonService
        .setPage(ngo.charityId)
        .subscribe(
          res => {
            this._ngoSharedService.changeSelectedNgo(ngo);
            this.refreshDashboard(ngo);
          }
        );
  }

  refreshDashboard(selectedNgo) { this.ngoChanged.emit(selectedNgo); }

  filter(event) {
    const searchText = event.target.value.toLocaleLowerCase();
    this.filteredNgoList$ = this.ngoList$.pipe(map(ngo => {
      return ngo.filter(val => val.name.toLocaleLowerCase().includes(searchText));
    }));
  }

  redirect(path: string) {
    this.router.navigate([path]);
  }

  closeNav() {    
    this.status = false;
  }

  ngOnDestroy() {
    this.charityIDSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }

}
