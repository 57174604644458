import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss']
})
export class MessageDetailComponent implements OnInit {
  @Input() messageDetails: any;
  // public less = true;
  // public more = false;

  constructor() { }

  ngOnInit() {
  }

  // showMore() {
  //   this.less = false;
  //   this.more = true;
  // }

  // showLess() {
  //   this.less = true;
  //   this.more = false;
  // }

}
