import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventListComponent } from './event-list/event-list.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../shared/module/shared.module';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { EventSeriesComponent } from './event-series/event-series.component';
import { NewEventsComponent } from './new-events/new-events.component';
import { FileUploadModule } from 'primeng/fileupload';
import { EditEventComponent } from './edit-event/edit-event.component';
import { RoleGuardService } from '../services/role-guard.service';
import { UserRoles } from '../shared/global/role';
import { RouteRedirectGuard } from '../shared/guard/route-redirect.guard';

const routes: Routes = [
  {
    path: '',
    component: EventListComponent,
    canActivate: [RouteRedirectGuard],
  },
  {
    path: 'detail/:event_id',
    component: EventDetailComponent,
    canActivate: [RouteRedirectGuard],
  },
  {
    path: 'series/:event_series_id',
    component: EventSeriesComponent,
    canActivate: [RouteRedirectGuard],
  },
  {
    path: 'new',
    component: NewEventsComponent,
    canActivate: [RoleGuardService, RouteRedirectGuard],
    data: {roles: UserRoles.SUPER_ADMIN, redirect: '/events'}
  },
  {
    path: 'edit',
    component: EditEventComponent,
    canActivate: [RoleGuardService, RouteRedirectGuard],
    data: {roles: UserRoles.SUPER_ADMIN, redirect: '/events'}
  }
];

@NgModule({
  declarations: [
    EventListComponent,
    EventDetailComponent,
    EventSeriesComponent,
    NewEventsComponent,
    EditEventComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FileUploadModule,
  ],
  exports: [
    RouterModule,
    FileUploadModule,
    EventListComponent,
    EventDetailComponent,
    EventSeriesComponent,
    NewEventsComponent,
    EditEventComponent,
  ]
})
export class EventsModule { }
