import { AddMessageComponent } from './../../add-message/add-message.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageComponent } from './message.component';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/module/shared.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';


const routes: Routes = [
  {
    path: '',
    component: MessageComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'add',
        component: AddMessageComponent
      },
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      }
    ]
  }
];
@NgModule({
  declarations: [
    MessageComponent,
    DashboardComponent,
    AddMessageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    AngularMultiSelectModule,
  ],
  exports: [
    RouterModule,
    SharedModule,
    MessageComponent,
    DashboardComponent,
    AddMessageComponent,
  ],
})
export class MessageModule { }
