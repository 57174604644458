import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportListComponent } from './report-list/report-list.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/module/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SubReportLengthPipe } from '../shared/pipes/sub-report-length.pipe';
import { ConfirmDialogueComponent } from './confirm-dialogue/confirm-dialogue.component';
import { ReportBuilderComponent } from './report-builder/report-builder.component';
import { NewCondtionComponent } from './new-condtion/new-condtion.component';
import { GetColumnNamePipe } from './get-column-name.pipe';
import { FilterColumnByNamePipe } from './filter-column-by-name.pipe';
import { GetConditionNamePipe } from './get-condition-name.pipe';
import { IsValueRequiredPipe } from './is-value-required.pipe';
import { GetValueFormatPipe } from './get-value-format.pipe';
import { GetReportColumnKeyPipe } from './get-report-column-key.pipe';

const routes: Routes = [
  {
    path: '',
    component: ReportListComponent,
  },
  {
    path: 'report-builder',
    component: ReportBuilderComponent
  }
];

@NgModule({
  declarations: [
    ReportListComponent,
    ConfirmDialogueComponent,
    SubReportLengthPipe,
    ReportBuilderComponent,
    NewCondtionComponent,
    GetColumnNamePipe,
    FilterColumnByNamePipe,
    GetConditionNamePipe,
    IsValueRequiredPipe,
    GetValueFormatPipe,
    GetReportColumnKeyPipe
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    RouterModule,
    ReportListComponent, 
  ]
})
export class ReportsModule { }
