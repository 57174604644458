<div class="continer-fluid">
  <div class="new-dashboard-wrapper">

    <!-- app header -->
    <app-new-header></app-new-header>
    <!-- Side Menu -->
    <app-side-menu (ngoChanged)="ngoChanged($event)"></app-side-menu>

    <div class="grid-wrapper">
      <div class="row no-gutters">
        <div class="col-12">

          <div class="heading_wrapper">
            <div class="page-title"> Sentence Classifier</div> 
          </div>

          <div class="content-box">
            <div class="overlay__inner custom-overlay" *ngIf="loading">
              <div class="overlay__content">
                <span class="spinner loadImg"></span>
              </div>
            </div>
                    
              <div class="row no-gutters mb-3">
                <div class="col-8">
                  <h4 class="box-title">Fundraiser Questions that require response</h4>
                </div>
                <div class="col-4">
                  <ng-container *ngIf="sentences?.length > 0">
                    <div class="form-wrapper">
                    <select class="form-control" (change)="changeDropDownValue($event.target.value)">                       
                      <option *ngFor="let type of sentenceType;" value="{{type}}" [selected]="type==='Question'">{{type}}</option>
                    </select>
                   </div>
                  </ng-container>                      
                </div>
              </div>
           

            <div class="table-responsive">
              <div class="reporting-outer">
                <div class="reporting-inner">
                  
                    <table class="table table-borderless">                      
                      <tbody>
                        <ng-container *ngIf="sentences?.length>0; else NotFound">
                          <tr *ngFor="let sentence of sentences | paginate: config; let i = index">
                            <td class="sno">{{i+1}}</td>
                            <td class="sentence">
                              <div (click)="displayContentDetail(sentence)">{{sentence?.ques}}</div>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-template #NotFound>
                          <tr>
                            <td colspan="2" style="text-align: center;">No data found</td>
                          </tr>
                        </ng-template>                      
                      </tbody>
                    </table>
                 
                </div>
              </div>
            </div>

          </div>
          <div class="newGuPagination" *ngIf="sentences?.length>0">
            <pagination-controls [directionLinks]="false" (pageChange)="pageChanged($event)">
            </pagination-controls>
            <span class="countNumber">{{currentPageCount}}/{{totalRecords}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="shape-bg">
  <div class="c-shape-bg is--full rt-25">
  </div>
</div>

