import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { SubscriberListService } from 'src/app/services/subscriber.service';
import { NgoSharedService } from 'src/app/shared/services/ngo-shared.service';
import { Chart } from 'chart.js';
import { ChartsService } from 'src/app/services/charts.service';


@Component({
  selector: 'app-subscriber-detail',
  templateUrl: './subscriber-detail.component.html',
  styleUrls: ['./subscriber-detail.component.scss']
})
export class SubscriberDetailComponent implements OnInit, OnDestroy {

  public selectedNgo: SelectedNgo;
  public charityId: string;
  public subscribeData: Subscription;
  public personId: any;
  public charityIDSubscription: Subscription;
  public DoChartColor:any[] = ['#8095A2','#002C45','#A8A8A8','#C8C8C8','#D3D3D3','#787878','#DCDCDC','#E0E0E0','#E8E8E8','#F8F8F8','#F5F5F5','#F0F0F0'];

  public alertMsg = { type: '', msg: ''};
  public loading = false;
  public subcriberDetailData = <any>[];
  public fundraiserEvents = [];
  public fundraiserDirect = [];
  public donationData = [];
  public fundraiserData = null;
  chart: any;
  chart1: any;

  public totalFundraiserEventPrice = 0;
  public totalFundraiserDirectPrice = 0;  
  public totalFundraiserPrice:any = 0;
  public totalDonationPrice:any = 0;
  public totalFundraiserData = [];
  public personalData:any;
  public replylink:string;
  public currentValue:any;
  public donationsLabel = [];
  public dontaionVal = [];
  public emptyFlag = false;
  public defaultFlag = true;
  public legendHtml = [];
  public currentPageNumber = sessionStorage.getItem('pageNo');
  public searchBrTxt = sessionStorage.getItem('searchKey');
  public orderByAsc = sessionStorage.getItem('AscVal');
  public orderByDsc = sessionStorage.getItem('DscVal');
  public totalCountFundraiserData:any = 0;
  public campaignCount:any = 0;
  public totalCampaignPrice = 0;
  public totalCampaignCount = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _ngoSharedService: NgoSharedService,
    private _subscriberListService: SubscriberListService,
    public _ChartService: ChartsService,
    private elementRef: ElementRef    
    ) { }

  ngOnInit() {
    this.getCharityId();  
    this.donationDonutChart();      
  }

  ngoChanged(ngo: SelectedNgo) {   
    this.router.navigate(['/subscribers']);
  }

  breadCrumbs(){
    this.router.navigate(['/subscribers'], { queryParams: { pageNo: this.currentPageNumber,  searchKey: this.searchBrTxt, orderByasc: this.orderByAsc, orderBydsc: this.orderByDsc,} });
  }

  getPersonId() {
    this.subscribeData = this.route.params.subscribe(params => {
      this.personId = +params.person_id;     
    });
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.selectedNgo = selectedNGO;
              this.charityId  = selectedNGO.charityId;
              this.getPersonId();
              this.getSubcriberDetails();              
            }
          },
          err => this.showAlertMsg(err.message, 'danger')
        );
  }

  getSubcriberDetails(){
    this.loading = true;
    this.fundraiserEvents = [];
    this.fundraiserDirect = [];
    this.fundraiserData = null;
    this.donationData = [];
    this.donationsLabel = [];
    this.dontaionVal = [];    

    this._subscriberListService.subcriberDetails(this.charityId, this.personId)
    .pipe(finalize(() => {
    this.loading = false;
    setTimeout(() =>{this.fundraiserDonutChart(); this.donationDonutChart();} , 100);    
    }))
    .subscribe(
      (res: any) => {
         this.subcriberDetailData = res.body;         
         this.fundraiserData = Object.keys(res.body.fundraisers).length;
         
         if (!this.subcriberDetailData) return;
         this.fundraiserEvents = this.subcriberDetailData.fundraisers.events;
         this.fundraiserDirect = this.subcriberDetailData.fundraisers.generic;         
         this.donationData = this.subcriberDetailData.donations;  
           
         this.personalData = this.subcriberDetailData.personal;
         this.replylink = this.subcriberDetailData.reply.url;
         
         if(this.fundraiserEvents.length > 0){
          for (let i = 0; i < this.fundraiserEvents.length; i++) {
            this.totalFundraiserEventPrice +=  this.fundraiserEvents[i].total_donation;  
          }
         }

         if(this.fundraiserEvents.length > 0){           
           for (let k = 0; k <this.fundraiserEvents.length; k++){
              this.totalCountFundraiserData = this.fundraiserEvents[k].campaigns.length; 
              this.campaignCount = this.campaignCount + this.totalCountFundraiserData;     
           }
         }

         this.totalCampaignCount = this.campaignCount + this.fundraiserDirect.length;
       
         if(this.fundraiserDirect.length > 0){
          for (let j = 0; j < this.fundraiserDirect.length; j++) {
             this.totalFundraiserDirectPrice += this.fundraiserDirect[j].total_donation;        
           }
         }          
         
          //this.totalFundraiserPrice = (this.totalFundraiserEventPrice + this.totalFundraiserDirectPrice); 
          if(this.donationData.length > 0){
            for (let k = 0; k < this.donationData.length; k++) {
               this.totalDonationPrice += this.donationData[k].donation_amount; 
               this.donationsLabel.push(this.donationData[k].name) 
               this.dontaionVal.push(this.donationData[k].donation_amount);      
             }    
           } 

           if(!this.fundraiserEvents.length && !this.fundraiserDirect.length && !this.donationData.length){
             this.emptyFlag = true;
             this.defaultFlag = false;
           }else{
             this.defaultFlag = true;
             this.emptyFlag = false;
           }

          this.totalCampaignPrice = this.gettotaldonation();
          this.totalFundraiserPrice = (this.totalCampaignPrice + this.totalFundraiserDirectPrice); 
                                      
      },
      err => this.showAlertMsg(err.message, 'danger')
    );
  }

  gettotaldonation(): number {
    let total = 0;

    this.fundraiserEvents.forEach(f => {
      f.campaigns.forEach(c => total += c.total_donation);
    });

    return total;
  }

  fundraiserDonutChart(){
    let htmlRef = this.elementRef.nativeElement.querySelector('#fundraiseDoughNut');
    if(htmlRef!=null)
    this.chart = new Chart(htmlRef, {
      type: 'doughnut',
      data: {        
        datasets: [
          { 
            data: [this.totalCampaignPrice,this.totalFundraiserDirectPrice],
            label: ['Challenges', 'Direct'],
            backgroundColor: ['#F8B630','#04C3CB'],
            fill: false
          },
        ]
      },
      options: {
        elements: { arc: { borderWidth: 0 } },       
        legend: {
          display: false
        },
        tooltips:{
          //enabled:false
          backgroundColor: 'rgba(0, 0, 0, 1)',
          callbacks: {

            title: (tooltipItems, data) => {
              let sum = 0;
              tooltipItems.forEach((tooltipItem) => {
                if (data.datasets[tooltipItem.datasetIndex]) {
                  sum += parseInt(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], 10);
                }
              });
              let currencySymbol = this.selectedNgo.currency_name && this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
              return sum > 0 ? 'Total Raised: ' + currencySymbol + this._ChartService.formatNumber(Math.round(sum)) : null;
            },

          
            label: (tooltipItem, data) => {   
              let label = data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index] || '';              
              let dataset = data.datasets[tooltipItem.datasetIndex]; 
              this.currentValue = dataset.data[tooltipItem.index];
              let currencySymbol = this.selectedNgo.currency_name && this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
              return label + ': ' + currencySymbol + this._ChartService.formatNumber(Math.round(this.currentValue));
            }
          },
          mode: "dataset"
        }
      }
    });

  }

  donationDonutChart(){
    let htmlRef1 = this.elementRef.nativeElement.querySelector('#donationDoughNut');
    if(htmlRef1!=null)
    this.chart1 = new Chart(htmlRef1, {
      type: 'doughnut',
      data: {
       
        datasets: [
          { 
            data: this.dontaionVal,
            label: this.donationsLabel,
            backgroundColor: this.DoChartColor,
            fill: false
          },
        ]
      },
      options: {
        elements: { arc: { borderWidth: 0 } }, 
        legend: {
          display: false
        },
        tooltips:{
          // enabled:false
          backgroundColor: 'rgba(0, 0, 0, 1)',
          callbacks: {

            title: (tooltipItem, data) => {
              let sum = 0;
              tooltipItem.forEach((tooltipItem) => {
                if (data.datasets[tooltipItem.datasetIndex]) {
                  sum += parseInt(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], 10);
                }
              });
              let currencySymbol = this.selectedNgo.currency_name && this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
              return sum > 0 ? 'Total Raised: ' + currencySymbol + this._ChartService.formatNumber(sum) : null;
            },

            label: (tooltipItem, data) => {         
              let dataset = data.datasets[tooltipItem.datasetIndex];                     
              let label = data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index] || '';                    
              let currentValue = dataset.data[tooltipItem.index];
              let currencySymbol = this.selectedNgo.currency_name && this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
              return label + ': ' + currencySymbol + this._ChartService.formatNumber(currentValue);
            }
          },
          mode: "dataset"
        }
      }
    });
  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg   = msg;
    this.alertMsg.type  = type;
    this.loading = false;
  }

  openCampaignLink(eid){
    window.open('https://www.facebook.com/donate/' + eid, "_blank");
  }

  openDirectCampaignLink(eid){
    window.open('https://www.facebook.com/donate/' + eid, "_blank");
  }
  
  ngOnDestroy() {    
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.subscribeData) this.subscribeData.unsubscribe(); 
    if (!this.router.routerState.snapshot.url.match('subscribers')) {
      sessionStorage.removeItem('pageNo'); 
      sessionStorage.removeItem('searchKey');
      sessionStorage.removeItem('AscVal');
      sessionStorage.removeItem('DscVal');
    }  
       
  }

}
