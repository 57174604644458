export interface EventdetailData {
    detailed_data: Array<string>;
    data: GraphData[];
    label: Array<string>;
    overall: Overall;
}

export interface Overall {
    average_fundraiser: number;
    start_date: string;
    end_date: string;
    event_goal: string;
    event_series_id: number;
    event_series_name: string;
    header_image_url: string;
    opt_in_url: string;
    total_activity: number;
    total_participants: number;
    total_raised: number;
    fb_group_url: string;
    event_name: string;
}

export interface GraphData {
    title: string;
    type: string;
    value: Array<number>;
}

export interface ISelectedTab {
    marketing: number;
    revenue: number;
}

export const ChartColor = {
    'Total Campaigns': '#002C45',
    'Total Donations': '#002C45',
    'Total Raised': '#F8B630',
    'USD': '#04C3CB',
};

export const ChartLegends = {
    'Total Campaigns': 'Fundraisers',
    'Total Donations': 'Donations',
    'Total Raised': 'Raised',
    'USD': 'USD',
};