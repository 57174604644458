import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { NgoSharedService } from '../shared/services/ngo-shared.service';
import { Categories } from '../models/Categories.model';
import { TestCohortService } from '../services/test-cohort.service';
import { TestCohort } from '../models/Test';
import { Subscription } from 'rxjs';
import { AddMessageComponent } from '../add-message/add-message.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectedNgo } from '../models/SelectedNgo.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, OnDestroy {
  public selectedNgo: SelectedNgo;
  public testCohortForm: UntypedFormGroup;
  public testCohorts: UntypedFormArray;
  public categoryLists;
  public msgCategoryLists: Array<Categories[]>;
  public activeTestList: Array<TestCohort>;
  public alertMsg = { type: '', msg: ''};
  public showLoader = false;
  public charityId: string;
  public messageDetailList = [];
  public newMessageDetailLists = [];
  public charityIDSubscription: Subscription;

  private donorCategories: Array<any>;

  constructor(
    private fb: UntypedFormBuilder,
    private _testCohortService: TestCohortService,
    private _ngoSharedService: NgoSharedService,
    public dialog: MatDialog,
    private router:Router,
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.getCharityId();

    this.testCohorts = this.testCohortForm.get('testCohorts') as UntypedFormArray;
  }

  receiveMessage($event) {
    if ($event) this.resetValues();
    else this.showLoader = false;
  }

  ngoChanged($event) {
    if ($event) this.resetValues();
    else this.showLoader = false;
  }

  initializeForm(): void {
    this.testCohortForm = this.fb.group({testCohorts: this.fb.array([])});
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.showLoader = true;
              this.charityId = selectedNGO.charityId;

              this.messageList();
            } else this.showLoader = false;
          },
          err => this.showAlertMsg('Charity ID Not Found', 'danger')
        );
  }

  createMessagePage(){
    const dialogRef = this.dialog.open(AddMessageComponent,{
      width: '410px',     
    });
  }

  messageList() {
    this._testCohortService
        .messageDetail(this.charityId)
        .subscribe(
          msgList => {
            if (msgList.status === 200) {
              this.messageDetailList = msgList.message;
              this.msgCategoryList();
            } else this.showLoader = false;
          },
          err => this.showAlertMsg(err.message, 'danger')
        );
  }

  msgCategoryList() {
    this._testCohortService
        .messageListByCategory(this.charityId)
        .subscribe(
          res => {
            if (res.status === 200) {
              this.msgCategoryLists = res.message;
              this.categoryList();
            } else this.showLoader = false;
          },
          err => this.showAlertMsg(err.message, 'danger')
        );
  }

  categoryList() {
    this._testCohortService
        .categoryList(this.charityId)
        .subscribe(
          res => {
            this.categoryLists = res.category_list;
            this.donorCategories = this.categoryLists.filter(cat => cat.category_type === 'donor');
            this.activeTest();
          },
          err => this.showAlertMsg(err.message, 'danger')
        );
  }

  activeTest() {
    this._testCohortService
        .activeTest(this.charityId)
        .subscribe(
          res => {
            if (res.status === 200) {
              this.activeTestList = res.active_test;
              if (this.activeTestList.length > 0) this.populateCohorts();
              else this.addTestCohortButtonClick();
            }

            this.showLoader = false;
          },
          err => this.showAlertMsg(err.message, 'danger')
        );
  }

  populateCohorts() {
    this.activeTestList.forEach((val, i) => {
      let data = {};
      this.categoryLists.forEach(cat => {
        let target;
        if (cat.category_type === 'owner') target = { [cat.id]: [null] };
        if (cat.category_type === 'donor') target = { [cat.id]: [null] };

        if (val.hasOwnProperty(cat.id) && cat.category_type === 'owner')
          target = { [cat.id]: [val[cat.id].message_id] };
        if (val.hasOwnProperty(cat.id) && cat.category_type === 'donor')
          target = { [cat.id]: [val[cat.id].message_id] };

        data = Object.assign(target, data);
      });

      this.testCohorts.push(this.fb.group(data));
      this.populateMessageDetail(i, val);
    });
  }

  populateMessageDetail(index: number, cohort: TestCohort) {
    this.categoryLists.forEach(cat => {
      let target = {};
      if (cohort.hasOwnProperty(cat.id))
        target = {[cat.id]: this.filterMessage(cohort[cat.id].message_id)};

      this.newMessageDetailLists[index] = Object.assign(target, this.newMessageDetailLists[index]);
    });
  }

  filterMessage(msgId: number) {
    let msgDetail;
    if (this.messageDetailList.length > 0)
      msgDetail = this.messageDetailList.filter((val) => val.message_id === msgId);
    else return null;

    if (msgDetail.length > 0) return msgDetail[0];
    else return null;
  }

  newGetMessageDetail(msgId, index: number, catId) {
    msgId = typeof(msgId) === 'string' ? parseInt(msgId.split(' ')[1], 10) : msgId;

    if (typeof(this.newMessageDetailLists[index]) === 'undefined')
      this.newMessageDetailLists.push({[catId]: this.filterMessage(msgId)});
    else this.newMessageDetailLists[index][catId] = this.filterMessage(msgId);
  }

  addTestCohortFormGroup(): UntypedFormGroup {
    let formFields = {};
    this.categoryLists.forEach(cat => {
      let target;
      if (cat.category_type === 'owner') target = {[cat.id]: [null]};
      if (cat.category_type === 'donor') target = {[cat.id]: [null]};

      formFields = Object.assign(target, formFields);
    });
    return this.fb.group(formFields);
  }

  addTestCohortButtonClick(): void {
    this.newMessageDetailLists.push({});
    (this.testCohortForm.get('testCohorts') as UntypedFormArray).push(this.addTestCohortFormGroup());
  }

  removeTestCohortButtonClick(index: number): void {
    (this.testCohortForm.get('testCohorts') as UntypedFormArray).removeAt(index);
    this.newMessageDetailLists.splice(index, 1);
  }

  getErrorFormGroup(
    index: number,
    control: number,
    errorType: string
  ): boolean {
    const formGroup: UntypedFormGroup = this.testCohorts.controls[index] as UntypedFormGroup;
    if (
      (formGroup.controls[control].dirty || formGroup.controls[control].touched) &&
      formGroup.controls[control].hasError(errorType)
    ) return true;
    else return false;
  }

  createTest(): void {
    this.showLoader = true;
    this.checkDonorCategoryValidation();
    this.testCohortForm.markAllAsTouched();

    //if (!this.testCohortForm.valid) return this.showAlertMsg('Please select the message in all the categories', 'danger');

    if (this.checkDuplicateCohorts()) return this.showAlertMsg('This cohort is already created in the same test.', 'danger');

    const formData = {cohorts: this.testCohortForm.value.testCohorts};

    this._testCohortService
        .updateTest(formData, this.charityId)
        .subscribe(
          res => this.showAlertMsg(res.message, 'success'),
          err => this.showAlertMsg(err.message, 'danger')
        );
  }

  checkDonorCategoryValidation() {
    if (this.donorCategories.length <= 0) return true;

    const formData  = this.testCohortForm.value.testCohorts;
    if (formData.length <= 1) return;

    this.donorCategories.forEach(cat => {
      let applyValidation = false;

      formData.forEach((val, i: number) => {
        if (formData[i][cat.id] !== null) applyValidation = true;
      });

      if (applyValidation) {
        const controls = (this.testCohortForm.get('testCohorts') as UntypedFormArray).controls;
        controls.forEach((formGroup: UntypedFormGroup) => {
          formGroup.controls[cat.id].setValidators([Validators.required]);
          formGroup.controls[cat.id].updateValueAndValidity();
        });
      } else {
        const controls = (this.testCohortForm.get('testCohorts') as UntypedFormArray).controls;
        controls.forEach((formGroup: UntypedFormGroup) => {
          formGroup.controls[cat.id].setValidators([]);
          formGroup.controls[cat.id].updateValueAndValidity();
        });
      }
    });
  }

  checkDuplicateCohorts(): boolean {
    const formData  = this.testCohortForm.value.testCohorts;
    let returnValue = true;

    if (formData.length > 1) {
      formData.forEach((val, i: number) => {
        if (formData.length > i + 1) {
          this.categoryLists.forEach(cat => {
            if (formData[i][cat.id] !== formData[i + 1][cat.id]) return returnValue = false;
          });
        }
      });
    } else returnValue = false;

    return returnValue;
  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg   = msg;
    this.alertMsg.type  = type;
    this.showLoader     = false;
  }

  ngOnDestroy() {
    this.charityIDSubscription.unsubscribe();
  }

  resetAndInitializeForm(msg: string, type: string) {
    this.showAlertMsg(msg, type);
    this.resetValues();
    this.charityIDSubscription.unsubscribe();
    this.getCharityId();
  }

  resetValues() {
    this.msgCategoryLists = null;
    this.newMessageDetailLists = [];
    this.activeTestList   = [];
    (this.testCohortForm.get('testCohorts') as UntypedFormArray).clear();
  }

}