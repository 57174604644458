import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IntegrationService } from '../services/integration.service';
import { NgoSharedService } from '../shared/services/ngo-shared.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-map-attributes',
  templateUrl: './map-attributes.component.html',
  styleUrls: ['./map-attributes.component.scss']
})
export class MapAttributesComponent implements OnInit, OnDestroy {

  constructor(
    private integrationService: IntegrationService,
    private formbuilder: UntypedFormBuilder,
    private _ngoSharedService: NgoSharedService,
    private router: Router
  ) {}

  get f() { return this.fundraiserForm.controls; }
  isConnected = true;
  isMappingatrributes = false;
  submitted = false;
  viewMode = 'tab1';
  public data_type = 'fundraiser';
  loading = false;
  DisplayMsg = false;
  team_raiser_attributes = [];
  public temp_team_raiser_attributes = [];
  public temp_team_raiser_attributes_temp: any;
  good_united_attributes = [];
  filter_team_raiser_attributes = [];
  fundraiserForm: UntypedFormGroup;
  donorForm: UntypedFormGroup;
  charityId: string;
  ngoID: any;
  connection: any;
  mappedData = [];
  donorMappedData = [];
  fundraiserMappedData = [];
  errorMsg: any;
  successMsg: string;
  sucessMessage = false;
  errorMessage = false;
  synMsg: string;
  is_Syn_start = true;
  checkStatus = true;
  interval: any;
  public alertMsg = { msg: '', type: ''};
  dupErrMessage: string;
  public setTimeOutForDup: any;
  public setTimeOutForErr: any;
  public setTimeOutForSuccess: any;
  public charityIdSubscription: Subscription;

  public currKey;
  public selectedArr = [];

  public completeJobMsg = '';
  public failedJobMsg = '';

  ngOnInit() {
    this.loading = true;
    this.checkNgoConnection();
    this.fundraiserform();
    this.donorform();
  }

  receiveMessage($event) {
    if ($event) {
      this.getMapAttributes();
      this.fundraiserform();
      this.donorform();
      clearInterval(this.interval);
    }
  }

  checkNgoConnection() {
    this.charityIdSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          (res: any) => {
            if (res.charityId === null) return this.loading = false;

            this.charityId = res.charityId;
            if (this.charityId)  this.getBlackbaudConnection();
            clearInterval(this.interval);
            this.loading = false;
            localStorage.removeItem('map-attribute-async-job-failed');
          },
          err => this.loading = false
        );
  }

  getBlackbaudConnection() {
    this.integrationService
        .getBlackbaudConnection(this.charityId)
        .subscribe(
          (res: any) => {
            if (!res.connected)  this.router.navigate(['/integrations']);
            this.getMapAttributes();
            this.checkIsActiveSyncStatus();
            this.displayMappedData('tab1');
            this.getDonorData();
          },
          err => this.router.navigate(['/integrations'])
        );
  }

  getMapAttributes() {
    this.integrationService
        .getMappingAttributes('donor', this.charityId)
        .subscribe(
          (res: any) => {
            if (res.team_raiser_attributes.status === 200) {
              this.temp_team_raiser_attributes = res.team_raiser_attributes.response;
              this.temp_team_raiser_attributes.map(
                item => (item.isDisabled = false)
              );
              this.temp_team_raiser_attributes_temp = JSON.stringify(
                res.team_raiser_attributes.response
              );
            }
          }
        );
  }

  checkIsActiveSyncStatus() {
    this.integrationService
        .isActiveSyncStatus(this.charityId)
        .subscribe(
          (res: any) => {
            if (typeof res.data.sync_id  !== 'undefined') {
              if (res.data.complete_status === 'in_progress')
                this.jobInProgress(res.data.start_time);
              else if (res.data.complete_status === 'completed')
                this.jobComplete(res.data);
              else if (res.data.complete_status === 'Failed')
                this.jobFailed(res.data.complete_status, res.data.start_time);
            }
          },
          error => this.showAlertMsg(error.message, 'danger')
        );
  }

  getFundraiserData() {
    this.integrationService
        .getlistmapping('fundraiser', this.charityId)
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.fundraiserMappedData = res.mapped_data;
            }
          }
        );
  }

  getDonorData() {
    this.integrationService
        .getlistmapping('donor', this.charityId)
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.donorMappedData = res.mapped_data;
            }
          }
        );
  }

  displayMappedData(viewmode?) {
    console.log('displaymapp')
    this.integrationService
        .getlistmapping(this.data_type, this.charityId)
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              if (viewmode === 'tab1') this.fundraiserMappedData = res.mapped_data;
              else this.donorMappedData = res.mapped_data;

              Object.keys(this.donorForm.controls).forEach((key, index) => {
                if (this.donorMappedData[index] && this.donorMappedData[index].team_raiser_attribute)
                  this.donorForm.controls[key].setValue(this.donorMappedData[index].team_raiser_attribute);
                else
                  this.donorForm.controls[key].setValue('');
              });

              Object.keys(this.fundraiserForm.controls).forEach((key, ind) => {
                if (this.fundraiserMappedData[ind] && this.fundraiserMappedData[ind].team_raiser_attribute)
                  this.fundraiserForm.controls[key].setValue(this.fundraiserMappedData[ind].team_raiser_attribute);
                else
                  this.fundraiserForm.controls[key].setValue('');
              });
            }
          }
        );
  }

  changeViewMode(tabval) {
    this.viewMode = tabval;
    if (this.viewMode === 'tab1') {
      this.data_type = 'fundraiser';
      this.errorMessage = false;
      this.sucessMessage = false;
    } else {
      this.data_type = 'donor';
      this.errorMessage = false;
      this.sucessMessage = false;
    }
    //this.getMapAttributes();
    this.displayMappedData(this.viewMode);
  }

  // for fundraiser
  fundraiserform() {
    this.fundraiserForm = this.formbuilder.group({
      persona_type: [''],
      compaign_id: [''],
      ngo_id: [''],
      ngo_name: [''],
      fundraiser_title: [''],
      permalink: [''],
      first_name: [''],
      last_name: [''],
      full_name: [''],
      nps: [''],
      nps_feedback: [''],
      email: ['', Validators.required],
      opt_in: [''],
      total_raised: [''],
      donation: [''],
      start_date: [''],
      thank_you_delivered: [''],
      goal: [''],
      end_date: [''],
      is_postmessage2_posted: [''],
      percent_goal: [''],
      status: [''],
      fundraiser_type: ['']
    });
  }

  checkDuplicateInObject(propertyName, inputArray) {
    let seenDuplicate = false;
    const testObject = {};

    inputArray.map((item) => {
      const itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  updateFundraiser() {
    this.submitted = true;
    const body = {
      data_type: this.data_type,
      //charity_id: this.charityId,
      data: [
        {
          good_united_attribute: '1',
          team_raiser_attribute: this.fundraiserForm.controls.persona_type.value
        },
        {
          good_united_attribute: '2',
          team_raiser_attribute: this.fundraiserForm.controls.compaign_id.value
        },
        {
          good_united_attribute: '3',
          team_raiser_attribute: this.fundraiserForm.controls.ngo_id.value
        },
        {
          good_united_attribute: '4',
          team_raiser_attribute: this.fundraiserForm.controls.ngo_name.value
        },
        {
          good_united_attribute: '5',
          team_raiser_attribute: this.fundraiserForm.controls.fundraiser_title.value
        },
        {
          good_united_attribute: '6',
          team_raiser_attribute: this.fundraiserForm.controls.permalink.value
        },
        {
          good_united_attribute: '7',
          team_raiser_attribute: this.fundraiserForm.controls.first_name.value
        },
        {
          good_united_attribute: '8',
          team_raiser_attribute: this.fundraiserForm.controls.last_name.value
        },
        {
          good_united_attribute: '9',
          team_raiser_attribute: this.fundraiserForm.controls.full_name.value
        },
        {
          good_united_attribute: '10',
          team_raiser_attribute: this.fundraiserForm.controls.nps.value
        },
        {
          good_united_attribute: '11',
          team_raiser_attribute: this.fundraiserForm.controls.nps_feedback.value
        },
        {
          good_united_attribute: '12',
          team_raiser_attribute: this.fundraiserForm.controls.email.value
        },
        {
          good_united_attribute: '13',
          team_raiser_attribute: this.fundraiserForm.controls.opt_in.value
        },
        {
          good_united_attribute: '14',
          team_raiser_attribute: this.fundraiserForm.controls.total_raised.value
        },
        {
          good_united_attribute: '15',
          team_raiser_attribute: this.fundraiserForm.controls.donation.value
        },
        {
          good_united_attribute: '16',
          team_raiser_attribute: this.fundraiserForm.controls.start_date.value
        },
        {
          good_united_attribute: '17',
          team_raiser_attribute: this.fundraiserForm.controls.thank_you_delivered.value
        },
        {
          good_united_attribute: '18',
          team_raiser_attribute: this.fundraiserForm.controls.goal.value
        },
        {
          good_united_attribute: '19',
          team_raiser_attribute: this.fundraiserForm.controls.end_date.value
        },
        {
          good_united_attribute: '20',
          team_raiser_attribute: this.fundraiserForm.controls.is_postmessage2_posted.value
        },
        {
          good_united_attribute: '21',
          team_raiser_attribute: this.fundraiserForm.controls.percent_goal.value
        },
        {
          good_united_attribute: '22',
          team_raiser_attribute: this.fundraiserForm.controls.status.value
        },
        {
          good_united_attribute: '24',
          team_raiser_attribute: this.fundraiserForm.controls.fundraiser_type.value
        }
      ]
    };

    const myArray = [];
    body.data.filter(item => {
      if (item.team_raiser_attribute !== '') {
        myArray.push(item);
      }
    });

    const isDuplicate = this.checkDuplicateInObject('team_raiser_attribute', myArray);
    if (isDuplicate === true) {
      this.dupErrMessage = 'You have selected duplicate value.';
      this.setTimeOutForDup = setTimeout(() => { this.dupErrMessage = ''; }, 8000);
    } else {
      if (this.fundraiserForm.controls.email.value !== '') {
        this.integrationService.postMappingAttributes(body, this.charityId).subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.sucessMessage = true;
              this.successMsg = res.message;
              this.setTimeOutForSuccess = setTimeout(() => {
                this.successMsg = '';
                this.sucessMessage = false;
              }, 8000);
              this.getFundraiserData();
            }
          },
          err => {
            this.errorMessage = true;
            this.errorMsg = err.message;
            this.setTimeOutForErr = setTimeout(() => {
              this.errorMsg = '',
              this.errorMessage = false;
            }, 8000);
          }
        );
      } else if (this.fundraiserForm.controls.email.value === '') {
        this.errorMessage = true;
        this.errorMsg = 'Email is mandatory';
        this.setTimeOutForErr = setTimeout(() => {
            this.errorMsg = '',
            this.errorMessage = false;
        }, 8000);

      }
    }

  }

  closeDupAlert() {
    this.dupErrMessage = '';
    clearTimeout(this.setTimeOutForDup);
  }

  closeErrMsg() {
    this.errorMsg = '';
    this.errorMessage = false;
    clearTimeout(this.setTimeOutForErr);
  }

  closeSuccessMsg() {
    this.successMsg = '';
    this.sucessMessage = false;
    clearTimeout(this.setTimeOutForSuccess);
  }


  donorform() {
    this.donorForm = this.formbuilder.group({
      donation_amount: [''],
      payment_id: [''],
      source_name: [''],
      charge_date: [''],
      email: ['', Validators.required],
      attribution_source: [''],
      campaign_id: [''],
      fundraiser_title: [''],
      permalink: [''],
      charity_id: [''],
      first_name: [''],
      last_name: [''],
      opt_in: [''],
      persona_type: [''],
      fundraiser_type: ['']
    });
  }

  updateDonorForm() {
    const body = {
      data_type: this.data_type,
      //charity_id: this.charityId,
      data: [
        {
          good_united_attribute: '1',
          team_raiser_attribute: this.donorForm.controls.donation_amount
            .value
        },
        {
          good_united_attribute: '2',
          team_raiser_attribute: this.donorForm.controls.payment_id.value
        },
        {
          good_united_attribute: '3',
          team_raiser_attribute: this.donorForm.controls.source_name.value
        },
        {
          good_united_attribute: '4',
          team_raiser_attribute: this.donorForm.controls.charge_date.value
        },
        {
          good_united_attribute: '5',
          team_raiser_attribute: this.donorForm.controls.email.value
        },
        {
          good_united_attribute: '6',
          team_raiser_attribute: this.donorForm.controls.attribution_source
            .value
        },
        {
          good_united_attribute: '7',
          team_raiser_attribute: this.donorForm.controls.campaign_id.value
        },
        {
          good_united_attribute: '8',
          team_raiser_attribute: this.donorForm.controls.fundraiser_title
            .value
        },
        {
          good_united_attribute: '9',
          team_raiser_attribute: this.donorForm.controls.permalink.value
        },
        {
          good_united_attribute: '10',
          team_raiser_attribute: this.donorForm.controls.charity_id.value
        },
        {
          good_united_attribute: '11',
          team_raiser_attribute: this.donorForm.controls.first_name.value
        },
        {
          good_united_attribute: '12',
          team_raiser_attribute: this.donorForm.controls.last_name.value
        },
        {
          good_united_attribute: '13',
          team_raiser_attribute: this.donorForm.controls.opt_in.value
        },
        {
          good_united_attribute: '14',
          team_raiser_attribute: this.donorForm.controls.persona_type.value
        },
        {
          good_united_attribute: '15',
          team_raiser_attribute: this.donorForm.controls.fundraiser_type.value
        }
      ]
    };

    const myArray = [];
    body.data.filter(item => {
      if (item.team_raiser_attribute !== '') {
        myArray.push(item);
      }
    });

    const isDuplicate = this.checkDuplicateInObject('team_raiser_attribute', myArray);
    if (isDuplicate === true) {
      this.dupErrMessage = 'You have selected duplicate value.';
      this.setTimeOutForDup = setTimeout(() => { this.dupErrMessage = ''; }, 8000);
    } else {
      if (this.donorForm.controls.email.value !== '') {
        this.integrationService.postMappingAttributes(body, this.charityId).subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.sucessMessage = true;
              this.successMsg = res.message;
              this.setTimeOutForSuccess = setTimeout(() => {
                this.successMsg = '';
                this.sucessMessage = false;
              }, 8000);
              this.getDonorData();
            }
          },
          err => {
            this.errorMessage = true;
            this.errorMsg = err.message;
            this.setTimeOutForErr = setTimeout(() => {
              this.errorMsg = '',
                this.errorMessage = false;
            }, 8000);
          }
        );
      } else if (this.donorForm.controls.email.value === '') {
        this.errorMessage = true;
        this.errorMsg = 'Email is mandatory';
        this.setTimeOutForErr = setTimeout(() => {
            this.errorMsg = '',
            this.errorMessage = false;
        }, 8000);
      }
    }
  }

  lookupKey(name) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.selectedArr.length; i++) {
      if (this.selectedArr[i].id === name) return true;
    }
    return false;
  }

  changeVal(typeofd, val) {
//     console.log('change')
//     console.log(this.temp_team_raiser_attributes_temp)
//     this.temp_team_raiser_attributes = JSON.parse(this.temp_team_raiser_attributes_temp);
//     this.currKey = typeofd;
//     const tempObj = {
//       id: typeofd,
//       val
//     };
// console.log(this.selectedArr)
//     const objIndex = this.selectedArr.findIndex(obj => obj.id === typeofd);
//     console.log(this.selectedArr)

//     if (!this.lookupKey(typeofd)) 
//     { console.log(1)
//      this.selectedArr.push(tempObj);
//     }
//     else  this.selectedArr[objIndex].val = val;
  }

  syncStart() {   
    if (this.checkMappingExists()) {
      localStorage.removeItem('map-attribute-async-job-complete');
      localStorage.removeItem('map-attribute-async-job-failed');
      this.integrationService
          .syncdata(this.charityId)
          .subscribe(
            (res: any) => {
              if (res) {
                this.checkStatus = false;
                this.is_Syn_start = true;
                this.synMsg = 'A sync job has started.';
                this.failedJobMsg = this.completeJobMsg = '';
                this.showAlertMsg(res.message, 'success');
                this.interval = setInterval(() => { this.syncCheckStatus(res.job_id); }, 15000);
              }
            },
            error => this.showAlertMsg(error.message, 'danger')
          );
    } else {
      this.showAlertMsg('No mapping of attributes exists. Please update the mapping to initiate sync.', 'danger');
    }
  }

  syncCheckStatus(jobId) {
    this.integrationService
        .syncStatus(jobId, this.charityId)
        .subscribe(
          (res: any) => {
            if (res.data.complete_status === 'in_progress')
              this.jobInProgress(res.data.start_time);
            else if (res.data.complete_status === 'completed')
              this.jobComplete(res.data);
            else if (res.data.complete_status === 'Failed')
              this.jobFailed(res.data.complete_status, res.data.start_time);
          },
          error => this.showAlertMsg(error.message, 'danger')
        );
  }

  jobInProgress(time: string) {
    this.is_Syn_start = true;
    this.checkStatus = false;
    this.completeJobMsg = this.failedJobMsg = '';
    this.synMsg = 'A sync job is running (started at ' + time + ').';
  }

  jobComplete(data: any) {
    this.checkStatus = true;
    this.is_Syn_start = false;
    this.synMsg = this.failedJobMsg = this.failedJobMsg = '';

    if (this.interval) clearInterval(this.interval);
    if (localStorage.getItem('map-attribute-async-job-complete') === null) {
      this.showAlertMsg(data.complete_status, 'success');
      this.completeJobMsg = `The sync job started on ${data.start_time.substring(0, 17)} has completed.`;
    }
    localStorage.setItem('map-attribute-async-job-complete', 'true');
    localStorage.removeItem('map-attribute-async-job-failed');
  }

  jobFailed(status: string, startTime: string) {
    this.checkStatus = true;
    this.is_Syn_start = false;
    this.synMsg = this.completeJobMsg = ''

    if (this.interval) clearInterval(this.interval);
    if (localStorage.getItem('map-attribute-async-job-failed') === null){
      this.failedJobMsg = `The sync job started at ${startTime.substring(0, 17)} has failed.`
      this.showAlertMsg(`Sync failed: The sync job triggered on ${startTime.substring(0, 17)} has failed.`, 'danger');
    }

    localStorage.setItem('map-attribute-async-job-failed', 'true');
    localStorage.removeItem('map-attribute-async-job-complete');
  }

  disconnectIntegrations() {
      this.loading = true;
      this.integrationService
        .disconnect(this.charityId)
        .subscribe((res: any) => {
          if (res) {
            this.loading = false;
            this.router.navigate(['/integrations']);
          }
        }, error => {
          this.loading = false;
        }
        );
  }

  viewMapattributes() {
    this.isMappingatrributes = true;
    this.isConnected = false;
  }

  backToDisconnect() {
    this.isMappingatrributes = false;
    this.isConnected = true;
  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg   = msg;
    this.alertMsg.type  = type;
    this.loading     = false;
  }

  viewlogs() {
    this.router.navigate(['integrations/view-logs']);
  }

  displaySyncSetting() {
    if (this.checkMappingExists()) this.router.navigate(['integrations/sync-settings']);
    else this.showAlertMsg('No mapping of attributes exists. Please update the mapping to initiate sync.', 'danger');
  }

  checkMappingExists(): boolean {
    let mapExist = false;
    this.temp_team_raiser_attributes.forEach(val => {
      const index = this.fundraiserMappedData.findIndex(val2 => val2.team_raiser_attribute === val.id);
      if (index !== -1) mapExist = true;
    });

    this.temp_team_raiser_attributes.forEach(val => {
      const index = this.donorMappedData.findIndex(val2 => val2.team_raiser_attribute === val.id);
      if (index !== -1) mapExist = true;
    });
    return mapExist;
  }

  ngOnDestroy() {    
    if(this.charityIdSubscription) this.charityIdSubscription.unsubscribe();
  }
}
