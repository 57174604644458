import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Filter, FilterApplied } from 'src/app/models/Fundraiser';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { SubscriberListService } from 'src/app/services/subscriber.service';
import { FilterSharedService } from 'src/app/shared/services/filter-shared.service';
import { NgoSharedService } from 'src/app/shared/services/ngo-shared.service';
import * as momentJs from 'moment';
import { finalize } from 'rxjs/operators';
import { UserSharedService } from 'src/app/shared/services/user-shared.service';
import { Sort } from '@angular/material/sort';
import {TooltipPosition} from '@angular/material/tooltip';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-subscriber-list',
  templateUrl: './subscriber-list.component.html',
  styleUrls: ['./subscriber-list.component.scss']
})
export class SubscriberListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('modalCloseBtnYear') modalCloseBtnYear: ElementRef<HTMLElement>;
  @ViewChild('modalCloseBtnMonth') modalCloseBtnMonth: ElementRef<HTMLElement>;
  @ViewChild('input') input;

  public selectedNgo: SelectedNgo;
  public charityId: string;
  public charityIDSubscription: Subscription;

  public filterData: Filter;
  public filterType: any;
  public filterApplied = FilterApplied;
  public filterDataSubscription: Subscription;

  public currentYear = new Date().getFullYear();
  public currentMonth = momentJs().month();

  public filterYear = Array.from({ length: this.currentYear - 2017 }, (v, k) => this.currentYear - k);
  public filterMonth = momentJs.monthsShort();

  public currentPage = 1;
  public currentPageCount: number;
  public totalRecords: number;
  public pages:any;
  public config: any;
  public page = 1;
  public limit = 10;
  public searchTxt: string = "";
  public subcribersData = [];
  public loader = false;
  public searchStatus: string;
  public SubscriberDataSubscription: Subscription;
  public user: any;
  public roles: any;
  public userRole: any;
  public userSubscription: Subscription;
  public sortingField = 'persona_date';
  public orderBy = 'desc';
  public orderByAsc = '';
  public orderByDsc = 'persona_date';
  public searchSearchData = '';
  public subs: Subscription;
  public href: string;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below'];
  positionTop = new UntypedFormControl(this.positionOptions[2]);
  positionBottom = new UntypedFormControl(this.positionOptions[3]);
  keyPressName:any = "";
  checkStage:any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _ngoSharedService: NgoSharedService,
    private _filterSharedService: FilterSharedService,
    private _subscriberListSerivices: SubscriberListService,
    private _userSharedService: UserSharedService,
  ) {

    this.config = {
      itemsPerPage: 10,
      currentPage: (sessionStorage.getItem("pageNo")) ? sessionStorage.getItem("pageNo") : 1,
      totalItems: this.totalRecords
    };   
   
  }

  ngOnInit() {
    // this.getUserinfo();
    this.getCharityId();
    this.href = this.router.url;    
  }

  ngAfterViewInit(): void {
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        // Perform actions
          this.config.currentPage = sessionStorage.getItem("pageNo");
          this.setBreadcrumbs();   
      }
    });
  }

  ngoChanged(ngo: SelectedNgo) {   
  }

  getUserinfo() {
    this.userSubscription = this._userSharedService
      .userData
      .subscribe(
        res => {
          if (Object.keys(res).length > 0) {
            this.user = res;
            this.roles = this.user.roles;
            this.userRole = this.roles[0].role;
          }
        }
      );
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        selectedNGO => {
          if (selectedNGO.charityId !== null) {
            this.selectedNgo  = selectedNGO;
            this.charityId    = selectedNGO.charityId;

            if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
                         
            if (!this.filterData) this.getDateFilter();
            else this.filter('daily', this.currentYear, this.filterMonth[this.currentMonth]);
            
            // this.resetData();      
            // this.setBreadcrumbs();
          }
        }
      );
  }

  getDateFilter() {    
    this.filterDataSubscription = this._filterSharedService
      .dateFilters$
      .subscribe(
        res => {
          this.filterData = res;
          this.filterType = res.type;

          this.orderByDsc = 'persona_date';
          this.setBreadcrumbs();
        }
      );
  }

  setBreadcrumbs() {
    this.subs = this.route.queryParams
        .subscribe((params) => {
          if (params.hasOwnProperty('pageNo')) this.config.currentPage  = params['pageNo'];
          if (params.hasOwnProperty('orderByasc')) this.orderByAsc      = params['orderByasc'];
          if (params.hasOwnProperty('orderBydsc')) this.orderByDsc      = params['orderBydsc'];
          if (params.hasOwnProperty('searchKey')) this.searchSearchData = this.searchTxt = params['searchKey'];

          this.getSubcriberDetailListData();

          // this.searchSearchData   = params['searchKey'] || '';
          // this.orderByAsc         = params['orderByasc'] || '';
          // this.orderByDsc         = params['orderBydsc'] || '';

          // if(this.orderByAsc || this.orderByDsc)
          //   setTimeout( () => this.getSubcriberDetailListData(), 100);

          // if (this.searchSearchData && this.searchSearchData!='undefined') {
          //   this.searchSearchData = this.searchSearchData.replace('%20', '');
          //   this.searchData(this.searchSearchData);
          // }     
        }
    );
  }

  getSubcriberDetailListData() {
    this.loader         = true;
    this.searchStatus   = 'Searching...';
    this.subcribersData = [];

    this.SubscriberDataSubscription = this._subscriberListSerivices
        .getSubscriberDetailList(this.charityId, this.filterData, this.limit, (sessionStorage.getItem("pageNo")) ? sessionStorage.getItem("pageNo") : this.config.currentPage, this.searchTxt, this.orderByAsc, this.orderByDsc)
        .pipe(finalize(() => this.loader = false))
        .subscribe((res: any) => {
          this.subcribersData     = res.message;
          this.searchStatus       = '';        
          this.pages              = Math.ceil(res.total_count_of_records/this.limit);
          this.currentPageCount   = this.config.itemsPerPage * (this.config.currentPage - 1) + this.subcribersData.length;
          this.totalRecords       = res.total_count_of_records;
          this.config.totalItems  = this.totalRecords;
        });
  }

  searchData(SearchValue) {  
    this.searchTxt = SearchValue.trim();
    sessionStorage.setItem('searchKey', SearchValue.trim());
    sessionStorage.removeItem("pageNo");
    this.config.currentPage = 1;
    if (this.SubscriberDataSubscription) this.SubscriberDataSubscription.unsubscribe();
    if (this.searchTxt === '' || this.searchTxt.length >= 3) {
      this.getSubcriberDetailListData();
      this.loader = true;
    } else {
      // this.searchTxt = '';
      // this.loader = false;
      // this.getSubcriberDetailListData();
    }
  }

  filter(type: string, year = null, month = null) {
    const yearBtn: HTMLElement = this.modalCloseBtnYear.nativeElement;
    yearBtn.click();

    const monthBtn: HTMLElement = this.modalCloseBtnMonth.nativeElement;
    monthBtn.click();

    this.filterData.type = type;
    this.filterData.year = year === undefined ? null : year;
    this.filterData.month = month === undefined ? null : month;

    this.filterType = type;
    this.config.currentPage = 1;
    this.setBreadcrumbs();  
  }

  pageChanged(event) {
    this.config.currentPage = event;
    sessionStorage.setItem('pageNo', event);
    this.getSubcriberDetailListData();
  }
 
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    this.keyPressName = event.key;
    console.log(this.keyPressName);
  }
  
  getSubcriberId(ev) {   
    if((this.keyPressName == 'Control') || (this.keyPressName == 'Meta')){
      const url = this.router.createUrlTree(['/subscribers/detail', ev.person_id])
      window.open(url.toString(), '_blank');
      this.keyPressName = '';
    }else{
      this.router.navigate(['/subscribers/detail', ev.person_id]); 
    }     
  }


  sortData(sort: Sort) {
    this.searchTxt = '';
    this.searchStatus = '';
    this.sortingField = sort.active;
    this.orderBy = sort.direction;

    if (this.orderBy === 'asc') {
      this.orderByAsc = this.sortingField;    
      sessionStorage.setItem('AscVal', this.orderByAsc);  
      this.orderByDsc = '';     
      sessionStorage.removeItem('DscVal');   
    } else if (this.orderBy === 'desc') {
      this.orderByDsc = this.sortingField;     
      sessionStorage.setItem('DscVal', this.orderByDsc); 
      this.orderByAsc = '';   
      sessionStorage.removeItem('AscVal');     
    }

    this.getSubcriberDetailListData();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (typeof (a) === 'string' && typeof (b) === 'string') {
      a = a.toLowerCase();
      b = b.toLowerCase();
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  resetData() {
    if (this.input && this.input.nativeElement) this.input.nativeElement.value = null;
    this.orderByAsc = '';
    this.searchTxt = '';
    this.searchStatus = '';
    this.config.currentPage = 1;
    // this.orderByDsc = '';
    // this.orderBy = '';
    // this.sortingField = '';
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
    if (this.SubscriberDataSubscription) this.SubscriberDataSubscription.unsubscribe();
    if (this.subs) this.subs.unsubscribe();
    if (!this.router.routerState.snapshot.url.match('subscribers/detail')) {
      sessionStorage.removeItem('pageNo');
      sessionStorage.removeItem('searchKey');
      sessionStorage.removeItem('AscVal');
      sessionStorage.removeItem('DscVal');
    }
  }

}