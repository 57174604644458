import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MessageService } from '../services/message.service';
import { first } from 'rxjs/operators';
import { NgoSharedService } from '../shared/services/ngo-shared.service';
import { Subscription } from 'rxjs';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.scss']
})
export class AddMessageComponent implements OnInit, OnDestroy {
  public redirectPath = 'messages';
  @ViewChild('matClose') matClose: ElementRef<HTMLElement>;
  submitted = false;
  dropdownList = [];
  categories = [];
  tactic_ids = [];
  messageForm: UntypedFormGroup;
  successMsg: string;
  loading = false;
  loader = false;
  charityID: string;
  public alertMsg = { type: '', msg: ''};
  public heading = 'Messages';

  public charityIDSubscription: Subscription;
  public showLinkError:boolean = false;


  selectedItems = [];
  dropdownSettings = {};
  public href:string;
  public helpText="The URL(entered in the text box)--<campaignId> appears in the message body in place of <link/>. Please make sure that you do not use any other tag or space within this tag to prevent incorrect tasks in Asana.";

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private messageSerivce: MessageService,
    private _ngoSharedService: NgoSharedService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddMessageComponent>,
  ) { }

  receiveMessage($event) {
    if ($event) {
      this.clear();
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (Object.entries(params).length > 0) {
        this.redirectPath = params.redirectUrl;
        if (this.redirectPath === 'current-test') this.heading = 'Current Tests';
      }
    });
    this.initializeMessageForm();
    this.getCharityId();
    this.selectedItems = [];

    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Tactic',
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      enableCheckAll:false,
      classes: 'myclass custom-class'
    };  
    this.href = this.router.url;
    if(this.href == '/activation-message/messages/add?redirectUrl=current-test')
    this.router.navigate(['/activation-message/messages']);
  }

  reloadComponent() {   
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      if(this.href == '/activation-message/messages'){
        this.router.navigate(['/activation-message/messages']);
      }else if(this.href == '/activation-message/current-test'){
        this.router.navigate(['/activation-message/current-test']);
      }else{
        this.router.navigate(['/activation-message/messages']);
      }     
  }

  onItemSelect(item: any) { }
  OnItemDeSelect(item: any) { }
  onSelectAll(items: any) { }
  onDeSelectAll(items: any) {
    this.selectedItems = [];
    this.tactic_ids = [];
  }

  
  initializeMessageForm() {
    const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    //const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.messageForm = this.fb.group({
      category_id:  ['', Validators.required],
      tactic_id:    [''],
      message:      ['', Validators.required],
      status:       ['true'],
      charity_id:   [''],
      url: ['', [Validators.required, Validators.pattern(reg)]]      
    });
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.loading = true;
              this.charityID = selectedNGO.charityId;
              this.displayCategories();
              this.displayTactics();
            } else this.hideLoader(selectedNGO);
          },
          err => this.hideLoader('Charity ID Not Found')
        );
  }


  displayTactics() {
    this.messageSerivce
        .tacticList(this.charityID)
        .subscribe(
          (data: any) => {
            if (data.status === 200) this.dropdownList = data.tactics_list;
          }
        );

  }

  displayCategories() {
    this.messageSerivce
        .categoryList(this.charityID)
        .subscribe(
          (data: any) => {
            if (data.status === 200) this.categories = data.category_list;
          }
        );
  }

  clear() {
    this.messageForm.reset();
    this.messageForm.controls.category_id.setValue('');
  }

  get f() { return this.messageForm.controls; }

  postMessage() {
    this.loading = true;
    this.submitted = true;

    if (this.messageForm.invalid) return this.hideLoader('');

    if (this.selectedItems.length > 0) {
      this.tactic_ids = this.selectedItems.map((obj) => {
        return obj.id;
      });
    }

    let message = this.messageForm.controls.message.value;
    if(message.includes('link')){
      if(message.includes('<link/>')){
        this.showLinkError = false;     
     }else{
       this.showLinkError = true;
       return false;
     }
    }else{
      this.showLinkError = false;      
    }

    // message = message.replaceAll('<link/>', ' <link/> ').replace(/\s+/g, " ");
    // message = message.replace(/<link>/g, "");
    // message = message.replace(/<\/link>/g,"");
    //return; 
    const formData = {
      category_id: this.messageForm.controls.category_id.value,
      tactic_id: this.tactic_ids,
      message,
      status: 'true',
      url: this.messageForm.controls.url.value == '' ? null : this.messageForm.controls.url.value
    };

    this.messageSerivce
      .postMessage(this.charityID, formData)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res) {
            this.successMsg = 'Message has been added successfully!!';
            setTimeout(() => (this.successMsg = ''), 8000);            
            this.submitted = false;
            this.clear();
            this.tactic_ids = [];
            this.displayCategories();
            this.displayTactics();  
            const matCloseBtn: HTMLElement = this.matClose.nativeElement;                
            setTimeout(() =>{ matCloseBtn.click(); }, 1000);  
            setTimeout(() =>{ this.reloadComponent(); }, 2000);          
          }else{
            const matCloseBtn: HTMLElement = this.matClose.nativeElement;
            matCloseBtn.click();  
          } 
          this.showAlertMsg(this.successMsg, 'success')
        },
        error => this.showAlertMsg(error.message, 'danger')
      );
  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg   = msg;
    this.alertMsg.type  = type;
    this.loading     = false;
  }

  onClose(output: boolean) {
    this.dialogRef.close(output);
  }

  ngOnDestroy() {
    this.charityIDSubscription.unsubscribe();
  }

  hideLoader(msg: any): void {
    this.loading = false;
  }

  displayMessageList() {
    this.router.navigate(['/']);
  }

}
