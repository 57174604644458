import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestCohortService {

  constructor(
    private http: HttpClient
  ) { }

  categoryList(charityId: string): Observable<any> {
    const params = { charity_id: charityId };

    return this.http.get('message_category_list', { params });
  }

  activeTest(charityId: string): Observable<any> {
    const params = { charity_id: charityId };

    return this.http.get('active_test', { params });
  }

  messageListByCategory(charityId: string): Observable<any> {
    const params = { charity_id: charityId };

    return this.http.get('view_message_per_category', { params });
  }

  messageDetail(charityId: string): Observable<any> {
    const params = { charity_id: charityId };

    return this.http.get('get_message_detail', { params });
  }

  updateTest(data, charityId: string): Observable<any> {
    return this.http.post('add_update_cohorts?charity_id=' + charityId, data);
  }
}
