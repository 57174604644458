<!-- <div class="editMessageStatus">
<mat-toolbar>
    <span>Edit Message Status</span>
    <span class="fill-remaining-space"></span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>

<form  [formGroup]="editMessageStatusForm" (ngSubmit)="editMsgStatus()" class="normal-form">
    <mat-grid-list cols="2" rowHeight="300px">
        
        <mat-grid-tile>
        <div class="controles-container">
            <div class="add-bottom-padding">
                <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      formControlName="status"
                      id="inlineRadio1"
                      value="1" />
                    <label class="form-check-label" for="inlineRadio1">Active</label>
                </div>

                <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      formControlName="status"
                      id="inlineRadio2"
                      value="0" />
                    <label class="form-check-label" for="inlineRadio2">Inactive</label>
                </div>
                
            </div>
            
            
            <div class="add-bottom-padding">
            </div>
            <div class="button-row">
            <button mat-raised-button class="btn btn-info" type="submit">Change Status</button>
            </div>
        </div>
        </mat-grid-tile>
    </mat-grid-list>
</form>
</div> -->
<div class="asanaConfirmation">   
    <!-- <div class="title">Edit Asana Status</div> -->
    <mat-dialog-content>
        <div class="closeBtn">
            <button class="btn-dialog-close pull-right" mat-stroked-button (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>  
        <div class="md-dialog-content text-center">            
            <div>
                <ng-container *ngIf="messageStatus === 1">                    
                    Do you really want to disable this message? It will disable its usage in any test.
                </ng-container>
                <ng-container *ngIf="messageStatus === 0">
                    Do you really want to enable this message? It will enable its usage in any test.
                </ng-container>
                <div class="button-wrapper">
                    <button class="custom-btn" type="button" (click)="editMsgStatus()">OK</button>&nbsp;&nbsp;
                    <button class="custom-btn" type="button" (click)="onClose(false)">Cancel</button>
                </div>
            </div> 
        </div>        
    </mat-dialog-content>  
</div>