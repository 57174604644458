import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { CommonService } from 'src/app/services/common.service';
import { ReportService } from 'src/app/services/report.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { SubscriberListService } from 'src/app/services/subscriber.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { NgoSharedService } from '../../services/ngo-shared.service';
import { ReportDownloadSharedService } from '../../services/report-download-shared.service';
import { UserSharedService } from '../../services/user-shared.service';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss']
})
export class NewHeaderComponent implements OnInit, OnDestroy {

  public selectedNgo: SelectedNgo;
  public charityId: string;
  public user: any;
  status: boolean;
  public roles: any;
  public userRole: any;
  public charityIDSubscription: Subscription;
  public ngoList$: Observable<SelectedNgo[]>;
  public ngoList1$: Observable<SelectedNgo[]>;
  public filteredNgoList$: Observable<SelectedNgo[]>;

  public reportId: number;
  @Output() ngoChanged = new EventEmitter<any>();
  @ViewChild('searchInput', { static: false}) searchInput: ElementRef;

  constructor(
    private router: Router,
    private _userSharedService: UserSharedService,
    private _sidenavservice: SidenavService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _ngoSharedService: NgoSharedService,
    private _commonService: CommonService,
    private _reportService: ReportService,
    private _reportDownloadSharedService: ReportDownloadSharedService
  ) { }

  ngOnInit() {    
    this.getCharityId();
    this.getNgoList();
    this.status = false;
  }

  getNgoList(): void {
    this.filteredNgoList$ = this.ngoList$ = this._ngoSharedService.ngoLists;
  }

  getUserinfo() {
    this._userSharedService
        .userData
        .subscribe(
          res => {

            if (Object.keys(res).length > 0) this.user = res;           
            this.roles = this.user.roles;
            this.userRole = this.roles[0].role;
          }
        );
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNgo => {
            if (selectedNgo.charityId !== null) 
            this.getUserinfo();
            this.selectedNgo = selectedNgo;
          }
        );
  }

  setPage(ngo: SelectedNgo) {
    this.searchInput.nativeElement.value = '';
    this.getNgoList();
    this._commonService
        .setPage(ngo.charityId)
        .subscribe(
          res => {
            this._ngoSharedService.changeSelectedNgo(ngo);

            this.charityId = ngo.charityId;
            this.getRecentReportId();
            this.refreshDashboard(ngo);
          }
        );
  }

  openNav() {
    this.status = true;
    this._sidenavservice.changeMessage(this.status);
  }

  getImage(image: string) {
    if (!image) return;
    return this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + image);
  }

  signOut(): void {
    sessionStorage.removeItem('redirect-url');
    this._userService
        .logout()
        .subscribe(
          res => window.location.href = environment.baseURL
        );
  }


  getRecentReportId() {    
    localStorage.removeItem(environment.localKey + 'reportId');
    
    this._reportService
        .recentReportId(this.charityId)
        .subscribe(
          res => {
            this.reportId = res.report_id;
            this._reportDownloadSharedService.changeReportId(this.reportId);
          }
        );
  }

  refreshDashboard(selectedNgo) { this.ngoChanged.emit(selectedNgo); }

  filter(event) {
    const searchText = event.target.value.toLocaleLowerCase();
    this.filteredNgoList$ = this.ngoList$.pipe(map(ngo => {
      return ngo.filter(val => val.name.toLocaleLowerCase().includes(searchText));
    }));
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 5 ||
      document.documentElement.scrollTop > 20) {
      document.getElementById('sticky').classList.add('stickyHeader');
    } else {
      document.getElementById('sticky').classList.remove('stickyHeader');
    }
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();  
  }

}
