import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-show-more-button',
  templateUrl: './show-more-button.component.html',
  styleUrls: ['./show-more-button.component.scss']
})
export class ShowMoreButtonComponent implements OnInit {
  @Input() message: string;
  public less = true;
  public formattedMessage: string;

  constructor() { }

  ngOnInit() { }

  get countNumberOfWords(): number { return this.message.split(' ').length; }

  get showMoreMesssage() { return this.message; }

  get showLessMessage() {
    let formattedMessage = '';

    this.message.split(' ').forEach((words, i: number) => {
      if (i < 20) formattedMessage += ' ' + words;
    });

    return formattedMessage;
  }

  showLessBtn() { this.less = true; }
  showMoreBtn() { this.less = false; }
}
