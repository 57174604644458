import { SharedModule } from './../shared/module/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TacticListComponent } from './tactic-list/tactic-list.component';
import { AddTacticComponent } from './add-tactic/add-tactic.component';
import { TacticsComponent } from './tactics/tactics.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: TacticsComponent,
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'list',
        component: TacticListComponent
      }
    ]
  }
];

@NgModule({
    declarations: [
        TacticListComponent,
        AddTacticComponent,
        TacticsComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedModule,
    ],
    exports: [
        RouterModule,
        SharedModule,
        TacticListComponent,
        AddTacticComponent,
        TacticsComponent,
    ]
})
export class TacticsModule { }
