import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgoSharedService } from '../shared/services/ngo-shared.service';
import { MessageService } from '../services/message.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categorymessageoverall',
  templateUrl: './categorymessageoverall.component.html',
  styleUrls: ['./categorymessageoverall.component.scss']
})
export class CategorymessageoverallComponent implements OnInit {
  loading = false;
  charity_id: any;
  overallList = [];
  public charityIDSubscription: Subscription;
  constructor(public messageService:MessageService,private _ngoSharedService: NgoSharedService, private router: Router) { }

  ngOnInit() {
    this.getCharityId();
  }


  ngoChanged($event) { if ($event) { this.getCharityId(); } }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        selectedNGO => {
          if (selectedNGO.charityId !== null) {
            this.loading = true;
            this.charity_id = selectedNGO.charityId;
            this.viewOverallMessage();
          } else this.hideLoader(selectedNGO);
        },
        err => this.hideLoader('Charity ID Not Found')
      );
  }
  viewOverallMessage() {
    this.loading = true;
    this.messageService.getOverallMsg(this.charity_id).subscribe(
      (res: any) => {
        if (res) {
          this.overallList = res.message;
          this.loading = false;
        }
      },
      err => this.loading = false
    );
  }

  hideLoader(msg: any): void {
    this.loading = false;
  }

  displayAllMessage(){
    this.router.navigate(['/activation-message/messages']);
  }
  
}
