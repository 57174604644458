<div class="asanaConfirmation">  
    <mat-dialog-content>
        <div class="closeBtn">
            <button class="btn-dialog-close pull-right" mat-stroked-button (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>  
        <div class="md-dialog-content text-center">
            <div>
                <h2 class="confirmTitle">Remove Report</h2>
                <div class="fontStyle">Do you really want to remove this report?</div>
                <div class="button-wrapper">                    
                    <button class="mono-dark-filled-md-btn-text mr-2" type="button" (click)="onClose(false)">Cancel</button>
                    <button class="error-filled-md-btn-text" type="button" (click)="onClose(true)">Remove</button>
                </div>
            </div>           
        </div>        
    </mat-dialog-content>  
</div>