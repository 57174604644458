import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { NewCondtionComponent } from '../new-condtion/new-condtion.component';
import { ReportService } from '../../services/report.service';
import { NgoSharedService } from 'src/app/shared/services/ngo-shared.service';
import { UserSharedService } from 'src/app/shared/services/user-shared.service';
import { DuplicateReportService } from 'src/app/services/duplicatereport.service';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { IReportCondition } from 'src/app/models/Report';
import { Subscription } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';
import { USER_ROLE,INTIAL_DATE, CONDITION_BY_DT } from 'src/app/constant';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ReportEditConfirmationComponent } from 'src/app/shared/componentss/report-edit-confirmation/report-edit-confirmation.component';
import { ReportDeleteConfirmationComponent } from 'src/app/shared/componentss/report-delete-confirmation/report-delete-confirmation.component';
import { GetValueFormatPipe } from '../get-value-format.pipe';
import { GetReportColumnKeyPipe } from '../get-report-column-key.pipe';

@Component({
  selector: 'app-report-builder',
  templateUrl: './report-builder.component.html',
  styleUrls: ['./report-builder.component.scss'],
  providers: [GetValueFormatPipe,GetReportColumnKeyPipe]
})
export class ReportBuilderComponent implements OnInit {
  @ViewChild('reportName') reportName : ElementRef<any>;
  @ViewChild('columnSearch') columnSearch;
  @ViewChild('searchInput') searchInput;
  @ViewChild('ngxDatePicker') ngxDatePicker: ElementRef<HTMLElement>;
  @ViewChild('modalCloseBtnRangeFilterData') modalCloseBtnRangeFilterData: ElementRef<HTMLElement>;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionLeft = new FormControl(this.positionOptions[4]);
  positionTop = new FormControl(this.positionOptions[2]);
  positionBottom = new FormControl(this.positionOptions[3]);

  public columnsData: any;
  public totalCheckBoxColumn = 0;
  public categoryKeys = {
    'affinity_information': 'Affinity Information',
    'campaign_details': 'Campaign Details',
    'contact_info': 'Contact Info',
    'challenge_participant': 'Challenge Participant',
    'donor': 'Donation Details',
    'fundraiser': 'Fundraiser Details',
    'general_info': 'General Info',
    'gift_aid': 'Gift Aid',
    'challenge_incentive': 'Challenge Incentive',
    'other': 'Other',
    'survey_answers': 'Survey Answers'
  }
  public columnsKeyObjectArray = [];
  public newReportingForm : FormGroup;
  public seq_id:string;
  public charityId: string;
  public selectedNgo: SelectedNgo;
  public charityIDSubscription: Subscription;
  public reportColumnSubscription:Subscription;
  public getNewReportSubscriptionData:Subscription;
  reportNameSubscription: Subscription;
  newSaveReportSubscription: Subscription;
  deleteSubscription: Subscription;
  public userSubscription:Subscription;
  public isBlank = false;
  public editable = false;
  public isvalidSpecialCharater = false;
  public isValidEscape:string;
  public reportNameData: any;
  public reportTypes: any = [];
  public reportFilterData = [];
  public alertMsg = { type: '', msg: '' };
  public showNoColumnData= false;
  public allPopActionreportLoader = false;
  public checkedColumnsKey = [];
  startDate:any = new Date();
  endDate:any = new Date();
  bsMaxDate:any = new Date();
  bsInlineRangeValue: Date[];
  maxDate = moment();
  public fromDate: any = '';
  public toDate: any = '';
  public fromDate1: any = '';
  public toDate1: any = '';
  public currentPage = 1;
  public dateFilterFlag = false;
  public selected: { start: Moment, end: Moment };
  public previewDatas = [];
  public checkedColumnData = [];
  public checkedColumns = '';
  public checkedDisplayName = [];
  public reportType = 'fundraiser_report';
  public userRole: any;
  showReportMenu = environment.showNewreport;
  public savedReports = [];
  public reportIds = [];
  public indexIds = [];
  public reportsSelected = false;
  public reportLoader = true;
  public filterTypeError:string;
  public isValidfilterType = false;
  public searchText:any | undefined = '';
  newReport: any = {};
  public totalRecords: number;
  public pages: any;
  public config: any;
  keysNameData: any[] = [];
  exportInProgress: boolean = false;
  @ViewChild('addEditExportDateCloseBtnRange') addEditExportDateCloseBtnRange: ElementRef<HTMLElement>;
  showExportSaveConfirmPopUp: boolean = false;
  loader: boolean;
  dwnldReportInterval: any;
  reportSubscription: Subscription;
  reportDownloadData: any;
  reportDownloadURL: any;
  reportMultiDownloadURL: any[];
  addEditExportFromDate: string;
  addEditExportToDate: string;
  editReportRow: any;
  newSaveReportRowData: any;
  filterType: string;
  searchTxtVal:any | undefined = '';
  timeoutID: any;
  isOpenCalendar: any;
  isNew: boolean = true;
  currentPageCount:Number;

  public locale = {
    format: 'YYYY-MM-DD', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'MM-DD-YYYY', // default is format value
    direction: 'ltr', // could be rtl
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Apply', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'Custom range',
  };
  
  operatorList: any[] = [
    { name: 'ALL', value: 'All' },
    { name: 'ANY', value: 'Any' }
  ];
  filterCondition: { operator: string, list: IReportCondition[] } = {
    operator: 'All',
    list: []
  }
  maxConditionLimit: number = 10;
  sortOrderStr: string = '';
  exportBtnName: string = 'Export';
  backToReportingFlag: boolean = false;
  beforeExportSaveReportFlag: boolean = false;
  conditionOverlay: boolean = false;

  constructor(
    public dialog: MatDialog,
    private _fb: FormBuilder,
    private renderer: Renderer2,
    private _reportService:ReportService,
    private _ngoSharedService: NgoSharedService,
    private _userSharedService: UserSharedService,
    private _duplicateReportService: DuplicateReportService,
    private router: Router,
    private _location: Location,
    public activatedRoute: ActivatedRoute,
    private getValueFormatPipe: GetValueFormatPipe,
    private getReportColumnKeyPipe:GetReportColumnKeyPipe
    ) {
      this.config = {
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: this.totalRecords
      };
      this.startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1, 
        new Date().getDate()
      ); 
      this.bsInlineRangeValue = [
        this.startDate,  
        this.endDate]
     }

  ngOnInit() {
    const { reportId } = this.activatedRoute?.snapshot?.queryParams;
    this.exportBtnName = reportId?'Export & Save':'Export';
    this.newReport.emailOnly = false;
    this.getCharityId(reportId);
    this.initialiseReportingForm();
  }

  initialiseReportingForm() {
    this.newReportingForm = this._fb.group({
      report_name: [''],
      report_type: [''],
      filter_type: [''],
      reportArrayColumnsData: this._fb.array([])
    })
  } 

  saveNewReportData = (from: string, is_save = true) => {
    try {
      const data  = this.payloadForReport();
      
      const { reportId } = this.activatedRoute?.snapshot?.queryParams;
      
      if(reportId){
        this.editSelectedReportSaveConfirmation(this.charityId,reportId,data);
      }else
      {      
        if(this.newSaveReportRowData?.ngo_report_id && this.beforeExportSaveReportFlag && from == 'generalSave'){
          this.newReportingForm.value.report_name = this.reportName.nativeElement.value;
          this.editSelectedReportSaveConfirmation(this.charityId,this.newSaveReportRowData?.ngo_report_id,data);
          return;
        } 

        data.is_save = is_save;
        if(!is_save){
          let d  = new Date();
          data.report_name = data.report_name + '_' + d.getTime();
        }
        this.saveReport(from,data);      
      }
    } catch (error) {
      this.showAlertMsg(error?.message, 'danger');
    }
  }

  payloadForReport = () => {
    this.newReportingForm.value.reportArrayColumnsData.forEach(k =>{
      k.reportColumns.forEach( j =>{
        if(j.is_checked){
          this.checkedColumnsKey.push(j.key);
        }
      })
    })
    this.checkedColumnsKey  = [...new Set(this.checkedColumnsKey)];
    let data:any = {
      report_name: this.reportName.nativeElement.value,
      sequence_id: this.seq_id,
      report_type: this.newReportingForm.controls.report_type.value,
      filter_type: this.newReportingForm.controls.filter_type.value,
      columns: this.checkedColumnsKey,
      conditions: this.filterCondition?.list?.map((filter: IReportCondition) => {
        return {
         column: filter?.columnKey,
         condition: filter?.conditionType,
         value: this.getValueFormatPipe.transform(filter?.value, filter?.columnType)
        }
     }),
      from_date: this.fromDate,
      to_date:this.toDate 
    }
    return data;
  }

  async saveAndExportCurrentReport(){
    this.newReportingForm.value.report_name = this.reportName.nativeElement.value;
    if(await this.checkDeepEqual(this.newSaveReportRowData?.ngo_report_id)){
      const reportIds=[];
      reportIds.push(this.newSaveReportRowData?.ngo_report_id);
      this.getSelectedReportTaskID("beforeExportSave",reportIds);
      this.backToReportingFlag = true;
      return;
    }
    const data  = this.payloadForReport();
    this.openReportConfirmationComponent(this.charityId, this.newSaveReportRowData?.ngo_report_id, data,false,false,false,'beforeExportSave');
  }

  async editSelectedReportSaveConfirmation(charity_id:string, report_id:string, data:object) {
    if(await this.checkDeepEqual(report_id)){
      this.updateReport(charity_id,report_id ,data);
      return;      
    }

    this.openReportConfirmationComponent(charity_id, report_id, data);
    
  }

  openReportConfirmationComponent(charity_id:string, report_id:string, data:object,isNewReport = false,redirection = false,goback = true,task = ''){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '370px';
    dialogConfig.height = '260px';
    dialogConfig.panelClass = 'custom-dialog-container';
    this.conditionOverlay = true;
    const dialogRef = this.dialog.open(ReportEditConfirmationComponent,{
      data: {
        reportName: this.reportName?.nativeElement?.value,
        isNewReport : isNewReport
      }
      
    });
    // dialogConfig

    dialogRef.afterClosed()
      .subscribe(
        res => {
          this.conditionOverlay = false;
          if (!res) {
            this.reportLoader = false;
            if(redirection) {
              this.locationBack(500);
            }
            if(!goback){
              this.backToReportingFlag = true;
              if(task == 'beforeExportSave'){
                const reportIds=[];
                reportIds.push(report_id);
                this.getSelectedReportTaskID("beforeExportSave",reportIds);
                return;
              }
              this.getSelectedReportTaskID("addEditExport");
              return;
            }
            return;
          }
          if(res && isNewReport){
            this.saveNewReportData('generalSave',true);
            return;
          }
          this.updateReport(charity_id,report_id ,data,goback,task);
        });
  }

  locationBack(time:number = 2000){
    setTimeout(() => {
      // this._location.back();
      this.router.navigate(['/newreports']);
    },time);
  }

  updateReport(charityId, reportId, data,goback = true,task = '')
  {    
    let isCheckDate = this.checkedaddEditDate();
    if(!isCheckDate){
      this.newSaveReportSubscription = this._reportService.updateReport(charityId, reportId, data, this.newReport.emailOnly,this.filterCondition?.operator)
        .pipe()
        .subscribe(
          res => {
            if (res.hasOwnProperty('Results') && res.Results.hasOwnProperty('statusCode') && res.Results.statusCode == 200) {
              if(!goback) {
                this.backToReportingFlag = true;
                if(task == 'beforeExportSave'){
                  const reportIds=[];
                  reportIds.push(reportId);
                  this.getSelectedReportTaskID("beforeExportSave",reportIds);
                  return;
                }
                this.getSelectedReportTaskID("addEditExport");
                return;
              }
              this.reportIds.length = 0;
              this.showAlertMsg(res.Results.message, 'success');
              if (goback) this.locationBack();
            }
            else {
              this.showAlertMsg(res.Results.message, 'danger');
            }
            this.allPopActionreportLoader = false;
          },
          err => {
            this.allPopActionreportLoader = false;
            this.showAlertMsg(err.message, 'danger');
          }
        );
    } else {
      return;
    }
  }

  saveReport = (from,data) => {
    try {
      this.newSaveReportSubscription = this._reportService.newSaveReport(this.charityId, data,this.newReport.emailOnly,this.filterCondition?.operator)
      .pipe(finalize(() => this.allPopActionreportLoader = false))
      .subscribe(
        res => {
          if(from=='beforeExportSave' && res?.Results)
            this.newSaveReportRowData = res?.Results;
          else
            this.newSaveReportRowData = {};

          if(data?.is_save){
            this.showAlertMsg(res.Results.message, 'success');
          }
          if(this.newSaveReportRowData?.ngo_report_id)
          {            
            const reportIds=[];
            reportIds.push(this.newSaveReportRowData.ngo_report_id);
            this.getSelectedReportTaskID("beforeExportSave",reportIds);
            this.backToReportingFlag = true;
            return;
          }
          this.locationBack();
        },
        err => {
          this.allPopActionreportLoader = false;
          this.exportInProgress = false;  
          this.showAlertMsg(err.message, 'danger');
        }
      );
    } catch (error) {
      this.allPopActionreportLoader = false;
      this.exportInProgress = false;  
      this.showAlertMsg(error.message, 'danger');
    }
  }

  openDeleteConfirmDialog(rId) {
    const index = this.savedReports.findIndex(r => r.report_id === rId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '360px';
    dialogConfig.height = '220px';
    dialogConfig.panelClass = 'custom-dialog-container';
    //dialogConfig.data = { status };
    const dialogRef = this.dialog.open(ReportDeleteConfirmationComponent, dialogConfig);
    // this.allPopActionreportLoader = true;
    dialogRef.afterClosed()
      .subscribe(
        res => {
          if (!res) 
          {
            this.allPopActionreportLoader=false;
            return
          };
          this.reportIds.push(rId);
          let apiData = this.reportIds;
          this.deleteSubscription = this._reportService.deleteReports(this.charityId, apiData).subscribe(res => {
            if (res) {
              this.showAlertMsg('Report has been deleted successfully', 'success');
              this.locationBack();
            }
          })
        });
  }

  checkedaddEditDate(){
    if(this.fromDate!=null && this.toDate!=null && this.fromDate > this.toDate) {
      let isErrorDataforDate = true;
      const errorDataforDate = 'From date should not greater then to date';
      setTimeout(()=>{ isErrorDataforDate = false;}, 5000);    
      return;    
    }
    else
    {
      return false;
    }
  }

  async goBackToReporting(){
    if(this.backToReportingFlag && !this.beforeExportSaveReportFlag) {
      this.locationBack(500);
      return;
    }
    let isEqual = true;
    if(this.beforeExportSaveReportFlag){
      this.newReportingForm.value.report_name = this.reportName.nativeElement.value;
      isEqual = await this.checkDeepEqual(this.newSaveReportRowData?.ngo_report_id);
    }else {
      isEqual = await this.checkDeepEqual(); 
    }

    if(!isEqual){
      const { reportId } = this.activatedRoute?.snapshot?.queryParams;

      let data:any = {
        report_name: this.reportName.nativeElement.value,
        sequence_id: this.seq_id,
        report_type: this.newReportingForm.controls.report_type.value,
        filter_type: this.newReportingForm.controls.filter_type.value,
        columns: this.checkedColumnsKey,
        conditions: this.filterCondition?.list?.map((filter: IReportCondition) => {
          return {
           column: filter?.columnKey,
           condition: filter?.conditionType,
           value: this.getValueFormatPipe.transform(filter?.value, filter?.columnType)
          }
       }),
        from_date: this.fromDate,
        to_date:this.toDate 
      }

      /*
        if for Redirection from New Report 
        else for Redirection from Edit Report 
      */
      if (!reportId) {
        if(this.beforeExportSaveReportFlag){
          this.openReportConfirmationComponent(this.charityId, this.newSaveReportRowData?.ngo_report_id, data, false, true);
        }else {
          this.openReportConfirmationComponent(this.charityId, reportId, data, true, true);
        }

        return;
      } else {
        this.openReportConfirmationComponent(this.charityId, reportId, data, false, true);
        return;
      }
    }
    this.locationBack(500);
  }

  getUserinfo() {
    this.userSubscription = this._userSharedService
        .userData
        .subscribe(
          (res:any) => {
            if (Object.keys(res).length > 0) {
              this.userRole = res?.roles[0]?.role;
              if (this.userRole == USER_ROLE.NGO_ADMIN || (this.userRole == USER_ROLE.SUPER_ADMIN && !this.showReportMenu)) {
                this.getReportingFlag(this.charityId);
              }
              
            }
          }
        );
  }

  ngoChanged(ngo: SelectedNgo) {
    this.savedReports = [];
    this.currentPage = 1;
    this.reportIds = []
    this.indexIds = [];
    //this.getSavedReports("ngoChange"); 
    if(this.userRole == USER_ROLE.NGO_ADMIN || (this.userRole == USER_ROLE.SUPER_ADMIN && !this.showReportMenu)){
      this.getReportingFlag(this.charityId);
    }
   
  }

  getReportingFlag = async(charityId) => {
    const { Results } = await this._reportService.reportingFlag(charityId);
    if(!Results.new_reporting){
      this.router.navigate(['/home']);
    }
  }

  // funtion to get charity id
  getCharityId(reportId): void {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        selectedNGO => {
          if (selectedNGO.charityId !== null) {
            this.selectedNgo = selectedNGO;
            if(this.charityId && this.charityId != selectedNGO.charityId){
              this.router.navigate(['/newreports']);
            }
            this.charityId = selectedNGO.charityId;
            this.currentPage = 1;
            this.getReportTypeName();
            this.fetchReportTypeList(reportId);
            this.getUserinfo(); 
            if (this.dwnldReportInterval) clearInterval(this.dwnldReportInterval); 
          }
        },
      );
  }

  // Function for report name edit case and validation

  CheckValidateTitle() {
    let valData = this.reportName.nativeElement.value; 
    if (valData.match(/^[ A-Za-z0-9_-]*$/)) {   
       this.isvalidSpecialCharater = false;
    } else {      
      this.isvalidSpecialCharater = true;
      this.isValidEscape = "Error: Your title must be alphanumeric. (A-Z, a-z, 0-9, - _ and space)";
    }
  }

  async fetchReportData(reportID: string) {  
    try {
      this.isNew = false;
      this.fromDate = '';
      this.toDate = ''; 
      this.fromDate1 = '';
      this.toDate1 = '';
      this.selected = { start:moment(), end:moment() };
      this.dateFilterFlag = false;
      this.searchTxtVal = '';
      this.allPopActionreportLoader = true;
      this.endDate = new Date();
      this.bsInlineRangeValue = [this.startDate, this.endDate];
      const apiData = {
        charity_id: this.charityId,
        report_id: reportID
      };
      const report: any = await this._reportService.getSavedReport(apiData);
      this.filterType = report?.data['filter'];
      this.newReport.emailOnly  = report?.data?.email_only || false;
      if(report.data['report_type_key'] && report.data['filter']) {
        const { success } = await this.setReportColumns(report.data['report_type_key'],false,report?.data);
        if (!success) return false;
      }
      if (report.data) this.setEditReportData(report.data);
    } catch(error) {
      // this.allPopActionreportLoader = true;
      if (this.currentPage > 1) return;
      this.showAlertMsg(error.message, 'danger');
    } finally {
    }
  }

  setEditReportData(reportData) {
    this.isNew = false;
    this.newReportingForm.controls.report_name.setValue(reportData['report_name']);
    this.newReportingForm.controls.report_type.setValue(reportData['report_type_key']);
    this.newReportingForm.controls.filter_type.setValue(reportData['filter_key']);
    if(reportData?.from_date && reportData?.to_date){
      this.dateFilterFlag = true;
      this.selected = {start:moment(reportData?.from_date), end:moment(reportData?.to_date)};
      this.fromDate1 = reportData?.from_date;
      this.toDate1 = reportData?.to_date;
      this.fromDate = new Date(reportData?.from_date);
      this.toDate = new Date(reportData?.to_date); 
      this.startDate = new Date(reportData?.from_date);
      this.endDate = new Date(reportData?.to_date); 
      this.bsInlineRangeValue = [this.startDate, this.endDate];
    }
    this.filterCondition.operator = reportData?.condition_type || 'All';
    if(reportData?.filter_conditions?.conditions?.length){
      this.filterCondition.list = reportData?.filter_conditions?.conditions?.map((ele:any) => {
        return {
          "columnGroupKey": this.getReportColumnKeyPipe.transform(ele?.column,this.columnsData,''),
          "columnKey": ele?.column,
          "columnType": this.getReportColumnKeyPipe.transform(ele?.column,this.columnsData,'columnType'),
          "conditionType": ele?.condition,
          "value": ele?.value
        }
      })
    }
    
    
    let selectedColData = reportData.selected_columns.hasOwnProperty("report_columns") ? 
    reportData.selected_columns.report_columns :  reportData.selected_columns;
    this.totalCheckBoxColumn = 0;
    Object.keys(selectedColData).forEach(k => {
      this.totalCheckBoxColumn += selectedColData[k].filter((ele:any) => ele?.is_checked).length;
    });   
    if(selectedColData.length!=0)
    {
      for (let i of Object.keys(selectedColData)) 
      {
        for (let j of Object.keys(selectedColData[i])) 
        { 
          if(selectedColData[i][j].is_default==false)
          {
            if (this.columnsData[i] && this.columnsData[i][j]) {
              if(selectedColData[i][j].is_checked){
                this.checkedColumnsKey.push(selectedColData[i][j].key);
                this.checkedDisplayName.push(selectedColData[i][j].display_name);
              }             
              if(this.columnsData[i])this.columnsData[i][j].is_checked = selectedColData[i][j].is_checked;
              this.setColumnFormControlValue(selectedColData[i][j].key, selectedColData[i][j].is_checked);
            }
          }
        } 
      }
      this.checkedColumnsKey = [...new Set(this.checkedColumnsKey)];
      this.checkedDisplayName = [...new Set(this.checkedDisplayName)];
      this.setFilterConditionPayload();
    }
  }

  setColumnFormControlValue(key,colValue)
  {
    // this.reportModalLoader = false;
    Object.keys(this.newReportingForm.controls.reportArrayColumnsData.value).forEach((k,row)=>{      
      for (let i of Object.keys(this.newReportingForm.controls.reportArrayColumnsData.value[k].reportColumns)) 
      {
        if(this.newReportingForm.controls.reportArrayColumnsData['controls'][k].controls.reportColumns.controls[i].controls.key.value == key)
        {
          this.newReportingForm.controls.reportArrayColumnsData['controls'][k].controls.reportColumns.controls[i].controls.is_checked.setValue(colValue);
          return;
        }
      }
    });
  }
  
  onDblClickEditBox(e){
    this.editable = true;
    this.isBlank = true;
  }
  
  toggleClass(){
    this.renderer.listen('window', 'click',(e:Event)=>{      
      if(e.target !== this.reportName.nativeElement){
        this.editable = false;  
        this.reportName.nativeElement.value == '' ? this.isBlank=true : this.isBlank=false;
      }
     }); 
  }

  setDefaultValue()
  {
    this.newReportingForm.controls.report_name.setValue(this.reportNameData?.report_name);
    this.newReportingForm.controls.report_type.setValue("sup");
    this.newReportingForm.controls.filter_type.setValue("blank");
  }

  
  // funtion for report type date
  getReportTypeName() {
    this.reportNameSubscription = this._reportService.getReportName(this.charityId).subscribe(res => {
      if (res) {
        this.reportNameData = res.data;
        this.seq_id = this.reportNameData.sequence_id.toString();
      }
    }, err => this.showAlertMsg(err.message, 'danger', true)
    );
  }

  async fetchReportTypeList(reportId) {
    try {
      const reportType: any = await this._reportService.getReportType(this.charityId);
      this.reportTypes = reportType?.data_list;
      const preSelectedReportType = !this.reportTypes?.length ? '': this.reportTypes[0]?.key || '';
      this.fetchReportTypeFilterList(preSelectedReportType,reportId);
      this.newReportingForm.patchValue({
        report_type: preSelectedReportType
      });
    } catch(error) {
      this.showAlertMsg(error.message, 'danger', true);
    }
  }

  async fetchReportTypeFilterList(key: string,reportId) {
    try {
      if (!key) return;
      const reportFilter: any = await this._reportService.getReportFilter(this.charityId, key);
      this.reportFilterData = reportFilter?.data_list;
      if(!reportId){
        this.newReportingForm.controls['filter_type'].setValue(this.reportFilterData[0]?.key, {onlySelf: true});
        this.filterType = this.reportFilterData[0]?.key;
        this.setReportColumns(key);
      }else{
        this.fetchReportData(reportId);
      }
    } catch(error) {
      this.showAlertMsg(error.message, 'danger', true);
    }
  }

  onChangeReportFilter(filterType:string){
    this.newReport.emailOnly = false;
    this.filterType = filterType;
    this.config.currentPage = 1;
    this.sortOrderStr = '';
    if(this.searchInput?.nativeElement?.value){
      this.searchInput.nativeElement.value = '';
      this.columnSearch.nativeElement.value = '';
      this.searchTxtVal = '';
    }
    this.filterCondition = {
      operator: 'All',
      list: []
    }
    this.setReportColumns(this.reportTypes[0]?.key);
  }

  async setReportColumns(reportType: string,fetchReport = true,reportData:any = {}) {
    try {
      this.allPopActionreportLoader = true;
      this.showNoColumnData = false;
      this.columnSearch.nativeElement.value = '';
      this.checkedColumnsKey = [];  
      this.startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1, 
        new Date().getDate()
      );
      this.endDate = new Date(); 
      this.bsInlineRangeValue = [this.startDate, this.endDate];
      this.fromDate = '';
      this.toDate = '';
      this.fromDate1 = '';
      this.toDate1 = '';
      this.dateFilterFlag = false;
      this.selected = {start:moment(), end:moment()};
      let clearFormarray = this.newReportingForm.controls['reportArrayColumnsData'] as FormArray; 
      clearFormarray.clear(); 
      this.checkedDisplayName = []; 
      const column = Object.keys(reportData)?.length == 0? await this._reportService.getReportColumn(this.filterType, reportType, this.charityId).toPromise():{};
      if (column) {
        this.checkedColumnData = [];    
        this.columnsData = Object.keys(reportData)?.length?reportData?.selected_columns?.report_columns:column?.data;
        this.columnsKeyObjectArray = Object.keys(this.columnsData);
        var ii = 0;
        for (let i of Object.keys(this.columnsData)) {
            const control = <FormArray>this.newReportingForm.controls['reportArrayColumnsData'];            
            control.push(this._fb.group({
              reportColumns:this._fb.array([]),
              name:i,
              selectAll: false
            }));
            this.createCheckBoxes(this.columnsData[i],ii);
            ii++;
        }     
        if(fetchReport) this.setFilterConditionPayload();
        this.allPopActionreportLoader = false;
      }
      return { success: true }
    } catch (error) {
      this.allPopActionreportLoader = false;
      this.showAlertMsg(error.message, 'danger', true);
      return { success: false }
    }
  }

  getFilterPreviewData(extraParams?: any, extraPayload?: any) {    
    if(!this.checkedColumnsKey?.length) {
      this.allPopActionreportLoader=false;
      this.previewDatas = [];
      this.config.currentPage = 1;
      return;
    }
    if(!this.charityId) {
      this.allPopActionreportLoader=false;
      return;
    }
    this.previewDatas.length = 0;
   
    const params = {
      charity_id: this.charityId,
      count: 10,
      report_type: this.reportTypes[0]?.key,
      filter: this.filterType,
      search: this.searchTxtVal,
      email_only: this.newReport.emailOnly || false,
      page: this.config.currentPage,
      ...(this.fromDate1 && this.toDate1 && {
        from_date: this.fromDate1,
        to_date: this.toDate1
      }),
      ...extraParams
    }
    
    const payload = {
      columns: this.checkedColumnsKey,
      ...extraPayload,
    }
    
    this.reportLoader = true;
    this.getNewReportSubscriptionData = this._reportService.getPreviewFilterService(params, payload)
      .pipe(finalize(() => this.reportLoader = false))
      .subscribe(
        res => {
          this.previewDatas = res.message.hasOwnProperty('data') ? res.message.data : [];
          if(this.previewDatas.length > 0){
              this.keysNameData = Object.keys(this.previewDatas[0]);   
          }     
          this.allPopActionreportLoader=false;
          this.pages = Math.ceil(res.total_no_of_records/this.config.itemsPerPage);
          this.currentPageCount = this.config.itemsPerPage * (this.config.currentPage - 1) + this.previewDatas.length;
          this.totalRecords = res.total_no_of_records;
          this.config.totalItems = this.totalRecords;  
        },
        err => {
          this.allPopActionreportLoader=false;
        }
      );
  }

  // To search from Report Preview via FullName/CompaignID
  searchInReportPreview(SearchValue,ev) {    
    this.searchTxtVal = SearchValue.trim();
    if(!this.searchTxtVal && ev.keyCode != 32) {
      if (this.getNewReportSubscriptionData) this.getNewReportSubscriptionData.unsubscribe();
      this.previewDatas = [];
      this.config.currentPage = 1;
      this.setFilterConditionPayload();
    }
    if(this.searchTxtVal && this.searchTxtVal.length >= 3){
        if (this.getNewReportSubscriptionData) this.getNewReportSubscriptionData.unsubscribe();
        this.previewDatas = [];
        this.config.currentPage = 1;
        this.setFilterConditionPayload(); 
    } 
  }

  // To search column from COLUMN DISPLAY
  onSearchColumns(searchText) {
    searchText = searchText?.trim();
    if (this.timeoutID) clearTimeout(this.timeoutID); // Cancel column search trigger(timeout)
    
    if (searchText?.length > 0) {
      this.timeoutID = setTimeout(() => this.getColumnFilterData(searchText), 1000);
    } else {
      searchText = '';
      this.showNoColumnData = false;
      this.getColumnFilterData('', false);
    }
  }

  getColumnFilterData(searchText: string, hasExpandChild: boolean = true) { 
    let temp = 0;
    this.showNoColumnData = false;
    Object.entries(this.columnsData).forEach(([key, value]: any, index) => {
      const filteredColumn = value?.filter((item: any) => {
        const tempDispName = item?.display_name?.toLowerCase();
        return tempDispName?.includes(searchText?.toLowerCase());
      });
      if(filteredColumn?.length) {
        temp+=1;
        setTimeout(() => {
          this.expandChildColumns(index, hasExpandChild);          
        }, 100);

      }else {
        setTimeout(() => {
          this.expandChildColumns(index, false);          
        },100);

      }
      this.createColFilterCheckBoxes(filteredColumn, index);
    });

    this.showNoColumnData = temp === 0;
  }

  expandChildColumns (index: number, hasExpandChild: boolean) {
    const subColumn: any =  document.getElementById(`headingOne-${index}`);
    const dataTargetElement = subColumn?.querySelector(`[data-target="#collapse-${index}"]`);
    const subColumnBody = document.getElementById(`collapse-${index}`);
    if (hasExpandChild) {
      dataTargetElement?.classList?.remove('collapsed');
      subColumnBody?.classList?.add('show');
    } else {
      dataTargetElement?.classList?.add('collapsed');
      subColumnBody?.classList?.remove('show');
    }
  }

  createColFilterCheckBoxes(reportColumns,index){    
    const control = <FormArray>this.newReportingForm.controls['reportArrayColumnsData']['controls'][index].controls['reportColumns'];
    control.clear();
    if(reportColumns.length!=0)
    {
      for(let reportCol of reportColumns){
        control.push(this._fb.group({
          display_name: reportCol.display_name,
          is_available: reportCol.is_available,
          is_checked: reportCol.is_checked,
          is_default: reportCol.is_default,
          key:reportCol.key,
          user_action: false
        }));
      }
    }
  }

  // To clear search input from Report Preview
  clearSearch(){
    if(this.searchInput?.nativeElement?.value){
      this.searchInput.nativeElement.value = '';
      this.searchTxtVal = '';
      this.config.currentPage = 1;
      if (this.getNewReportSubscriptionData) this.getNewReportSubscriptionData.unsubscribe();
      this.previewDatas = [];
      this.setFilterConditionPayload();
    }
    
  }

  setupTimeID() {
    if (typeof this.timeoutID === 'number') {
      this.cancel();
    }

    this.timeoutID = setTimeout(function() {
      this.remind();
    }.bind(this), 1000);
  }

  cancel() {
    clearTimeout(this.timeoutID);
  }

  remind() {    
    this.allPopActionreportLoader = true;
    this.timeoutID = undefined;
    this.setFilterConditionPayload();
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.setFilterConditionPayload();
  }

  openDatepicker() {
    setTimeout(() => {
      const rangeBtn: HTMLElement = this.ngxDatePicker.nativeElement;
      rangeBtn.click();
    }, 100);
  }

  ngDateChange(ev) {
    this.config.currentPage = 1;
    if (ev.start != null && ev.end != null) {
      this.dateFilterFlag = true;
      if (this.modalCloseBtnRangeFilterData) {
        // this.renderer.addClass(document.body, 'modal-open1');
        const rangeBtn: HTMLElement = this.modalCloseBtnRangeFilterData.nativeElement;
        rangeBtn.click();        
      }

      const date1 = moment(ev.start._d).format('MM-DD-YYYY');
      const date2 = moment(ev.end._d).format('MM-DD-YYYY');

      const fromDate = date1.split('T');
      this.fromDate = fromDate[0];
      this.fromDate = this.fromDate.replace(/-/g,'/');

      const toDate = date2.split('T');
      this.toDate = toDate[0];
      this.toDate = this.toDate.replace(/-/g,'/');

      let fdt = new Date(this.fromDate);
      let tdt = new Date(this.toDate);
      this.startDate = fdt;
      this.endDate = tdt;
      this.bsInlineRangeValue = [this.startDate,this.endDate];
      this.fromDate1 = fdt.getFullYear() + '-' + ( fdt.getMonth() + 1) + '-' + fdt.getDate();
      this.toDate1 = tdt.getFullYear() + '-' + ( tdt.getMonth() + 1) + '-' + tdt.getDate();
      this.setFilterConditionPayload();
    } else {   
      this.dateFilterFlag = false;
      
      this.startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1, 
        new Date().getDate()
      );
      this.endDate = new Date(); 
      
      this.bsInlineRangeValue = [
        this.startDate,  
        this.endDate]
        
      if (this.modalCloseBtnRangeFilterData) {
        // this.renderer.addClass(document.body, 'modal-open1');
        // const rangeBtn: HTMLElement = this.modalCloseBtnRangeFilterData.nativeElement;
        // rangeBtn.click();  
        this.fromDate = '';
        this.toDate = ''  
        this.fromDate1 = '';
        this.toDate1 = '';
        setTimeout(() => {
          const rangeBtn: HTMLElement = this.ngxDatePicker.nativeElement;
          rangeBtn.click();
        }, 0);
        this.ngxDatePicker.nativeElement.focus();
        this.setFilterConditionPayload(); 
        this.closeOpenCalendarfilterData();
      }
    }
  }

  closeOpenCalendarfilterData(){    
    if (this.modalCloseBtnRangeFilterData) {
       const rangeBtn: HTMLElement = this.modalCloseBtnRangeFilterData.nativeElement;
       rangeBtn.click();
      //  this.renderer.addClass(document.body, 'modal-open1');
     }
  }

  stopCloseCalendar(ev){    
    ev.stopPropagation();
    this.isOpenCalendar = ev.type;
  }

  selectCurrentCol(colIndex: number, childColName: any) {
    const reportColumnData = (<FormArray>this.newReportingForm.get('reportArrayColumnsData'));
    const reportColumnDataItem = reportColumnData?.at(colIndex);
    reportColumnDataItem?.patchValue({
      selectAll: !reportColumnDataItem?.value?.selectAll
    });
        
    if(!reportColumnDataItem?.value.selectAll) {
      for (let i = 0; i < reportColumnDataItem?.value?.reportColumns?.length; i++) {
        const reportSubColumnData = (<FormArray>reportColumnDataItem.get('reportColumns'));
        const reportSubColumnDataItem = reportSubColumnData?.at(i);
        reportSubColumnDataItem?.patchValue({
          is_checked: reportSubColumnDataItem?.value?.is_default
        });
      }
    } else {
      for (let i = 0; i < reportColumnDataItem?.value?.reportColumns?.length; i++) {
        const reportSubColumnData = (<FormArray>reportColumnDataItem.get('reportColumns'));
        const reportSubColumnDataItem = reportSubColumnData?.at(i);
        reportSubColumnDataItem?.patchValue({
          is_checked: true,
        });
      }
    }
    this.IsCheckedColumn();
  }

  deSelectCurrentCol(colIndex: number) {
    const reportColumnData = (<FormArray>this.newReportingForm.get('reportArrayColumnsData'));
    const reportColumnDataItem = reportColumnData?.at(colIndex);
    reportColumnDataItem?.patchValue({
      selectAll: false
    });
    for (let i = 0; i < reportColumnDataItem?.value?.reportColumns?.length; i++) {
      const reportSubColumnData = (<FormArray>reportColumnDataItem.get('reportColumns'));
      const reportSubColumnDataItem = reportSubColumnData?.at(i);
      reportSubColumnDataItem?.patchValue({
        is_checked: false,
      });
    }
    this.IsCheckedColumn();
    
  }

  IsCheckedColumn(){
    const columns = this.newReportingForm.value.reportArrayColumnsData; 
    columns?.forEach(k =>{      
      k.reportColumns.forEach( j =>{
        const colFindData =  this.checkedColumnsKey.filter(function(val) {
          // return val.includes(j.key);
          return val== j.key;
        });

        if(j.is_checked)
        {
          this.setReportColumnFormControlVlaue(k.name, j.key ,j.display_name);

          if(colFindData.length==0)
          {
            this.checkedColumnsKey.push(j.key);
            this.checkedDisplayName.push(j.display_name);
          }
        }
        else
        {  
          const findColIndex = this.checkedColumnsKey.findIndex(r => r === j.key);
          const findColDisplayIndex = this.checkedDisplayName.findIndex(r => r === j.display_name);
  
          if(findColIndex!=-1 && findColDisplayIndex!=-1)
          {            
            this.checkedColumnsKey.splice(findColIndex,1);
            this.checkedDisplayName.splice(findColDisplayIndex,1);
          }
        }
        this.columnsData[k.name].map((ele:any) => {
          if(ele.key == j.key){
            ele.is_checked = j.is_checked
          }
        })
      })
    });
    this.checkedColumns = this.checkedColumnsKey.join(',');
    this.setupTimeID();
  }

  setReportColumnFormControlVlaue(parentKey,childKey,childDisplayName)
  {    
    Object.keys(this.newReportingForm.controls.reportArrayColumnsData.value).forEach((k,row)=>{            
      for (let i of Object.keys(this.newReportingForm.controls.reportArrayColumnsData.value[k].reportColumns)) 
      {
        if(this.newReportingForm.controls.reportArrayColumnsData['controls'][k].controls.name.value == parentKey
        && this.newReportingForm.controls.reportArrayColumnsData['controls'][k].controls.reportColumns.controls[i].controls.key.value == childKey 
        && this.newReportingForm.controls.reportArrayColumnsData['controls'][k].controls.reportColumns.controls[i].controls.display_name.value == childDisplayName
        )
        {
          this.newReportingForm.controls.reportArrayColumnsData['controls'][k].controls.reportColumns.controls[i].controls.user_action.setValue(true);
          return;
        }
      }
    });
    
    // this.newReportingForm.controls.reportArrayColumnsData['controls'][k].controls.reportColumns.controls[j].controls.user_action.setValue(true);
  }

  createCheckBoxes(reportColumns,index){
    for(let reportCol of reportColumns){
      if(reportCol.is_checked){
        this.checkedColumnsKey.push(reportCol.key);
        this.checkedDisplayName.push(reportCol.display_name);
      }    
      const control = <FormArray>this.newReportingForm.controls['reportArrayColumnsData']['controls'][index].controls['reportColumns'];
      control.push(this._fb.group({
          display_name: reportCol.display_name,
          tool_tip: reportCol.tool_tip,
          is_available: reportCol.is_available,
          is_checked: reportCol.is_checked,
          is_default: reportCol.is_default,
          key:reportCol.key,
          user_action: false
       }));       
    }
    this.checkedColumns = this.checkedColumnData.join(','); 
  }


  // Function for open dialog for new condtion
  openCondition(value: any| null, index: number = -1 ) : void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '260px';
    
    const data = {
      columnCategory: this.columnsData,
      value,
      index,
      filterType:this.filterType,
      fixedPattern:Object.keys(this.columnsData)
    }
    this.conditionOverlay = true;
    const dialogRef = this.dialog.open(NewCondtionComponent,{ data, panelClass: 'custom-modalbox' });
      dialogRef.afterClosed().subscribe(result => {
        this.config.currentPage = 1;
        this.conditionOverlay = false;
        switch(result?.status) {
          case 'submitted':
            this.filterCondition?.list.push(result?.data);
            this.setFilterConditionPayload();
            break;
          case 'editted':
            this.filterCondition.list[index] = result?.data;
            this.setFilterConditionPayload();
            break;
          case 'removed':
            this.filterCondition?.list?.splice(index, 1);
            this.setFilterConditionPayload();
            break;
          case 'cancelled':
          default:
            break;
        }
      });
  }

  setFilterConditionPayload() {
    let params:any = {
      condition_type: this.filterCondition?.operator,
    };
    if(this.sortOrderStr) {
      params.sort_column = this.sortOrderStr
    }
    const conditionList = [...CONDITION_BY_DT['string'], ...CONDITION_BY_DT['boolean'], ...CONDITION_BY_DT['date'], ...CONDITION_BY_DT['number']];
    const conditionKeys = conditionList.map((item: any) => item?.key);
    
    const payload = {
      conditions: this.filterCondition?.list?.map((filter: IReportCondition) => {
         return {
          column: filter?.columnKey,
          condition: filter?.conditionType,
          value: this.getValueFormatPipe.transform(filter?.value, filter?.columnType)
         }
      }),
      valid_filter: [...new Set(conditionKeys)]
    };
    this.getFilterPreviewData(params, payload);
  }

  showAlertMsg(msg, type, status = null): void {
    this.alertMsg.msg = msg;
    this.alertMsg.type = type;

    setTimeout(() => {
      this.alertMsg.msg = '';
      this.alertMsg.type = '';
    }, 3000);
  }

  onDateRangeChange(event: any) {
    this.addEditExportFromDate = moment.utc(event[0]).local().format('YYYY-MM-DD');
    this.addEditExportToDate = moment.utc(event[1]).local().format('YYYY-MM-DD');
    this.startDate = event[0];
    this.endDate = event[1];
  }

  addEditExportAllData() {
    this.startDate = new Date(INTIAL_DATE);
    this.endDate = new Date();
    this.bsInlineRangeValue = [this.startDate,this.endDate];
  }

  onExport(){
    try {
      if (this.exportInProgress) {
        this.showAlertMsg('Export report already in progress', 'danger');
        const addEditExportRangeBtn: HTMLElement = this.addEditExportDateCloseBtnRange.nativeElement;
        addEditExportRangeBtn.click();
        return;
      }
      
      if(!this.checkedColumnsKey?.length){
        this.showAlertMsg('You can’t export reports with 0 fields selected! Please select a field and then try again', 'danger');
        const addEditExportRangeBtn: HTMLElement = this.addEditExportDateCloseBtnRange.nativeElement;
        addEditExportRangeBtn.click();
        return;
      }

      this.exportInProgress = true;
      const { reportId } = this.activatedRoute?.snapshot?.queryParams;
      if(reportId){
        const reportIndex = this.reportIds.findIndex(r => r === reportId);
        if(reportIndex) this.reportIds.push(reportId);
        this.saveAndExport();
        
      }else {
        if(this.beforeExportSaveReportFlag) this.saveAndExportCurrentReport();
        else this.showExportSaveConfirmPopUp = true;
      }
      const addEditExportRangeBtn: HTMLElement = this.addEditExportDateCloseBtnRange.nativeElement;
      addEditExportRangeBtn.click(); 
    } catch (error) {
      this.exportInProgress = false;
    }
    
  }

  saveAndExport = async() => {
    const data  = this.payloadForReport();
    const { reportId } = this.activatedRoute?.snapshot?.queryParams;
    
    if(await this.checkDeepEqual()){
      this.updateReport(this.charityId,reportId ,data,false);
      return;      
    }

    this.openReportConfirmationComponent(this.charityId, reportId, data,false,false,false);
    // this.getSelectedReportTaskID("addEditExport");
  }

  beforeExportSaveReportData(confirm:string)
  {
    this.showExportSaveConfirmPopUp = false;
    if(confirm == 'no'){
      this.saveNewReportData('beforeExportSave',false);
      return;
    }
    this.beforeExportSaveReportFlag = true;
    this.saveNewReportData('beforeExportSave',true);
  }

  getSelectedReportTaskID(from,reportId = []) { 
    this.loader = true;    
    const apiData = {
      from_date: from=="home" ? this.fromDate : this.addEditExportFromDate,
      to_date: from=="home" ? this.toDate : this.addEditExportToDate,
      report_id: reportId.length ? reportId : this.reportIds
    };   
    
    this._reportService
    .getSelectedReportTaskID(this.charityId, apiData,this.newReport.emailOnly)
    .pipe(finalize(() => this.loader = false))
    .subscribe(
      resp => {
        if(resp.status==200)
        {
          this.showReportDownloadAlertMsg("If you're downloading a large amount of data, this could take a few minutes. The file will automatically save to your computer once complete. Please don't close this page.", 'success');
          // this.setToUnselectReport();
          this.startTimer(resp.celery_task_id);
        }
      }, 
      error => {
        this.exportInProgress = false;
        this.showAlertMsg(error.message, 'danger')
      }
    );
  }

  getReportDownloadLinks(celeryTaskId)
  {  
    try {
      this._reportService
      .getReportDownloadLinks(this.charityId, celeryTaskId)
      .pipe().subscribe(
        resp => {
          // const MainBtn: HTMLElement = this.closeMainModel.nativeElement;
          // MainBtn.click();
          if(resp.status==200)
          {
            if(resp.task_status == 'Success')
            {
              this.exportInProgress = false;
              if(!resp.url){
                this.showReportDownloadAlertMsg(resp.message, 'danger');
                this.downloadCSVClicked();
                if (this.reportSubscription) this.reportSubscription.unsubscribe();
                clearInterval(this.dwnldReportInterval);
                return
              }
              if (this.reportSubscription) this.reportSubscription.unsubscribe();
              this.reportMultiDownloadURL = [];
              this.reportDownloadData = resp;
              this.reportDownloadURL = resp.url;
              clearInterval(this.dwnldReportInterval);
              window.open(resp?.url,"_self");
              return;
            }
          }
          else
          {
            clearInterval(this.dwnldReportInterval);
            //to disappear last alert
            this.alertMsg.msg = '';
            this.alertMsg.type = '';
            this.exportInProgress = false;
            this.showAlertMsg(resp.message, 'danger');
            this.downloadCSVClicked();
          }
        }, 
        error => {
          clearInterval(this.dwnldReportInterval);
          //to disappear last alert
          this.alertMsg.msg = '';
          this.alertMsg.type = '';
          this.exportInProgress = false;
          this.showAlertMsg(error.message, 'danger');
          this.downloadCSVClicked();
        }
      );
      
    }catch (error) {      
      clearInterval(this.dwnldReportInterval);
    }
  }

  downloadCSVClicked() {    
    // this.setToUnselectReport();
    // this.reportDownloaded = true;
    this.reportIds.length = 0;
    this.indexIds.length=0;
    this.reportDownloadData = {};
    this.reportDownloadURL = '';
    this.reportMultiDownloadURL = [];
  }

  // togggle for email only for new report
  emailOnlyToggle(evt:any){
    this.config.currentPage = 1;
    this.newReport.emailOnly = evt.target.checked;
    this.setFilterConditionPayload();
  }

  showReportDownloadAlertMsg(msg, type, status = null): void {
    this.alertMsg.msg = msg;
    this.alertMsg.type = type;

    setTimeout(() => {
      this.alertMsg.msg = '';
      this.alertMsg.type = '';
    }, 6000);
  }

  startTimer(celeryTaskId) {
    //in 30 seconds check report status
    this.dwnldReportInterval = setInterval(()=>{
      this.getReportDownloadLinks(celeryTaskId);
    },30000);
  }

  onOperatorChange() {
    if (this.filterCondition?.list?.length) {
      this.setFilterConditionPayload();
    }
  }

  async checkDeepEqual(rid = null){
    let isDeepEqual = true;
    const { reportId } = this.activatedRoute?.snapshot?.queryParams;
    let report:any;
    if(reportId || rid){
    
      const apiData = {
        charity_id: this.charityId,
        report_id: reportId || rid
      };
      report = await this._reportService.getSavedReport(apiData);
    }
    const previousReportData:any = report?.data || {};
    if (Object.keys(previousReportData).length > 0) {
      let newReportColumn = {};
      this.newReportingForm.value.reportArrayColumnsData.forEach((ele) => {
        let mappedEle = ele.reportColumns.map((v) => {
          return {
            "is_checked": v.is_checked
          }
        });

        newReportColumn[ele.name] = mappedEle;
      });
      Object.keys(previousReportData.selected_columns.report_columns).forEach((ele) => {
        let mappedEle = previousReportData.selected_columns.report_columns[ele].map((v) => {
          return {
            "is_checked": v.is_checked
          }
        })
        previousReportData.selected_columns.report_columns[ele] = mappedEle
      });
      
      let previousData: any = {};
      let newData: any = {};
     
      previousData.report_name = previousReportData.report_name;
      previousData.filter_type = previousReportData.filter;
      previousData.report_columns = previousReportData.selected_columns.report_columns;
      previousData.email_only = previousReportData.email_only;
      previousData.from_date = previousReportData?.from_date ? moment(previousReportData?.from_date).format('MM/DD/YYYY'):'';
      previousData.to_date= previousReportData?.to_date ? moment(previousReportData?.to_date).format('MM/DD/YYYY'):'';
      previousData.condition_type = previousReportData?.condition_type || 'All';
      previousData.filter_conditions = previousReportData?.filter_conditions?.conditions?.length || 0;
      previousData.filter_conditions_data = previousReportData?.filter_conditions?.conditions || [];

      newData.report_name = this.newReportingForm.value.report_name;
      newData.filter_type = this.newReportingForm.value.filter_type;
      newData.report_columns = newReportColumn;
      newData.email_only = this.newReport.emailOnly;
      newData.from_date = this.fromDate ? moment(this.fromDate).format('MM/DD/YYYY'):'';
      newData.to_date= this.toDate ? moment(this.toDate).format('MM/DD/YYYY'): '';
      newData.condition_type = this.filterCondition?.operator || 'All';
      newData.filter_conditions = this.filterCondition?.list?.length || 0;
      newData.filter_conditions_data = [];
      const filterConds = [];
      if(newData.filter_conditions) {
        this.filterCondition.list?.forEach((ele:any) => {
          let obj:any = {};
          obj.column = ele.columnKey;
          obj.condition = ele.conditionType;
          obj.value = ele.value;
          filterConds.push(obj);
        })
        newData.filter_conditions_data = filterConds || [];
      }
      isDeepEqual = this.deepEqual(previousData, newData);
      return isDeepEqual;
    }
  }

  // to check two object equals or not
  deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];

      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        areObjects && !this.deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }
    return true;
  }

  isObject(object) {
    return object != null && typeof object === 'object';
  }
  
  reportSortOrder(order:string,heading:string) {
    this.config.currentPage = 1;
    const columnkey = this.checkedColumnsKey[this.checkedDisplayName?.findIndex((ele) => ele == heading)];
    this.sortOrderStr = columnkey + ' ' +order; 
    this.setFilterConditionPayload();
  }

  ngOnDestroy() {
    this.reportNameSubscription?.unsubscribe();
    this.newSaveReportSubscription?.unsubscribe();
    if (this.deleteSubscription) this.deleteSubscription.unsubscribe();
  }

}
