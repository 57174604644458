<ng-container *ngIf="message">
  <span class="name" *ngIf="countNumberOfWords > 20 && less; else lessTemplete">
    {{ showLessMessage }}
    <span
      class="btn btn-sm btn-secondary show-data-btn"
      *ngIf="countNumberOfWords > 20"
      (click)="showMoreBtn()">show more...</span>
  </span>

  <ng-template #lessTemplete>
    <span class="name">
      {{ showMoreMesssage }}
      <span
        class="btn btn-sm btn-secondary show-data-btn"
        *ngIf="countNumberOfWords > 20"
        (click)="showLessBtn()">show less</span>
    </span>
  </ng-template>
</ng-container>

