<div class="app-header header-shadow" id="sticky">   

      <!-- <div class="alert-wrapper p-4 col col-12 col-md-4 offset-0 offset-md-8" *ngIf="closeFlag && selectedNgo.charityId">
        <div class="alert alert-warning" role="alert">
          <span class="alert-message">
            <span class="alert-icon" style="height: 60px;">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            Please note that Challenge donations are in  UTC time.  We’re working to align the donations to your preferred time zone so they show up on the appropriate day.
          </span>
          <span class="close warning-close" data-dismiss="alert" aria-label="Close" (click)="closeWarning()"> Close </span>
        </div>
      </div> -->
      <div class="organisation" *ngIf="selectedNgo">        
        <div class="btn-group" [ngClass]="{'PointerNone': userRole === 'ROLE_ADMIN' && (filteredNgoList$ | async)?.length == 1}">
            <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <div class="imageIcon">
                    <img [src]="selectedNgo?.profilePicture" class="circle"> 
                    <!-- <span class="selectedOrgName">{{selectedNgo?.name}}</span> -->
                </div>
                <div class="orgName" [title]="selectedNgo?.name">
                    {{ selectedNgo?.name }}
                    <i *ngIf="(filteredNgoList$ | async)?.length > 1" class="fa fa-caret-down" aria-hidden="true"></i>
                </div>                
            </button>
            <div class="ngo-wrapper dropdown-menu">
                <div class="searchBox">
                    <input type="search" class="search" #searchInput (input)="filter($event)" placeholder="Search">
                    <span class="searchIcon"></span>
                </div>
                <ul id="style-3" *ngIf="selectedNgo">
                    <ng-container *ngFor="let ngo of filteredNgoList$ | async">
                        <li *ngIf="ngo.charityId !== selectedNgo.charityId">
                            <div class="imageIcon">
                                <img [src]="ngo.profilePicture" alt="">
                            </div>
                            <div class="orgName" [title]="ngo.name" (click)="setPage(ngo)">
                                {{ ngo.name }}
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>

    </div>

    <div class="app-header__logo">
        <nav class="navbar navbar-default" role="navigation">
            <div class="navbar-header" (click)="openNav()">
                <button type="button" class="navbar-toggle x collapsed" data-toggle="collapse"
                    data-target="#navbar-collapse-x">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>                    
            </div>
        </nav>
    </div>

    <div class="user-widget-content">
        <div class="btn-group">
            <a type="button" class="btn p-0 dropdown-toggle" data-toggle="dropdown">
                <img width="32" class="rounded-circle" [src]="getImage(user?.user_profile_pic)" alt="">
                <i class="fa fa-angle-down ml-2 opacity-8"></i>
            </a>
            <div class="dropdown-menu u-open">
                <div class="dropdown-item">
                    <ul class="user-details">
                        <li>
                            <p class="username">{{ user?.name }}</p>
                            <p class="useremail">{{ user?.email }}</p>
                        </li>
                        <!-- <li><a *ngIf="userRole == 'ROLE_SUPER_ADMIN'" [routerLink]="['/organizations']" class="setting" href="javascript:void(0);"><img src="../../assets/images/setting.svg" alt="">
                                <span>Settings</span></a></li> 
                             <li *ngIf="userRole == 'ROLE_ADMIN'"><a [routerLink]="['/integrations']" class="setting" href="javascript:void(0);"><img src="../../assets/images/setting.svg" alt="">
                                    <span>Settings</span></a></li> -->
                        <li><a class="logout" (click)="signOut()">Log Out</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>