import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { TacticService } from 'src/app/services/tactic.service';
import { NgoSharedService } from 'src/app/shared/services/ngo-shared.service';
import { TacticList } from 'src/app/models/TacticList.model';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditTacticStatusComponent } from 'src/app/shared/componentss/edit-tactic-status/edit-tactic-status.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CustomValidationMessages } from 'src/app/shared/custom/custom-validation-messages';
import { AddTacticComponent } from '../add-tactic/add-tactic.component';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';

@Component({
  selector: 'app-tactic-list',
  templateUrl: './tactic-list.component.html',
  styleUrls: ['./tactic-list.component.scss']
})
export class TacticListComponent implements OnInit, OnDestroy {
  public selectedNgo: SelectedNgo;
  loading = false;
  showLoader = false;
  charityID: any;
  tacticLists = [];
  public charityIDSubscription: Subscription;
  sortedData: TacticList[];
  currentPage = 1;
  totalRecords: number;
  pages = [];
  config: any;
  currentPageCount: number;
  sortingField: string;
  orderBy: string;
  public alertMsg = { type: '', msg: '' };
  public tacticCategory = [];
  public tacticUpdateForm: UntypedFormGroup;
  public validationMessages = CustomValidationMessages.validationMessages;
  public formErrors = {
    tacticName: '',
    tacticDescription: '',
    tacticExample:'',
    templateCategory: '',
    status: '',
  };

  constructor(
    private tacticService: TacticService,
    private _ngoSharedService: NgoSharedService,
    private router: Router,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
  ) {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalRecords
    };
  }

  ngOnInit() { 
    this.getCharityId();
  }


  ngoChanged($event) { if ($event) { } }
  

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        selectedNGO => {
          this.config.currentPage = 1;
          this.sortingField = 'tactic_id';
          this.orderBy = 'asc';
          this.sortedData = [];
          if (selectedNGO.charityId !== null) {
            this.loading = true;
            this.charityID = selectedNGO.charityId;            
            this.viewTacticList();
            this.getTacticCategory();
          } else this.hideLoader(selectedNGO);
        },
        err => this.hideLoader('Charity ID Not Found')
      );
  }


  openAddTactic() {   
    const dialogRef = this.dialog.open(AddTacticComponent,{
      width: '460px',     
    });
  }

  getTacticCategory() {
    this.tacticService
      .getTacticCategory(this.charityID)
      .subscribe(
        res => {          
          this.tacticCategory = res.tactic_categories_list;          
        },
        err => this.showAlertMsg(err.message, 'danger')
      );
  }

  viewTacticList() {
    this.loading = true;
    this.tacticService.getTacticsList(this.charityID, this.config.currentPage, this.sortingField, this.orderBy).subscribe(
      (res: any) => {
        if (res) {
          this.tacticLists = res.message;
          this.pages = res.pages;
          this.totalRecords = res.tactic_detail_data_count;
          this.config.totalItems = this.totalRecords;
          this.currentPageCount = this.config.itemsPerPage * (this.config.currentPage - 1) + this.tacticLists.length;
          this.sortedData = this.tacticLists.slice();
          this.loading = false;
        }
      },
      err => this.loading = false
    );
  }

  onPage(val) {
    this.currentPage = val;
    this.viewTacticList();
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.viewTacticList();
  }

  hideLoader(msg: any): void { this.loading = false; }

  openDialog(tactic) {     
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '30%';
    dialogConfig.data = {
      charityId: this.charityID,
      messageId: tactic.tactic_id,
      mStatus: tactic.status
    };

    const dialogRef = this.dialog.open(EditTacticStatusComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe(res => {
        this.showAlertMsg(res.msg, res.type);
        this.viewTacticList();
      });
  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg = msg;
    this.alertMsg.type = type;
  }

  displayTacticForm() { this.router.navigate(['/activation-message/tactics/add']); }

    sortData(sort: Sort) {
    this.sortingField = sort.active;
    this.orderBy = sort.direction;
    this.viewTacticList();
    const data = this.tacticLists.slice() as TacticList[];

    if (!sort.active || sort.direction === '') return this.sortedData = data;

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'tactic_id': return compare(a.tactic_id, b.tactic_id, isAsc);
        case 'tactic_name': return compare(a.tactic_name, b.tactic_name, isAsc);
        case 'tactic_category_name': return compare(a.tactic_category_name, b.tactic_category_name, isAsc);
        case 'template': return compare(a.template, b.template, isAsc);
        case 'tactic_example': return compare(a.tactic_example, b.tactic_example, isAsc);
        case 'total_messages_posted': return compare(a.total_messages_posted, b.total_messages_posted, isAsc);
        case 'optins': return compare(a.optins, b.optins, isAsc);
        case 'messages_posted_for_org': return compare(a.messages_posted_for_org, b.messages_posted_for_org, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        case 'optins_percentage': return compare(a.optins_percentage, b.optins_percentage, isAsc);
        case 'org_optins': return compare(a.optins_percentage, b.optins_percentage, isAsc);
        case 'org_optins_percentage': return compare(a.optins_percentage, b.optins_percentage, isAsc);
        case 'created_date': return compare(a.created_date, b.created_date, isAsc);
        default: return 0;
      }
    });

  }

  ngOnDestroy() { 
    if(this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
   }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
