export const CreateEventFormErrors = {
    event_name: '',
    start_date: '',
    end_date: '',
    month: '',
    fundraiser_goal: '',
    opt_in_url: '',
    group_url: '',
    event_identifier: '',
    asana_project_id: '',
    event_series_id: '',
    activity_label: '',
    activity_goal: '',
    fifty_activity_mark: '',
    hundred_activity_mark: '',
};
