import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowMoreButtonComponent } from '../componentss/show-more-button/show-more-button.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertComponent } from 'src/app/alert/alert.component';
import { MessageDetailComponent } from 'src/app/test/message-detail/message-detail.component';
import { ActivationTabsComponent } from 'src/app/activation-message/activation-tabs/activation-tabs.component';
import { GudatepickerComponent } from '../componentss/gudatepicker/gudatepicker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SideMenuComponent } from '../componentss/side-menu/side-menu.component';
import { NewHeaderComponent } from '../componentss/new-header/new-header.component';
import { ActiveEventsComponent } from '../componentss/active-events/active-events.component';
import { SubscriptionGraphComponent } from '../componentss/subscription-graph/subscription-graph.component';
import { ActiveSubscribersListComponent } from '../componentss/active-subscribers-list/active-subscribers-list.component';
import { CallToActionGraphComponent } from '../componentss/call-to-action-graph/call-to-action-graph.component';
import { ConversionGraphComponent } from '../componentss/conversion-graph/conversion-graph.component';
import { DateMonthFilterComponent } from '../componentss/date-month-filter/date-month-filter.component';
import { AdminSideMenuComponent } from '../componentss/admin-side-menu/admin-side-menu.component';
import { AdminNewHeaderComponent } from '../componentss/admin-new-header/admin-new-header.component';
import { HighlightSearch } from 'src/app/services/highlight.pipe';
import { HighlightSearchSentence } from 'src/app/services/highlightsententence.pipe';
import { ReportDataListComponent } from '../componentss/report-data-list/report-data-list.component';
import { nl2brPipe } from 'src/app/services/nl2br.pipe';
import { ReportDataDialogComponent } from '../componentss/report-data-dialog/report-data-dialog.component';
import { ReportDeleteConfirmationComponent } from '../componentss/report-delete-confirmation/report-delete-confirmation.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NumFormatterPipe } from '../pipes/num-formatter.pipe';
import { FormatText } from '../pipes/format.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        AngularMaterialModule,
        NgxDaterangepickerMd.forRoot({ separator: ' to ', }),
        InfiniteScrollModule,
    ],
    declarations: [
        ShowMoreButtonComponent,
        AlertComponent,
        MessageDetailComponent,
        ActivationTabsComponent,
        GudatepickerComponent,
        SideMenuComponent,
        NewHeaderComponent,
        ActiveEventsComponent,
        SubscriptionGraphComponent,
        ActiveSubscribersListComponent,
        CallToActionGraphComponent,
        ConversionGraphComponent,
        DateMonthFilterComponent,
        AdminSideMenuComponent,
        AdminNewHeaderComponent,
        HighlightSearch,
        HighlightSearchSentence,
        ReportDataListComponent,
        nl2brPipe,
        ReportDataDialogComponent,
        ReportDeleteConfirmationComponent,
        NumFormatterPipe,
        FormatText,
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        NgxPaginationModule,
        NgxDaterangepickerMd,
        ShowMoreButtonComponent,
        AlertComponent,
        MessageDetailComponent,
        ActivationTabsComponent,
        GudatepickerComponent,
        SideMenuComponent,
        NewHeaderComponent,
        ActiveEventsComponent,
        SubscriptionGraphComponent,
        ActiveSubscribersListComponent,
        CallToActionGraphComponent,
        ConversionGraphComponent,
        DateMonthFilterComponent,
        AdminSideMenuComponent,
        AdminNewHeaderComponent,
        HighlightSearch,
        HighlightSearchSentence,
        ReportDataListComponent,
        nl2brPipe,
        ReportDataDialogComponent,
        ReportDeleteConfirmationComponent,
        InfiniteScrollModule,
        FormatText,
    ]
})
export class SharedModule { }
