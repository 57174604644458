<div class="continer-fluid">
  <div class="new-dashboard-wrapper">

    <!-- app header -->
    <app-new-header></app-new-header>
    <!-- Side Menu -->
    <app-side-menu (ngoChanged)="ngoChanged($event)"></app-side-menu>

    <ng-container *ngIf="alertMsg.msg !== ''">
      <app-alert [message]="alertMsg"></app-alert>
    </ng-container>

    <div class="grid-wrapper">
      <div class="row no-gutters">
        <div class="col-12">

          <div class="heading_wrapper">
            <div class="page-title">Tactics</div>
            <div class="position-relative">
              <!-- <ul class="topOption">
                              <li>
                                  <div class="searchBox">
                                      <input type="search" class="searchText" #searchInput (input)="searchThis(searchInput.value)" placeholder="Search">
                                      <span class="searchIcon"></span>
                                  </div>
                              </li>
                              <li>
                                  <div class="filterBox">
                                      <div class="filter-wrapper">
                                          <div class="filterText">Filters: {{ coltype }}</div>
                                          <div class="custom-dropdown position-absolute">
                                              <div class="dropdown">
                                                  <a class="dropdown-toggle gu-dropdown-toggle" href="#" role="button"
                                                      id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                      aria-expanded="false">
                                                  </a>
              
                                                  <div class="dropdown-menu w-open w-145" aria-labelledby="dropdownMenuLink">                                                  
                                                      <a class="dropdown-item" [ngClass]="{'active': coltype == 'Fundraisers'}" (click)="changeColumn('Fundraisers')">Fundraisers</a>
                                                      <a class="dropdown-item" [ngClass]="{'active': coltype == 'Donors'}" (click)="changeColumn('Donors')">Donors</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                 </div> 
                                 <div class="sresult" *ngIf="fundraiserSearchLoader">
                                  <ng-container *ngIf="totalSearchResult > 0">
                                      <a href="javascript:void(0)">
                                          {{searchStatus}}
                                      </a>
                                  </ng-container>                                  
                                </div> 
                              </li>
                              <li>
                                  <div class="columnBox">
                                      <div class="editCol">
                                         <a class="fontstyle" *ngIf="coltype == 'Fundraisers'" data-toggle="modal" data-target="#Fundraisers"><i class="fa fa-pencil" aria-hidden="true"></i> Columns</a>
                                         <a class="fontstyle" *ngIf="coltype == 'Donors'"  data-toggle="modal" data-target="#Donor"><i class="fa fa-pencil" aria-hidden="true"></i> Columns</a>
                                      </div>
                                  </div> 
                              </li>
                              <li>
                                  <div class="dateBox">
                                      <div class="date-wrapper">
                                          <ng-container *ngIf="dateFilterFlag; else chooseDateTemplate">
                                              <div (click)="openDatepicker()" class="dateRangeWrapper form-wrapper" data-toggle="modal" data-target="#reportsdaterangepick">
                                                  <span></span>
                                                  <input type="text" class="form-control min-wd-240" [value]="fromDate + ' to ' + toDate">
                                              </div>
                                          </ng-container>
                                          
                                          <ng-template #chooseDateTemplate>
                                              <a (click)="openDatepicker()" class="ngx-daterangepicker-action fontstyle" data-toggle="modal" data-target="#reportsdaterangepick"><i class="fa fa-calendar-o" aria-hidden="true"></i> Choose Date Range</a>
                                          </ng-template>
                                        
                                      </div>
                                  </div> 
                              </li>
                              <li>
                                  <div class="emailBox">
                                    <div class="editMail">
                                      <label class="checkbox-container">Email Only
                                          <input type="checkbox" [(ngModel)]="emailsOnlyFlag" (change)="checkEmailsOnly($event)">
                                          <span class="checkmarkBox"></span>
                                      </label>
                                    </div>
                                  </div>   
                              </li>
                          </ul> -->
                <div class="btnWrapper">
                    <button (click)="openAddTactic()" class="custom-btn" type="submit">Add Tactic</button>
                </div> 
            </div>
          </div>

          <div class="content-box">
            <div class="overlay__inner custom-overlay" *ngIf="loading">
              <div class="overlay__content">
                <span class="spinner loadImg"></span>
              </div>
            </div>

            <div class="table-responsive">
              <div class="reporting-outer">
                <div class="reporting-inner">
                  
                    <table class="table table-borderless" matSort (matSortChange)="sortData($event)"
                      [matSortActive]="sortingField" [matSortDirection]="orderBy" matSortDisableClear>
                      <thead>
                        <tr>
                          <th class="serial" mat-sort-header="tactic_id">ID</th>
                          <th class="w-20" mat-sort-header="tactic_name">Tactic Name</th>
                          <th class="w-20" mat-sort-header="tactic_category_name">Category</th>
                          <th class="w-20" mat-sort-header="template">Description</th>
                          <th class="w-20" mat-sort-header="tactic_example">Example</th>
                          <th mat-sort-header="total_messages_posted">Messages Posted (all orgs)</th>
                          <th mat-sort-header="optins">Opt-ins (all orgs)</th>
                          <th mat-sort-header="optins_percentage">Opt-in % (all orgs)</th>
                          <th mat-sort-header="messages_posted_for_org">Messages Posted</th>
                          <th mat-sort-header="org_optins">Opt-in</th>
                          <th mat-sort-header="org_optins_percentage">Opt-in %</th>
                          <th style="width: 120px;" mat-sort-header="message_status">Enable/<br>Disable</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="sortedData?.length > 0; else NotFound">
                          <tr *ngFor="let tactic of sortedData | paginate: config; let idx = index">
                            <td class="serial">{{tactic.tactic_id}}</td>
                            <td><span class="name">{{tactic.tactic_name}}</span></td>
                            <td><span class="name">{{tactic.tactic_category_name}}</span></td>
                            <td>
                              <span class="name">
                                <app-show-more-button [message]="tactic.template ==''?'-':tactic.template">
                                </app-show-more-button>
                              </span>
                            </td>
                            <td>
                              <span class="name">
                                <app-show-more-button [message]="tactic.tactic_example ==''?'-':tactic.tactic_example">
                                </app-show-more-button>
                              </span>
                            </td>
                            <td><span class="count">{{tactic.total_messages_posted | number:'1.0-0'}}</span></td>
                            <td>
                              <span class="count">{{tactic.optins | number:'1.0-0'}}</span>
                            </td>
                            <td>
                              <span class="count">{{tactic.optins_percentage | number : '1.0-2'}}%</span>
                            </td>
                            <td><span class="count">{{tactic.messages_posted_for_org | number:'1.0-0'}}</span></td>
                            <td><span class="count">{{tactic.org_optins | number:'1.0-0'}}</span></td>
                            <td><span class="count">{{tactic.org_optins_percentage | number : '1.0-2'}}%</span></td>
                            <td style="text-align: center;">
                              <div class="switcher-wrapper">
                                <label class="connect-switch" for="{{tactic.tactic_id}}">                                 
                                  <input [id]="tactic.tactic_id" [(ngModel)]="tactic.status" [value]="tactic.tactic_id"
                                    type="checkbox" (click)="openDialog(tactic)">
                                  <span class="slider round"></span>
                               </label>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-template #NotFound>
                          <tr>
                            <td colspan="12" style="text-align: center;">No data found</td>
                          </tr>
                        </ng-template>
                      </tbody>
                    </table>
                 
                </div>
              </div>
            </div>

          </div>
          <div class="newGuPagination" *ngIf="tacticLists?.length>0">
            <pagination-controls [directionLinks]="false" (pageChange)="pageChanged($event)">
            </pagination-controls>
            <span class="countNumber">{{currentPageCount}}/{{totalRecords}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="shape-bg">
  <div class="c-shape-bg is--full rt-25">
  </div>
</div>