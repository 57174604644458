import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgoSharedService } from '../shared/services/ngo-shared.service';
import { IntegrationService } from '../services/integration.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { BlackbaudComponent } from '../blackbaud/blackbaud.component';

@Component({
  selector: 'app-cardintegrations',
  templateUrl: './cardintegrations.component.html',
  styleUrls: ['./cardintegrations.component.scss']
})
export class CardintegrationsComponent implements OnInit, OnDestroy {
  public isCharityIdExistInServer = true;
  public charityId: string;
  public connected = false;
  public message: string;
  public loading = false;
  public charityIdSubscription: Subscription;
  public setTimeOutForErr: any;

  constructor(
    private _ngoSharedService: NgoSharedService,
    private integrationService: IntegrationService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.checkNgoconnection();   
  }

  checkNgoconnection() {
    this.loading    = false;
    this.charityIdSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          (res: any) => {            
            if (res.charityId !== null) {
              this.charityId  = res.charityId;
              this.connected = res.connected;
              this.loading    = false;
              this.getBlackbaudConnection();
            }
          },
          err => this.loading = false
        );
  }




  getBlackbaudConnection() {
    this.integrationService
        .getBlackbaudConnection(this.charityId)
        .subscribe(
          (res: any) => {
            this.connected  = res.connected;           
            this.loading    = false;
            //if (!res.connected) this.message = res.message;
          },
          error => {
            if (error.status === 404) this.isCharityIdExistInServer = false;
            //this.message    = error.message;
            this.loading    = false;
            this.connected  = false;
          }
        );
  }

  closeErr() {
    this.message = '';
    this.loading    = false;
    this.connected  = false;
    clearTimeout(this.setTimeOutForErr);
  }

  displayConnectAccountPage() {
    const dialogRef = this.dialog.open(BlackbaudComponent, {
      width: '360px',     
    });

  }

  ngOnDestroy() {   
    this.charityIdSubscription.unsubscribe();
  }

}
