<div class="continer-fluid">
    <div class="new-dashboard-wrapper">
    
        <div class="overlay" *ngIf="loader">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner">Loading...</span></div>
            </div>
        </div>
    
        <!-- app header -->
        <app-new-header></app-new-header>
    
        <!-- Side Menu -->
        <app-side-menu></app-side-menu>
    
        <ng-container *ngIf="alertMsg.msg !== ''">
            <app-alert [message]="alertMsg"></app-alert>
        </ng-container>
    
        <!-- Main Content -->
        <div class="grid-wrapper">
        <div class="row no-gutters">
    
            <div class="col-12 col-lg-12">
    
                <div class="breadcrumbs">
                    <ul>
                        <li>
                            <a [routerLink]='"/events"'>Challenges</a>
                        </li>
                        <li>
                            <a [routerLink]="['/events/detail/', eventID]">Challenge Details</a>
                        </li>
                        <li class="active">
                            <a href="javascript:void(0)">Edit Challenge</a>
                        </li>
                    </ul>
                </div>
                
                <div class="heading_wrapper mbTitle">
                    <div class="page-title">Edit Challenge</div>                        
                </div>
    
                <form [formGroup]="editEventForm" (ngSubmit)="submitForm()">
                    <div class="form-content mb-4">                      
                        <div class="details mb-2">Details</div>
                        <div class="form-wrapper">
    
                            <div class="form-group row">
                                <label for="event-name" class="col-sm-4 col-form-label">
                                    Challenge Name
                                </label>
                                <div class="col-sm-8">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="My New Challenge"
                                        formControlName="event_name"
                                        (input)="logValidationErrors()">
    
                                    <div class="form-error" *ngIf="formErrors.event_name">
                                        {{ formErrors.event_name }}
                                    </div>
                                </div>                                
                            </div>
    
                            <div class="form-group row">
                                <label class="col-form-label" for="duration" class="col-sm-4 col-form-label">Duration</label>
    
                                <div class="col-sm-8">
                                    <div class="row no-gutters">
                                        <div class="col-sm-5">
                                            <label class="col-form-label pt-0" for="startDate">Start Date</label>
                                            <input
                                                type="text"
                                                placeholder="Start Date"
                                                class="form-control wt-148"
                                                formControlName="start_date"
                                                ngxDaterangepickerMd
                                                [autoApply]="true"
                                                [showClearButton]="true"
                                                [locale]="locale"
                                                [singleDatePicker]="true"
                                                [minDate]="startMinDate"
                                                (change)="setEndDate($event)"
                                                (datesUpdated)="datesUpdated($event)"
                                                readonly>
                                                <span class="arrow"></span> 
                                            <div class="form-error" *ngIf="formErrors.start_date">
                                                {{ formErrors.start_date }}
                                            </div>
                                        </div>
    
                                        <div class="col-sm-5">
                                            <label class="col-form-label pt-0" for="endDate">End Date</label>
                                            <input
                                                type="text"
                                                placeholder="End Date"
                                                class="form-control wt-148"
                                                formControlName="end_date"
                                                ngxDaterangepickerMd
                                                [autoApply]="true"
                                                [locale]="locale"
                                                [singleDatePicker]="true"
                                                [showClearButton]="true"
                                                [minDate]="endMinDate"
                                                (change)="setMonth()"
                                                readonly>
                                                <span class="arrow"></span>
                                            <div class="form-error" *ngIf="formErrors.end_date">
                                                {{ formErrors.end_date }}
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
    
                            <div class="form-group row">
                                <label class="col-form-label" for="duration" class="col-sm-4 col-form-label">Month</label>
                                <div class="col-sm-8">
                                    <div class="row no-gutters">
                                        <div class="col-sm-6">                                       
                                            <select
                                                class="form-control wt-148"
                                                formControlName="month"
                                                (change)="logValidationErrors()">
                                                    <option value="" disabled selected>Month</option>
                                                    <option *ngFor="let month of months" [value]="month.index">
                                                        {{ month.name }}
                                                    </option>
                                            </select>
    
                                            <div class="form-error" *ngIf="formErrors.month">
                                                {{ formErrors.month }}
                                            </div>
                                        </div>                                      
                                    </div>
                                </div>    
                            </div>
                            <div class="form-group row">
                                <label for="fundraiser-goal" class="col-sm-4 col-form-label">Fundraiser Goal</label>
                                <div class="col-sm-8">
                                    <input
                                        type="number"
                                        class="form-control wt-198 number"
                                        [placeholder]="chkCurrency"
                                        formControlName="fundraiser_goal"
                                        (input)="logValidationErrors()">
    
                                    <div class="form-error" *ngIf="formErrors.fundraiser_goal">
                                        {{ formErrors.fundraiser_goal }}
                                    </div>                                
                                </div>                                
                            </div>
                            <div class="form-group row">
                                <label for="optinurl" class="col-sm-4 col-form-label">Opt In  URL</label>
                                <div class="col-sm-8">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="https://"
                                        formControlName="opt_in_url"
                                        (input)="logValidationErrors()">
    
                                    <div class="form-error" *ngIf="formErrors.opt_in_url">
                                        {{ formErrors.opt_in_url }}
                                    </div>                         
                                </div>                                
                            </div>
                            <div class="form-group row">
                                <label for="optinurl" class="col-sm-4 col-form-label">Group  URL</label>
                                <div class="col-sm-8">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="https://"
                                        formControlName="group_url"
                                        (input)="logValidationErrors()">
    
                                    <div class="form-error" *ngIf="formErrors.group_url">
                                        {{ formErrors.group_url }}
                                    </div>                
                                </div>                                
                            </div>
                            <div class="form-group row">
                                <label for="eventurl" class="col-sm-4 col-form-label">Challenge Image</label>
                                <div class="col-sm-8">

                                    <div class="custom-upload-wrapper">

                                      <div class="file-upload" *ngIf="!eventDetail?.event_image_url">
                                        <span class="ui-fileupload-choose ui-button-update ui-widget ui-state-default ui-corner-all ui-button-text-icon-left" pbutton="">
                                            <input type="file" accept="image/*" title="" (change)="dealWithFiles($event)">
                                            <span aria-hidden="true" class="ui-button-icon-left ui-clickable pi pi-plus"></span>
                                            <span class="ui-button-text ui-clickable">Upload image</span>
                                        </span> 
                                      </div>

                                      <div class="file-upload-content" (click)="editEventImageInput.click()" *ngIf="base64 || eventDetail?.event_image_url">
                                        <div class="fileUpload-files">
                                            <div class="fileImage">
                                            <input type="file" accept="image/*"  #editEventImageInput (change)="dealWithFiles($event)" style="display: none;">
                                             <img [src]="base64 ? base64 : eventDetail?.event_image_url || base64" class="ng-star-inserted">
                                               <div class="overlay">
                                                  <div class="text">Click to replace image</div>
                                               </div>
                                            </div>
                                        </div>
                                     </div>
                                    </div>
    
                                </div>                                
                            </div>
    
                            <div class="form-group row">
                                <label for="eventIdentifier" class="col-sm-4 col-form-label">Challenge Identifier</label>
                                <div class="col-sm-8">
                                    <input
                                        type="text"
                                        class="form-control wt-240"
                                        placeholder="Challenge Identifier"
                                        formControlName="event_identifier"
                                        (input)="logValidationErrors()">
    
                                    <div class="form-error" *ngIf="formErrors.event_identifier">
                                        {{ formErrors.event_identifier }}
                                    </div>                        
                                </div>                                
                            </div>
    
                            <div class="form-group row">
                                <label for="eventseriesname" class="col-sm-4 col-form-label">Challenge Series Name</label>
                                <div class="col-sm-8" *ngIf="eventListSeriesActivities">
                                    <select
                                        class="form-control wt-240"
                                        formControlName="event_series_id"
                                        (change)="logValidationErrors()">
                                            <option *ngFor="let series of eventListSeriesActivities.series_data" [value]="series.series_id">
                                                {{ series.series_name }}
                                            </option>
                                    </select>
    
                                    <div class="form-error" *ngIf="formErrors.event_series_id">
                                        {{ formErrors.event_series_id }}
                                    </div>
                                    <a class="add-more" *ngIf="!isDisable" data-toggle="modal" data-target="#addSeries"></a>
                                </div>
                            </div>
    
                        </div>
                    </div>
    
                    <div class="form-content mb-4">
                        <div class="custom-heading">
                            <h6>Activity</h6>
                            <p>Add custom activity goals and communication links to this Challenge when your participants hit those goals</p>
                        </div>
    
                        <div class="form-wrapper">
                            <div formGroupName="activity_details">
    
                                <div class="form-group row">
                                    <label for="eventseriesname" class="col-sm-4 col-form-label">Activity Details</label>
                                    <div class="col-sm-8" *ngIf="eventListSeriesActivities">
                                        <label for="activityLabel" class="col-form-label">Activity Label</label>                                                             
                                        <select
                                            class="form-control"
                                            formControlName="activity_label"
                                            (change)="logValidationErrors()"> 
                                            <option *ngFor="let activity of eventListSeriesActivities.activity_data" [value]="activity.goal_type_id">
                                                {{ activity.goal_type }}
                                            </option>
                                        </select>
    
                                        <div class="form-error" *ngIf="formErrors.activity_label">
                                            {{ formErrors.activity_label }}
                                        </div>
                                    </div>
                                </div>
    
                                <div class="form-group row">
                                    <label for="eventseriesname" class="col-sm-4 col-form-label">&nbsp;</label>
                                    <div class="col-sm-8">
                                        <label for="activityLabel" class="col-form-label">Activity Goal</label>
                                        <input
                                            type="number"
                                            class="form-control wt-198 number"
                                            placeholder="Ex: 50"
                                            formControlName="activity_goal"
                                            (input)="logValidationErrors()">
    
                                        <div class="form-error" *ngIf="formErrors.activity_goal">
                                            {{ formErrors.activity_goal }}
                                        </div>    
                                    </div>
                                </div>
    
                            </div>
    
                            <div formGroupName="activity_markers">
                                <div class="form-group row">
                                    <label for="eventseriesname" class="col-sm-4 col-form-label">Activity Markers</label>
                                    <div class="col-sm-8">
                                        <label for="activityLabel" class="col-form-label">50% Activity Mark</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="https://"
                                            formControlName="fifty_activity_mark"
                                            (input)="logValidationErrors()">
    
                                        <div class="form-error" *ngIf="formErrors.fifty_activity_mark">
                                            {{ formErrors.fifty_activity_mark }}
                                        </div>
                                    </div>
                                </div>
    
                                <div class="form-group row">
                                    <label for="eventseriesname" class="col-sm-4 col-form-label">&nbsp;</label>
                                    <div class="col-sm-8">
                                        <label for="activityLabel" class="col-form-label">100% Activity Mark</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="https://"
                                            formControlName="hundred_activity_mark"
                                            (input)="logValidationErrors()">
    
                                        <div class="form-error" *ngIf="formErrors.hundred_activity_mark">
                                            {{ formErrors.hundred_activity_mark }}
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
    
                    <div class="btn-wrapper mb-5" *ngIf="!isDisable">
                        <button type="submit" class="custom-btn">Edit Challenge</button>
                    </div>
    
                </form>
    
            </div>             
        </div>
        </div>
    
    </div>
    </div>
    
    <div class="shape-bg">
        <div class="c-shape-bg is--full rt-25">        
        </div>
    </div>

    <div class="modal above fade" id="addSeries" role="dialog">
        <div class="modal-dialog modal-dialog-centered custom-dialog-modal modal-md" role="document">
            <div class="modal-content m-2 max-wt-380">
                <div class="modal-header px-4" style="border-bottom: none;">
                    <h6 class="modal-title">Add Series</h6>
                    <button #modalCloseAddSeries data-dismiss="modal" class="close invisible" type="button">close</button>
                    <button type="button" data-dismiss="modal"
                        class="close"><span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body pt-0">
                   <div class="form-wrapper">
                       <form [formGroup]="createSeriesForm" (ngSubmit)="addSeries()">
                        <div class="mb-1 row">
                            <label class="col-form-label" for="role" class="col-sm-12 col-form-label">Series Full Name<span class="astrix">*</span></label>
                            <div class="col-sm-12">                                       
                                <input type="text" formControlName="full_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.full_name.errors }"> 
                                <div *ngIf="submitted && f.full_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.full_name.errors.required">Full Name is required</div>
                                </div>                                                  
                            </div>  
                        </div>  
                        <div class="mb-1 row">
                            <label class="col-form-label" for="role" class="col-sm-12 col-form-label">Series Short Name<span class="astrix">*</span></label>
                            <div class="col-sm-12">                                       
                                <input type="text" formControlName="short_name" [ngClass]="{ 'is-invalid': submitted && f.short_name.errors }" class="form-control">  
                                <div *ngIf="submitted && f.short_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.short_name.errors.required">Short Name is required</div>
                                </div>                       
                            </div>   
                        </div>                       
                        <div class="btn-wrapper"><button type="submit" class="custom-btn">Add Series</button></div> 
                      </form>    
                   </div>
                </div>
            </div>
        </div>
    </div>
    
    
    
    