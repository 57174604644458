import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router, NavigationEnd } from '@angular/router';
import { FbLoginService } from '../services/fb-login.service';
import { NgoSharedService } from '../shared/services/ngo-shared.service';
import { UserSharedService } from '../shared/services/user-shared.service';
import { CommonService } from '../services/common.service';
import { UserService } from '../services/user.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ReportDownloadSharedService } from '../shared/services/report-download-shared.service';

declare let pendo: any;

@Component({
  selector: 'app-fb-callback',
  templateUrl: './fb-callback.component.html',
  styleUrls: ['./fb-callback.component.scss']
})
export class FbCallbackComponent implements OnInit {
  public code: string;
  public userData;
  public routerSubscription: Subscription;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _userService: UserService,
    private _fbLoginService: FbLoginService,
    private _ngoSharedService: NgoSharedService,
    private _userSharedService: UserSharedService,
    private _commonService: CommonService,
    private ngZone: NgZone,
    private _reportDownloadSharedService: ReportDownloadSharedService

  ) {
  }

  ngOnInit() {
    console.log('in ngoninit start....');
    this.route.queryParams.subscribe(params => {
      this.code = params.code + '#_=_';
      console.log('in ngoninit getting params....',params);
      if(params && params.error && params.error=="access_denied")
      {
        console.log('in ngoninit if condition....');
        this.router.navigate(['/login']);
      }
      else
      {
        this.fbLogin();
      }
    });
  }

  fbLogin() {
    console.log("fbLogin function at callback file");
    this._fbLoginService
        .fbLogin(this.code)
        .subscribe(
          res => {
            console.log('in response',res);
            this.checkUserAuth();
            },
          err => {
            // this.router.navigate(['/login']);
            console.log('in fbloginerror....',err);
            this.router.navigate(['/fb-error']); // for unit testing 
            // this.checkUrlFbCallBackExistence();
          }
        );
  }

  checkUrlFbCallBackExistence(){    
    console.log('in checkUrlFbCallBackExistence....');
    this.routerSubscription = this.router.events.pipe(
      filter(ev => (ev instanceof NavigationEnd))
    ).subscribe((ev: NavigationEnd) => {
      console.log('in checkUrlFbCallBackExistence subscribe ....');
      console.log(ev);
      if (ev.url.includes('fb-callback')){
            // this.router.navigate(['/login']);
            console.log('in callback.....')
            // this.router.navigate(['/fb-error']);
      };

    });
  }

  checkUserAuth() {
    this._userService
        .getUserInfo()
        .subscribe(
          res => {
            this.userData = res;

            this.ngZone.runOutsideAngular(() => {
              pendo.initialize({
                visitor: {
                    id:    this.userData.fbid,   
                    email: this.userData.email, 
                    name:  this.userData.name, 
                    role:  this.userData.roles[0].role  
                },
                account: {
                    id:  this.userData.fbid, 
                    email: this.userData.email,
                    name:  this.userData.name,
                    role:  this.userData.roles[0].role
                },
                events: {
                  ready: () => {
                    // console.log('pendo initialized')
                  }
                }
              });
            });
                        
            if (!(this.userData.selectedPageId)) {
              return this.router.navigate(['/organizations']);
            }

            this._userSharedService.changeUserData(res);
            if (!this.userData.selectedPageId) return this.router.navigate(['/organizations']);
            
            this.setNgoList();
            this._reportDownloadSharedService.changeReportId(null);
          },
          err => 
          {
            console.log('in checkUserAuth error....',err);
            this.router.navigate(['/login']);
          }
        );
  }

  setNgoList() {
    
    this._commonService
        .fundraiserPages()
        .subscribe(
          (res) => {
            const connectedNgos = res.filter(item => item.connected === true);

            this._ngoSharedService.changeNgoList(connectedNgos);
            this.setSelectedNgo(connectedNgos);
          },
          err => this.router.navigate(['/login'])
        );
  }

  setSelectedNgo(connectedNgos) {

    const selectedNgo = connectedNgos.filter(data => data.pageId === this.userData.selectedPageId);
    this._ngoSharedService.changeSelectedNgo(selectedNgo[0]);
    
    const redirectUrl = sessionStorage.getItem('redirect-url') || '/';
    sessionStorage.removeItem('redirect-url');

    let paramsExist = this.checkQueryParams(redirectUrl);

    if (Object.keys(paramsExist).length < 1) {
      return this.router.navigate([redirectUrl])
    };

    const queryParams = {...paramsExist};    

    this.router.navigate([redirectUrl.split('?')[0]], {queryParams});
  }


  checkQueryParams(path: string): NavigationExtras {
    const paramsExist = path.includes('?');

    let queryParams = {};

    if (!paramsExist) return {};

    const params = path.split('?')[1];
    const keyValuePair = params.split('&&');

    keyValuePair.forEach(v => {
      const param = v.split('=');
      queryParams[param[0]] = param[1];
    });

    return queryParams;
  }

}
