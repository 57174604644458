<div class="continer-fluid">
  <div class="new-dashboard-wrapper">

    <!-- <ng-container *ngIf="alertMsg.msg !== ''">
          <app-alert [message]="alertMsg"></app-alert>
      </ng-container> -->

    <!-- app header -->
    <app-new-header></app-new-header>

    <!-- Side Menu -->
    <app-side-menu></app-side-menu>

    <div class="grid-wrapper">
      <div class="row no-gutters mx-864">
        <div class="col-12">

          <div class="heading_wrapper">
            <div class="page-title">Integrations</div>
          </div>
          <div *ngIf="message" class="alert-wrapper p-4 col col-12 col-md-4 offset-0 offset-md-8">
            <div class="alert alert-danger" role="alert">
              <span class="alert-message">
                <span class="alert-icon">
                  <svg style="width: 38px; height:38px;" (click)="closeErr()" aria-hidden="true" data-prefix="far"
                    data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16 arrow d-inline" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
                    </path>
                  </svg>
                </span>
                {{message}}
              </span>
              <span class="close" (click)="closeErr()"> Close </span>
            </div>
          </div>
          <div class="overlay__inner custom-overlay" *ngIf="loading">
            <div class="overlay__content">
              <span class="spinner loadImg"></span>
            </div>
          </div>

          <div class="content-box">

            <div class="row no-gutters">
              <div class="col-12">
                <div class="integrationWrapper">

                  <div class="luminateBox">
                    <div class="ImgBox">
                      <img src="../../assets/images/luminate.svg" alt="">
                    </div>
                    <div class="luminate-content">
                      <h2 class="common-heading mb-0">Luminate Online</h2>
                      <p class="card-text">Sync your GoodUnited data to Luminate Online and
                        TeamRaiser.</p>
                    </div>
                  </div>
                  <div class="integrationLink">
                    <a *ngIf="connected" routerLink="/integrations/map-attributes" class="manageLink">Manage</a>
                    <a *ngIf="!connected" (click)="displayConnectAccountPage()"
                      class="custom-btn connect-link pointer">Connect Account</a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg"></div>