
export class CustomValidationMessages {
  private static _validationMessages = {
    name: {
      required: 'Full name is required',
      minlength: 'Full name must be greater than 4 characters',
      maxlength: 'Full name must be less than 20 characters',
      startSpace: 'Full name can not start with space'
    },
    userName: {
      required: 'Username is required',
      invalid: 'Email is invalid',
      startSpace: 'Email can not start with space',
      minlength: 'Phone number must be equal to 10 digits',
      digits: 'Only digits allowed',
      length: 'Phone number must be only 10 digits long'
    },
    email: {
      required: 'Email is required',
      invalid: 'Email is invalid',
      startSpace: 'Email can not start with space',
    },
    phone: {
      required: 'Phone number is required',
      minlength: 'Phone number must be equal to 10 digits',
      digits: 'Only digits allowed',
      length: 'Phone number must be only 10 digits long'
    },
    // code edition by pooja start
/*     oldPassword: {
      required: 'Password is required',
      minlength: 'Password must be greater than or equal to 6 characters',
      maxlength: 'Password must be less than 20 characters',
      startSpace: 'Password can not start with space'
    },
    password: {
      required: 'Password is required',
      minlength: 'Password must be greater than or equal to 6 characters',
      maxlength: 'Password must be less than 20 characters',
      startSpace: 'Password can not start with space'
    },
    passwordGroup: {
      passwordMismatch: 'Password and Confirm Password do not match'
    },
    confirmPassword: {
      required: 'Confirm password is required',
    }, */
    
    // code edition by pooja end

    otp: {
      required: 'OTP is required',
    },
    address: {
      required: 'Address is required',
      minlength: 'Address must be greater than 6 characters',
      maxlength: 'Address must be less than 20 characters',
      startSpace: 'Address can not start with space'
    },
    apartment: {
      required: 'Please select apartment',
    },
    tacticName: {
      required: 'Tactic name is required',
      minlength: 'Tactic name must be greater than 4 characters',
      maxlength: 'Tactic name must be less than 20 characters',
      startSpace: 'Tactic name can not start with space'
    },
    tacticDescription: {
      required: 'Tactic Description is required',
      minlength: 'Tactic Description must be greater than 4 characters',
      maxlength: 'Tactic Description must be less than 20 characters',
      startSpace: 'Tactic Description can not start with space'
    },
    templateCategory: {
      required: 'Tactic Category is required',
      minlength: 'Tactic Category must be greater than 4 characters',
      maxlength: 'Tactic Category must be less than 20 characters',
      startSpace: 'Tactic Category can not start with space'
    },
    event_name: {
      required: 'Event Name is required',
      minlength: 'Event Name must be greater than 4 characters',
      maxlength: 'Event Name must be less than 20 characters',
      startSpace: 'Event Name can not start with space'
    },
    start_date: {
      required: 'Start date is required',
    },
    end_date: {
      required: 'End date is required',
    },
    month: {
      required: 'Month is required',
    },
    fundraiser_goal: {
      required: 'Fundraiser goal is required',
      digits: 'Only digits allowed',
      zeroAmount: 'Zero not allowed',
      maxlength: 'Fundraiser goal must be less than 10 digits',
    },
    opt_in_url: {
      required: 'Opt in Url is required',
      url: 'This doesn\'t seem like url',
    },
    manychatApiKey: {
      required: 'Manychat Api Key is required',
      manyChatTokenValue: 'The format must be <Page Id>:<Token>',
    },
    group_url: {
      required: 'Group Url is required',
      url: 'This doesn\'t seem like url',
    },
    event_identifier: {
      required: 'Event Identifier is required',
      startSpace: 'Event Identifier can not start with space',
      maxlength: 'Event Identifier must be less than 20 characters',
    },
    asana_project_id: {
      required: 'Asana Project Id is required',
      digits: 'Only digits allowed',
      zeroAmount: 'Zero not allowed',
      startSpace: 'Asana Project Id can not start with space'
    },
    event_series_id: {
      required: 'Event Series Name is required',
    },
    activity_label: {
      required: 'Activity label is required',
    },
    activity_goal: {
      required: 'Activity goal is required',
      digits: 'Only digits allowed',
      zeroAmount: 'Zero not allowed',
      maxlength: 'Activity goal must be less than 10 digits',
    },
    fifty_activity_mark: {
      required: 'Fifty activity mark is required',
      url: 'This doesn\'t seem like url',
    },
    hundred_activity_mark: {
      required: 'Hundred activity mark is required',
      url: 'This doesn\'t seem like url',
    },
    fbUrl: {
      required: 'Facebook URL is required',
      url: 'This doesn\'t seem like url',
    },
    orgName: {
      required: 'Organization name is required',
      minlength: 'Organization name must be greater than 4 characters',
      maxlength: 'Organization name must be less than 20 characters',
      startSpace: 'Organization name can not start with space'
    },
    fbId: {
      required: 'Facebook ID is required',
      minlength: 'Facebook ID must be greater than 4 characters',
      maxlength: 'Facebook ID must be less than 20 characters',
      digits: 'Only digits allowed',
      zeroAmount: 'Zero not allowed',
      startSpace: 'Charity Id can not start with space'
    },
    currency: {
      required: 'Currency is required'
    },
    charityId: {
      required: 'Charity Id is required',
      digits: 'Only digits allowed',
      zeroAmount: 'Zero not allowed',
      startSpace: 'Charity Id can not start with space'
    },
  };

  static get validationMessages() {
    return this._validationMessages;
  }
}
