
export interface EventDetail {
    event_id: number;
    event_name: string;
    start_date: string;
    month: number;
    fundraiser_goal: number;
    end_date: string;
    opt_in_url: string;
    event_image_url: string;
    event_identifier: number;
    asana_project_id: number;
    event_series_id: number;
    resource_id?: string;
    activity_details: {
        activity_label_id: number;
        activity_goal: number;
    };
    activity_markers: {
        activity_mark_50: string;
        activity_mark_100: string;
    };
    group_url: string;
}

export interface EventSubscriberList {
    profile_image_url: string;
    fundraiser_amount: number;
    full_name: string;
}

export interface EventList {
    data: ChartData[];
    label: Array<string>;
    type: string;
    overall_result_dict: Overall;
}

export interface Events {
    image: string;
    referal: number;
    Fundraisers: string;
    name: string;
    Raised: number;
    Participants: number;
    CAC: string;
    average: number;
    opt_in_per: number;
}

export interface ActiveEventGraph {
    data: ChartData[];
    label: Array<string>;
    overall: Overall;
    type: string;
    top_three_series: TopSeries[];
}

export interface ChartData {
    title: string;
    type: string;
    value: Array<number>;
}

export interface Overall {
    averageCampaign: number;
    averageDonation: number;
    averageFundraiser: number;
    totalCampaign: number;
    totalDonation: number;
    totalRaised: number;
    series_name: string;
}

export interface TopSeries {
    name: string;
    participants: number;
    raised: number;
    image: string;
    series_id: string;
}

export interface TopEvents {
    name: string;
    participants: number;
    raised: number;
}

export const ChartColor = [
    '#facc6e',
    '#F8B630',
    '#E6AC31',
    '#D4A233',
    '#C29834',
    '#B08E36',
    '#9E8437',
    '#957F38',
    '#77662d',
    '#594c22',
    '#facc6e',
    '#F8B630',
    '#E6AC31',
    '#D4A233',
    '#C29834',
    '#B08E36',
    '#9E8437',
    '#957F38',
    '#77662d',
    '#594c22',
    '#facc6e',
    '#F8B630',
    '#E6AC31',
    '#D4A233',
    '#C29834',
    '#B08E36',
    '#9E8437',
    '#957F38',
    '#77662d',
    '#594c22',
    '#facc6e',
    '#F8B630',
    '#E6AC31',
    '#D4A233',
    '#C29834',
    '#B08E36',
    '#9E8437',
    '#957F38',
    '#77662d',
    '#594c22',
];

export const ChartColorLine = {
    'Total Campaigns': '#002C45',
    'Total Donations': '#002C45',
};

export const ChartLegends = {
    'Total Campaigns': 'Campaigns',
    'Total Donations': 'Donations',
};

export const ChartOrder = {
    'Total Campaigns': 2,
    'Total Donations': 1,
};

export interface Filter {
    type: string;
    year: string;
    month: string;
}

export const FilterApplied = {
    all: 'All time',
    monthly: 'Yearly',
    daily: 'Monthly'
};

export interface EventSeriesActivities {
    series_data: SeriesData[];
    activity_data: ActivityData[];
}

interface SeriesData {
    series_name: string;
    series_id: number;
}

interface ActivityData {
    goal_type: string;
    goal_type_id: number;
}
