import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private http: HttpClient
  ) { }

  postMessage(charityId: string, data:any): Observable<any> {
    const params = {
      charity_id: charityId,
    };
    //data = JSON.stringify(data);
    return this.http.post('add_message', data, {params})
      .pipe(
        map(res => res,
          err => throwError(err)
        )
      );

  }

  tacticList(charityId: string): Observable<any> {
    const params = { charity_id: charityId };
    return this.http.get('list_tactics', { params });
  }

  categoryList(charityId: string): Observable<any> {
    const params = { charity_id: charityId };
    return this.http.get('message_category_list', { params });
  }

  getmessagelist(charityID: any, pageID: any, sortingField: any, order:any): Observable<any> {
    const params = new HttpParams().set('charity_id', charityID).set('page', pageID).set('sort_field', sortingField).set('order', order);
    return this.http.get('view_message', { params });
  }

  getOverallMsg(cId: any): Observable<any> {
    const params = new HttpParams().set('charity_id', cId);
    return this.http.get('overall_view_message', { params });
  }

  editMessageStatus(data): Observable<any> {
    const params = new HttpParams().set('charity_id', data.charityId);
    return this.http.post('edit_message?charity_id=' + data.charityId, {status: data.status, message_id: data.id});
  }

  getSentenceLevellist(charityID: any, pageID: any): Observable<any> {
    const params = new HttpParams().set('charity_id', charityID).set('page', pageID);
    return this.http.get('sentence_based_classification_questions', { params });
  }

  getSentenceType(charityID: any): Observable<any> {
    const params = new HttpParams().set('charity_id', charityID);
    return this.http.get('sentence_types', { params });
  }

  postSentenceType(charityID: any, type:any, pageID: any): Observable<any> {
    const params = new HttpParams().set('charity_id', charityID).set('type', type).set('page', pageID);
    return this.http.get('sentence_based_classification_questions', { params })
  }
  
  getSentenceDetail(charityID: any, sentence: any): Observable<any> {
    const params = new HttpParams().set('charity_id', charityID).set('sentence', sentence);
    return this.http.get('fundraisers_stories_for_sentence_type', { params });
  }

}
