<ng-container *ngIf="alertMsg.msg !== ''">
  <app-alert [message]="alertMsg"></app-alert>
</ng-container>

<div class="overlay" *ngIf="showLoader">
  <div class="overlay__inner">
    <div class="overlay__content"><span class="spinner loadImg"></span></div>
  </div>
</div>


<div class="row no-gutters">
  <div class="col-12">
    <div class="form-wrapper full-width-wrapper">

      <div class="closeBtn">
        <div class="close-btn" #matClose mat-dialog-close><span aria-hidden="true">×</span></div>
      </div>

      <div class="NewMsgHeading">
        <h4 class="commonFont p-0 mb-2">Add Tactic</h4>
      </div>

      <form [formGroup]="profileUpdateForm" (ngSubmit)="createTactic()">

        <div class="row mb-1">
          <div class="col col-md-12">
            <label for="tactic_name" class=" form-control-label">Category</label>
          </div>
          <div class="col col-md-12">
            <select name="templateCategory" formControlName="templateCategory" class="form-control">
              <option value="null">Select Tactic Category</option>

              <ng-container *ngIf="tacticCategory">
                <option *ngFor="let cat of tacticCategory.tactic_categories_list" [value]="cat.tactic_category_id"
                  [title]="cat.tactic_category_name">{{ cat.tactic_category_name }}</option>
              </ng-container>
            </select>
            <!-- <ng-container *ngIf="formErrors.templateCategory">
              <div class="alert alert-danger" role="alert">
                {{ formErrors.templateCategory }}
              </div>
            </ng-container> -->
          </div>
        </div>

        <div class="row mb-1">
          <div class="col col-md-12">
            <label for="tactic_name" class=" form-control-label">Tactic Name</label>
          </div>
          <div class="col col-md-12">
            <input type="text" name="tacticName" autocomplete="off" formControlName="tacticName"
              placeholder="Tactic name" (input)="logValidationErrors()" class="form-control" />

            <ng-container *ngIf="formErrors.tacticName">
              <div class="alert alert-danger" role="alert">
                {{ formErrors.tacticName }}
              </div>
            </ng-container>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col col-md-12">
            <label for="tactic_name" class=" form-control-label">Description</label>
          </div>
          <div class="col col-md-12">
            <div class="textarea_wrapper">
              <textarea id="textarea-input" name="tacticDescription" formControlName="tacticDescription" rows="4"
                placeholder="Description" class="form-control">
                            </textarea>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col col-md-12">
            <label for="tactic_name" class=" form-control-label">Example</label>
          </div>
          <div class="col col-md-12">
            <div class="textarea_wrapper">
              <textarea id="textarea-input" name="tacticExample" formControlName="tacticExample" rows="4"
                placeholder="Example" class="form-control">
                            </textarea>
            </div>
          </div>
        </div>
        <!-- 
                        <div class="row mb-1">
                          <div class="col col-md-12">
                            <label for="select" class=" form-control-label">Status<span class="astrix">*</span></label>
                          </div>

                          <div class="col-12 col-md-12">

                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                formControlName="status"
                                id="inlineRadio1"
                                value="true" />
                              <label class="form-check-label" for="inlineRadio1">Active</label>
                            </div>

                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                formControlName="status"
                                id="inlineRadio2"
                                value="false" />
                              <label class="form-check-label" for="inlineRadio2">Inactive</label>
                            </div>

                          </div>
                        </div> -->

        <div class="row">
          <div class="col-md-12">
            <button id="payment-button" type="submit" class="custom-btn pull-right">
              Create Tactic
            </button>
          </div>
        </div>

      </form>

    </div>
  </div>
</div>