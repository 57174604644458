import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Filter } from '../models/subscription';
import { map } from 'rxjs/operators';
import { DateFilter } from '../shared/custom/DateFilter';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionGraphService {
  public dateFilter = new DateFilter();

  constructor(private http: HttpClient) {}

  getSubscriptionGraph(charityId: string, data: Filter): Observable<SubscriptionGraphService> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;

    const path = 'get_subscription_data';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

}
