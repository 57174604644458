import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CustomValidationMessages } from 'src/app/shared/custom/custom-validation-messages';
import { TacticService } from 'src/app/services/tactic.service';
import { Subscription } from 'rxjs';
import { NgoSharedService } from 'src/app/shared/services/ngo-shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-tactic',
  templateUrl: './add-tactic.component.html',
  styleUrls: ['./add-tactic.component.scss']
})
export class AddTacticComponent implements OnInit {
  @ViewChild('matClose') matClose: ElementRef<HTMLElement>;
  public tacticCategory;
  public charityIDSubscription: Subscription;
  public charityId: string;
  public alertMsg = { type: '', msg: '' };
  public showLoader = false;
  public profileUpdateForm: UntypedFormGroup;
  public validationMessages = CustomValidationMessages.validationMessages;
  public formErrors = {
    tacticName: '',
    tacticDescription: '',
    tacticExample:'',
    templateCategory: '',
    status: '',
  };
  public postData:any;
  public formData:any;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private _ngoSharedService: NgoSharedService,
    private _tacticService: TacticService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddTacticComponent>,
  ) { }

  receiveMessage($event) { if ($event) { } }

  ngOnInit() {
    this.getCharityId();
    this.initializeForm();
    this.profileUpdateForm.valueChanges.subscribe(data => this.logValidationErrors());
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        selectedNGO => {
          if (selectedNGO.charityId !== null) {
            this.showLoader = true;
            this.charityId = selectedNGO.charityId;

            this.getTacticCategory();
          } else this.showLoader = false;
        },
        err => this.showAlertMsg('Charity ID Not Found', 'danger')
      );
  }

  getTacticCategory() {
    this._tacticService
      .getTacticCategory(this.charityId)
      .subscribe(
        res => {
          this.showLoader = false;
          this.tacticCategory = res;
        },
        err => this.showAlertMsg(err.message, 'danger')
      );
  }

  initializeForm(): void {
    this.profileUpdateForm = this.fb.group({
      tacticName: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(75)]],
      tacticDescription: [null],
      tacticExample: [null],
      templateCategory: [null],
      status: ['true']
    });
  }


  logValidationErrors(group: UntypedFormGroup = this.profileUpdateForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl: AbstractControl = group.get(key);

      this.formErrors[key] = '';
      if (
        abstractControl &&
        !abstractControl.valid &&
        (abstractControl.touched || abstractControl.dirty)
      ) {
        const msg = this.validationMessages[key];

        for (const errorKey in abstractControl.errors) {
          if (errorKey) this.formErrors[key] = msg[errorKey] + ' ';
        }
      }
    });
  }

  createTactic() {
    this.showLoader = true;
    this.profileUpdateForm.markAllAsTouched();
    this.logValidationErrors();
    if (!this.profileUpdateForm.valid) return this.showAlertMsg('Please fill all the required fields', 'danger');

    this.formData = this.profileUpdateForm.value;
    if(this.formData.templateCategory){
      this.postData = {      
        tactic_name: this.formData.tacticName,
        tactic_description: this.formData.tacticDescription ? this.formData.tacticDescription : '',
        tactic_example: this.formData.tacticExample ? this.formData.tacticExample : '',      
        tactic_category_id: this.formData.templateCategory,
        status: 'true'
      };  
    }else{
      this.postData = {      
        tactic_name: this.formData.tacticName,
        tactic_description: this.formData.tacticDescription ? this.formData.tacticDescription : '',
        tactic_example: this.formData.tacticExample ? this.formData.tacticExample : '',      
        //tactic_category_id: formData.templateCategory !== 'null' ? formData.templateCategory : '',
        status: 'true'
      };  
    }
    
    this._tacticService
      .addTactic(this.charityId, this.postData)
      .subscribe(
        res => {
          if(res){
          this.showAlertMsg(res.message, 'success');
          setTimeout(() => this.displayTacticList(), 2000);
          const matCloseBtn: HTMLElement = this.matClose.nativeElement; 
          setTimeout(() => {matCloseBtn.click();}, 3000);          
          }else{
            const matCloseBtn: HTMLElement = this.matClose.nativeElement;
            matCloseBtn.click(); 
          }
        },
        err => this.showAlertMsg(err.message, 'danger')
      );
  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg = msg;
    this.alertMsg.type = type;
    this.showLoader = false;
  }

  closeErr() {
    this.alertMsg.msg = '';
    this.alertMsg.type = '';
  }

  onClose(output: boolean) {
    this.dialogRef.close(output);
  }

  displayTacticList() {
    this.router.navigate(['/activation-message/tactics']);
  }

}
