import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';

@Injectable({
  providedIn: 'root'
})
export class NgoSharedService {
  private defaultNgo: SelectedNgo = {
    charityId: null,
    connected: false,
    customerPlanId:  null,
    lastUpdated:  null,
    name: '',
    nonprofitPageURL: '',
    pageId: '',
    profilePicture:  null,
    currency_name: null,
  };

  private ngoListSource     = new BehaviorSubject<any>([]);
  private selectedNgoSource = new BehaviorSubject<SelectedNgo>(this.defaultNgo);

  ngoLists     = this.ngoListSource.asObservable();
  selectedNgo  = this.selectedNgoSource.asObservable();

  constructor() { }

  changeNgoList(data: SelectedNgo[]) {
    data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    this.ngoListSource.next(data);
  }

  changeSelectedNgo(data: SelectedNgo) {
    this.selectedNgoSource.next(data);
  }
}
