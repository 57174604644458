import { Component, OnInit, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { FundraiserService } from '../../../services/fundraiser.service';
import { ChartColor } from 'src/app/models/Fundraiser';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgoSharedService } from '../../services/ngo-shared.service';
import { ChartsService } from 'src/app/services/charts.service';
import { RetentionScoreGraph } from 'src/app/models/RetentionScoreGraph';
import { TooltipPosition } from '@angular/material/tooltip';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-call-to-action-graph',
  templateUrl: './call-to-action-graph.component.html',
  styleUrls: ['./call-to-action-graph.component.scss']
})
export class CallToActionGraphComponent implements OnInit, OnDestroy {
  public chart;
  public chartColor = ChartColor;
  public chartData: any[] = [];

  public charityId: string;

  public charityIDSubscription: Subscription;
  public retentionScoreData: RetentionScoreGraph;

  public retentionScoreLoader   = true;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionLeft = new UntypedFormControl(this.positionOptions[4]);
  positionTop = new UntypedFormControl(this.positionOptions[2]);
  positionBottom = new UntypedFormControl(this.positionOptions[3]);

  constructor(
    private _ngoSharedService: NgoSharedService,
    private _chartService: ChartsService,
    private _fundraiserService: FundraiserService,
  ) { }

  ngOnInit() {
    this.getCharityId();
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.charityId = selectedNGO.charityId;
              this.retentionScoreData = null;
              if (this.chart) this.chart.destroy();
              this.retentionScoreGraphData();
            }
          }
        );
  }

  retentionScoreGraphData() {
    this.retentionScoreLoader = true;
    this._fundraiserService
        .getRetentionScore(this.charityId)
        .pipe(finalize(() => {
          this.retentionScoreLoader = false;
          setTimeout(() => this.createChart(), 100);
        }))
        .subscribe(
          (res: any) => this.retentionScoreData = res,
          err => this.retentionScoreData = null
        );
  }

  createChart() {
    this.getDataSets();

    const labels = this.getLabels();

    if (this.chartData.length < 1) return;

    this.chart = new Chart('retentionScore', {
      type: 'horizontalBar',
      data: {
        labels,
        datasets: this.chartData
      },
      options: this._chartService.retentionScore
    });
  }

  getDataSets() {
    this.chartData = [];
    if (!this.retentionScoreData || !this.retentionScoreData.hasOwnProperty('datasets')) return;

    this.retentionScoreData.datasets.forEach((val, i) => {
      const data = {
        label: null,
        data: val.data.reverse(),
        backgroundColor: ['#04CB9B', '#04CB6B', '#F9C45A', '#F2994A', 'rgba(0,44,69, 0.5)'],
        borderColor: ['#04CB9B', '#04CB6B', '#F9C45A', '#F2994A', 'rgba(0,44,69, 0.5)'],
        fill: true,
        barThickness: 6,
      };

      this.chartData.push(data);
    });
  }

  getLabels() {
    if (!this.retentionScoreData || !this.retentionScoreData.hasOwnProperty('labels')) return [];

    const labels = [];
    this.retentionScoreData.labels.forEach(val => {
      const label = val.split(' ').map(([firstChar, ...rest]) => firstChar.toUpperCase() + rest.join('').toLowerCase()).join(' ');
      labels.push(label);
    });

    return labels.reverse();
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
  }

}
