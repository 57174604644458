import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Filter } from '../models/Fundraiser';
import { DateFilter } from '../shared/custom/DateFilter';

@Injectable({
    providedIn: 'root'
})
export class DuplicateReportService {
    public dateFilter = new DateFilter();

    constructor(
        private http: HttpClient,
    ) { }

    duplicateReport(charityId:any, report_id: any): Observable<any> {      
        let params      = new HttpParams();
        params = params.append('charity_id', charityId);
        const data = {
            report_id: report_id,
        }
        return this.http.post('duplicateReport', data , { params });
    }


}

 