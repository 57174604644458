

<table class="table table-borderless subscribers_heading">
    <thead>
        <tr>
            <th class="theading" [matTooltipClass]="positionBottom.value" matTooltip="Subscriber list which shows your top Subscribers.">&nbsp;&nbsp;Subscribers</th>
            <th class="view-all"><a [routerLink]="['/subscribers']">View All</a></th>
        </tr>
    </thead>
</table>
<div class="subscribers-wrapper">
    <div class="overlay__inner custom-overlay" *ngIf="activeSubscriberLoader">
        <div class="overlay__content">
            <span class="spinner loadImg"></span>
        </div>
    </div>
    <table class="table table-borderless">
        <!-- <thead>
            <tr>
                <th class="theading">Subscribers</th>
                <th class="view-all"><a [routerLink]="['/subscribers']">View All</a></th>
            </tr>
        </thead> -->
        <tbody>
            <ng-container *ngIf="subscriberlistData && subscriberlistData.length > 0; else NotFound">
            <tr class="cursor-pointer" *ngFor="let subscriber of subscriberlistData" (click)="openSubscriberDetail(subscriber.person_id)">
                <td>
                    <div class="user-profile">
                        <div class="widget-content-left">
                            <ng-container *ngIf="subscriber?.profile_image_url != null; else noImage">   
                                <img [src]="subscriber?.profile_image_url" alt="">                                
                            </ng-container>
                            <ng-template #noImage>
                                <img src="../../../assets/images/noImage.png" alt="">  
                            </ng-template>
                        </div>
                        <div class="widget-heading" [title]="subscriber.full_name">{{subscriber.full_name}}</div>
                    </div>
                </td>
                <td class="price">
                    <!-- ${{ subscriber.fundraiser_amount }} -->
                    {{currencyName=='USD' ? '$' : '£'}}{{ subscriber.fundraiser_amount }}
                </td>
            </tr>
           </ng-container>
           <ng-template #NotFound>
            <tr>
               <td colspan="2" class="notFound text-center">No data found</td>
            </tr>
          </ng-template>
        </tbody>
    </table>
</div>
