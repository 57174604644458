<div class="continer-fluid">
  <div class="new-dashboard-wrapper">

    <!-- app header -->
    <app-new-header></app-new-header>
    <!-- Side Menu -->
    <app-side-menu (ngoChanged)="ngoChanged($event)"></app-side-menu>

    <ng-container *ngIf="alertMsg.msg !== ''">
      <app-alert [message]="alertMsg"></app-alert>
    </ng-container>

    <div class="grid-wrapper">
      <div class="row no-gutters">
        <div class="col-12">

          <div class="heading_wrapper">
            <div class="page-title">Activation Messages</div>
            <div class="position-relative">
                <div class="btnWrapper">
                    <button (click)="openAddMessage()" class="custom-btn" type="button">Add Message</button>
                </div> 
            </div>
          </div>

          <div class="content-box">
            <div class="overlay__inner custom-overlay" *ngIf="loader">
              <div class="overlay__content">
                <span class="spinner loadImg"></span>
              </div>
            </div>

            <div class="table-responsive">
              <div class="reporting-outer">
                <div class="reporting-inner">
                  
                    <table class="table table-borderless"matSort
                    (matSortChange)="sortData($event)" matSortDisableClear>
                      <thead>
                        <tr>
                          <th class="serial" mat-sort-header="message_id">ID</th>
                          <th class="w-25" mat-sort-header="message_body">Message</th>
                          <th class="w-20" mat-sort-header="message_category_name">Category</th>
                          <th class="w-20" mat-sort-header="tactic_name">Tactic</th>
                          <th mat-sort-header="status">Status</th>
                          <th mat-sort-header="messages_posted">Messages Posted</th>
                          <th mat-sort-header="optins">Opt-in</th>
                          <th mat-sort-header="optins_percentage">Opt-in %</th>
                          <th mat-sort-header="created_date">Created</th>    
                          <th mat-sort-header="message_status" class="text-center">Enable/<br>Disable</th>                       
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="sortedData?.length > 0; else NotFound">
                          <tr *ngFor="let message of sortedData | paginate: config; let idx = index">
                            <td class="serial">
                              <span>{{message.message_id}}</span>
                            </td>
                            <td>
                              <span class="name">
                                <app-show-more-button [innerHTML]="message.message_body | nl2br"></app-show-more-button>                                
                              </span>
                            </td>
                            <td>
                              <span class="name">{{ message.message_category_name }}</span>
                            </td>
                            <td>
                              <span class="name">{{ message.tactic_name == 0?'-':message.tactic_name }}</span>
                            </td>
                            <td>
                              <span class="badge"
                                [ngClass]="message.status == 'Active' ? 'badge-complete' : 'badge-pending'">
                                {{message.status}}</span>
                            </td>
                            <td>
                              <span class="count">{{ message.messages_posted  | number:'1.0-0'}}</span>
                            </td>
                            <td>
                              <span class="count">{{ message.optins  | number:'1.0-0'}}</span>
                            </td>
                            <td>
                              <span class="count">{{ message.optins_percentage | number : '1.0-2' }}%</span>
                            </td>
                            <td class="created-Date">{{ message.created_date }}</td>          
                            <td style="text-align: center;">    
                              <div class="switcher-wrapper"> 
                                <label class="connect-switch" [ngClass]="{'notAllowed': message.status == 'Active' }" for="{{message.message_id}}">
                                  <input   
                                  [id]="message.message_id"                                 
                                  [(ngModel)]="message.message_status" 
                                  [value]="message.message_id"
                                  type="checkbox"                                    
                                  class="data-structure" 
                                  (click)="openDialog(message)"                                   
                                  >                                
                                  <span *ngIf="message.message_status == 0" class="slider round Inactive"></span>
                                  <span *ngIf="message.message_status == 1" class="slider round disabledData"></span>
                              </label>
                                <!-- <label class="switch" [ngClass]="{'notAllowed': message.status == 'Active' }" for="{{message.message_id}}">
                                  <input   
                                    [id]="message.message_id"                                 
                                    [(ngModel)]="message.message_status" 
                                    [value]="message.message_id"
                                    type="checkbox"                                    
                                    class="data-structure" 
                                    (click)="openDialog(message)"                                   
                                    >                            
                                  <span *ngIf="message.message_status == 0" class="aslider Inactive"></span>
                                  <span *ngIf="message.message_status == 1" class="aslider disabledData"></span>
                                </label> -->
                              </div>
                            </td>                  
                          </tr>
                        </ng-container>
                        <ng-template #NotFound>
                          <tr>
                            <td colspan="10" style="text-align: center;">No data found</td>
                          </tr>
                        </ng-template>
                        
                      </tbody>
                    </table>
                 
                </div>
              </div>
            </div>

          </div>
          <div class="newGuPagination" *ngIf="messages?.length>0">
            <pagination-controls [directionLinks]="false" (pageChange)="pageChanged($event)">
            </pagination-controls>
            <span class="countNumber">{{currentPageCount}}/{{totalRecords}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="shape-bg">
  <div class="c-shape-bg is--full rt-25">
  </div>
</div>

