import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-facebook-login',
  templateUrl: './facebook-login.component.html',
  styleUrls: ['./facebook-login.component.scss']
})
export class FacebookLoginComponent implements OnInit, OnDestroy {
  public fbAppKey     = environment.fbAppKey;
  public redirectUrl  = environment.baseURL + 'fb-callback'; // This exact same url must be whitelisted in the fb app

  // tslint:disable-next-line: max-line-length
  public fbLoginUri = `https://www.facebook.com/dialog/oauth?client_id=${this.fbAppKey}&redirect_uri=${this.redirectUrl}&response_type=code&scope=public_profile,email`;

  constructor(
  ) { }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('loginBg');
  }

  signInWithFB(): void {
    window.location.href = this.fbLoginUri;
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('loginBg');
  }

}
