import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { TacticService } from 'src/app/services/tactic.service';

@Component({
  selector: 'app-edit-tactic-status',
  templateUrl: './edit-tactic-status.component.html',
  styleUrls: ['./edit-tactic-status.component.scss']
})
export class EditTacticStatusComponent implements OnInit {
  public editMessageStatusForm: UntypedFormGroup;
  public alertMsg = { type: '', msg: ''};
  tacticStatus:boolean = this.data.mStatus;
  //@Output() filterCategoryEmit = new EventEmitter<{}>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditTacticStatusComponent>,
    private fb: UntypedFormBuilder,
    private _tacticService: TacticService,
  ) { }
   
  ngOnInit() {   
  }

  initializeForm(): void {
     }

   editTacStatus() {  
    const formData =  {
      id: this.data.messageId,
      status: this.tacticStatus === false ? true : false,
      charityId: this.data.charityId
    };    
    this._tacticService
        .editTacticStatus(formData)
        .subscribe(
          res => {
            this.showAlertMsg(res.message, 'success');
          },
          err => this.showAlertMsg(err.message, 'danger')
        );
  }

  showAlertMsg(msg, type): void {
    //this.filterCategoryEmit.emit({msg, type});
    this.onClose({msg, type});
  }

  onClose(output?) {
    this.dialogRef.close(output);
  }
}
