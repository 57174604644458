import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage;
          
          if (error.error instanceof ErrorEvent || error.error) errorMessage = { message: error.error.message };
          else errorMessage = {status: error.status, message: error.message};

          if (error.status === 403) {
            errorMessage = {status: error.status, message: 'Session expired.'};
            this.router.navigate(['/']);
          }

          if (error.status === 401) {
            errorMessage = {status: error.status, message: 'Session expired.'};
            this.router.navigate(['/login']);
          }

          return throwError(errorMessage);
        })
      );
  }
}
