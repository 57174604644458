<div class="continer-fluid">
  <div class="new-dashboard-wrapper">

      <ng-container *ngIf="alertMsg.msg !== ''">
          <app-alert [message]="alertMsg"></app-alert>
      </ng-container> 

    <!-- app header -->
    <app-new-header></app-new-header>

    <!-- Side Menu -->
    <app-side-menu></app-side-menu>

    <div class="grid-wrapper" *ngIf="isConnected">
      <div class="row no-gutters mx-864">
        <div class="col-12">
    
      
              <div class="ngo-admin-box">
                <div class="breadcrumbs">
                  <ul>
                    <li>
                      <a [routerLink]="['/integrations']">Integrations</a>
                    </li>
                    <li class="active">
                      <a href="javascript:void(0)">Luminate Online</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="content-integrations  mt-44">
    
                <div class="heading_wrapper mt-5">
                  <div class="page-title">Luminate Online</div>
                  <div class="disconnect pull-right">                   
                    <a class="custom-btn" (click)="disconnectIntegrations()">Disconnect</a>
                  </div>
                  <div class="synSettingWrapper">
                      <span matTooltip="By clicking this button, you'll trigger a manual data sync" *ngIf="!checkStatus" class="syncData disabled">
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                      </span>
                      <span matTooltip="By clicking this button, you'll trigger a manual data sync" *ngIf="checkStatus" class="syncData" (click)="syncStart()">
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                      </span>
                      <div *ngIf="is_Syn_start">
                        <div class="alert alert-warning fade in show" *ngIf="synMsg">
                          <strong>Sync Status:</strong> {{synMsg}}
                        </div>
                      </div>

                      <div>
                        <div class="alert alert-success fade in show" *ngIf="completeJobMsg">
                          <strong>Sync Status:</strong> {{ completeJobMsg }}
                        </div>
          
                        <div class="alert alert-danger fade in show" *ngIf="failedJobMsg">
                          <strong>Sync Status:</strong> {{ failedJobMsg }}
                        </div>
                      </div>
                      
                  </div>
                </div>
    
                <!-- <span class="connected"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Connected</span> -->
                <div class="content-box">
                  <div class="box mapAtrributes" (click)="viewMapattributes()">
                    <h4>Map GoodUnited Attributes</h4>
                    <p class="mb-0 subHeading">Map which fields you want to sync between GoodUnited and Luminate Online</p>
                  </div>                 
                  <div class="mapAtrributes" (click)="displaySyncSetting()">
                    <h4 class="pointer">Sync Settings</h4>
                    <p class="mb-0 subHeading">
                      Configure the settings for your Luminate Online Integration
                    </p>
                  </div>                
                  <!-- <div class="box pull-right">                   
                    <a class="btn btn-info" (click)="disconnectIntegrations()">Disconnect</a>
                  </div> -->
                </div>
              </div>
          
        </div>
      </div>
    </div>


    <div class="grid-wrapper" *ngIf="isMappingatrributes">
      <div class="row no-gutters mx-864">
        <div class="col-12">   
    
          <div class="ngo-admin-box">
            <div class="breadcrumbs">
              <ul>
                <li>
                  <a [routerLink]="['/integrations']">Integrations</a>
                </li>
                <li>
                  <a (click)="backToDisconnect()" href="javascript:void(0)">Luminate Online</a>
                </li>
                <li class="active">
                  <a href="javascript:void(0)">Map Attributes</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="heading_wrapper mt-44">
            <div class="page-title">Luminate Online</div>
            <div class="synSettingWrapper">
              <span title="Sync Settings" *ngIf="!checkStatus" class="syncData disabled">
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </span>
              <span title="Sync Settings" *ngIf="checkStatus" class="syncData" (click)="syncStart()">
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </span>
              <div *ngIf="is_Syn_start">
                <div class="alert alert-warning fade in show" *ngIf="synMsg">
                  <strong>Sync Status:</strong> {{synMsg}}
                </div>
              </div>

              <div class="alert alert-success fade in show" *ngIf="completeJobMsg">
                <strong>Sync Status:</strong> {{ completeJobMsg }}
              </div>

              <div class="alert alert-danger fade in show" *ngIf="failedJobMsg">
                <strong>Sync Status:</strong> {{ failedJobMsg }}
              </div>
          </div>
          </div>
    
          <div class="overlay__inner custom-overlay" *ngIf="loading">
            <div class="overlay__content">
              <span class="spinner loadImg"></span>
            </div>
          </div>
    
          <div *ngIf="sucessMessage" class="alert-wrapper p-4 col col-12 col-md-4 offset-0 offset-md-8">
            <div class="alert alert-success" role="alert">
              <span class="alert-message">
                <span class="alert-icon">
                  <svg style="width: 38px; height:38px;" (click)="closeSuccessMsg()" aria-hidden="true" data-prefix="fas"
                    data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16 arrow d-inline" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                    </path>
                  </svg>
                </span>
                {{successMsg}}
              </span>
              <span class="close" (click)="closeSuccessMsg()"> Close </span>
            </div>
          </div>
    
          <div *ngIf="errorMessage" class="alert-wrapper p-4 col col-12 col-md-4 offset-0 offset-md-8">
            <div class="alert alert-danger" role="alert">
              <span class="alert-message">
                <span class="alert-icon">
                  <svg style="width: 38px; height:38px;" (click)="closeErrMsg()" aria-hidden="true" data-prefix="far"
                    data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16 arrow d-inline" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
                    </path>
                  </svg>
                </span>
                {{errorMsg}}
              </span>
              <span class="close" (click)="closeErrMsg()"> Close </span>
            </div>
          </div>
    
          <div *ngIf="dupErrMessage" class="alert-wrapper p-4 col col-12 col-md-4 offset-0 offset-md-8">
            <div class="alert alert-danger" role="alert">
              <span class="alert-message">
                <span class="alert-icon">
                  <svg style="width: 38px; height:38px;" (click)="closeDupAlert()" aria-hidden="true" data-prefix="far"
                    data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16 arrow d-inline" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
                    </path>
                  </svg>
                </span>
                {{dupErrMessage}}
              </span>
              <span class="close" (click)="closeDupAlert()"> Close </span>
            </div>
          </div>
    
          <div class="custom-tab">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a class="nav-item nav-link" [class.active]="viewMode == 'tab1'" rel="tab1"
                  (click)="changeViewMode('tab1')">Fundraiser Properties</a>
                <a class="nav-item nav-link" [class.active]="viewMode == 'tab2'" rel="tab2"
                  (click)="changeViewMode('tab2')">Donor Properties</a>
              </div>
            </nav>
    
            <div class="clear"></div>
    
            <div class="ht-33">
              <ng-container *ngIf="data_type == 'fundraiser'">
                <!-- <span class="astrix mandatory pull-left">Email is mandatory(*)</span><br>                 -->
                <button type="button" (click)="updateFundraiser()" style="margin-top: -33px;"
                  class="custom-btn mgl-16 pull-right">
                  Update
                </button>
              </ng-container>
              <ng-container *ngIf="data_type == 'donor'">
                <!-- <span class="astrix mandatory pull-left">Email is mandatory(*)</span><br>                -->
                <button type="button" (click)="updateDonorForm()" style="margin-top: -33px;"
                  class="custom-btn mgl-16 pull-right">
                  Update Donor
                </button>
              </ng-container>
            </div>
    
            <div class="content-box mb-4">
              <div class="tab-content" [ngSwitch]="viewMode">
                <div class="" id="tab1" *ngSwitchCase="'tab1'">
                  <div class="form-wrapper">
                    <form [formGroup]="fundraiserForm">
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <th>GoodUnited Fields</th>
                            <th>Constituent Fields</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="width: 40%;">
                              Email<span class="astrix">*</span>
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Email address of individual who created campaign (if
                                  available)</span>
                              </div>
                              <div class="mandatoryField">Email is mandatory</div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="email"
                                (change)="changeVal('email',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Fundraiser Title
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Name of campaign designated by fundraiser</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="fundraiser_title"
                                (change)="changeVal('fundraiser_title',$event.target.value)"
                                [ngClass]="{ 'is-invalid': submitted && f.fundraiser_title.errors }">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                              <!-- <div *ngIf="submitted && f.fundraiser_title.invalid" class="invalid-feedback">
                                              <div *ngIf="f.fundraiser_title.errors.required">Fundraiser title is mandatory</div>
                                            </div> -->
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Persona Type
                              <!-- <div class="tooltipBox">
                                              <img src="assets/images/solidquestion.png" alt="">
                                              <span class="tooltiptextBox">Designates the Facebook giving tool used to receive the donation</span>
                                            </div> -->
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="persona_type"
                                (change)="changeVal('persona_type',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Campaign ID
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Unique ID assigned to campaign by Facebook</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="compaign_id"
                                (change)="changeVal('compaign_id',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Charity ID
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="ngo_id"
                                (change)="changeVal('ngo_id',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Organization
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="ngo_name"
                                (change)="changeVal('ngo_name',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Permalink
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Link to campaign on Facebook</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="permalink"
                                (change)="changeVal('permalink',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              First Name
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">First name of individual who created campaign</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="first_name"
                                (change)="changeVal('first_name', $event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Last Name
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Last name of individual who created campaign</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="last_name"
                                (change)="changeVal('last_name',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Full Name
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Full name of individual who created campaign</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="full_name"
                                (change)="changeVal('full_name',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Retention Score
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Fundraiser response as to the likelihood of creating a
                                  future campaign (1-5 scale, 5 highest)</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="nps"
                                (change)="changeVal('nps',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Mission Story
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Open text response from fundraiser about why they
                                  raised money for your organization</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="nps_feedback"
                                (change)="changeVal('nps_feedback',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Opt In
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Designates whether the fundraiser opted-in to receive
                                  messages through Messenger</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="opt_in"
                                (change)="changeVal('opt_in',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Total Raised
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Total amount of donations contributed to
                                  campaign</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="total_raised"
                                (change)="changeVal('total_raised',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Number of Donations
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Total number of donors who contributed to the
                                  campaign</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="donation"
                                (change)="changeVal('donation',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Start Date
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">The date the campaign received the first donation (e.g.
                                  2012-01-17)</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="start_date"
                                (change)="changeVal('start_date',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Thank You's Delivered
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="thank_you_delivered"
                                (change)="changeVal('thank_you_delivered',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Goal
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">The goal amount the fundraiser set for the
                                  campaign</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="goal"
                                (change)="changeVal('goal',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              End Date
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">The data the campaign is scheduled to end (e.g.
                                  2012-01-17)</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="end_date"
                                (change)="changeVal('end_date',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Post Message 2 Posted
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="is_postmessage2_posted"
                                (change)="changeVal('is_postmessage2_posted',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              % of goal
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">The ratio between the total amount of donations
                                  received and the goal.</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="percent_goal"
                                (change)="changeVal('percent_goal',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Status
                              <div class="tooltipBox lastTooltip">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Indicates whether a campaign is Active or Complete. The
                                  Complete status occurs when a campaign end date has passed, or if we have not
                                  received an end date and the campaign has not received a donation for 14
                                  days.</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="status"
                                (change)="changeVal('status',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Fundraiser Type
                              <div class="tooltipBox lastTooltip">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">
                                  The Type of fundraiser on Facebook. May be any of the following:<br>
                                  <ul class="tooltipText">
                                    <li>API Integrated Fundraiser</li>
                                    <li>Birthday Fundraiser</li>
                                    <li>Generic Fundraiser</li>
                                    <li>Instagram Profile Donate Button</li>
                                    <li>Instagram Stories Sticker</li>
                                    <li>Page Donate Button</li>
                                    <li>Post Donate Button</li>
                                  </ul>
                                </span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="fundraiser_type"
                                (change)="changeVal('fundraiser_type',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" >
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
                <div class="" id="tab2" *ngSwitchCase="'tab2'">
                  <div class="form-wrapper">
                    <form [formGroup]="donorForm">
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <th style="width: 40%;">GoodUnited Fields</th>
                            <th style="width: 60%;">Constituent Fields</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="width: 40%;">
                              Email<span class="astrix">*</span>
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">The donor’s email (if shared).</span>
                              </div>
                              <div class="mandatoryField">Email is mandatory</div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="email"
                                (change)="changeVal('email',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Fundraiser Title
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Name of campaign designated by fundraiser, if
                                  applicable</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="fundraiser_title"
                                (change)="changeVal('fundraiser_title',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Persona Type
                              <!-- <div class="tooltipBox">
                                              <img src="assets/images/solidquestion.png" alt="">
                                              <span class="tooltiptextBox">Designates the Facebook giving tool used to receive the donation</span>
                                            </div> -->
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="persona_type"
                                (change)="changeVal('persona_type',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Donation Amount
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">The amount donated.</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="donation_amount"
                                (change)="changeVal('donation_amount',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Payment ID
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Unique transaction/payment ID assigned by
                                  Facebook.</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="payment_id"
                                (change)="changeVal('payment_id',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Source Name
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="source_name"
                                (change)="changeVal('source_name',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Charge Date
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">The date the donation was processed in (e.g.
                                  2012-01-17)</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="charge_date"
                                (change)="changeVal('charge_date',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Attribution Source
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Where the user email address was obtained</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="attribution_source"
                                (change)="changeVal('attribution_source',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Campaign ID
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Link to campaign on Facebook, if applicable</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="campaign_id"
                                (change)="changeVal('campaign_id',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Permalink
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">Link to campaign on Facebook, if applicable</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="permalink"
                                (change)="changeVal('permalink',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Charity ID
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="charity_id"
                                (change)="changeVal('charity_id',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              First Name
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">The donor’s first name.</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="first_name"
                                (change)="changeVal('first_name',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Last Name
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">The donor’s last name.</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="last_name"
                                (change)="changeVal('last_name',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Opt In
                              <div class="tooltipBox">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">If the user opted in through Messenger. Possible values
                                  are either “true” or "false".</span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="opt_in"
                                (change)="changeVal('opt_in',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 40%;">
                              Fundraiser Type
                              <div class="tooltipBox lastTooltip">
                                <img src="assets/images/solidquestion.png" alt="">
                                <span class="tooltiptextBox">
                                  The Type of fundraiser on Facebook. May be any of the following:<br>
                                  <ul class="tooltipText">
                                    <li>API Integrated Fundraiser</li>
                                    <li>Birthday Fundraiser</li>
                                    <li>Generic Fundraiser</li>
                                    <li>Instagram Profile Donate Button</li>
                                    <li>Instagram Stories Sticker</li>
                                    <li>Page Donate Button</li>
                                    <li>Post Donate Button</li>
                                  </ul>
                                </span>
                              </div>
                            </td>
                            <td style="width: 60%;">
                              <select class="form-control" formControlName="fundraiser_type"
                                (change)="changeVal('fundraiser_type',$event.target.value)">
                                <option value="">Please Select</option>
                                <option *ngFor="let team_raiser_attribute of temp_team_raiser_attributes"
                                  [value]="team_raiser_attribute.id" [disabled]="team_raiser_attribute.isDisabled">
                                  {{team_raiser_attribute.d_teamraiser_attribute}}
                                </option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-wrapper mb-5">
              <ng-container *ngIf="data_type == 'fundraiser'">
                <button type="button" (click)="updateFundraiser()" style="margin-top: -25px;" class="custom-btn">
                  Update
                </button>
              </ng-container>
              <ng-container *ngIf="data_type == 'donor'">
                <button type="button" (click)="updateDonorForm()" style="margin-top: -25px;" class="custom-btn">
                  Update Donor
                </button>
              </ng-container>
            </div>
          </div>
    
        </div>
      </div>
    </div>

  
  </div>
</div>
<div class="bg"></div>