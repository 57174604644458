import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SelectedNgo } from '../models/SelectedNgo.model';
import { AddProjectImageResponse } from '../models/Common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  fundraiserPages(): Observable<SelectedNgo[]> {
    return this.http.get<any>('fundraiserPages')
      .pipe(
        map((res: any) => res.message),
        catchError((error) => this.redirect(error))
      );
  }

  setPage(charityId: string) {
    const params = {
      charity_id: charityId,
    }
    return this.http.put('setPage', {}, { params })
      .pipe(
        catchError((error) => this.redirect(error))
      );
  }

  presignedUrl(charityId: string, eventIdentifier: string, extension: string): Observable<AddProjectImageResponse> {
    let params      = new HttpParams();
    params = params.append('extension', extension);
    params = params.append('charity_id', charityId);
    params = params.append('event_identifier', eventIdentifier);

    const path = 'event/upload/image';
    return this.http.get<AddProjectImageResponse>(path, { params })
      .pipe(
        catchError((error) => this.redirect(error))
      );
  }

  uploadImage(blob: Blob, ext: string, path: string) {
    let headers   = new HttpHeaders();
    headers = headers.set('Content-Type', ext);
    headers = headers.set('x-amz-acl', 'public-read');

    return this.http.put(path, blob, {headers});
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })

  dataURItoBlob(dataURI): Blob {
    let byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  dataURLtoFile(dataURI, filename) {
    let byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ia], filename, { type: mimeString });
  }

  uploadReport(charityId: string, file: File) {
    let formData: FormData = new FormData();
    formData.append('file', file);

    let params      = new HttpParams();
    params = params.append('charity_id', charityId);
    

    return this.http.post('upload', formData, { params });
  }

  redirect(err) {
    if (err.status === 401) this.router.navigate(['/login']);
    return throwError(err);
  }
}
