import * as momentJs from 'moment';

export class MomentDate {
  public months: string[] = momentJs.monthsShort();

  public eventLocaleOptions = {
    format: 'MM-DD-YYYY',
    displayFormat: 'MM-DD-YYYY',
    direction: 'ltr',
    cancelLabel: 'Cancel',
    applyLabel: 'Apply',
    clearLabel: 'Clear',
    customRangeLabel: 'Custom range',
  };

  public ngxLocaleOptions = {
      format: 'DD-MM-YYYY',
      displayFormat: 'DD-MM-YYYY',
      direction: 'ltr',
      cancelLabel: 'Cancel',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      customRangeLabel: 'Custom range',
  };

  constructor() {
  }

  monthInt(month: string): number {
    return this.months.findIndex(val => val === month) + 1;
  }
}

