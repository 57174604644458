import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subReportLength'
})
export class SubReportLengthPipe implements PipeTransform {

  transform(value: any,changeDetect:any): any {
    return value.filter(ele => ele.is_checked)?.length;
  }

}
