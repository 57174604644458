export interface SubscriptionGraph {
    data: ChartData[];
    label: Array<string>;
    overall: Overall;
    trend: any;
}

export interface ChartData {
    title: string;
    type: string;
    value: Array<number>;
}

export interface Overall {
    totalOptins: number;
}

// export const ChartColor = ['#957F38', '#C69A34', '#F8B630', '#FCDA97', '#002C45'];

export const ChartColor = {
    Direct: '#04C3CB',

    Events: '#F8B630',

    P2P: '#F8B630',

    Challenges: '#F8B630',

};


export interface Filter {
    type: string;
    year: number;
    month: string;
}
