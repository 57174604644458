import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  getUserInfo(): Observable<any> {    
    return this.http.get('getBasicInfo');
  }

  // getCurrentPage(pageid: string) {
  //   return this.http.post('fundraiserService/setPage?pageId=' + pageid, {});
  // }

  getUserList(charityId: string, pageNumber = null, role = null,): Observable<any> {
    const params = {
      charity_id: charityId,      
      page: pageNumber,
      role: role
    }
    const path = 'get_users';
    return this.http.get(path, { params }).pipe(
      catchError((error) => this.redirect(error))
    );
  }

  getSearchUserList(charityId: string, pageNumber = null, role = null, search:string): Observable<any> {
    const params = {
      charity_id: charityId,      
      page: pageNumber,
      role: role,
      search: search
    }
    const path = 'get_users';
    return this.http.get(path, { params }).pipe(
      catchError((error) => this.redirect(error))
    );
  }

  getRole(charityId: string): Observable<any> {
    const params = {
      charity_id: charityId
    }
    const path = 'get_roles';
    return this.http.get(path, { params }).pipe(
      catchError((error) => this.redirect(error))
    );
  }

  postUser(charityId: string, data:any): Observable<any>{
    const params = {
      charity_id: charityId,
    };

    return this.http.post('invite_user', data, { params }).pipe(
      catchError((error) => this.redirect(error))
    );
  }

  deactivateUser(charityId: string, data:any): Observable<any>{
   
    const params = new HttpParams().set('charity_id', charityId);
    return this.http.put('deactivate_user', data, { params }).pipe(
      catchError((error) => this.redirect(error))
    );
    
  }

  logout(): Observable<any> {
    return this.http.get('logout')
    .pipe(
      catchError((error) => this.redirect(error))
    );
  }

  redirect(err) {
    if (err.status === 401) this.router.navigate(['/login']);
    return throwError(err);
  }

}