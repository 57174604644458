<div class="overlay__inner custom-overlay" *ngIf="conversionLoader">
    <div class="overlay__content">
        <span class="spinner loadImg"></span>
    </div>
</div>

<ng-container *ngIf="conversionData && (conversionData?.thank_you_count > 0 || conversionData?.opt_in_count > 0 || conversionData?.email_count > 0); else notfound">
    <h2 class="common-heading"><span [matTooltipClass]="positionBottom.value" matTooltip="Showing how many people opted into engage with GU and your business via messenger, and or, provided their email address.">Conversions</span></h2>
    <div class="stat-row1 conversions">
        <div class="fundraiser_thanked_tv ctotalv">{{fundraiserthankedTotalVal | numFormatter}}</div>
        <div class="opted_in ctotalv" [ngStyle] = "{'left':(scrWidth/3) + 20 +'px'}">{{optinTotalVal | numFormatter}}<span class="common_conversion_style">({{conversionData?.opt_in_percent | number:'1.0-0'}}%)</span></div>
        <div class="emails_collected ctotalv" [ngStyle] = "{'left':(scrWidth/3 + 168) +'px'}">{{emailTotalVal | numFormatter}}<span class="common_conversion_style">({{conversionData?.email_percent | number:'1.0-0'}}%)</span></div>
        <!-- <div class="email_percent common_conversion_style" [ngStyle] = "{'left':2*(scrWidth/3) + 80 +'px'}">({{conversionData?.email_percent | number:'1.0-0'}}%)</div>
        <div class="opt_in_percent common_conversion_style" [ngStyle] = "{'left':(scrWidth/3 + 75) +'px'}">({{conversionData?.opt_in_percent | number:'1.0-0'}}%)</div> -->
    </div>
    <div class="funnel-data-container">
        <div class="stat-blocks1">
            <div class="stat-block">
                <div class="stat-label-wrap" style="justify-content:left;">                                               
                    <div class="stat-label common">Direct <span class="skyblue">{{conversionDirectData?.thank_you_count_direct | numFormatter}}</span></div>
                </div>
                <div class="stat-label-wrap" style="justify-content:left;">                                               
                    <div class="stat-label common">Challenges<span class="orange">{{conversionEventData?.thank_you_count_fbc | numFormatter}}</span></div>
                </div>           
            </div>
        </div>
        <div class="stat-blocks2" [ngStyle] = "{'left':(scrWidth/3) + 5 +'px'}">
            <div class="stat-block">
                <div class="stat-label-wrap" style="justify-content:left;">                                               
                    <div class="stat-label common">Direct <span class="skyblue">{{conversionDirectData?.opt_in_count_direct | numFormatter}}</span> <span class="percentColor" *ngIf="conversionDirectData && conversionDirectData?.opt_in_percent_direct">({{conversionDirectData?.opt_in_percent_direct | number:'1.0-0'}}%)</span></div>
                </div>
                <div class="stat-label-wrap" style="justify-content:left;">                                               
                    <div class="stat-label common">Challenges<span class="orange">{{conversionEventData?.opt_in_count_fbc | numFormatter}}</span> <span class="percentColor" *ngIf="conversionEventData && conversionEventData?.opt_in_percent_fbc">({{conversionEventData?.opt_in_percent_fbc | number:'1.0-0'}}%)</span></div>
                </div>           
            </div>
        </div>
        <div class="stat-blocks3" [ngStyle] = "{'left':2*(scrWidth/3) + 10 +'px'}">
            <div class="stat-block">
                <div class="stat-label-wrap" style="justify-content:left;">                                               
                    <div class="stat-label common">Direct <span class="skyblue">{{conversionDirectData?.email_count_direct | numFormatter}}</span> <span class="percentColor" *ngIf="conversionDirectData && conversionDirectData?.email_percent_direct">({{conversionDirectData?.email_percent_direct | number:'1.0-0'}}%)</span></div>
                </div>
                <div class="stat-label-wrap" style="justify-content:left;">                                               
                    <div class="stat-label common">Challenges<span class="orange">{{conversionEventData?.email_count_fbc | numFormatter}}</span> <span class="percentColor" *ngIf="conversionEventData && conversionEventData?.email_percent_fbc">({{conversionEventData?.email_percent_fbc | number:'1.0-0'}}%)</span></div>
                </div>           
            </div>
        </div>
    </div>
    <div class="html-embed-9 w-embed w-script">
        <div class="flex flex-chart">
            <div class="funnel padTop-95">
            </div>
        </div>
    </div>
</ng-container>

<ng-template #notfound>
    <h2 class="common-heading mr-3">
        <span [matTooltipClass]="positionBottom.value" matTooltip="Showing how many people opted into engage with GU and your business via messenger, and or, provided their email address.">Conversions</span>
    </h2> 
  <div class="nofound"><p>No Data Found</p></div>
</ng-template>