import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Filter } from '../models/Fundraiser';
import { map } from 'rxjs/operators';
import { DateFilter } from '../shared/custom/DateFilter';

@Injectable({
  providedIn: 'root',
})
export class SubscriberListService {
  public dateFilter = new DateFilter();

  constructor(
    private http: HttpClient,
  ) { }

  getSubscriberList(charityId: string, data: Filter): Observable<SubscriberListService> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;

    const path = 'get_top_subscribers';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  getSubscriberDetailList(charityId: string, data: Filter, limit: any, page: any, search:string, ordebyasc:string, orderbydsc:string): Observable<SubscriberListService> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;
    params['limit'] = limit;
    params['page'] = page;
    params['search'] = search;
    params['order_by_asc'] = ordebyasc;
    params['order_by_dsc'] = orderbydsc;

    const path = 'get_subscribers_list';

    return this.http.get(path, { params }).pipe(map((res: any) => res));
  }

  subcriberDetails(charityId: string, person_id: string): Observable<any> {
    const params = {
      charity_id: charityId,
      person_id: person_id,
    };
    const path = 'get_subscriber_details';

    return this.http.get(path, { params });
  }

}