import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-report-delete-confirmation',
  templateUrl: './report-delete-confirmation.component.html',
  styleUrls: ['./report-delete-confirmation.component.scss']
})
export class ReportDeleteConfirmationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReportDeleteConfirmationComponent>,
  ) { }

  ngOnInit() {
  }

  onClose(output: boolean) {
    this.dialogRef.close(output);
  }

}
