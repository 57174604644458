<div class="continer-fluid">
    <div class="new-dashboard-wrapper">
        <div class="overlay__inner custom-overlay" *ngIf="loading">
            <div class="overlay__content custom">
                <span class="spinner loadImg"></span>
            </div>
        </div>
        <!-- app header -->
        <app-new-header></app-new-header>
        <!-- Side Menu -->
        <app-side-menu (ngoChanged)="ngoChanged($event)"></app-side-menu>
        <div class="grid-wrapper subscriberDetail position-relative">
            <ng-container
                *ngIf="fundraiserEvents?.length > 0 || fundraiserDirect?.length > 0 || donationData?.length > 0 || personalData?.mission_story?.length > 0; else NoDataFoundView">
                <div class="row no-gutters">
                    <div class="breadcrumbs csBrd">
                        <ul>
                            <li>
                                <a href="javascript:void(0)" (click)="breadCrumbs()">Subscribers</a>
                            </li>
                            <li class="active">
                                <a href="javascript:void(0)">Subscriber Details</a>
                            </li>
                        </ul>
                    </div>
                    <div class="leftCol">
                        <div class="row no-gutters">
                            <div class="col-12">
                                <div class="heading_wrapper mbTitle">
                                    <div class="page-title">Subscriber Details</div>
                                </div>
                                <div class="topcontent">
                                    <div class="leftBox">
                                        <div class="imageBox">
                                            <ng-container *ngIf="personalData?.profile_pic_url!=null; else NoImgFound">
                                                <img *ngIf="personalData?.profile_pic_url"
                                                    [src]="personalData?.profile_pic_url" alt="">
                                            </ng-container>
                                            <ng-template #NoImgFound>
                                                <img class="round" src="../../../assets/images/defaultImg.png" alt="">
                                            </ng-template>
                                        </div>
                                        <div class="userInfo">
                                            <h4 [ngClass]="{'invisible' : personalData?.full_name==null}">{{personalData?.full_name}}</h4>
                                            <div class="box  mb-3">
                                                <div class="status" [ngClass]="{'invisible' : personalData?.status==null}">
                                                    <span>{{personalData?.status}}</span></div>
                                                <div class="subscribed" [ngClass]="{'invisible' : personalData?.subscribed_at==null}">
                                                    Subscribed: {{personalData?.subscribed_at}}</div>
                                            </div>
                                            <div class="box">
                                                <div class="focus">Focus Area

                                                    <div class="focusarea" *ngIf="personalData?.focus_area!=null; else NotAvailable">
                                                        <span>{{personalData?.focus_area}}</span>
                                                    </div>
                                                    <ng-template #NotAvailable>
                                                        <div class="focusarea"> 
                                                           <span>--</span> 
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="rightBox">
                                        <div class="commonfont email" *ngIf="personalData?.email_id!=null">
                                            <span><a href="mailto:{{personalData?.email_id}}" class="mktNoTrack" target="_blank">{{personalData?.email_id}}</a></span>
                                        </div>
                                        <div class="commonfont calender"
                                            [ngClass]="{'invisible' : personalData?.fundraiser_birthday==null}">
                                            <span>{{personalData?.fundraiser_birthday}}</span>
                                        </div>
                                        <div class="commonfont location" [ngClass]="{'invisible' : personalData?.zip_code==null}">
                                            <span>{{personalData?.zip_code}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="content-box" *ngIf="personalData?.mission_story!=null">
                                    <div class="content-wrapper">
                                        <div class="heading">Their Story:</div>
                                        <p>{{personalData?.mission_story}}</p>
                                    </div>
                                    <div class="btn-wrapper">
                                        <a [href]="replylink" target="_blank" class="custom-btn">Reply</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-336" *ngIf="fundraiserEvents?.length > 0 || fundraiserDirect?.length > 0 || donationData?.length > 0">

                        <div class="tab-wrapper">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" href="#fundraisers">Fundraisers ({{totalCampaignCount }})</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#donations">Donations ({{donationData?.length}})</a>
                                </li>
                            </ul>


                            <div class="tab-content">
                                <div class="tab-pane active" id="fundraisers">


                                    <div class="chartBox">
                                        <div class="total" *ngIf="totalFundraiserPrice">
                                            <span class="totalTitle">Total</span>
                                            <span class="totalPrice">{{ totalFundraiserPrice |
                                                currency: selectedNgo.currency_name :'symbol':'1.0-0' }}</span>
                                        </div>
                                        <div [hidden]="!chart" *ngIf="totalFundraiserPrice">
                                            <canvas id="fundraiseDoughNut"></canvas>
                                        </div>
                                    </div>

                                    <div class="eventType" *ngIf="fundraiserData > 0">
                                        <ul>
                                            <li *ngIf="fundraiserDirect?.length > 0">
                                                <div class="sdisc prBg"></div>
                                                <div class="direct commonFont font-weight-bold">Direct</div>
                                            </li>
                                            <li *ngIf="fundraiserEvents?.length > 0">
                                                <div class="sdisc orBg"></div>
                                                <div class="events commonFont font-weight-bold">Challenges</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="donutList">
                                        <ul>
                                            <ng-container *ngIf="fundraiserEvents?.length > 0">
                                                <li *ngFor="let event of fundraiserEvents; let i = index">
                                                    <div class="row no-gutters">
                                                        <div class="col-md-8">
                                                            <div class="eventBox">
                                                                <div class="disc orBg"></div>
                                                                <div class="title commonFont font-weight-bold">
                                                                    {{event?.name}}</div>
                                                                <div class="evDate subHeading">
                                                                    {{event?.event_start_date}} -
                                                                    {{event?.event_end_date}}</div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-md-4 text-right">
                                                            <div class="price subHeading">{{event?.total_donation |
                                                                currency:'USD':'symbol':'1.0-0'}}</div>
                                                        </div> -->
                                                        <ul class="eventsCampaigns">
                                                            <li *ngFor="let eCampaign of fundraiserEvents[i].campaigns">
                                                                <div class="row no-gutters">
                                                                    <div class="col-md-8">
                                                                        <div class="eventBox">
                                                                            <div class="disc orBg"></div>
                                                                            <div class="title commonFont font-weight-bold pointer" (click)="openCampaignLink(eCampaign?.id)">
                                                                                {{eCampaign?.campaign_title}}
                                                                            </div>
                                                                            <div class="evDate subHeading">
                                                                                {{eCampaign?.campaign_start_date}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 text-right">
                                                                        <div class="price subHeading">{{eCampaign?.total_donation |
                                                                            currency: selectedNgo.currency_name :'symbol':'1.0-0'}}</div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            <ng-container *ngIf="fundraiserDirect?.length > 0">
                                                <li *ngFor="let direct of fundraiserDirect">
                                                    <div class="row no-gutters">
                                                        <div class="col-8">
                                                            <div class="eventBox">
                                                                <div class="disc prBg"></div>
                                                                <ng-container
                                                                    *ngIf="direct?.fundraiser_type!=null || direct?.campaign_title!=null; else defaultVal">
                                                                    <div class="title commonFont font-weight-bold pointer" (click)="openCampaignLink(direct?.id)">
                                                                        {{direct?.fundraiser_type}} <span class="dash" *ngIf="direct?.fundraiser_type!=null && direct?.campaign_title!=null"> - </span> {{direct?.campaign_title}}
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #defaultVal>
                                                                    <div class="title commonFont font-weight-bold">
                                                                        Fundraiser
                                                                    </div>
                                                                </ng-template>
                                                                <div class="evDate subHeading">
                                                                    {{direct?.campaign_start_date}}</div>
                                                            </div>
                                                        </div>
                                                        <!-- <ul class="eventsCampaigns">
                                                            <li *ngFor="let eCampaign of fundraiserDirect[i].campaigns">
                                                                <div class="row no-gutters">
                                                                    <div class="col-md-8">
                                                                        <div class="eventBox">
                                                                            <div class="disc orBg"></div>
                                                                            <div class="title commonFont font-weight-bold pointer" (click)="openDirectCampaignLink(eCampaign?.id)">
                                                                                {{eCampaign?.campaign_title}}
                                                                            </div>
                                                                            <div class="evDate subHeading">
                                                                                {{eCampaign?.campaign_start_date}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4 text-right">
                                                                        <div class="price subHeading">{{eCampaign?.total_donation |
                                                                            currency:'USD':'symbol':'1.0-0'}}</div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul> -->
                                                        <div class="col-4 text-right">
                                                           <div class="price subHeading">{{direct?.total_donation |
                                                                currency: selectedNgo.currency_name :'symbol':'1.0-0'}}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="donations">
                                    <ng-container *ngIf="donationData?.length > 0; else noDonationData">

                                        <div class="chartBox">
                                            <div class="total" *ngIf="totalDonationPrice">
                                                <span class="totalTitle">Total</span>
                                                <span class="totalPrice">{{ totalDonationPrice |
                                                    currency: selectedNgo.currency_name :'symbol':'1.0-0' }}</span>
                                            </div>
                                            <div [hidden]="!chart1" *ngIf="totalDonationPrice">
                                                <canvas id="donationDoughNut"></canvas>
                                            </div>
                                        </div>

                                        <div class="eventType">

                                            <ul *ngIf="donationsLabel?.length > 0">
                                                <li *ngFor="let label of donationsLabel;let i = index">
                                                    <div class="sdisc"
                                                        [ngStyle]="{'background-color': DoChartColor[i]}"></div>
                                                    <div class="direct commonFont font-weight-bold">{{label}}</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="donutList">
                                            <ul>
                                                <li *ngFor="let donation of donationData;let i = index">
                                                    <div class="row no-gutters">
                                                        <div class="col-8">
                                                            <div class="eventBox">
                                                                <div class="disc"
                                                                    [ngStyle]="{'background-color': DoChartColor[i]}">
                                                                </div>
                                                                <div class="title commonFont font-weight-bold">
                                                                    {{donation?.name}}</div>
                                                                <div class="evDate subHeading">{{donation?.charge_date}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 col-xs-4 text-right">
                                                            <div class="price subHeading">{{donation?.donation_amount |
                                                                currency: selectedNgo.currency_name :'symbol':'1.0-0'}}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-container>
                                    <ng-template #noDonationData>
                                        <div class="notFound text-center">
                                            <p class="donation_not">This Subscriber has not made any donations yet.</p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #NoDataFoundView>
                <div class="row no-gutters">
                    <div class="breadcrumbs csBrd">
                        <ul>
                            <li>
                                <a href="javascript:void(0)" (click)="breadCrumbs()">Subscribers</a>
                            </li>
                            <li class="active">
                                <a href="javascript:void(0)">Subscriber Details</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <div class="heading_wrapper mbTitle">
                            <div class="page-title">Subscriber Details</div>
                        </div>
                        <div class="onlySubInfo">
                            <div class="topcontent">
                                <div class="leftBox">
                                    <ng-container *ngIf="personalData?.profile_pic_url!=null; else NoImg">
                                        <div class="ImgBox">
                                            <img class="round" *ngIf="personalData?.profile_pic_url"
                                                [src]="personalData?.profile_pic_url" alt="">
                                        </div>
                                    </ng-container>
                                    <ng-template #NoImg>
                                        <img class="round" src="../../../assets/images/defaultImg.png" alt="">
                                    </ng-template>
                                    <div class="userInfo">
                                        <h4 *ngIf="personalData?.full_name!=null">{{personalData?.full_name}}</h4>
                                        <div class="box  mb-3">
                                            <div class="status" *ngIf="personalData?.status!=null">
                                                <span>{{personalData?.status}}</span></div>
                                            <div class="subscribed" *ngIf="personalData?.subscribed_at!=null">
                                                Subscribed: {{personalData?.subscribed_at}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="msgNoinfo">
                                <p>We're Still Gathering Information on this Subscriber!</p>
                                <p>Information related to their Fundraiser, Donations, and more will appear as they take further actions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

        </div>
    </div>
</div>
<div class="shape-bg">
    <div class="c-shape-bg is--full rt-25">
    </div>
</div>