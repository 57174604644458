import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-direct-confirmation',
  templateUrl: './direct-confirmation.component.html',
  styleUrls: ['./direct-confirmation.component.scss']
})
export class DirectConfirmationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DirectConfirmationComponent>,
  ) { }

  ngOnInit() {
  }

  onClose(output: boolean) {
    this.dialogRef.close(output);
  }

}
