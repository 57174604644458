import { Component, OnInit, OnDestroy } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MessageService } from '../services/message.service';
import { NgoSharedService } from '../shared/services/ngo-shared.service';
import { Subscription } from 'rxjs';
import { SentenceStoryDetailComponent } from '../sentence-story-detail/sentence-story-detail.component';


@Component({
  selector: 'sentence-classifier',
  templateUrl: './sentence-classifier.component.html',
  styleUrls: ['./sentence-classifier.component.scss']
})
export class SentenceClassifierComponent implements OnInit, OnDestroy {

  charityIDSubscription: Subscription;
  charityID: any;  
  sentences = [];
  loading = false;
  totalRecords: number;
  currentPage = 1;
  currentPageCount: number;
  pages = [];
  config: any;
  sentenceType = [];
  sType:any;
  
  constructor(
    public dialog: MatDialog,
    private _messageservice: MessageService,
    private _ngoSharedService: NgoSharedService
  ) {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalRecords
    };
  }

  receiveMessage($event) { if ($event) { } }

  ngOnInit() {
    this.getCharityId();
  }

  ngoChanged($event) { if ($event) { } }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        selectedNGO => {
          this.config.currentPage = 1;
          this.sentences = [];
          if(selectedNGO.charityId !== null){
            this.charityID = selectedNGO.charityId;
            this.viewSentenceLevelSpecifier();
            this.viewSentenceType();
            localStorage.clear();
            //this.getFilteredSentenceData(this.sType);
          }
          
        },
      );
  }

  viewSentenceLevelSpecifier() {
    this.loading = true;
    this._messageservice.getSentenceLevellist(this.charityID, this.config.currentPage).subscribe((res: any) => {
       if(res){
         this.sentences = res.message;
         this.pages = res.pages;
         this.totalRecords = res.records;
         this.config.totalItems = this.totalRecords;
         this.currentPageCount = this.config.itemsPerPage * (this.config.currentPage - 1) + this.sentences.length;
         this.loading = false;
       }
    })
  }

  viewSentenceType(){
    this._messageservice.getSentenceType(this.charityID).subscribe((res:any) =>{
      if(res){
        this.sentenceType = res.message;
      }
    });
  }

  getFilteredSentenceData(){
    this.loading = true;
    this._messageservice.postSentenceType(this.charityID, this.sType, this.config.currentPage).subscribe((res:any) => {
      if(res){
        this.sentences = res.message;
        this.pages = res.pages;
        this.totalRecords = res.records;
        this.config.totalItems = this.totalRecords;
        this.currentPageCount = this.config.itemsPerPage * (this.config.currentPage - 1) + this.sentences.length;
        this.loading = false;
        if(res.message.length === 0){
          this.viewSentenceLevelSpecifier();
        }
      }
    })
  }

  changeDropDownValue(type){
    this.config.currentPage = 1;
    localStorage.setItem('sentencetype', type);
    this.sType = localStorage.getItem('sentencetype');
    this.getFilteredSentenceData();
  }

  pageChanged(event) {
    this.config.currentPage = event;
    if(typeof this.sType == 'undefined' || this.sType == '' ){
      this.viewSentenceLevelSpecifier();
    }else{      
      this.getFilteredSentenceData();
    }   
  }

  displayContentDetail(ev) {
    //this.status = !this.status; 
    const dialogRef = this.dialog.open(SentenceStoryDetailComponent,{
      // height: '450px',
      // width: '1000px',
      data:{question: ev.ques, type:ev.type, charityId: this.charityID}
    });

    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  ngOnDestroy() { this.charityIDSubscription.unsubscribe(); }

}

