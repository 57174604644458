import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Filter } from '../models/Fundraiser';
import { DateFilter } from '../shared/custom/DateFilter';

@Injectable({
    providedIn: 'root'
})
export class FundraiserService {
  public dateFilter = new DateFilter();

  constructor(
        private http: HttpClient,
    ) { }

    getFundraiserStories(charityId: string, page: any, data: Filter, size: any, pageId: any): Observable<any> {
        let params = this.dateFilter.getParams(data);
        params['charity_id'] = charityId;
        params['page'] = page;
        params['size'] = size;
        params['pageId'] = pageId;

        const path = 'fundraiserMissionStories';
        return this.http.get(path, { params });
    }

    wordList(charityId: string, data: Filter, cluster_id: any): Observable<any> {
        let params = this.dateFilter.getParams(data);
        params['charity_id'] = charityId;
        params['cluster_id'] = cluster_id;

        const path = 'word_cloud_each_word_count';

        return this.http.get(path, { params }).pipe(map((res: any) => res.message));
      }

      wordStories(charityID: any, word: any, pageID: any, data: Filter, cluster_id: any): Observable<any> {
        let params = this.dateFilter.getParams(data);
        params['charity_id'] = charityID;
        params['cluster_id'] = cluster_id;
        params['page'] = pageID;
        params['word'] = word;

        const path = 'get_stories_from_word';

        return this.http.get(path, { params });
    }

    getRetentionScore(charityId: string): Observable<any> {
        const params = {
            charity_id: charityId,
        };

        return this.http.get('get_retention_score_data', { params }).pipe(map((res: any) => res.message));
    }

    getConversion(charityId: string, data: Filter): Observable<any> {
        let params = this.dateFilter.getParams(data);
        params['charity_id'] = charityId;

        return this.http.get('get_conversions_data', { params }).pipe(map((res: any) => res.message) );
    }

}