import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'getValueFormat'
})
export class GetValueFormatPipe implements PipeTransform {

  transform(value: any, type: string): string {
    if (type === 'date') 
    {
      if(value?.startDate){
        return moment(new Date(value?.startDate)).format('MM-DD-YYYY');
      }
      value = value.replace(/-/g,'/'); 
      return moment(new Date(value)).format('MM-DD-YYYY');
    }
    return value;
  }

}
