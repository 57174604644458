import { Pipe, PipeTransform } from '@angular/core';
import { CONDITION_BY_DT } from '../constant';

@Pipe({
  name: 'isValueRequired'
})
export class IsValueRequiredPipe implements PipeTransform {

  transform(value: string): boolean {
    const noValueConditionList = [
      CONDITION_BY_DT['string'][2].key, 
      CONDITION_BY_DT['string'][6].key,
      CONDITION_BY_DT['boolean'][0].key,
      CONDITION_BY_DT['boolean'][1].key,
      CONDITION_BY_DT['boolean'][2].key,
      CONDITION_BY_DT['boolean'][3].key,
    ];
    return value ? !noValueConditionList?.includes(value): false;
  }

}
