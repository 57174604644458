import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-facebook-error',
  templateUrl: './facebook-error.component.html',
  styleUrls: ['./facebook-error.component.scss']
})
export class FacebookErrorComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  backToLogin(){
    this.router.navigate(['/login']);
  }


}
