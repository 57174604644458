<div class="continer-fluid">
    <div class="new-dashboard-wrapper">
        
        <!-- app header -->
        <app-new-header></app-new-header>
        <!-- Side Menu -->
        <app-side-menu (ngoChanged)="ngoChanged($event)"></app-side-menu>
        <div class="grid-wrapper position-relative">
            <div class="row no-gutters">
                <div class="breadcrumbs csBrd">
                    <ul>
                        <li class="active">
                            <a [routerLink]="['/subscribers']">Subscribers</a>
                        </li>                 
                    </ul>
                </div>
                <div class="col-12">
                                  
                    <div class="heading_wrapper mbTitle">
                        <div class="page-title">Subscribers</div>
                        <div class="common-box-wrapper"> 
                               <div class="searchBox mr-15">
                                   
                                   <ng-container *ngIf="searchSearchData || searchSearchData!='undefined'; else NoSearch">
                                      <input type="search" class="searchText" #input (input)="searchData(input.value)" [value]="searchSearchData" placeholder="Search">
                                   </ng-container>
                                   <ng-template #NoSearch>
                                    <input type="search" class="searchText" #input (input)="searchData(input.value)" placeholder="Search">
                                   </ng-template> 

                                    <span class="searchIcon"></span>
                                    <ng-container *ngIf="searchTxt?.length > 3;else noResult">
                                        <a class="searchIng" href="javascript:void(0)">
                                            {{searchStatus}}
                                        </a>
                                    </ng-container>    
                                    <ng-template #noResult>
                                    </ng-template> 
                               </div>
                               <div class="filterBox">
                                    <div class="filter-wrapper">
                                        <div class="filterText">
                                            <div class="filterText" [matTooltipClass]="positionBottom.value" matTooltip="Provides you with time based boundaries to view your Dashboard results. (E.g. All Time, Year, Month)">
                                                Filters: {{ filterApplied[filterData?.type] }}
                                                {{ filterData?.type === 'daily' ? '(' + filterData?.month  + ' &nbsp;' + filterData?.year + ')' : filterData?.type === 'monthly' ?  '(' + filterData?.year + ')' : '' }}
                                            </div>
                                        </div>
                                        <div class="custom-dropdown position-absolute">
                                            <div class="dropdown">
                                                <a class="dropdown-toggle gu-dropdown-toggle" href="#" role="button"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                </a>
            
                                                <div class="dropdown-menu w-open" aria-labelledby="dropdownMenuLink">
                                                    <button
                                                        class="dropdown-item"
                                                        [disabled]="filterData?.type === 'all'" 
                                                        (click)="filter('all')">
                                                            All Time
                                                    </button>
            
                                                    <button
                                                        class="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#detailedDashboardYear">
                                                            Year
                                                    </button>
            
                                                    <button
                                                        class="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#detailedDashboardMonth">
                                                            Month
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               </div>                            
                        </div>
                    </div>                   
                    <div class="content-box">
                        <div class="overlay__inner custom-overlay" *ngIf="loader">
                            <div class="overlay__content custom">
                                <span class="spinner loadImg"></span>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-borderless table-hover"
                                    matSort
                                    (matSortChange)="sortData($event)"
                                    [matSortActive]="sortingField"
                                    [matSortDirection]="orderBy"
                                    matSortDisableClear>
                                <thead>
                                    <tr>
                                        <th scope="col" mat-sort-header="full_name"><span [matTooltipClass]="positionBottom.value" matTooltipClass="matTopTooltip"  matTooltip="Subscriber list showing all Subscribers, within the selected time period.">Subscriber</span></th>
                                        <th scope="col" mat-sort-header="focus_area"><span [matTooltipClass]="positionBottom.value" matTooltip="The reason for Subscribers and sharing their story.">Focus Area</span></th>
                                        <th scope="col" mat-sort-header="persona_date"><span [matTooltipClass]="positionBottom.value" matTooltip="Date the Subscriber Opted Into engage on messenger">Date of Acquisition</span></th>
                                        <th scope="col" mat-sort-header="total_raised"><span [matTooltipClass]="positionBottom.value" matTooltip="Total amount the Subscriber has raised via Direct / Facebook Challenges">Raised</span></th>
                                        <th scope="col" mat-sort-header="n_campaigns"><span [matTooltipClass]="positionBottom.value" matTooltip="Total amount of Facebook Challenges they have participated in">Campaigns</span></th>
                                        <th scope="col" mat-sort-header="avg_campaign"><span [matTooltipClass]="positionBottom.value" matTooltip="The average amount their campaigns have raised.">Avg Campaign</span></th>
                                        <th scope="col" mat-sort-header="n_events"><span [matTooltipClass]="positionBottom.value" matTooltip="Total amount of Facebook Challenges they have participated in">Challenges</span></th>
                                        <th style="width: 5%; text-align: center;" scope="col" mat-sort-header="retention_score"><span [matTooltipClass]="positionBottom.value" matTooltip="Their Retention Survey answer, on a scale 1-5, of whether they will do this again for the organization">Retention</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="subcribersData?.length > 0; else NoDataFound">
                                        <tr class="subcriberList" *ngFor="let sdata of subcribersData | paginate: config; let i = index" (click)="getSubcriberId(sdata);">
                                            <td>
                                                <div class="user-profile">
                                                    <div class="widget-content-left">
                                                        <ng-container *ngIf="sdata?.profile_image_url != null; else noImage">
                                                            <img [src]="sdata?.profile_image_url" alt="">
                                                        </ng-container>
                                                        <ng-template #noImage>
                                                            <img src="../../../assets/images/noImage.png" alt="">
                                                        </ng-template>
                                                    </div>
                                                    <div class="widget-heading">{{sdata.full_name}}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="sdata?.focus_area != null; else NoFocusArea">
                                                    {{sdata.focus_area}}
                                                </ng-container>
                                                <ng-template #NoFocusArea>
                                                   -
                                                </ng-template> 
                                            </td>
                                            <td>
                                                <ng-container *ngIf="sdata?.persona_date != null; else NoPersonaDate">
                                                    {{sdata.persona_date}}
                                                </ng-container>
                                                <ng-template #NoPersonaDate>
                                                   -
                                                </ng-template>
                                            </td>
                                            <td>                                               
                                                <ng-container *ngIf="sdata?.total_raised != null; else NoRaised">
                                                    {{sdata.total_raised | currency: selectedNgo.currency_name :'symbol':'1.0-0'}}
                                                </ng-container>
                                                <ng-template #NoRaised>
                                                   -
                                                </ng-template>
                                            </td>
                                            <td>                                                
                                                <ng-container *ngIf="sdata?.n_campaigns != null; else nocampaigns">
                                                    {{sdata.n_campaigns}}
                                                </ng-container>
                                                <ng-template #nocampaigns>
                                                   -
                                                </ng-template>
                                            </td>
                                            <td>                                               
                                                <ng-container *ngIf="sdata?.avg_campaign != null; else noAvgcampaign">
                                                    {{sdata.avg_campaign | currency: selectedNgo.currency_name :'symbol':'1.0-0'}}
                                                </ng-container>
                                                <ng-template #noAvgcampaign>
                                                   -
                                                </ng-template>
                                            </td>
                                            <td>                                                
                                                <ng-container *ngIf="sdata?.n_events != null; else noevents">
                                                    {{sdata.n_events}}
                                                </ng-container>
                                                <ng-template #noevents>
                                                   -
                                                </ng-template>
                                            </td>
                                            <td style="width: 5%; text-align: center;">
                                                <ng-container *ngIf="sdata?.retention_score!=null; else NoRetension">
                                                    <div class="retention rt-1" *ngIf="sdata.retention_score == 1">{{sdata.retention_score}}</div>
                                                    <div class="retention rt-2" *ngIf="sdata.retention_score == 2">{{sdata.retention_score}}</div>
                                                    <div class="retention rt-3" *ngIf="sdata.retention_score == 3">{{sdata.retention_score}}</div>
                                                    <div class="retention rt-4" *ngIf="sdata.retention_score == 4">{{sdata.retention_score}}</div>
                                                    <div class="retention rt-5" *ngIf="sdata.retention_score == 5">{{sdata.retention_score}}</div>
                                                </ng-container>
                                                <ng-template #NoRetension>
                                                    -
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-template #NoDataFound>
                                       <tr>
                                           <td colspan="7" class="notFound text-center">No Data Found</td>
                                       </tr>
                                    </ng-template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="newGuPagination" *ngIf="subcribersData?.length>0">
                        <pagination-controls [directionLinks]="false"
                          (pageChange)="pageChanged($event)">
                        </pagination-controls>
                        <span class="countNumber">{{currentPageCount}}/{{totalRecords}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bg"></div>

<div class="modal above fade" id="detailedDashboardYear" role="dialog">
    <div class="modal-dialog modal-dialog-centered custom-dialog-modal modal-md" role="document">
        <div class="modal-content m-2">
            <div class="modal-header px-4">
                <h6 class="modal-title">Choose the year</h6>
                <button #modalCloseBtnYear type="button" data-dismiss="modal"
                    class="close"><span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <ul class="yearPicker">
                    <ng-container *ngFor="let year of filterYear">
                        <li
                            [ngClass]="(filterData?.type === 'monthly' && filterData?.year === year) ? 'active' : ''"
                            (click)="filter('monthly', year)">
                                {{ year }}
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="modal above fade" id="detailedDashboardMonth" role="dialog">
    <div class="modal-dialog modal-dialog-centered custom-dialog-modal modal-md" role="document">
        <div class="modal-content m-2">
            <div class="modal-header px-4">
                <h6 class="modal-title">Choose the month</h6>
                <button #modalCloseBtnMonth type="button" data-dismiss="modal"
                    class="close"><span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="monthPicker" *ngFor="let year of filterYear">
                    <span class="my-1">{{ year }}</span>
                    <ul class="year mx-0 my-1 pl-4 pr-4 pb-4 pt-2">
                        <ng-container *ngFor="let month of filterMonth; let i = index">
                            <ng-container *ngIf="currentYear === year && currentMonth < i; else months">
                                <li class="disabled">
                                    {{ month }}
                                </li>
                            </ng-container>

                            <ng-template #months>
                                <li
                                    [ngClass]="(filterData?.type === 'daily' && filterData?.year === year && filterData?.month === month) ? 'active' : ''"
                                    (click)="filter('daily', year, month)">
                                        {{ month }}
                                </li>
                            </ng-template>                            
                        </ng-container>                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>