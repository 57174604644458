import { Pipe, PipeTransform } from '@angular/core';
import { CONDITION_BY_DT } from '../constant';

@Pipe({
  name: 'getConditionName'
})
export class GetConditionNamePipe implements PipeTransform {

  transform(value: string, columnType: string): string {
    if (!value) return `Select ${columnType?.substring(0,1)?.toUpperCase()}${columnType?.substring(1)?.toLowerCase()}`;

    const condition = CONDITION_BY_DT[columnType]?.find((item: { text: string, key: string }) => item?.key === value);
    return condition?.text || 'N/A';
  }

}
