import { Component, OnInit, OnDestroy, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgoSharedService } from '../../services/ngo-shared.service';
import { Subscription, Observable } from 'rxjs';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { CommonService } from 'src/app/services/common.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { map } from 'rxjs/operators';
import { UserSharedService } from '../../services/user-shared.service';
import { ReportService } from 'src/app/services/report.service';
import { ReportDownloadSharedService } from '../../services/report-download-shared.service';
import { environment } from 'src/environments/environment';
import { USER_ROLE } from 'src/app/constant';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;
  public url: string;
  public charityIDSubscription: Subscription;
  public ngoList$: Observable<SelectedNgo[]>;
  public ngoList1$: Observable<SelectedNgo[]>;
  public filteredNgoList$: Observable<SelectedNgo[]>;
  public selectedNgo: SelectedNgo;

  status: any;
  public user: any;
  public roles: any;
  public userRole: any;
  public userSubscription: Subscription;

  @Output() ngoChanged = new EventEmitter<any>();

  public reportId: number;
  public charityId: string;
  public showReportMenu = environment.showNewreport;
  new_reporting: boolean = false;
  userROLE = USER_ROLE;
  
  constructor(
    private router: Router,
    private _ngoSharedService: NgoSharedService,
    private _commonService: CommonService,
    private _sidenavservice: SidenavService,
    private _userSharedService: UserSharedService,
    private _reportService: ReportService,
    private _reportDownloadSharedService: ReportDownloadSharedService
  ) { }

  ngOnInit() {
    this.url = this.router.url;
    this.getCharityId();
    this.getNgoList();
    this._sidenavservice.currentMessage.subscribe(status => this.status = status);
    this.getUserinfo();
  }

  getNgoList(): void {
    this.filteredNgoList$ = this.ngoList$ = this._ngoSharedService.ngoLists;
  }

  getUserinfo() {
    this.userSubscription = this._userSharedService
        .userData
        .subscribe(
          res => {
            if (Object.keys(res).length > 0) {
              this.user = res;
              this.roles = this.user.roles;
              this.userRole = this.roles[0].role;
            }
          }
        );
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNgo => {
            if (selectedNgo.charityId !== null) this.selectedNgo = selectedNgo;
            if(selectedNgo.charityId){
              this.getReportingFlag(selectedNgo?.charityId);
            }
          }
        );
  }

  getReportingFlag = async(charityId) => {
    const { Results } = await this._reportService.reportingFlag(charityId);
    this.new_reporting = Results.new_reporting;
  }

  setPage(ngo: SelectedNgo) {
    this.searchInput.nativeElement.value = '';
    this.getNgoList();
    this._commonService
        .setPage(ngo.charityId)
        .subscribe(
          res => {

            this._ngoSharedService.changeSelectedNgo(ngo);
            
            this.charityId = ngo.charityId;
            this.getRecentReportId();
            this.refreshDashboard(ngo);
          }
        );
  }

  getRecentReportId() {    
    localStorage.removeItem(environment.localKey + 'reportId');
    
    this._reportService
        .recentReportId(this.charityId)
        .subscribe(
          res => {
            this.reportId = res.report_id;
            this._reportDownloadSharedService.changeReportId(this.reportId);
          }
        );
  }

  refreshDashboard(selectedNgo) { this.ngoChanged.emit(selectedNgo); }

  filter(event) {
    const searchText = event.target.value.toLocaleLowerCase();
    this.filteredNgoList$ = this.ngoList$.pipe(map(ngo => {
      return ngo.filter(val => val.name.toLocaleLowerCase().includes(searchText));
    }));
  }

  redirect(path: string) {
    this.router.navigate([path]);
  }

  closeNav() {
    //this.searchInput.nativeElement.value = '';
    this.status = false;
  }

  ngOnDestroy() {
    this.charityIDSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }

}
