import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { AuthInterceptor } from './interceptor/AuthInterceptor';
import { FooterComponent } from './shared/componentss/footer/footer.component';
import { AdvanceConfiguration } from './services/advanceconfiguration.service';
import { NgoSharedService } from './shared/services/ngo-shared.service';
import { UserSharedService } from './shared/services/user-shared.service';
import { MapAttributesComponent } from './map-attributes/map-attributes.component';
import { SessionInterceptor } from './interceptor/SessionInterceptor';

import { HttpconfigInterceptor } from './interceptor/HttpconfigInterceptor';

import { TestCohortService } from './services/test-cohort.service';
import { BlackbaudComponent } from './blackbaud/blackbaud.component';
import { CardintegrationsComponent } from './cardintegrations/cardintegrations.component';
import { RoleGuardService } from './services/role-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSortModule } from '@angular/material/sort';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { AngularMaterialModule } from './shared/module/angular-material/angular-material.module';
import { EditMessageStatusComponent } from './shared/componentss/edit-message-status/edit-message-status.component';

import { ActivationMessageModule } from './activation-message/activation-message.module';
import { AsanaConfirmationComponent } from './shared/componentss/asana-confirmation/asana-confirmation.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DonorConfirmationComponent } from './shared/componentss/donor-confirmation/donor-confirmation.component';
import { FundraiserConfirmationComponent } from './shared/componentss/fundraiser-confirmation/fundraiser-confirmation.component';
import { SentenceClassifierComponent } from './sentence-classifier/sentence-classifier.component';

import { TagCloudModule } from 'angular-tag-cloud-module';
import { SentenceStoryDetailComponent } from './sentence-story-detail/sentence-story-detail.component';
import { FbcharityConfirmationComponent } from './shared/componentss/fbcharity-confirmation/fbcharity-confirmation.component';
import { EditTacticStatusComponent } from './shared/componentss/edit-tactic-status/edit-tactic-status.component';
import { SyncSettingsComponent } from './sync-settings/sync-settings.component';
import { SubscribersModule } from './subscribers/subscribers.module';
import { EventsModule } from './events/events.module';
import { ReportsModule } from './reports/reports.module';

import { FacebookLoginComponent } from './facebook-login/facebook-login.component';
import { FbCallbackComponent } from './fb-callback/fb-callback.component';
import { SidenavService } from './services/sidenav.service';
import { searchFilter } from './services/searchfilter.pipe';
import { AuthService } from './services/auth.service';
import { UserConfirmationComponent } from './shared/componentss/user-confirmation/user-confirmation.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import { RouteRedirectGuard } from './shared/guard/route-redirect.guard';
import { ReportDownloadAlertComponent } from './shared/componentss/report-download-alert/report-download-alert.component';
import { ThankyouConfirmationComponent } from './shared/componentss/thankyou-confirmation/thankyou-confirmation.component';
import { DirectConfirmationComponent } from './shared/componentss/direct-confirmation/direct-confirmation.component';
import { FacebookErrorComponent } from './facebook-error/facebook-error.component';

@NgModule({
    declarations: [
        AppComponent,
        IntegrationsComponent,
        FooterComponent,
        MapAttributesComponent,
        BlackbaudComponent,
        CardintegrationsComponent,
        EditMessageStatusComponent,
        AsanaConfirmationComponent,
        DonorConfirmationComponent,
        FundraiserConfirmationComponent,
        SentenceClassifierComponent,
        SentenceStoryDetailComponent,
        FbcharityConfirmationComponent,
        EditTacticStatusComponent,
        SyncSettingsComponent,
        FacebookLoginComponent,
        FbCallbackComponent,
        searchFilter,
        UserConfirmationComponent,
        ReportDownloadAlertComponent,
        ThankyouConfirmationComponent,
        DirectConfirmationComponent,
        FacebookErrorComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'XSRF-TOKEN',
        }),
        AngularMultiSelectModule,
        BrowserAnimationsModule,
        MatSortModule,
        NoopAnimationsModule,
        NgxPaginationModule,
        AngularMaterialModule,
        NgxDaterangepickerMd.forRoot(),
        ActivationMessageModule,
        TagCloudModule,
        SubscribersModule,
        EventsModule,
        ReportsModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpconfigInterceptor,
            multi: true,
        },
        {
            provide: MatDialogRef,
            useValue: {},
        },
        {
            provide: MAT_DIALOG_DATA,
            useValue: {},
        },
        AdvanceConfiguration,
        NgoSharedService,
        UserSharedService,
        TestCohortService,
        RoleGuardService,
        RouteRedirectGuard,
        SidenavService,
        AuthService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
