<div class="exportCalendar form-wrapper">
  <h2>Export CSV</h2>
  <div class="captionText"><p>Choose A Date Range. Or, leave empty to export your "all time" data.</p></div>
  <div class="dRangeBox">
     <div class="from common">
        <input
        #pickerDirective
        type="text"
        matInput
        ngxDaterangepickerMd
        [locale]="{applyLabel: 'Export', format: 'DD-MM-YYYY'}"
        startKey="start"
        endKey="end"
        [(ngModel)]="selected"
        [alwaysShowCalendars]="true"
        placeholder="From"
        class="form-control"
        name="daterange"/> 
        <span class="from"></span>
     </div>
     <div class="common">to</div>
     <div class="to common">
        <input
        #pickerDirective
        type="text"   
        placeholder="To"
        class="form-control"
        name="to"/> 
        <span class="to"></span>
     </div>
  </div>




    <div class="closeIcon">
      <mat-toolbar>
        <button
          class="btn-dialog-close"
          mat-stroked-button
          (click)="onClose()"
          tabIndex="-1">
          <mat-icon>clear</mat-icon></button>
      </mat-toolbar>
    </div>    
</div>
