import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SubscriberListComponent } from './subscriber-list/subscriber-list.component';
import { SubscriberDetailComponent } from './subscriber-detail/subscriber-detail.component';
import { SharedModule } from '../shared/module/shared.module';

const routes: Routes = [
  {
    path: '',
    component: SubscriberListComponent,
  },
  {
    path:'detail/:person_id',
    component: SubscriberDetailComponent
  }
];

@NgModule({
  declarations: [
    SubscriberListComponent,
    SubscriberDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule
  ],
  exports: [
    RouterModule,
    SubscriberListComponent,
    SubscriberDetailComponent
  ]
})
export class SubscribersModule { }
