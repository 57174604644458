<ng-container *ngIf="alertMsg.msg !== ''">
  <app-alert [message]="alertMsg"></app-alert>
</ng-container>

<div class="row no-gutters">
  <div class="col-12">
    <div class="form-wrapper">

      <div class="closeBtn">
        <div class="close-btn" #matClose mat-dialog-close><span aria-hidden="true">×</span></div>       
      </div> 

      <div class="NewMsgHeading">
        <h4 class="commonFont p-0 mb-0">Add Message</h4>
        <p class="subHeading mb-2">Add a new Activation message below</p>
      </div>
      <form [formGroup]="messageForm" (ngSubmit)="postMessage()">
        <div class="row">
          <div class="col col-md-12">
            <label for="select" class="blabel">Category<span class="astrix">*</span></label>
          </div>
          <div class="col-12 col-md-12">
            <select class="form-control" formControlName="category_id"
              [ngClass]="{ 'is-invalid': submitted && f.category_id.errors }">
              <option value="">Please select</option>
              <option *ngFor="let category of categories;" [value]="category.id">
                {{category.category_name}}</option>
            </select>
            <div *ngIf="submitted && f.category_id.errors" class="invalid-feedback">
              <div *ngIf="f.category_id.errors.required">Category is required</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-12">
            <label for="select" class="blabel">Tactic</label>
          </div>
          <div class="col col-md-12">
            <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems" [settings]="dropdownSettings"
              (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
              (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)" formControlName="tactic_id">
            </angular2-multiselect>

          </div>
        </div>
        <div class="row">
          <div class="col col-md-12">
            <label for="textarea-input" class=" blabel">Message<span class="astrix">*</span></label>
            <div class="tooltipBox">
              <img src="assets/images/solidquestion.png" alt="">
              <span class="tooltiptextBox">{{helpText}}</span>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <textarea formControlName="message" id="textarea-input" rows="3" placeholder="Content..."
              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
              <div *ngIf="f.message.errors.required">Message is required</div>
            </div>
            <div class="message-feedback" *ngIf="showLinkError">
              Please enter valid link tag.             
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col col-md-12">
            <label for="textarea-input" class=" blabel">URL<span class="astrix">*</span></label>
          </div>
          <div class="col-12 col-md-12">
            <input type="text" class="form-control" formControlName="url" placeholder="URL"
              [ngClass]="{ 'is-invalid': submitted && f.url.errors }">
            <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
              <div *ngIf="f.url.errors.required">URL is required</div>
              <div *ngIf="f.url.errors.pattern">Please enter valid url</div>
            </div>           
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button id="payment-button" type="submit" class="custom-btn pull-right">
              Create Message
            </button>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>