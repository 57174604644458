import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportDownloadSharedService {

  private reportIdSource     = new BehaviorSubject<number>(null);

  public reportId     = this.reportIdSource.asObservable();

  constructor() { }

  changeReportId(data: number) {
    this.reportIdSource.next(data);
  }
}
