import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TacticService {
  constructor(
    private http: HttpClient
  ) { }

  getTacticsList(charityID: any, pageID: any, sortingField: any, order:any): Observable<any> {
    const params = new HttpParams().set('charity_id', charityID).set('page', pageID).set('sort_field', sortingField).set('order', order);;

    return this.http.get('list_tactics_detail', { params });
  }

  addTactic(charityId: string, tacticObject): Observable<any> {
    const params = {
      charity_id: charityId,
    };
    return this.http.post('add_new_tactic', tacticObject, {params});
  }

  editTacticStatus(data): Observable<any> {
    const params = new HttpParams().set('charity_id', data.charityId);
    return this.http.post('edit_tactic?charity_id=' + data.charityId, {status: data.status, edit_tactic_id: data.id});
  }

  getTacticCategory(charityId: string): Observable<any> {
    const params = {
      charity_id: charityId  
    }
    const path = 'tactic_category_list';    
    return this.http.get(path, { params });
  }
}
