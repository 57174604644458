import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({

  name: 'nl2br'

})

export class nl2brPipe implements PipeTransform {

    constructor(private _sanitizer:DomSanitizer) {
    }

  transform(value: string) :SafeHtml {
      
      return this._sanitizer.bypassSecurityTrustHtml(value.replace(/\n/g, '<br/>'));

 }

     

}