import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private API_URL         = environment.apiUrl;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (!req.headers.has('Content-Type'))
    //   req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});

    // req = this.setCookie(req);
    req = this.setUrl(req);

    return next.handle(req);
  }

  setCookie(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({ withCredentials: true});
  }

  private setUrl(req: HttpRequest<any>): HttpRequest<any> {
    let url = this.API_URL;

    const arr = [
      'https://gu-dev-data.s3.amazonaws.com/',
      'https://gu-qa-data.s3.amazonaws.com/',
      'https://gu-stage-data.s3.amazonaws.com/',
      'https://gu-prod-data.s3.amazonaws.com/',
    ];
    const exist = arr.filter((val: string) => req.url.indexOf(val) !== -1);

    if (exist.length > 0) url = '';
    else req = req.clone({ withCredentials: true});

    return req.clone({url: url + req.url});
  }
}
