import { EditMessageStatusComponent } from './../shared/componentss/edit-message-status/edit-message-status.component';
import { Component, OnInit, OnDestroy, Input, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MessageService } from '../services/message.service';
import { NgoSharedService } from '../shared/services/ngo-shared.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Sort, SortDirection, MatSort, MatSortable } from '@angular/material/sort';
import { MessageList } from '../models/MessageList.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GudatepickerComponent } from '../shared/componentss/gudatepicker/gudatepicker.component';
import { AddMessageComponent } from '../add-message/add-message.component';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  dropdownList = [];
  successMsg: string;
  loading = false;
  loader = false;
  charityID: string;
  messages: [];
  public charityIDSubscription: Subscription;
  public alertMsg = { type: '', msg: '' };
  index;
  dropdownSettings = {};
  sortedData: MessageList[];
  totalRecords: number;
  currentPage = 1;
  currentPageCount: number;
  pages = [];
  config: any;
  sortingField: string;
  orderBy: string;
  isChecked = [];

  @ViewChild(MatSort) sort: MatSort;
 
  constructor(
    private messageSerivce: MessageService,
    private _ngoSharedService: NgoSharedService,
    private router: Router,
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef
  ) {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalRecords
    };
  }

  sortData(sort: Sort) {    
    this.sortingField = sort.active;
    this.orderBy = sort.direction;
    this.viewMessageList();
    const data = this.messages.slice() as MessageList[];

    if (!sort.active || sort.direction === '') return this.sortedData = data;

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'message_id': return compare(a.message_id, b.message_id, isAsc);
        case 'message_body': return compare(a.message_body, b.message_body, isAsc);
        case 'message_category_name': return compare(a.message_category_name, b.message_category_name, isAsc);
        case 'tactic_name': return compare(a.tactic_name, b.tactic_name, isAsc);
        case 'messages_posted': return compare(a.messages_posted, b.messages_posted, isAsc);
        case 'optins': return compare(a.optins, b.optins, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        case 'optins_percentage': return compare(a.optins_percentage, b.optins_percentage, isAsc);
        case 'created_date': return compare(a.created_date, b.created_date, isAsc);
        case 'message_status': return compare(a.message_status, b.message_status, isAsc);
        default: return 0;
      }
    });

  }

  ngoChanged($event) { if ($event) { } }

  ngOnInit() {
    this.getCharityId();
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        selectedNGO => {
          this.config.currentPage = 1;
          this.sortingField = '';
          this.orderBy      = '';  
          this.sortedData = [];
          this.changeDetector.detectChanges();
          if (selectedNGO.charityId !== null) {
            this.loading = true;
            this.charityID = selectedNGO.charityId;
            this.viewMessageList();
          } else this.hideLoader(selectedNGO);
        },
        err => this.hideLoader('Charity ID Not Found')
      );
  }

  viewMessageList() {
    this.loading = true;
    this.orderBy = (this.orderBy == '') ? 'desc' : this.orderBy;
    this.messageSerivce
      .getmessagelist(this.charityID, this.config.currentPage, this.sortingField, this.orderBy)
      .subscribe(
        (res: any) => {
          if (res)
          this.messages = res.message;         
          // for (let index = 0; index < this.messages.length; index++) {
          //   const element = this.messages[index];  
          //   this.isChecked[index] = false;            
          // }          
          this.pages = res.pages;
          this.totalRecords = res.view_msg_records_count;
          this.config.totalItems = this.totalRecords;
          this.currentPageCount = this.config.itemsPerPage * (this.config.currentPage - 1) + this.messages.length;
          this.sortedData = this.messages.slice();
          this.hideLoader(res);
          this.loading = false;
        },
        err => this.hideLoader(err));
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.viewMessageList();
  }

  displayAddMessageForm() {
    const queryParams = { redirectUrl: 'messages' };

    this.router.navigate(['/activation-message/messages/add'], { queryParams });
  }

  hideLoader(msg: any): void { this.loading = false; }

  openDialog(message) {     
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '30%';
    dialogConfig.data = {
      charityId: this.charityID,
      messageId: message.message_id,
      //status: message.status,
      mStatus:message.message_status     
    };
   
    const dialogRef = this.dialog.open(EditMessageStatusComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe(res => {    
        //if (res != false) {
          this.showAlertMsg(res.msg, res.type);
          this.viewMessageList();
        //  this.isChecked[this.index] = false;
        // }
        //else{
        //  this.isChecked[this.index] = false;
        //}        
    });
  }

  openDialogCalendar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.height = '450px';
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(GudatepickerComponent, dialogConfig);
  }

  openAddMessage() {
   
    const dialogRef = this.dialog.open(AddMessageComponent,{
      // height: '450px',
      width: '410px',     
    });

  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg = msg;
    this.alertMsg.type = type;
  }

  ngOnDestroy() { this.charityIDSubscription.unsubscribe(); }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
