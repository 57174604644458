
  <div class="card1 border1">
    <div class="table-stats ov-h">

      <table class="table custom-table-test">
        <thead>
          <tr>
            <th class="serial">ID</th>
            <th class="w-30">Message</th>
            <th class="w-20">Tactic</th>
            <th>Messages Posted</th>
            <th>Optins</th>
            <th>Optin (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="serial">{{ messageDetails.message_id }}</td>
            <td>
              <app-show-more-button [innerHTML]="messageDetails.message | nl2br"></app-show-more-button>
            </td>
            <td>
              <span class="name">
                {{ messageDetails.tactic }}
              </span>
            </td>
            <td>
              <span class="count">{{ messageDetails.messages_posted | number:'1.0-0'}}</span></td>
            <td>
              <span class="count">{{ messageDetails.optins | number:'1.0-0'}}</span>
            </td>
            <td>
              <span class="count">{{ messageDetails.optinspercent | number : '1.0-2' }}</span>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>


