<div class="condtionWrapper" mat-dialog-content>
  <button type="button" class="closeCondtionModal float-right" (click)="onClose('cancelled')"></button>
  <div class="pop-up-body">
    <form class="form-wrapper" [formGroup]="conditionForm" (ngSubmit)="onSubmit()">
      <div class="row no-gutters text-center">
        <div class="col-12">
          <h2 class="mb-3"> {{ data?.index > -1 ? 'Edit Condition': 'New Condition' }}</h2>
          <div class="form-wrapper">
            <div class="mb-3">

              <div class="select-wrapper">
                <div class="select" id="select">
                  <div class="select__trigger" data-toggle="dropdown" id="columnListDD"><span
                      id="select__trigger" class="shortTxt" [matTooltipClass]="positionBelow.value" [matTooltip] = "conditionForm?.value?.columnKey | getColumnName: conditionForm?.value?.columnGroupKey: data?.columnCategory">{{ conditionForm?.value?.columnKey | getColumnName: conditionForm?.value?.columnGroupKey: data?.columnCategory }}</span>
                    <div class="arrow"></div>
                  </div>
                  <ul class="dropdown-menu columnName-wrapper top" [ngClass] = "searchByColumnName?.length >= 3 ?'searchItems':''" aria-labelledby="columnListDD">
                    <div class="searchBox">
                      <input type="text" placeholder="Search columns" (keyup) = "getTransformedData()" [(ngModel)]="searchByColumnName" [ngModelOptions]="{standalone: true}" class="search-form-control">
                      <span class="searchIcon"></span>
                    </div>
                    <!-- <li *ngFor="let group of data?.columnCategory | filterColumnByName: searchByColumnName | keyvalue;let i =index;"> 
                      <a class="dropdown-item">
                        {{ COLUMN_CATEGORY[group?.key] }} <span class="rarrow"></span>
                      </a>
                      <ul class="dropdown-menu dropdown-submenu" *ngIf="group?.value"
                      [ngClass] = "i>=4?data?.filterType == 'all'?
                      'showupall'+i:data?.filterType == 'fundraiser'?
                      'showupf'+i:data?.filterType == 'donor'?
                      'showupd'+i:'showupe'+i:''">
                        <li *ngFor="let item of group?.value">
                          <a class="dropdown-item" (click)="onColumnSelect(group?.key, item?.key, item?.type)" >
                            {{ item?.display_name }}
                          </a>
                        </li>
                      </ul>
                    </li>-->
                    <ng-container *ngIf="searchByColumnName?.length == 0">
                      <li *ngFor="let group of data?.fixedPattern;let i =index;">
                        <a class="dropdown-item">
                          {{ COLUMN_CATEGORY[group] }} <span class="rarrow"></span>
                        </a>
                        <ul class="dropdown-menu dropdown-submenu" *ngIf="group" [ngClass]="i>=4 && searchByColumnName?.length == 0?data?.filterType == 'all'?
                                          'showupall'+i:data?.filterType == 'fundraiser'?
                                          'showupf'+i:data?.filterType == 'donor'?
                                          'showupd'+i:'showupe'+i:''">
                          <li *ngFor="let item of columnCategory[group]">
                            <a class="dropdown-item" (click)="onColumnSelect(group, item?.key, item?.type)">
                              {{ item?.display_name }}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="searchByColumnName?.length >= 3">
                      <li *ngFor="let listItem of searchedList" >
                        <a class="dropdown-item" (click)="onColumnSelect(listItem?.group, listItem?.key, listItem?.type)" >
                          {{ listItem?.display_name }}
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>

            </div>
            <div class="mb-3" [ngClass]="{ 'hide': !conditionForm?.value?.columnType }">
              <div class="select-wrapper">
                <div class="select" id="select">
                  <div class="select__trigger" data-toggle="dropdown" id="conditionListDD"><span
                      id="select__trigger">{{ conditionForm?.value?.conditionType | getConditionName: conditionForm?.value?.columnType }} </span>
                    <div class="arrow"></div>
                  </div>
                  <ul class="dropdown-menu string-columnName-wrapper top" aria-labelledby="conditionListDD">
                    <li *ngFor="let condition of CONDITION_BY_DT[conditionForm?.value?.columnType]">
                      <a class="dropdown-item" (click)="onConditionSelect(condition?.key)">
                        {{ condition?.text }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
              <ng-container [ngSwitch]="conditionForm?.value?.columnType">
                <ng-container *ngSwitchCase="'string'">
                  <div class="optionBox mb-3" [ngClass]="{ 'hide': !(conditionForm?.value?.conditionType | isValueRequired) }">
                    <input type="text" class="form-control mx-190" formControlName="value">
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'number'">
                  <div class="optionBox mb-3" [ngClass]="{ 'hide': !(conditionForm?.value?.conditionType | isValueRequired) }">
                    <input type="number" class="form-control mx-190" formControlName="value">
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'boolean'">
                  <div class="optionBox mb-3" [ngClass]="{ 'hide': !(conditionForm?.value?.conditionType | isValueRequired) }">
                    <input type="text" class="form-control mx-190" formControlName="value">
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'date'">
                  <div class="optionBox position-relative mb-3" [ngClass]="{ 'hide': !conditionForm?.value?.conditionType }">
                    <input
                      type="text"          
                      drops="up"
                      ngxDaterangepickerMd
                      [autoApply]="true"
                      [locale]="locale"
                      [singleDatePicker]="true"
                      class="form-control mx-190 singleCal"
                      [maxDate]="maxDate"
                      readonly="true"
                      formControlName="value"   
                    />
                    <span class="calIcon"></span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="optionBox mb-3 hide">
                    <input type="text" class="form-control mx-190">
                  </div>
                </ng-container>
              </ng-container>
            <div class="mt-3 mb-2">
              <button type="button" class="error-filled-md-btn-text mr-2" (click)="onClose('removed')" *ngIf="data?.index > -1">Remove</button>
              <button type="button" class="mono-dark-text-md-btn-text mr-2" (click)="onClose('cancelled')" *ngIf="data?.index === -1">Cancel</button>
              <button type="submit" class="accent-filled-md-btn-text" [disabled]="conditionForm?.invalid"> Save </button>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>
<!-- <div class="condtionWrapper" mat-dialog-content>
    <button type="button" class="closeDatePicker float-right" (click)="onClose()"></button>
    <div class="pop-up-body">
        <div class="form-wrapper">                   
            <div class="row no-gutters text-center"> 
                <div class="col-12">
                   <h2 class="mb-3">Edit Condition</h2>
                   <div class="form-wrapper">
                      <div class="optionBox mb-3">
                        <select class="form-control mx-190">
                            <option>Email</option>
                        </select>
                      </div>
                      <div class="optionBox mb-3">
                        <select class="form-control mx-190">
                            <option>contains</option>
                        </select>
                      </div>
                      <div class="optionBox mb-3">
                        <input type="text" class="form-control mx-190" value="goodUnited">
                      </div>
                      <div class="mt-3 mb-2">                           
                        <button type="button" class="error-filled-md-btn-text mr-2" (click)="onClose()">Remove</button>
                        <button type="button" class="accent-filled-md-btn-text">Save </button>
                      </div>
                   </div> 
                </div>
                
            </div>
        </div>
    </div>
</div> -->