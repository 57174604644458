import {Component, NgModule, VERSION, Pipe, PipeTransform} from '@angular/core'
import {BrowserModule, DomSanitizer} from '@angular/platform-browser'

@Pipe({
    name: 'formatText'
})
export class FormatText implements PipeTransform {
  transform(value: any, args: any): any {
    if (!args) {
      return value;
    }   
    
    const replacedValue = value.replace(/[-]/g, '\n');
    return replacedValue;
  }
}