<div class="overlay__inner custom-overlay" *ngIf="retentionScoreLoader">
    <div class="overlay__content">
        <span class="spinner loadImg"></span>
    </div>
</div>

<ng-container *ngIf="retentionScoreData && retentionScoreData.datasets; else notfound">
    <h2 class="common-heading mr-3"><span [matTooltipClass]="positionBottom.value" matTooltip="The results of the retention survey question, which should indicate affinity to give again.">Retention Call To Action</span></h2>
    <div class="stat-row">
        <div class="stat-blocks">
            <div class="stat-block">
                <div class="stat-label-wrap content-end">
                    <div class="stat-label" [matTooltipClass]="positionBottom.value" matTooltip="Average answer out of a scale 1-5. (e.g. an average of 4.6 would indicate most everyone who responded is likely to give again.)">Average Sentiment</div>
                </div>
                <div class="stat-number" *ngIf="retentionScoreData" style="color:#04C3CB;">
                    {{ retentionScoreData?.overall?.average | number:'1.0-2'  }}
                </div>
<!--                 <div class="trend" *ngIf="retentionScoreData">
                    <div class="text-block-27 shade">
                        ({{ (retentionScoreData?.overall?.average / 5) * 100 | number:'1.0-2' }}%)
                    </div>
                </div> -->
                <div>
                    <div class="stat-sub" *ngIf="retentionScoreData" [matTooltipClass]="positionBottom.value" matTooltip="Total number of people who responded to the survey">
                        Total Responses {{ retentionScoreData?.overall?.totalResponse | number }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="html-embed-9 w-embed w-script" *ngIf="retentionScoreData">
        <ul class="retentionNumber">
            <li>5</li>
            <li>4</li>
            <li>3</li>
            <li>2</li>
            <li>1</li>
        </ul>
        <canvas id="retentionScore"></canvas>
    </div>
</ng-container>

<ng-template #notfound>
    <h2 class="common-heading mr-3">
        <span [matTooltipClass]="positionBottom.value" matTooltip="The results of the retention survey question, which should indicate affinity to give again.">Retention Call To Action</span>
    </h2> 
  <div class="nofound"><p>No Data Found</p></div>
</ng-template>