
<div *ngIf="message.type === 'danger'" class="alert-wrapper p-4 col col-12 col-md-4 offset-0 offset-md-8">
  <div class="alert alert-danger" role="alert">
    <span class="alert-message">
      <span class="alert-icon">
        <svg style="width: 38px; height:38px;" (click)="closeErr()" aria-hidden="true"
          data-prefix="far" data-icon="times-circle"
          class="svg-inline--fa fa-times-circle fa-w-16 arrow d-inline" role="img"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor"
            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
          </path>
        </svg>
      </span>
      {{message.msg}}
    </span>
    <span class="close" (click)="closeErr()"> Close </span>
  </div>
</div>

<div *ngIf="message.type === 'success'" class="alert-wrapper p-4 col col-12 col-md-4 offset-0 offset-md-8">
  <div class="alert alert-success" role="alert">
    <span class="alert-message">
      <span class="alert-icon">
        <svg style="width: 38px; height:38px;" aria-hidden="true" data-prefix="fas" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16 arrow d-inline" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>
      </span>
      {{ message.msg }}
    </span>
    <span class="close" (click)="closeErr()"> Close </span>
  </div>
</div>
