import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() message: any;

  constructor() { }

  ngOnInit() {
  }

  closeErr() {
    this.message.msg = '';
    this.message.type = '';
  }

}
