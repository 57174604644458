import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterColumnByName'
})
export class FilterColumnByNamePipe implements PipeTransform {

  transform(columnGroup: any, searchText: string): any {
    const newColumnGroup = {};
    Object.entries(columnGroup || {}).forEach(([key, value]: any) => {
      const filteredColumn = value?.filter((item: any) => {
        const tempDispName = item?.display_name?.toLowerCase();
        return tempDispName?.includes(searchText?.toLowerCase());
      });
      if (filteredColumn?.length) newColumnGroup[key] = filteredColumn;
    });
    return newColumnGroup;
  }

}
