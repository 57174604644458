import { Component, OnInit, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { Subscription } from 'rxjs';
import { NgoSharedService } from '../../services/ngo-shared.service';
import { ChartsService } from 'src/app/services/charts.service';
import { EventService } from '../../../services/event.service';
import { Filter } from 'src/app/models/Fundraiser';
import { ActiveEventGraph, ChartColor, TopSeries } from 'src/app/models/Event';
import { finalize } from 'rxjs/operators';
import { FilterSharedService } from '../../services/filter-shared.service';
import { Router } from '@angular/router';
import { TooltipPosition } from '@angular/material/tooltip';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-active-events',
  templateUrl: './active-events.component.html',
  styleUrls: ['./active-events.component.scss']
})
export class ActiveEventsComponent implements OnInit, OnDestroy {
  public chart;
  public chartColor = ChartColor;
  public chartData: any[] = [];

  public charityId: string;
  public currencyName: string;


  public charityIDSubscription: Subscription;
  public activeEventData: ActiveEventGraph;

  public filterData: Filter;
  public filterDataSubscription: Subscription;

  public activeEventLoader   = true;

  public datasetFlag = {
  };

  public datasets = {
  };
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionLeft = new UntypedFormControl(this.positionOptions[4]);
  positionTop = new UntypedFormControl(this.positionOptions[2]);
  positionBottom = new UntypedFormControl(this.positionOptions[3]);

  constructor(
    private router: Router,
    private _ngoSharedService: NgoSharedService,
    private _filterSharedService: FilterSharedService,
    private _chartService: ChartsService,
    private _eventService: EventService,
  ) { }

  ngOnInit() {
    this.getCharityId();
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.currencyName = selectedNGO.currency_name;
              this.charityId = selectedNGO.charityId;
              if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
              this.getDateFilter();
            }
          }
        );
  }

  getDateFilter() {
    this.filterDataSubscription = this._filterSharedService
        .dateFilters$
        .subscribe(
          res => {
            this.filterData = res;
            this.activeEventData = null;
            if (this.chart) this.chart.destroy();
            this.activeEventGraphData();
          }
        );
  }

  activeEventGraphData() {
    this.activeEventLoader = true;
    this._eventService
        .eventSeriesFundraiserGraphData(this.charityId, this.filterData)
        .pipe(
          finalize(() => {
            this.activeEventLoader = false;
            setTimeout(() => this.createChart(), 100);
          })
        )
        .subscribe(
          (res: any) => {
            this.activeEventData = res;
            if (res && this.activeEventData.top_three_series) this.activeEventData.top_three_series.sort((a, b) => b.raised - a.raised);
          },
          err => this.activeEventData = null
        );
  }

  createChart() {
    this.getDataSets();

    const labels = this.getLabels();

    if (this.chartData.length < 1) return;

    this.chart = new Chart('activeEvents', {
      type: 'bar',
      data: {
        labels,
        datasets: this.chartData
      },
      options: this._chartService.activeEventOptions
    });
  }

  getDataSets() {
    this.chartData = [];
    if (!this.activeEventData || !this.activeEventData.hasOwnProperty('data')) return;

    this.activeEventData.data.forEach((val, i) => {
      const data = {
        label: val.title,
        data: val.value,
        backgroundColor: this.chartColor[i],
        borderColor: 'rgba(255, 255, 255, 0.9)',
        fill: true,
        barThickness: 6,
      };

      this.chartData.push(data);

      this.datasets[val.title] = null;
      this.datasetFlag[val.title] = true;
    });
  }

  getLabels() {
    if (!this.activeEventData || !this.activeEventData.hasOwnProperty('label')) return [];

    const labels = [];
    this.activeEventData.label.forEach(val => labels.push(val));

    return labels;
  }

  getTotalValue(title: string) {
    if (!(this.activeEventData && this.activeEventData.hasOwnProperty('data'))) return;

    let sum = 0;
    const data: any = this.activeEventData.data.filter(val => val.title === title);
    if (data.length < 1) return;

    data[0].value.forEach(val => {
      if (!isNaN(val)) sum += val;
    });

    if (title === 'Total Campaigns' || title === 'Total Donations') return ' ' + sum.toLocaleString();
    return (this.currencyName=='USD' ? ' $' : ' £') + Math.round(sum).toLocaleString();
  }

  removeDataset(label: string) {
    this.datasetFlag[label] = false;
    this.datasets[label] = this.chart.data.datasets.filter((dataset) => (dataset.label === label))[0];
    this.chart.data.datasets = this.chart.data.datasets.filter((dataset) => (dataset.label !== label));
    this.chart.update();
  }

  addDataset(label: string) {
    this.datasetFlag[label] = true;
    this.chart.data.datasets.push(this.datasets[label]);
    this.chart.update();
  }

  getEventId(ev: TopSeries) {
    this.router.navigate(['/events/series', ev.series_id]);
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
  }

}
