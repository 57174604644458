import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { CommonService } from 'src/app/services/common.service';
import { ReportService } from 'src/app/services/report.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { NgoSharedService } from '../../services/ngo-shared.service';
import { ReportDownloadSharedService } from '../../services/report-download-shared.service';
import { UserSharedService } from '../../services/user-shared.service';

@Component({
  selector: 'app-admin-new-header',
  templateUrl: './admin-new-header.component.html',
  styleUrls: ['./admin-new-header.component.scss']
})
export class AdminNewHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: false}) searchInput: ElementRef;
  public reportId: number;
  @Output() ngoChanged = new EventEmitter<any>();
  public selectedNgo: SelectedNgo;
  public charityId: string;
  public user: any;
  status: boolean;
  public roles: any;
  public userRole: any;
  public charityIDSubscription: Subscription;
  public ngoList$: Observable<SelectedNgo[]>;
  public filteredNgoList$: Observable<SelectedNgo[]>;
 
  constructor(
    private _commonService: CommonService,
    private _reportService: ReportService,
    private _reportDownloadSharedService: ReportDownloadSharedService,
    private _userSharedService: UserSharedService,
    private _sidenavservice: SidenavService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _ngoSharedService: NgoSharedService
  ) { }

  ngOnInit() {
    this.getNgoList();
    this.getUserinfo();
    this.getCharityId();
    this.status = false; 
  }

  getNgoList(): void {
    this.filteredNgoList$ = this.ngoList$ = this._ngoSharedService.ngoLists;
  }
  
  getUserinfo() {
    this._userSharedService
        .userData
        .subscribe(
          res => {
            if (Object.keys(res).length > 0) {
              this.user = res;
              this.roles = this.user.roles;
              this.userRole = this.roles[0].role;
            }
          }
        );
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNgo => {
            if (selectedNgo.charityId !== null) 
            this.selectedNgo = selectedNgo;            
          }
        );
  }

  setPage(ngo: SelectedNgo) {
    this.searchInput.nativeElement.value = '';
    this.getNgoList();
    this._commonService
        .setPage(ngo.charityId)
        .subscribe(
          res => {
            this._ngoSharedService.changeSelectedNgo(ngo);

            this.charityId = ngo.charityId;
            this.getRecentReportId();
            this.refreshDashboard(ngo);
          }
        );
  }

  getRecentReportId() {    
    localStorage.removeItem(environment.localKey + 'reportId');
    
    this._reportService
        .recentReportId(this.charityId)
        .subscribe(
          res => {
            this.reportId = res.report_id;
            this._reportDownloadSharedService.changeReportId(this.reportId);
          }
        );
  }

  refreshDashboard(selectedNgo) { this.ngoChanged.emit(selectedNgo); }

  openNav() {
    this.status = true;
    this._sidenavservice.changeMessage(this.status);
  }

  getImage(image: string) {
    if (!image) return;
    return this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + image);
  }

  filter(event) {
    const searchText = event.target.value.toLocaleLowerCase();
    this.filteredNgoList$ = this.ngoList$.pipe(map(ngo => {
      return ngo.filter(val => val.name.toLocaleLowerCase().includes(searchText));
    }));
  }
  
  signOut(): void {
    sessionStorage.removeItem('redirect-url');
    this._userService
        .logout()
        .subscribe(
          res => window.location.href = environment.baseURL
        );
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();  
  }

}
