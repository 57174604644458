import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AdvanceConfiguration } from '../services/advanceconfiguration.service';
import { Subscription } from 'rxjs';
import { NgoSharedService } from '../shared/services/ngo-shared.service';
import { IntegrationService } from '../services/integration.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { error } from 'protractor';

@Component({
  selector: 'app-sync-settings',
  templateUrl: './sync-settings.component.html',
  styleUrls: ['./sync-settings.component.scss']
})
export class SyncSettingsComponent implements OnInit, OnDestroy {

  public REACTURL = environment.apiEndpointReact;
  public charityIDSubscription: Subscription;
  public charityId: string;
  public syncsettingForm: UntypedFormGroup;
  public new_cons: boolean;
  public sync_24_hours: boolean;
  public p2p_sync: boolean;
  public birthday_fundraiser_sync: boolean;
  public alertMsg = { type: '', msg: ''};
  public loading = false;

  constructor(
    private router: Router, 
    private _advanceConfig: AdvanceConfiguration,
    private _ngoSharedService: NgoSharedService,
    private _integrationService: IntegrationService,
    private fb: UntypedFormBuilder,
    ) { }

  ngOnInit() {
    this.getSelectedNGO();
    this.initializesynSettingForm();
  }


 initializesynSettingForm() {
  this.syncsettingForm = this.fb.group({
    new_cons:  [''],
    birthday_fundraiser_sync:  [''],
    p2p_sync:      [''],
    sync_24_hours:  [''],   
  });
}


  getSelectedNGO() {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        (selectedNGO: any) => {
          if (selectedNGO.charityId !== null) {
            this.charityId = selectedNGO.charityId;  
            this.getSyncSetting();        
          }
        }
      );
  }

  receiveMessage(selectedNgo) {
    this.charityId = selectedNgo.charityId;
  }

  displayGoodUnitedSetting(){
    window.location.href = this.REACTURL + 'goodunitedsettings';
  }

  displayAdvanceConfig(){
    this.router.navigate(['/advanceconfig']);
  }

  getSyncSetting(){
    this.loading = true;
    this._integrationService.getsettingmapping(this.charityId).subscribe((res:any) => {
      if(res){
        this.loading = false;
        this.new_cons = res.new_cons;
        this.sync_24_hours = res.sync_24_hours;
        this.p2p_sync = res.p2p_sync;
        this.birthday_fundraiser_sync = res.birthday_fundraiser_sync;       
      }
    })
  }

  updateSynsetting(){  
    this.loading = true;  
    const body = {
      new_cons: this.syncsettingForm.value.new_cons,      
      p2p_sync: this.syncsettingForm.value.p2p_sync,
      sync_24_hours: this.syncsettingForm.value.sync_24_hours,
      birthday_fundraiser_sync: this.syncsettingForm.value.birthday_fundraiser_sync,
    }

    this._integrationService.postSettingAttributes(body, this.charityId).subscribe(
      (res:any) =>{
      if(res){  
        this.loading = false;  
        setTimeout (() => {
          this.showAlertMsg('Sync settings has been updated successfully', 'success');
        }, 1000);                 
        this.getSyncSetting();
      }
    },error => { this.showAlertMsg(error.message, 'danger') }
    )
  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg   = msg;
    this.alertMsg.type  = type;  
  }

  backToMapattributes(){
    this.router.navigate(['/integrations/map-attributes'])
  }

  ngOnDestroy() {
    this.charityIDSubscription.unsubscribe(); 
  }

}
