import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-asana-confirmation',
  templateUrl: './asana-confirmation.component.html',
  styleUrls: ['./asana-confirmation.component.scss']
})
export class AsanaConfirmationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AsanaConfirmationComponent>,
  ) { }

  ngOnInit() {
  }

  onClose(output: boolean) {
    this.dialogRef.close(output);
  }

}
