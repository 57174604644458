import { Pipe, PipeTransform } from '@angular/core';
import { element } from 'protractor';

@Pipe({
  name: 'getReportColumnKey'
})
export class GetReportColumnKeyPipe implements PipeTransform {

  transform(value: string, columns: any,type:string): any {
    let res = '';
    for (let i of Object.keys(columns)) {
      columns[i]?.some((element:any) => {
        if (element?.key == value) {
          if(!type){
            res = i;
          }else {
            res = element?.type;
          }
          
        }
      })

    }

    return res;
  }

}
