<div *ngIf="message" class="alert-wrapper p-4 col col-12 col-md-4 offset-0 offset-md-8">
  <div class="alert alert-danger" role="alert">
    <span class="alert-message">
      <span class="alert-icon">
        <svg style="width: 38px; height:38px;" data-dismiss="alert" aria-hidden="true" data-prefix="far"
          data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16 arrow d-inline" role="img"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor"
            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
          </path>
        </svg>
      </span>
      {{message}}
    </span>
    <span class="close" data-dismiss="alert"> Close </span>
  </div>
</div>



<div class="row no-gutters">
  <div class="col-12">
    <div class="integrationWrapper">

      <div class="overlay" *ngIf="loader">
        <div class="overlay__inner">
          <div class="overlay__content"><span class="spinner">Loading...</span></div>
        </div>
      </div>

      <div class="form-wrapper" *ngIf="loginFormflag">

        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="content-box-blackbaud">            
            <div class="closeIcon" #matClose mat-dialog-close><span aria-hidden="true">×</span></div>
            <h2 class="heading mb-1">Luminate Online Details</h2>
            <div class="fontWarning">All fields are mandatory</div>
            <div class="mt-3">

              <div class="mb-2 row">
                <label class="col-sm-12 blabel">API Key</label>
                <div class="col-sm-12">
                  <input type="text" class="form-control" placeholder="API key" formControlName="api_key"
                    [ngClass]="{ 'is-invalid': submitted && f.api_key.errors }">
                  <div *ngIf="submitted && f.login_name.errors" class="invalid-feedback">
                    <div *ngIf="f.api_key.errors?.required">API Key is required</div>
                  </div>
                </div>
              </div>

              <div class="mb-2 row">
                <label class="col-sm-12 blabel">Login Name</label>
                <div class="col-sm-12">
                  <input type="text" class="form-control" placeholder="Login" formControlName="login_name"
                    [ngClass]="{ 'is-invalid': submitted && f.login_name.errors }">
                  <div *ngIf="submitted && f.login_name.errors" class="invalid-feedback">
                    <div *ngIf="f.login_name.errors?.required">Login Name is required</div>
                  </div>
                </div>
              </div>

              <div class="mb-2 row">
                <label class="col-sm-12 blabel">Password</label>
                <div class="col-sm-12">
                  <input type="password" class="form-control" placeholder="Password" formControlName="password"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors?.required">Password is required</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <label class="col-sm-12 blabel">Rest URL</label>
                <div class="col-sm-12">
                  <input type="text" class="form-control" placeholder="Rest URL" formControlName="url"
                    [ngClass]="{ 'is-invalid': submitted && f.url.errors }">
                  <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                    <div *ngIf="f.url.errors?.required">Rest URL is required</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="btn-wrapper mb-0">
            <button type="submit" [disabled]="loading" class="custom-btn">Connect</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>