import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getColumnName'
})
export class GetColumnNamePipe implements PipeTransform {

  transform(value: string, columnGroupKey: string, columnGroup: any): string {
    if (!value) return 'Select Column';
    const column = columnGroup[columnGroupKey]?.find((item: any) => item?.key === value);

    return column?.display_name || 'Select Column';
    
  }

}
