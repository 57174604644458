import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { IsValueRequiredPipe } from '..//is-value-required.pipe';
import { COLUMN_CATEGORY, CONDITION_BY_DT } from '../../constant';
import { TooltipPosition } from '@angular/material/tooltip';
import {UntypedFormControl} from '@angular/forms';
import { FilterColumnByNamePipe } from '../filter-column-by-name.pipe';
@Component({
  selector: 'app-new-condtion',
  templateUrl: './new-condtion.component.html',
  styleUrls: ['./new-condtion.component.scss'],
  providers: [IsValueRequiredPipe,FilterColumnByNamePipe]
})
export class NewCondtionComponent implements OnInit {
  readonly COLUMN_CATEGORY = COLUMN_CATEGORY;
  readonly CONDITION_BY_DT = CONDITION_BY_DT;

  maxDate = moment();
  conditionForm: FormGroup;
  searchByColumnName: string = '';
  locale = {
    format: 'MM-DD-YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'MM-DD-YYYY', // default is format value
    direction: 'ltr', // could be rtl
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Apply', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'Custom range',
  };
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionBelow = new UntypedFormControl(this.positionOptions[3]); 
  columnCategory = {};
  searchedList: any[];
  cflag: boolean = false;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewCondtionComponent>,
    private isValueRequiredPipe: IsValueRequiredPipe,
    private filterColumnByNamePipe:FilterColumnByNamePipe
  ) { }

  ngOnInit() {
    this.conditionForm = this.fb.group({
      columnGroupKey:  ['', [Validators.required]],
      columnKey: ['', [Validators.required]],
      columnType: ['', [Validators.required]], // string, boolean, date, number
      conditionType: ['', [Validators.required]],
      value: ['', [Validators.required]],
    });
    this.columnCategory = this.data?.columnCategory;
    if (this.data?.index > -1) {
      setTimeout(() => {
        if (this.data.value.columnType == 'date') {
          if(this.data?.value?.value?.startDate){
            this.data.value.value = { startDate: moment(new Date(this.data?.value?.value?.startDate), "MM-DD-YYYY"), endDate: moment(new Date(this.data?.value?.value?.startDate), "MM-DD-YYYY") }
          }else {
          this.data.value.value = { startDate: moment(new Date(this.data?.value?.value), "MM-DD-YYYY"), endDate: moment(new Date(this.data?.value?.value), "MM-DD-YYYY") }
          }
        }
        this.conditionForm?.patchValue({ ...this.data?.value });
        this.updateValueControlValidation();
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this.conditionForm?.reset();
  }

  onClose(status: string) {
    this.dialogRef.close({
      status,
      data: null
    });
  }

  onSubmit() {    
    this.dialogRef.close({
      status: this.data?.index > -1 ? 'editted' :'submitted',
      data: this.conditionForm?.value,
    });
  }

  onColumnSelect(columnGroupKey: string, columnKey: string, columnType: string) {
    const columnGroupKeyControl = this.conditionForm?.get('columnGroupKey');
    columnGroupKeyControl?.patchValue(columnGroupKey);

    const columnKeyControl = this.conditionForm?.get('columnKey');
    columnKeyControl?.patchValue(columnKey);

    const columnTypeControl = this.conditionForm?.get('columnType');
    columnTypeControl?.patchValue(columnType);

    const conditionTypeControl = this.conditionForm?.get('conditionType');
    conditionTypeControl?.patchValue('');
    this.cflag = true;
  }

  onConditionSelect(conditionString: string) {
    const conditionTypeControl = this.conditionForm?.get('conditionType');
    conditionTypeControl?.patchValue(conditionString);
    const res = ['has_any_value','no_value']?.includes(conditionTypeControl?.value);
    
    if(res || this.cflag){
      const valueControl = this.conditionForm?.get('value');
      valueControl?.patchValue('');
    }
    this.cflag = false;
    this.updateValueControlValidation();
  }

  updateValueControlValidation() {
    const valueControl = this.conditionForm?.get('value');
    const conditionTypeControl = this.conditionForm?.get('conditionType');
    const isRequired = this.isValueRequiredPipe.transform(conditionTypeControl?.value);

    if (isRequired) {
      valueControl?.setValidators([Validators.required]);
    } else {
      valueControl?.clearValidators();
    }
    valueControl?.updateValueAndValidity();
  }

  getTransformedData() {
    this.searchedList = [];
    const data = [];
    const transformedData = this.filterColumnByNamePipe.transform(this.data?.columnCategory, this.searchByColumnName);
    this.columnCategory = transformedData;
    if (this.searchByColumnName?.length >= 3) {
      Object.keys(transformedData)?.forEach((ele: any) => {
        transformedData[ele]?.map((element: any) => {
          element.group = ele;
          data.push(element)
        })
      })
      this.searchedList = [...new Set(data)];
    }
    this.data.fixedPattern = Object.keys(transformedData);

  }

}
