<div class="filter-wrapper">
    Filters:
    <div class="custom-dropdown position-absolute">
        <div class="dropdown">
            <a class="dropdown-toggle gu-dropdown-toggle" href="#" role="button"
                id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
            </a>

            <div class="dropdown-menu w-open" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" href="#">All Time</a>
                <a class="dropdown-item" data-toggle="modal"
                    data-target="#detailedDashboardYear" href="javascript:void(0)">Year</a>
                <a class="dropdown-item" data-toggle="modal"
                    data-target="#detailedDashboardMonth" href="javascript:void(0)">Month</a>
            </div>
        </div>
    </div>
</div>


<div class="modal above fade" id="detailedDashboardYear" role="dialog">
    <div class="modal-dialog modal-dialog-centered custom-dialog-modal modal-md" role="document">
        <div class="modal-content m-2">
            <div class="modal-header px-4">
                <h6 class="modal-title">Choose the year</h6>
                <button #modalCloseBtnYear type="button" data-dismiss="modal"
                    class="close"><span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <ul class="yearPicker">
                    <li *ngFor="let year of filterYear" (click)="filter('monthly', year)">
                        {{ year }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="modal above fade" id="detailedDashboardMonth" role="dialog">
    <div class="modal-dialog modal-dialog-centered custom-dialog-modal modal-md" role="document">
        <div class="modal-content m-2">
            <div class="modal-header px-4">
                <h6 class="modal-title">Choose the month</h6>
                <button #modalCloseBtnMonth type="button" data-dismiss="modal"
                    class="close"><span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="monthPicker" *ngFor="let year of filterYear">
                    <span class="my-1">{{ year }}</span>
                    <ul class="year mx-0 my-1 pl-4 pr-4 pb-4 pt-2">
                        <ng-container *ngFor="let month of filterMonth; let i = index">
                            <ng-container *ngIf="currentYear === year && currentMonth < i; else months">
                                <li class="disabled">
                                    {{ month }}
                                </li>
                            </ng-container>

                            <ng-template #months>
                                <li (click)="filter('daily', year, month)">
                                    {{ month }}
                                </li>
                            </ng-template>
                            
                        </ng-container>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>