import { TestComponent } from './../test/test.component';
import { AngularMaterialModule } from './../shared/module/angular-material/angular-material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivationMessageComponent } from './activation-message.component';
import { RouterModule, Routes } from '@angular/router';
import { CategorymessageoverallComponent } from '../categorymessageoverall/categorymessageoverall.component';
import { TacticsModule } from '../tactics/tactics.module';
import { MessageModule } from './message/message.module';

const routes: Routes = [
  {
    path: '',
    component: ActivationMessageComponent,
    children: [
      {
        path: 'category',
        component: CategorymessageoverallComponent
      },
      {
        path: 'current-test',
        component: TestComponent
      },
      {
        path: 'messages',
        loadChildren: () => import('./../activation-message/message/message.module').then(m => m.MessageModule),
      },
      {
        path: 'tactics',
        loadChildren: () => import('./../tactics/tactics.module').then(m => m.TacticsModule),
      },
      {
        path: '**',
        redirectTo: '/activation-message/messages',
        pathMatch: 'full'
      }
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TacticsModule,
    MessageModule,
    AngularMaterialModule,
  ],
  declarations: [
    ActivationMessageComponent,
    CategorymessageoverallComponent,
    TestComponent,
  ],
  exports: [
    RouterModule,
    TacticsModule,
    ActivationMessageComponent,
    CategorymessageoverallComponent,
    TestComponent,    
  ],
})
export class ActivationMessageModule { }
