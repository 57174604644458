import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ReportService } from 'src/app/services/report.service';
import { GudatepickerComponent } from 'src/app/shared/componentss/gudatepicker/gudatepicker.component';
import { NgoSharedService } from 'src/app/shared/services/ngo-shared.service';
import { debounce, debounceTime, finalize, map, retry } from 'rxjs/operators';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { Moment } from 'moment';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { environment } from '../../../environments/environment';
import { ReportDownloadSharedService } from 'src/app/shared/services/report-download-shared.service';
import { ReportDeleteConfirmationComponent } from 'src/app/shared/componentss/report-delete-confirmation/report-delete-confirmation.component';
import { DuplicateReportService } from 'src/app/services/duplicatereport.service';
import { MomentDate } from 'src/app/models/Date';
import { UserSharedService } from 'src/app/shared/services/user-shared.service';
import { USER_ROLE,INTIAL_DATE } from 'src/app/constant';


@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements OnInit, OnDestroy {
  @ViewChild('closeOpenCalendar') closeOpenCalendar: ElementRef<HTMLElement>;
  @ViewChild('modalCloseBtnRange') modalCloseBtnRange: ElementRef<HTMLElement>;
  @ViewChild('ngxDatePicker') ngxDatePicker: ElementRef<HTMLElement>;
  public selected: { start: Moment, end: Moment };
  public singleExportRange: any;
  public multiExportRange: any;
  public charityId: string;
  public selectedNgo: SelectedNgo;
  public charityIDSubscription: Subscription;
  public totalRecords: number;
  public currentPage = 1;
  public currentPageCount: number;
  public pages: any;
  public config: any;
  public columns = '';
  public reportType = 'fundraiser_report';
  public fromDate: any = '';
  public toDate: any = '';
  public fromDate1: any = '';
  public toDate1: any = '';

  public addEditExportFromDate: any = '';
  public addEditExportToDate: any = '';

  public loader = true;
  public maxDate = moment();
  public searchTxt: any | undefined = '';
  public searchSubscription: Subscription;
  public fundraiserSubscription: Subscription;
  public fundraiserSearchLoader = false;
  public searchStatus: string;
  public isPreviewEnabled: boolean;

  public locale = {
    format: 'YYYY-MM-DD', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'MM-DD-YYYY', // default is format value
    direction: 'ltr', // could be rtl
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Apply', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'Custom range',
  };

  public dateFilterFlag = false;
  public alertMsg = { type: '', msg: '' };

  public reportId: number;
  public reportSubscription: Subscription;
  public reportDownloadURL: string;
  public reportDownloadData: any;
  public reportDownloaded = false;
  public alwaysShowCalendars: boolean;
  public duplicateSubscription: Subscription;
  public saveReportSubscription: Subscription;
  public deleteSubscription: Subscription;

  @ViewChild(MatMenuTrigger, { static: false })
  contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  public reportLoader = true;
  public reportIds = [];
  public indexIds = [];
  public deleteLoader = false;
  public dwnldReportInterval: any;
  public reportMultiDownloadURL = [];
  public editReportRow: any;
  public timeoutID: any;
  public allPopActionreportLoader = false;
  public reportNameData: any;
  public reportTypes: any = [];
  // public repType: any;
  public reportFilterData = [];
  public filterType = "blank";
  public columnsData: any;
  public totalCheckBoxColumn = 0;
  public categoryKeys = {
    'affinity_information': 'Affinity Information',
    'campaign_details': 'Campaign Details',
    'contact_info': 'Contact Info',
    'donor': 'Donation Details',
    'challenge_participant': 'Challenge Participant',
    'fundraiser': 'Fundraiser Details',
    'general_info': 'General Info',
    'gift_aid': 'Gift Aid',
    'challenge_incentive': 'Challenge Incentive',
    'other': 'Other',
    'survey_answers': 'Survey Answers'
  }
  public columnsKeyObjectArray = [];
  public newReportingForm : UntypedFormGroup;
  @ViewChild('reportName') reportName:ElementRef;
  public seq_id:string;
  public checkedColumnsKey = [];
  public previewDatas = [];
  public checkedColumnData = [];
  public checkedColumns = '';
  public checkedDisplayName = [];
  public beforeKeys:string = '';
  public afterKeys:string = '';
  public cursorPrevLength= 0;
  public cursorNextLength = 0;
  public keysNameData = [];
  public from_date = '';
  public to_date ='';
  public MomentDate = new MomentDate();
  public monthList = this.MomentDate.months;
  public months = [];
  public startMinDate = moment();
  public endMinDate = moment();
  public endMaxDate = moment();
  public locale1 = this.MomentDate.eventLocaleOptions;
  public dateRangeFired = false;
  public getNewReportSubscription: Subscription;
  public newSaveReportSubscription: Subscription;
  public errorDataforDate:string;
  public isErrorDataforDate = false;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionLeft = new UntypedFormControl(this.positionOptions[4]);
  positionTop = new UntypedFormControl(this.positionOptions[2]);
  positionBottom = new UntypedFormControl(this.positionOptions[3]);
  public nextPagination:any;
  public previousPagination:any;
  public isDisplayFullPreviewScreen = false;
  public getSaveReportStatus: Number;
  public isBlank = false;
  public editable = false;
  public isOpenCalendar:any;
  public isOpenCalendar1:any;
  exportInProgress: boolean = false;
  newReport: any = {}; 
  userSubscription: Subscription;
  userRole: any;
  public filterTypeError:string;
  public isValidfilterType = false;
  public searchTxtVal: any | undefined;
  public searchText:any | undefined = '';
  public searchTextData:any | undefined = '';
  showReportMenu = environment.showNewreport;
  isvalidSpecialCharater = false;
  isValidEscape:string;
  startDate:any = new Date();
  endDate:any = new Date();
  bsMaxDate:any = new Date();
  bsInlineRangeValue: Date[];
  previousReportData: any = {};
  isNew:boolean = false;
  overWriteFlag: boolean = false;
  hasExpanded: boolean = true;
  
  onContextMenu(event: MouseEvent, report) {
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { report: report };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  doubleClick(reportID, row) {
    
    this.startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1, 
      new Date().getDate()
    );
      this.openNewReport(reportID);
  }

  onContextMenuDuplicate(RID) {
    this.duplicateSubscription = this._duplicateReportService.duplicateReport(this.charityId, RID.content.report_id).subscribe(res => {
      if (res) {
        this.showAlertMsg(res.message, 'success');
        this.reportIds = [];
        this.indexIds = [];
        this.currentPage = 1;
        window.scrollTo(0, 0);
        this.getSavedReports("add"); 
      }
    }, error => {
      this.showAlertMsg(error.message, 'danger');
    }
    )
  }

  clikedContextMenu(event: MouseEvent, report) {
    event.stopPropagation();
    event.preventDefault();
    this.reportIds = [];
    this.savedReports.map((e) => e.isChecked = false);
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { report: report };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  public savedReports = [];

  constructor(
    public dialog: MatDialog,
    private _reportService: ReportService,
    private _ngoSharedService: NgoSharedService,
    private router: Router,
    private _duplicateReportService: DuplicateReportService,
    private renderer: Renderer2,
    private _userSharedService: UserSharedService,
  ) {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalRecords
    };
    this.alwaysShowCalendars = true;
    this.startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1, 
      new Date().getDate()
    ); 
    this.bsInlineRangeValue = [
      this.startDate,  
      this.endDate]
  }

  ngOnInit() {   
    this.singleExportRange = {start:moment().subtract(1, 'month'),end:  moment()};
    this.multiExportRange = {start:moment(), end:  moment()};
    this.newReport.emailOnly = false;
    this.reportDownloadURL = "";
    this.editReportRow = '';
    this.getCharityId();
    
  }  

  getUserinfo() {
    this.userSubscription = this._userSharedService
        .userData
        .subscribe(
          (res:any) => {
            if (Object.keys(res).length > 0) {
              this.userRole = res?.roles[0]?.role;
              if (this.userRole == USER_ROLE.NGO_ADMIN || (this.userRole == USER_ROLE.SUPER_ADMIN && !this.showReportMenu)) {
                this.getReportingFlag(this.charityId);
              }
              
            }
          }
        );
  }

  ngoChanged(ngo: SelectedNgo) {
    this.savedReports = [];
    this.currentPage = 1;
    this.reportIds = []
    this.indexIds = [];
    this.getSavedReports("ngoChange"); 
    if(this.userRole == USER_ROLE.NGO_ADMIN || (this.userRole == USER_ROLE.SUPER_ADMIN && !this.showReportMenu)){
      this.getReportingFlag(this.charityId);
    }
   
  }

  getReportingFlag = async(charityId) => {
    const { Results } = await this._reportService.reportingFlag(charityId);
    if(!Results.new_reporting){
      this.router.navigate(['/home']);
    }
  }

  openCal() {
    //setTimeout(() => this.pickerDirective.open(), 0);    
    this.singleExportRange = {start:moment().subtract(1, 'month'),end:  moment()};
    this.resetForm();
    // setTimeout(() => this.pickerDirective.nativeElement.click(), 0);
    this.renderer.addClass(document.body,'modal-open1');
  }


  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        selectedNGO => {
          if (selectedNGO.charityId !== null) {
            this.selectedNgo = selectedNGO;
            this.charityId = selectedNGO.charityId;
            this.currentPage = 1;
            this.getSavedReports("list");
            this.getUserinfo();  
            if (this.dwnldReportInterval) clearInterval(this.dwnldReportInterval);
            // this.getRecentReportId();
          }
        },
      );
  }

  setupTimeID() {
    if (typeof this.timeoutID === 'number') {
      this.cancel();
    }

    this.timeoutID = setTimeout(function() {
      this.remind();
    }.bind(this), 1000);
  }

  cancel() {
    clearTimeout(this.timeoutID);
  }

  remind() {    
    this.allPopActionreportLoader = true;
    this.timeoutID = undefined;
  }
  
  ngSelectedReportDateChange(ev:any) {    
    
    this.fromDate = moment.utc(ev[0]).local().format('YYYY-MM-DD');
    this.toDate = moment.utc(ev[1]).local().format('YYYY-MM-DD');
    
    this.startDate = ev[0];
    this.endDate = ev[1];
     
  }

  resetForm() {
    // this.addEditExportfromInput.value = '';
  }

  CheckValidateTitle() {
    let valData = this.reportName.nativeElement.value; 
    if (valData.match(/^[ A-Za-z0-9_-]*$/)) {   
       this.isvalidSpecialCharater = false;
    } else {      
      this.isvalidSpecialCharater = true;
      this.isValidEscape = "Error: Your title must be alphanumeric. (A-Z, a-z, 0-9, - _ and space)";
    }
  }

  dismisConfirmModal(){
    this.renderer.addClass(document.body, 'modal-open1');
  }

  confirmSaveReportData(){
    this.isNew = false;
    this.renderer.removeClass(document.body, 'modal-open1');
  }

  checkedaddEditDate(){
    if(this.fromDate!=null && this.toDate!=null && this.fromDate > this.toDate) {
      this.isErrorDataforDate = true;
      this.errorDataforDate = 'From date should not greater then to date';
      setTimeout(()=>{this.isErrorDataforDate = false;}, 5000);    
      return;    
    }
    else
    {
      return false;
    }
  }
  

  getSavedReports(from) { 
    this.reportLoader = true;
    const apiData = {
      charity_id: this.charityId,
      page_n: this.currentPage,
      per_page: 10
    };

    if (this.searchTxt) apiData['search_data'] = this.searchTxt;

    this.saveReportSubscription = this._reportService
      .savedReports(apiData)
      .pipe(debounceTime(1000), finalize(() => this.reportLoader = false))
      .subscribe(
        res => {
          this.reportLoader = false;     
          this.getSaveReportStatus = res.status;    
          this.renderer.removeClass(document.body, 'modal-open1');         
          res.report_dict_list.map(v => {
            let b = moment.utc(v.updated_at);
            const date = moment().utc().diff(b, 'days');
            if (date > 0) v['dateDiff'] = date + ' Days';
            else v['dateDiff'] = moment().utc().diff(b, 'hours') + ' Hours';
            v['isChecked'] = false;
          });

          if (this.searchTxt || !this.searchTxt) 
          {
            if(from=="add" || from=="update" || from=="ngoChange")
            {
              this.savedReports = [];
            }
            for(let temp of res.report_dict_list)
            {
              this.savedReports.push(temp);
            }
            return;
          }          
          this.savedReports = [...this.savedReports, ...res.report_dict_list]; 
        },
        err => {
          this.getSaveReportStatus = err.status;  
          if (this.currentPage > 1) return;
          this.showAlertMsg(err.message, 'danger'); 
        }
      );
  }

  searchReports(input: string) {
    this.searchTxt = '';
    this.currentPage = 1;
    this.searchStatus = 'Searching...';
    this.searchTxt = input.trim();
    if (this.fundraiserSubscription) this.fundraiserSubscription.unsubscribe();
    if (this.searchTxt === '' || this.searchTxt.length >= 3) {
      this.savedReports = [];
      this.getSavedReports("search");
      this.fundraiserSearchLoader = true;
    } else {
      this.searchTxt = '';
      this.fundraiserSearchLoader = false;
    }
  }

  onScrollDown() {
    if(this.getSaveReportStatus === 200) {
      this.currentPage++; 
      this.getSavedReports("list");
    }else{
      return;
    } 
  }

  isCheckedBox(index: number, reportD) {    
    this.savedReports[index].isChecked = !this.savedReports[index].isChecked;
    if (this.savedReports[index].isChecked) {
      this.reportIds.push(reportD.report_id);
      this.indexIds.push(index);
    } else if (!this.savedReports[index].isChecked) {
      const i = this.reportIds.findIndex(v => v === reportD.report_id);
      if (i !== -1) this.reportIds.splice(i, 1)
      this.indexIds.splice(index, 1)
    }
    this.checkSelectedReportCount();
  }

  checkSelectedReportCount()
  {
    if(this.reportIds.length>5)
    {
      this.setToUnselectReport();
      this.reportIds.length=0;
      this.showAlertMsg("You can select only five reports at a time", 'danger');
    }
  }

  exportSelected() {    
    this.multiExportRange = {start:moment(), end:  moment()};
    // this.homeExportInput.value = '';
    this.startDate = new Date();
    this.endDate = new Date();
    this.bsInlineRangeValue = [this.startDate,this.endDate];
    // setTimeout(() => this.exportSelectedDirective.nativeElement.click(), 0);
  }

  stopCloseCalendarExport(event){
    event.stopPropagation();   
    this.isOpenCalendar = event.type;
  }

  closeCalendarModal(){  
    if(this.isOpenCalendar === 'click'){
      let el: HTMLElement = this.closeOpenCalendar.nativeElement as HTMLElement;
      el.click();
    }   
  }

  stopCloseCalendar(ev){    
    ev.stopPropagation();
    this.isOpenCalendar1 = ev.type;
  }

  openDeleteConfirmDialog(rId) {
    let tempRID;
    if(rId.hasOwnProperty('content') && rId.content.hasOwnProperty("report_id"))
    {
      tempRID = rId.content.report_id
    }
    else
    {
      tempRID = rId.report_id;
    }
    const index = this.savedReports.findIndex(r => r.report_id === tempRID);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '360px';
    dialogConfig.height = '220px';
    dialogConfig.panelClass = 'custom-dialog-container';
    //dialogConfig.data = { status };
    const dialogRef = this.dialog.open(ReportDeleteConfirmationComponent, dialogConfig);
    this.allPopActionreportLoader = true;
    dialogRef.afterClosed()
      .subscribe(
        res => {
          if (!res) 
          {
            this.allPopActionreportLoader=false;
            return
          };
          this.reportIds.push(tempRID);
          let apiData = this.reportIds;
          this.deleteSubscription = this._reportService.deleteReports(this.charityId, apiData).subscribe(res => {
            if (res) {
              this.showAlertMsg('Report has been deleted successfully', 'success');
              this.reportIds = [];
              this.allPopActionreportLoader = false;
              this.renderer.removeClass(document.body, 'modal-open1');
              if (index !== -1) {
                this.savedReports.splice(index, 1);
              }
            }
            this.allPopActionreportLoader = false;
            this.editReportRow = '';
            // const confirmBtn: HTMLElement = this.closeMainModel.nativeElement;
            // confirmBtn.click();
          })
        });
  }

  deleteSelectedReport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '415px';
    dialogConfig.height = '260px';
    dialogConfig.panelClass = 'custom-dialog-container';
    //dialogConfig.data = { status };
    const dialogRef = this.dialog.open(ReportDeleteConfirmationComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe(
        res => {
          if (!res) return;
          this.deleteLoader = true;
          let apiData = this.reportIds;
          this.deleteSubscription = this._reportService.deleteReports(this.charityId, apiData).subscribe(res => {
            if (res) {
              this.showAlertMsg(res.message, 'success');
              this.reportIds = [];
              this.deleteLoader = false;
              this.indexIds = this.indexIds.sort();
              for (let i = this.indexIds.length - 1; i >= 0; i--)
                this.savedReports.splice(this.indexIds[i], 1);
            }
          }, error => {
            this.showAlertMsg(error.message, 'danger');
          });

        });
  }

  onContextMenuExport() {
    this.openDialogCalendar();
  }

  openDialogCalendar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '616px';
    dialogConfig.height = '460px';
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(GudatepickerComponent, dialogConfig);
  }

  openDatepicker() {
    setTimeout(() => {
      const rangeBtn: HTMLElement = this.ngxDatePicker.nativeElement;
      rangeBtn.click();
    }, 100);
  }

  addEditExportAllData() {
    this.startDate = new Date(INTIAL_DATE);
    this.endDate = new Date();
    this.bsInlineRangeValue = [this.startDate,this.endDate];
  }

  homeReportExportAllData() {
    this.startDate = new Date(INTIAL_DATE);
    this.endDate = new Date();
    this.bsInlineRangeValue = [this.startDate,this.endDate];
  }

  getSelectedReportTaskID(from,reportId = []) { 
    this.loader = true;    
    const apiData = {
      from_date: from=="home" ? this.fromDate : this.addEditExportFromDate,
      to_date: from=="home" ? this.toDate : this.addEditExportToDate,
      report_id: reportId.length ? reportId : this.reportIds
    };    
    
    this._reportService
    .getSelectedReportTaskID(this.charityId, apiData,this.newReport.emailOnly)
    .pipe(finalize(() => this.loader = false))
    .subscribe(
      resp => {
        if(resp.status==200)
        {
          this.showReportDownloadAlertMsg("If you're downloading a large amount of data, this could take a few minutes. The file will automatically save to your computer once complete. Please don't close this page.", 'success');
          this.setToUnselectReport();
          this.startTimer(resp.celery_task_id);
        }
      }, 
      error => this.showAlertMsg(error.message, 'danger')
    );
  }

  getReportDownloadLinks(celeryTaskId)
  {  
    try {
      this._reportService
      .getReportDownloadLinks(this.charityId, celeryTaskId)
      .pipe().subscribe(
        resp => {
          // const MainBtn: HTMLElement = this.closeMainModel.nativeElement;
          // MainBtn.click();
          if(resp.status==200)
          {
            if(resp.task_status == 'Success')
            {
              this.exportInProgress = false;
              if(!resp.url){
                this.showReportDownloadAlertMsg(resp.message, 'danger');
                this.downloadCSVClicked();
                if (this.reportSubscription) this.reportSubscription.unsubscribe();
                clearInterval(this.dwnldReportInterval);
                return
              }
              if (this.reportSubscription) this.reportSubscription.unsubscribe();
              this.reportMultiDownloadURL = [];
              this.reportDownloadData = resp;
              this.reportDownloadURL = resp.url;
              clearInterval(this.dwnldReportInterval);
              window.open(resp?.url,"_self");
              return;
            }
          }
          else
          {
            clearInterval(this.dwnldReportInterval);
            //to disappear last alert
            this.alertMsg.msg = '';
            this.alertMsg.type = '';
            this.exportInProgress = false;
            this.showAlertMsg(resp.message, 'danger');
            this.downloadCSVClicked();
          }
        }, 
        error => {
          // const MainBtn: HTMLElement = this.closeMainModel.nativeElement;
          // MainBtn.click();
          clearInterval(this.dwnldReportInterval);
          //to disappear last alert
          this.alertMsg.msg = '';
          this.alertMsg.type = '';
          this.exportInProgress = false;
          this.showAlertMsg(error.message, 'danger');
          this.downloadCSVClicked();
        }
      );
      
    }catch (error) {      
      clearInterval(this.dwnldReportInterval);
    }
  }

  downloadAllfiles()
  {   
    for(let url of this.reportMultiDownloadURL)
    {      
      window.open(url, '_blank');
    }    
    this.downloadCSVClicked();
  }

  objectKeys(obj) {
    return Object.entries(obj);
  }
  
  startTimer(celeryTaskId) {
    //in 30 seconds check report status
    this.dwnldReportInterval = setInterval(()=>{
      this.getReportDownloadLinks(celeryTaskId);
    },30000);
  }

  setToUnselectReport()
  {
    for(let row of this.reportIds)
    {      
      const findReportIndex = this.savedReports.findIndex(r => r.report_id === row);
      if(findReportIndex!=-1)
      {        
        this.savedReports[findReportIndex].isChecked = false;
      }
    }
    this.reportIds = [];
  }

  downloadCSVClicked() {    
    this.setToUnselectReport();
    this.reportDownloaded = true;
    this.reportIds.length = 0;
    this.indexIds.length=0;
    this.reportDownloadData = {};
    this.reportDownloadURL = '';
    this.reportMultiDownloadURL = [];
    // this.reportSubscription.unsubscribe();
    // localStorage.setItem(environment.localKey + 'reportId', this.reportId.toString());
  }

  close() {
    this.reportDownloadData = {};
    this.reportDownloadURL = '';
    this.downloadCSVClicked();
    
  }

  openSubscriberDetail(_id) {
    this.router.navigate(['/subscribers/detail', _id]);
  }

  showReportDownloadAlertMsg(msg, type, status = null): void {
    this.alertMsg.msg = msg;
    this.alertMsg.type = type;

    setTimeout(() => {
      this.alertMsg.msg = '';
      this.alertMsg.type = '';
    }, 6000);
  }

  showAlertMsg(msg, type, status = null): void {
    this.alertMsg.msg = msg;
    this.alertMsg.type = type;

    setTimeout(() => {
      this.alertMsg.msg = '';
      this.alertMsg.type = '';
    }, 3000);
  }

  previewModel() {
    this.isPreviewEnabled = !this.isPreviewEnabled;
  }

  openReportDataList() {
    this.isDisplayFullPreviewScreen = true;
    this.renderer.addClass(document.body, 'modal-open1');
  }

  closePreviewModal(){
    this.isDisplayFullPreviewScreen = false;
  }
 
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 10 ||
      document.documentElement.scrollTop > 40) {
      document.getElementById('reportHeading').classList.add('reportHeading');
    } else {
      document.getElementById('reportHeading').classList.remove('reportHeading');
    }
  }

  truncateStories(str) {
    if(str === null){return;}
    let formattedMessage = '';
    str.split(' ').forEach((words, i: number) => {
      if (i < 2) formattedMessage += ' ' + words;
    });

    return formattedMessage;
  }

  truncateCharacters(str) {
    if(str === null){return;}
    return (str.length > 70) ? str.substr(0, 70 - 1) + '...' : str;
  }


  onDateRangeChange(event: any) {
    
    this.addEditExportFromDate = moment.utc(event[0]).local().format('YYYY-MM-DD');
    this.addEditExportToDate = moment.utc(event[1]).local().format('YYYY-MM-DD');
    this.startDate = event[0];
    this.endDate = event[1];
  }

  onExportMulti() {
    this.dateFilterFlag = true;
    if (this.modalCloseBtnRange) {
      const rangeBtn: HTMLElement = this.modalCloseBtnRange.nativeElement;
      rangeBtn.click();
    }
    if (this.closeOpenCalendar) {
      this.closeCalendarModal();
    }

    const monthDifference = moment(this.toDate).diff(moment(this.fromDate), 'months');
    if (monthDifference > 6 && this.reportIds.length > 1) {
      this.showAlertMsg("Please choose date range in six months", 'danger');
      return;
    }

    if (this.reportIds.length > 1) {
      if (this.exportInProgress) {
        this.showAlertMsg('Export report already in progress', 'danger');
        return;
      }
      this.exportInProgress = true;
      this.getSelectedReportTaskID("home");
    }else {
      if (this.exportInProgress) {
        this.showAlertMsg('Export report already in progress', 'danger');
        return;
      }
      this.exportInProgress = true;
      const reportIds = [];
      if (this.contextMenu?.menuData?.report?.content?.report_id) {
        reportIds.push(this.contextMenu?.menuData?.report?.content?.report_id);
      }
      this.getSelectedReportTaskID("home", reportIds);
    }
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.fundraiserSubscription) this.fundraiserSubscription.unsubscribe();
    if (this.reportSubscription) this.reportSubscription.unsubscribe();
    if (this.saveReportSubscription) this.saveReportSubscription.unsubscribe();
    if (this.duplicateSubscription) this.duplicateSubscription.unsubscribe();
    if (this.deleteSubscription) this.deleteSubscription.unsubscribe();
    if (this.getNewReportSubscription) this.getNewReportSubscription.unsubscribe();
    if (this.newSaveReportSubscription) this.newSaveReportSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
    // const MainBtn: HTMLElement = this.closeMainModel.nativeElement;
    // MainBtn.click();
  }

  isObject(object) {
    return object != null && typeof object === 'object';
  }

  openNewReport(reportId = null){
    if (reportId) this.router.navigate(['/newreports/report-builder'], { queryParams: { reportId } });
    else this.router.navigate(['/newreports/report-builder']);
  }

}
