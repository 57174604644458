<!-- 
<div class="asanaConfirmation">
   
    <div class="title">Edit Asana Status</div>
    <div class="closeBtn">
    <button class="btn-dialog-close pull-right" mat-stroked-button (click)="onClose(false)" tabIndex="-1">
        <mat-icon>clear</mat-icon>
    </button>
    </div>       
    
    <mat-dialog-content>
        <div *ngIf="data.status" class="md-dialog-content">
            This will start creating tasks in Asana. Are you sure?
        </div>
        <div *ngIf="!data.status" class="md-dialog-content">
            This will stop creating tasks in Asana. Are you sure?
        </div>      
    </mat-dialog-content>  

    <mat-dialog-actions align="end">
        <button mat-raised-button class="btn-outline-custom back-button" type="button" (click)="onClose(false)">Cancel</button>
        <button mat-raised-button class="btn btn-info" type="button" (click)="onClose(true)">Change
            Status</button>
    </mat-dialog-actions> 
</div> -->


<div class="asanaConfirmation">   
    <!-- <div class="title">Edit Asana Status</div> -->
    <mat-dialog-content>
        <div class="closeBtn">
            <button class="btn-dialog-close pull-right" mat-stroked-button (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>  
        <div class="md-dialog-content text-center">
            <div *ngIf="data.status">
                <div class="fontStyle">This will start creating tasks in Asana. Are you sure?</div>
                <div class="button-wrapper">
                    <button class="custom-btn" type="button" (click)="onClose(true)">OK</button>&nbsp;&nbsp;
                    <button class="btn-outline-custom back-button custom-btn" type="button" (click)="onClose(false)">Cancel</button>
                </div>
            </div>
            <div *ngIf="!data.status">
                <div class="fontStyle">This will stop creating tasks in Asana. Are you sure?</div>
                <div class="button-wrapper">
                    <button class="custom-btn" type="button" (click)="onClose(true)">OK</button>&nbsp;&nbsp;
                    <button class="btn-outline-custom back-button custom-btn" type="button" (click)="onClose(false)">Cancel</button>
                </div>
            </div>  
        </div>        
    </mat-dialog-content>  
</div>