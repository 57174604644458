import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialogue',
  templateUrl: './confirm-dialogue.component.html',
  styleUrls: ['./confirm-dialogue.component.scss','../report-list/report-list.component.scss']
})
export class ConfirmDialogueComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogueComponent>,

  ) { }

  ngOnInit(): void {
  }

  dismisConfirmModal(output: string) {
    this.dialogRef.close(output);
  }
}
