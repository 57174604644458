export class DateFilter {

    getParams(filter) {
        let params = {};

        for (let key in filter) {
            if (filter[key]) {
              if (key === 'type') params['filter'] = filter[key];
              else if (key === 'year') params[key] = filter[key].toString();
              else params[key] = filter[key];
            }
        }

        return params;
    }
}