
<div class="overlay__inner custom-overlay" *ngIf="activeEventLoader">
    <div class="overlay__content">
        <span class="spinner loadImg"></span>
    </div>
</div>

<ng-container *ngIf="activeEventData && (activeEventData.data || activeEventData.top_three_series); else notfound">
    <h2 class="common-heading mr-3">
        <span [matTooltipClass]="positionBottom.value" matTooltip="This shows you the metrics / donation activity for all of your active events.">Active Challenge Series</span>
        <a class="view-all pull-right" [routerLink]="['/events']">
            View All
        </a>
    </h2>

    <div class="stat-row">
        <div class="stat-blocks">
            <div class="stat-block">
                <div class="stat-label-wrap content-end">
                    <div class="stat-label" matTooltip="The total amount raised within your challenges within the selected time period.">Total Raised</div>
                </div>
                <div class="stat-number">
                    {{ activeEventData?.overall | currency: currencyName :'symbol':'1.0-0' }}
                </div>
            </div>
        </div>
    </div>

    <div class="html-embed-9 w-embed w-script" *ngIf="activeEventData">
        <div class="row no-gutters">
            <div class="col-12 text-left">
                <ng-container *ngIf="activeEventData && activeEventData.data">
                    <ul class="legends frgraphLegend">
                        <li
                            *ngFor="let graph of activeEventData.data; let i = index"
                            (click)="datasetFlag[graph.title] ? removeDataset(graph.title) : addDataset(graph.title)"
                            [ngClass]="{'cross-line': !datasetFlag[graph.title]}">
                                <ng-container *ngIf="graph.type === 'stack'">
                                    <div class="legendBox">
                                        <div class="lgDisc mr-1" [ngStyle]="{'background-color': chartColor[i]}"></div>
                                        <div class="lgText lgFont mr-1">
                                            {{ graph.title }}
                                        </div>
                                        <div class="lgFont" [ngStyle]="{'color': chartColor[i]}">
                                            {{ getTotalValue(graph.title) }}
                                        </div>
                                    </div>  
                                </ng-container>
                        </li>
                    </ul>
                </ng-container>
            </div>
        </div>
        <canvas id="activeEvents"></canvas>
    </div>

    <div class="event-section mt-3" *ngIf="activeEventData && activeEventData.top_three_series">
        <table class="table table-borderless">
            <tr class="topEvent" *ngFor="let activeEvent of activeEventData.top_three_series; let i = index" (click)="getEventId(activeEvent)">
               
                    <td>                                                 
                        <div class="user-profile"> 
                            <div class="srNumber">{{ i + 1 }}</div>
                            <div  class="widget-content-left">
                                <ng-container *ngIf="!activeEvent?.image; else noImage">                                     
                                    <img [ngClass]="{'imgShadow' : activeEvent?.events > 1}" src="../../../assets/images/noImage.png" alt="">                                  
                                </ng-container>
                                <ng-template #noImage>
                                    <img [ngClass]="{'imgShadow' : activeEvent?.events > 1}" [src]="activeEvent?.image" alt="">  
                                </ng-template>
                               
                            </div>
                            <div class="widget-heading">
                                <div class="title" [title]="activeEvent.name">{{ activeEvent.name }}</div>
                                <div class="particepants">
                                    <ng-container *ngIf="activeEvent?.events > 1; else singleEvent">
                                        {{ activeEvent?.events | number:'1.0-0' }} Challenges
                                    </ng-container>
                                    <ng-template #singleEvent>
                                        {{ activeEvent?.events | number:'1.0-0' }} Challenge
                                    </ng-template>  
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="text-right">
                        <div class="fundraised-data">
                            <div class="fund">
                                {{ activeEvent.raised | currency: currencyName:'symbol':'1.0-0' }}
                            </div>
                        </div>
                    </td>
               
            </tr>
        </table>
    </div>
</ng-container>

<ng-template #notfound>
    <h2 class="common-heading mr-3">
        <span matTooltip="This shows you the metrics / donation activity for all of your active challenges.">Active Challenge Series</span>
        <a class="view-all pull-right" [routerLink]="['/events']">
            View All
        </a>
    </h2> 
  <div class="nofound"><p>No Data Found</p></div>
</ng-template>
