import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSharedService {
  private userSource     = new BehaviorSubject<object>({});

  userData     = this.userSource.asObservable();

  constructor() { }

  changeUserData(data: object) {
    this.userSource.next(data);
  }
}
