import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, NavigationEnd, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(
    private router: Router,
    private _userService: UserService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // const roles = route.data.roles as string;
    const roles = route.data.roles as Array<string> | string;

    return this._userService
        .getUserInfo()
        .pipe(
          map(
            (res: any) => {
              if(typeof roles === 'string'){ 
                if (roles === res.roles[0].role) return true;
              }else{
                const exist = roles.filter(r => res.roles[0].role === r);
                if (exist.length > 0) return true;
              } 
              // if (roles === res.roles[0].role) return true;

              if (route.data.hasOwnProperty('redirect')) this.router.navigate([route.data.redirect]);
              else this.router.navigate(['/']);
              return false;
            }
          )
        );
  }
}
