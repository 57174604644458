import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IntegrationService } from '../services/integration.service';
import { first } from 'rxjs/operators';
import { NgoSharedService } from '../shared/services/ngo-shared.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-blackbaud',
  templateUrl: './blackbaud.component.html',
  styleUrls: ['./blackbaud.component.scss']
})
export class BlackbaudComponent implements OnInit, OnDestroy {
  @ViewChild('matClose') matClose: ElementRef<HTMLElement>;
  public submitted      = false;
  public loading        = false;
  public loginFormflag  = false;
  public loginForm: UntypedFormGroup;
  public charityId: string;
  public message: string;
  public charityIdSubscription: Subscription;
  public alertMsg = { type: '', msg: ''};
  public loader = false;

  constructor(
    private integrationService: IntegrationService,
    private formbuilder: UntypedFormBuilder,
    private _ngoSharedService: NgoSharedService,
    private router: Router,
    public dialogRef: MatDialogRef<BlackbaudComponent>,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.loginform();
    this.checkNgoConnection();
  }

  receiveMessage($event) { }

  checkNgoConnection() {
    this.charityIdSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          (res: any) => {
            if (res.charityId !== null) {
              this.charityId = res.charityId;
              this.getBlackbaudConnection();
            } else this.loading = false;
          },
          error => this.loading = false
        );
  }

  getBlackbaudConnection(): void {
    this.integrationService
        .getBlackbaudConnection(this.charityId)
        .subscribe(
          (res: any) => {
            if (res.connected) {
              this.loading = false;
              this.router.navigate(['/integrations/map-attributes']);
            } else {
              this.loading = false;
              this.loginform();
            }
          },
          err => {
            this.loading = false;
            this.router.navigate(['/integrations']);
          }
        );
  }

  loginform() {
    this.loginForm = this.formbuilder.group({
      api_key:    ['', Validators.required],
      login_name: ['', Validators.required],
      password:   ['', Validators.required],
      url:        ['', Validators.required]
    });
    this.loginFormflag = true;
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.message = 'All fields are mandatory.';
      setTimeout(() => { this.message = ''; }, 8000);
      return;
    }

    this.loader      = true;
    this.message      = '';
    this.submitted    = true;
    const connectData = {
      base_fr_id:   '',
      api_key:      this.loginForm.controls.api_key.value,
      login_name:   this.loginForm.controls.login_name.value,
      password:     this.loginForm.controls.password.value,
      url:          this.loginForm.controls.url.value
      //charity_id:   this.charityId
    };

    this.integrationService
        .connect(connectData, this.charityId)
        .pipe(first())
        .subscribe(
          data => {
            if (data.status === 200) {
              this.loader = false;
              this.dialogRef.close('');
              this.router.navigate(['integrations/map-attributes']);
              const matCloseBtn: HTMLElement = this.matClose.nativeElement;
              matCloseBtn.click();
            }else{
              const matCloseBtn: HTMLElement = this.matClose.nativeElement;
              matCloseBtn.click();
            }
          },
          error => {
            this.message = error.message;
            setTimeout(() => { this.message = ''; }, 8000);            
            this.loader = false;
          }
        );
  }

  ngOnDestroy() {
    this.charityIdSubscription.unsubscribe();
  }

}
