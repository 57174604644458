export interface FundraiserRevenue {
    data: ChartData[];
    label: Array<string>;
    overall: Overall;
    type: string;
    charge_date: string;
}

export interface ChartData {
    title: string;
    type: string;
    value: any;
}

export interface Overall {
    Direct: Direct;
    P2P: P2P;
  }

export interface Direct {
    averageCampaign: number;
    averageDonation: number;
    averageFundraiser: number;
    totalCampaign: number;
    totalDonation: number;
    totalRaised: number;
}

export interface P2P {
    averageCampaign: number;
    averageDonation: number;
    averageFundraiser: number;
    totalCampaign: number;
    totalDonation: number;
    totalRaised: number;
}

// export interface Overall {
//     averageCampaign: number;
//     averageDonation: number;
//     averageFundraiser: number;
//     totalCampaign: number;
//     totalDonation: number;
//     totalRaised: number;
// }

export const ChartLegends = {
    Direct: 'Direct',

    P2P: 'Challenges',

    total_donations: 'Donations',

    total_campaigns: 'Campaigns',
};

export const ChartColor = {
    Direct: '#04C3CB',

    Events: '#755DB9',

    P2P: '#F8B630',

    total_donations: '#002C45',

    total_campaigns: '#002C45',
};

export const ChartOrder = {
    Direct: 5,
    Events: 4,
    P2P: 3,
    total_donations: 2,
    total_campaigns: 1,
};

export interface Filter {
    type: string;
    year: number;
    month: string;
}

export const FilterApplied = {
    all: 'All time',
    monthly: 'Yearly',
    daily: 'Monthly'
};

