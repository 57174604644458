const USER_ROLE = {
    SUPER_ADMIN: "ROLE_SUPER_ADMIN",
    NGO_ADMIN: "ROLE_ADMIN"
};

const INTIAL_DATE = '01/01/2018';

const LOG_ROCKET_USER_TYPE = {
  SUPER_ADMIN: "SuperAdmin",
  NORMAL_USER: "NonprofitUser"
};

const COLUMN_CATEGORY = {
  affinity_information: 'Affinity Information',
  campaign_details: 'Campaign Details',
  contact_info: 'Contact Info',
  challenge_participant: 'Challenge Participant',
  donor: 'Donation Details',
  fundraiser: 'Fundraiser Details',
  general_info: 'General Info',
  gift_aid: 'Gift Aid',
  challenge_incentive: 'Challenge Incentive',
  other: 'Other',
  survey_answers: 'Survey Answers'
}

const FUNDRAISER_CREATION_LINK = 'https://challenge-hub.goodunited.io/CreateFundraiser?cid=';
const CONDITION_BY_DT = {
  boolean: [
    { text: `is true`, key: 'is_true' },
    { text: `is false`, key: 'is_false' },
    { text: `has any value`, key: 'has_any_value' },
    { text: `is unknown`, key: 'is_unknown' },
  ],
  string: [
    { text: `is`, key: 'is' },
    { text: `isn't`, key: 'is_not' },
    { text: `has any value`, key: 'has_any_value' },
    { text: `contains`, key: 'contains' },
    { text: `doesn't contain`, key: 'not_contain' },
    { text: `begins with`, key: 'begin' },
    { text: `has no value`, key: 'no_value' }
  ],
  number: [
    { text: `greater than`, key: '>' },
    { text: `greater than or equal to`, key: '>=' },
    { text: `less than`, key: '<' },
    { text: `less than or equal to`, key: '<=' },
    { text: `is exactly`, key: 'is' },
    { text: `isn't`, key: 'is_not' },
    { text: `has any value`, key: 'has_any_value' },
    { text: `has no value`, key: 'no_value' },
  ],
  date: [
    { text: `after`, key: 'after' },
    { text: `before`, key: 'before' },
    { text: `on`, key: 'on' },
  ],
}

export {
    USER_ROLE,
    INTIAL_DATE,
    LOG_ROCKET_USER_TYPE,
    FUNDRAISER_CREATION_LINK,
    COLUMN_CATEGORY,
    CONDITION_BY_DT
};