import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activation-tabs',
  templateUrl: './activation-tabs.component.html',
  styleUrls: ['./activation-tabs.component.scss']
})
export class ActivationTabsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
