import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { ReportService } from 'src/app/services/report.service';
import { NgoSharedService } from '../../services/ngo-shared.service';

@Component({
  selector: 'app-report-data-list',
  templateUrl: './report-data-list.component.html',
  styleUrls: ['./report-data-list.component.scss']
})
export class ReportDataListComponent implements OnInit {

  public charityIDSubscription:Subscription;
  public charityId:string;
  public selectedNgo: SelectedNgo;

   constructor(
    private _reportService: ReportService,  
    private _ngoSharedService: NgoSharedService,
  ) { }

  ngOnInit() {
    this.getCharityId();
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        selectedNGO => {
          if (selectedNGO.charityId !== null) {
            this.selectedNgo = selectedNGO;
            this.charityId = selectedNGO.charityId;
            // this.getDefaultFundraiserColumns();
            // this.getDefaultDonorColumns();
            // this.getRecentReportId();
          }
        },
      );
  }

  // truncateStories(str) {
  //   let formattedMessage = '';
  //   str.split(' ').forEach((words, i: number) => {
  //     if (i < 20) formattedMessage += ' ' + words;
  //   });

  //   return formattedMessage;
  // }

  // ShowMoreButton(index) {
  //   for (let i = 0; i < this.fundStories.length; i++) {
  //     if (i === index) this.fundStories[index].isExpanded = true;
  //   }
  // }

  // ShowLessButton(index) {
  //   for (let i = 0; i < this.fundStories.length; i++) {
  //     if (i === index) this.fundStories[index].isExpanded = false;
  //   }
  // }

  // truncateCharacters(str) {
  //   return (str.length > 30) ? str.substr(0, 30 - 1) + '' : str;
  // }

  


}
