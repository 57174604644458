<div class="loginBGbox">  
  <div class="guarrowImg"></div>
  
      <div class="card-box text-center login_box">

        <div class="while-logo">
          <img src="../../assets/images/GU_Logo_Primary_Color.svg" alt="">
        </div>

        <button class="btn btn-social-icon btn-facebook" (click)="signInWithFB()">
          <img src="../../assets/images/fbicon.svg" alt="">
        </button>  
        <!-- <a [href]="fbLoginUri">log in</a> -->  
        <p class="spend_time">
          Ready to start connecting where your donors spend time? 
          <a href="https://www.goodunited.io/schedule-a-demo" target="_blank">
            Get A Demo
          </a>
        </p>
        
      </div>

  
  <div class="login-footer">
    <p>
      <a href="https://www.goodunited.io/privacy-policy" target="_blank">
        Privacy Policy
      </a>
    </p>
    <p>Copyright © 2022 GoodUnited.io</p>
  </div>  
</div>
