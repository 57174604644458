<h2 mat-dialog-title>Fundraiser Stories - {{qType}}<button class="btn mat-button" mat-button
    mat-dialog-close>Close</button></h2>
<div class="cloud-content-wrapper">

  <!-- <div class="overlay" *ngIf="loading">
    <div class="overlay__inner">
      <div class="overlay__content">
        <span class="spinner">Loading...</span>
      </div>
    </div>
  </div> -->

  <mat-dialog-content class="mat-typography">

    <div class="row justify-content-sm-end">
      <div class="col col-12">

        <div class="responsive">

          <div class="row p-0 m-0 cloud-content">

            <table class="table table-hover mb-0 table-striped table-bordered">
              <thead>
                <tr class="cloudHeading">
                  <th style="width: 200px;">Name</th>
                  <th style="width: 400px;">Story</th>
                  <th style="width: 150px;">Start Date</th>
                  <th style="width: 150px;">E-mail</th>
                  <th style="width: 150px;">Messenger</th>
                  <th style="width: 150px;">Retention Score</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let story of tmpStories; let i = index">
                  <td>{{story.Name}}</td>
                  <td>
                    <div [innerHTML]="story.Story | highlight: searchTerm"></div> 
                  <!-- <ng-container *ngIf="story.Story.length > 130; else allStory">
                    <div *ngIf="!story.isExpanded">
                      <div [innerHTML]="truncateStories(story.Story) | highlightSentence: searchTerm"></div>
                      <a class="showMore" (click)="ShowMoreButton(i)">Show More...</a>
                    </div>
                  </ng-container>
                  <ng-template #allStory>
                    <div [innerHTML]="story.Story | highlightSentence: searchTerm"></div>
                  </ng-template>

                    <div *ngIf="story.isExpanded">
                      <div [innerHTML]="story.Story | highlightSentence: searchTerm"></div>
                      <a class="showMore" (click)="ShowLessButton(i)">Show Less...</a>
                    </div> -->

                  </td>
                  <td>{{story.StartDate}}</td>
                  <td style="width: 150px; word-break: break-all;">{{story.email_address}}</td>
                  <td style="width: 150px;"><a class = "replygreen" [href]="story.url" target="_blank">Reply</a></td>
                  <td>{{story.retention}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>