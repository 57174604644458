import { Component, OnInit, OnDestroy, HostListener, HostBinding, ElementRef } from '@angular/core';
import FunnelGraph from 'funnel-graph-js';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Filter } from 'src/app/models/Fundraiser';
import { NgoSharedService } from '../../services/ngo-shared.service';
import { FilterSharedService } from '../../services/filter-shared.service';
import { FundraiserService } from 'src/app/services/fundraiser.service';
import { ConversionGraph, DirectConversionGraph, EventConversionGraph, ChartColor, ChartColorFbc,ChartColorTotalFunnel } from 'src/app/models/Conversion';
import { finalize } from 'rxjs/operators';
import { TooltipPosition } from '@angular/material/tooltip';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-conversion-graph',
  templateUrl: './conversion-graph.component.html',
  styleUrls: ['./conversion-graph.component.scss']  
})
export class ConversionGraphComponent implements OnInit, OnDestroy {
  public chart;
  public chartColor = ChartColor;
  public chartFbcColor = ChartColorFbc;
  public chartColorTotalFunnel = ChartColorTotalFunnel;


  public chartData: any[] = [];
  public charityId: string;

  public charityIDSubscription: Subscription;
  public conversionData: ConversionGraph;
  public conversionDirectData: DirectConversionGraph;
  public conversionDirectFlag =false;
  public conversionEventData: EventConversionGraph;
  public conversionEventFlag =false;


  public filterData: Filter;
  public filterDataSubscription: Subscription;

  public conversionLoader = true;
  public scrHeight:any;
  public scrWidth:any = 435;
  public scrWidth1:any;
  fundraiserthankedTotalVal:any;
  optinTotalVal:any;
  emailTotalVal:any;
  totLength = 0;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
          this.scrHeight = window.innerHeight;
          this.scrWidth1 = window.innerWidth;
          if(this.scrWidth1 < 400) {
            this.scrWidth = 280; 
          }else if(this.scrWidth1 >= 400 && this.scrWidth1 <= 600){
            this.scrWidth = 310;
          }else if(this.scrWidth1 > 600 && this.scrWidth1 <= 768){
            this.scrWidth = 650;
          }else if(this.scrWidth1 > 768 && this.scrWidth1 < 1024){
            this.scrWidth = 570;
          }else if(this.scrWidth1 >= 1024 && this.scrWidth1 < 1440){
            this.scrWidth = 435;
          }else if(this.scrWidth1 >= 1440 && this.scrWidth1 <= 1680){
            this.scrWidth = 450;
          }else if(this.scrWidth1 > 1680 && this.scrWidth1 <= 1920){
            this.scrWidth = 450;
          }else{
            this.scrWidth = 450;
          }
    }

    positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
    positionLeft = new UntypedFormControl(this.positionOptions[4]);
    positionTop = new UntypedFormControl(this.positionOptions[2]);
    positionBottom = new UntypedFormControl(this.positionOptions[3]);

  constructor(
    private _ngoSharedService: NgoSharedService,
    private _filterSharedService: FilterSharedService,
    private _fundraiserService: FundraiserService,
    public el: ElementRef
  ) { this.getScreenSize(); }

  ngOnInit() {
    this.getCharityId();   
  } 

 getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.charityId = selectedNGO.charityId;
              if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
              this.getDateFilter();
            }
          }
        );
  }

  getDateFilter() {
    this.filterDataSubscription = this._filterSharedService
        .dateFilters$
        .subscribe(
          res => {
            this.filterData = res;
            this.conversionData = null;
            this.getConversion();
          }
        );
  }

  getConversion() {
    this.conversionLoader = true;
    this._fundraiserService
        .getConversion(this.charityId, this.filterData)
        .pipe(finalize(() => this.conversionLoader = false))
        .subscribe(
          (res: any) => {
            if (typeof res === 'string') this.conversionData = null;
            else {             
              this.conversionData = res?.Total || null;
              this.conversionDirectData = res?.Direct || null;
              this.conversionEventData = res?.Event || null;

              if (res?.Total || res?.Direct || res?.Event) {
                const { conversionDirectFlag, conversionEventFlag } = this.getUpdatedFunnelGraphFlags(res?.Direct, res?.Event);
                setTimeout(() => {
                  this.conversionDirectFlag = conversionDirectFlag;
                  this.conversionEventFlag = conversionEventFlag;
                  this.createChart(conversionDirectFlag, conversionEventFlag);
                }, 100);
              }
            }
          },
          err => this.conversionData = null
        );
  }

  getUpdatedFunnelGraphFlags(direct: any, event: any) {
    // To check direct data
    const conversionDirectFlag = Object.keys(direct || null).some(key => direct[key] != 0 );

    // To check event data
    const conversionEventFlag = Object.keys(event || null).some(key => event[key] != 0 );

    return {
      conversionDirectFlag,
      conversionEventFlag
    }
  }

  createChart(directFlag: boolean = false, eventFlag: boolean = false) {    
    if (!(
        (
          this.conversionDirectData ||
          this.conversionEventData ||
          this.conversionData
        )
      )
    ) return;

    this.chart = new FunnelGraph({
      container: '.funnel',
      gradientDirection: '',
      displayPercent: false,
      direction: 'horizontal',
      width: this.scrWidth,
      height: 220,
     
      data: {        
        labels: ['Fundraisers Thanked', 'Opted In', 'Emails Collected'],    
        //subLabels: ['Direct', 'FBC'],    
        colors: directFlag==true && eventFlag==true ? this.chartColorTotalFunnel 
                : (directFlag==true ? this.chartColor : this.chartFbcColor), 
     
        
        values: directFlag==true && eventFlag==true ? ([
          [this.conversionDirectData.thank_you_count_direct, this.conversionEventData.thank_you_count_fbc],
          [this.conversionDirectData.opt_in_count_direct, this.conversionEventData.opt_in_count_fbc],
          [this.conversionDirectData.email_count_direct, this.conversionEventData.email_count_fbc]
        ]) : (directFlag == true ? (
          [this.conversionDirectData.thank_you_count_direct, this.conversionDirectData.opt_in_count_direct, this.conversionDirectData.email_count_direct]) : 
         ([this.conversionEventData.thank_you_count_fbc, this.conversionEventData.opt_in_count_fbc, this.conversionEventData.email_count_fbc]))
       
    },      
          
    });   
    const arrayOne = Array.isArray(this.chart?.data?.values[0]) ? this.chart?.data?.values[0]: [this.chart?.data?.values[0] || 0];
    const arrayTwo = Array.isArray(this.chart?.data?.values[1]) ? this.chart?.data?.values[1]: [this.chart?.data?.values[1] || 0];
    const arrayThree = Array.isArray(this.chart?.data?.values[2]) ? this.chart?.data?.values[2]: [this.chart?.data?.values[2] || 0];

    this.fundraiserthankedTotalVal = arrayOne.reduce((acc, curr) => acc + curr, 0);
    this.optinTotalVal = arrayTwo.reduce((acc, curr) => acc + curr, 0);    
    this.emailTotalVal = arrayThree.reduce((acc, curr) => acc + curr, 0);
    this.totLength = this.optinTotalVal.toString().length;    
    this.chart.draw();
  }
  
  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
  }

}