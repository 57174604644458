<div class="errBGbox">  
    
        <div class="card-box text-center">
  
          <div class="while-logo">
            <img src="../../assets/images/GU_Logo_Primary_Color.svg" alt="">
          </div>
          <h4 class="errPage">Error Page</h4>
          <p class="errorTxt mb-5">
            Please check the login credentials. The login works only if you are invited through app link. Please request for an invite.
          </p>
          <div class="bckTolgn"><a (click) = "backToLogin()" href="javascript:void(0)">Back To Login</a></div>
        </div>
  
    
    <div class="login-footer">
      <p>Copyright © 2022 GoodUnited.io</p>
    </div>  
  </div>
  