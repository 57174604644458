import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {
  private BASE_URL           = environment.baseURL;

  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.includes('/login'))
    {      
      return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {

          let errorMessage;

          if (error.error instanceof ErrorEvent) errorMessage = { message: error.error.message };
          else errorMessage = {status: error.status, message: error.error.message};

          if(error.status === 302)
          {
            this.router.navigate(['/organizations']);
          }

          if (error.status === 403) {            
            this.router.navigate(['/']);
          }

          if (error.status === 401) {
            this.router.navigate(['/login']);
          }

          return throwError(errorMessage);
        })
      );
    }
    else
    {
      return next.handle(req);
    }
   
  }
}