<div class="continer-fluid">
<div class="new-dashboard-wrapper">

<!-- app header -->
<app-new-header (ngoChanged)="ngoChanged($event)"></app-new-header>

<!-- Side Menu -->
<app-side-menu></app-side-menu>

<ng-container *ngIf="alertMsg.msg !== ''">
  <app-alert [message]="alertMsg"></app-alert>
</ng-container>

<!-- Content Starts -->
<div class="grid-wrapper">
<div class="row">
<div class="col-lg-12">

  <!-- <app-activation-tabs></app-activation-tabs> -->

  <div class="content-box">
  <form [formGroup]="testCohortForm" (ngSubmit)="createTest()" class="form-horizontal">

    <div class="overlay" *ngIf="showLoader">
      <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner loadImg"></span></div>
      </div>
    </div>

    <div class="border-btm1">
      <h4 class="page-title">
        Cohorts
      </h4>
      <p class="subTitle">You can use this page to set up Post Messages for all Categories. You can also A/B test different Post Messages against each other by adding additional Cohorts using the + button at the bottom of the page. Each cohort's messages will be evenly distributed (50/50) across the Fundraiser Walls.</p>
    </div>
    <div class="text-right mb-2">
      <!-- <a class="custom-btn" (click)="createMessagePage()" routerLink="/activation-message/messages/add"
        [queryParams]="{redirectUrl: 'current-test'}">
        Add Message
      </a>&nbsp; -->
      <button type="button" class="custom-btn" (click)="createMessagePage()">
        Add Message
      </button>
        &nbsp;
      <button type="submit" class="custom-btn" *ngIf="msgCategoryLists">
        Save
      </button>
    </div>
    <div class="card-body-box">

      <div formArrayName="testCohorts"
        *ngFor="let pm1 of testCohortForm.get('testCohorts')['controls']; let i = index"
        class="card repeatCard test-cohort">

        <div class="card-header">
          Cohort {{ i + 1 }}

          <div class="pull-right" *ngIf="testCohortForm.get('testCohorts')['controls'].length > 1">
            <div class="addIcon" title="Remove This Cohort" (click)="removeTestCohortButtonClick(i)">
              <i class="fa fa-trash"></i>
            </div>
          </div>
        </div>

        <div class="card-body card-block" [formGroupName]="i">
          <div *ngFor="let category of categoryLists" class="row form-group">
            <div class="col col-sm-3">
              <label class=" form-control-label">{{ category.category_name }}</label>
            </div>

            <div class="col col-sm-9">
              <ng-container *ngIf="msgCategoryLists">
                <select
                  [formControlName]="category.id"
                  class="form-control-test"
                  (change)="newGetMessageDetail($event.target.value, i, category.id)">
                    <option
                      *ngIf="category.category_type === 'owner'"
                      [ngValue]="null">Select Message
                    </option>
                    
                    <option
                      *ngIf="category.category_type === 'donor'"
                      [ngValue]="null">Select Message</option>

                    <option
                      *ngFor="let msg of msgCategoryLists[category.id]"
                      [ngValue]="msg.message_id"
                      title="{{msg.message_body}}">
                      {{msg.message_id}}: {{(msg.message_body.length>95)? (msg.message_body |
                      slice:0:95)+'...':(msg.message_body)}}</option>
                </select>
              </ng-container>
<!-- 
              <ng-container *ngIf="getErrorFormGroup(i, category.id, 'required')">
                <div class="alert alert-danger" role="alert">
                  Please select a message
                </div>
              </ng-container> -->
            </div>

            <ng-container *ngIf="newMessageDetailLists[i] && newMessageDetailLists[i][category.id]">
              <div class="col-md-9 offset-md-3">
                <div class="card-body-box testWrapper pt-3">
                  <app-message-detail [messageDetails]="newMessageDetailLists[i][category.id]">
                  </app-message-detail>
                </div>
              </div>
            </ng-container>

          </div>
        </div>

      </div>

      <div class="card-footer1 btn-update">
        <button type="submit" class="custom-btn pull-right" *ngIf="msgCategoryLists">
          Save
        </button>
        <a
          href="javascript:void(0)"
          title="Add More"
          class="pull-right"
          *ngIf="msgCategoryLists"
          (click)="addTestCohortButtonClick()">
            <i class="fa fa-plus"></i>
        </a>
      </div>

    </div>

  </form>
  </div>

</div>
</div>
</div>
<!-- Content Ends-->

<div class="clearfix"></div>

</div>
</div>

<div class="shape-bg">
  <div class="c-shape-bg is--full rt-25">
  </div>
</div>