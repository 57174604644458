import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { map, catchError, retry } from 'rxjs/operators';

@Injectable({
   providedIn: 'root'
 })

 export class IntegrationService{

   constructor(private http: HttpClient){}
   private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

   connect(data: any, charity_id:any): Observable<any> {
      //data = JSON.stringify(data);
      let params =  new HttpParams().set('charity_id', charity_id);
      return this.http
        .post('login_blackbaud', data, {params})
        .pipe(map(res => {
          return res;
        }, err => {
          throwError(err);
        })
        );

    }

    disconnect(charity_id: any): Observable<any>{
      let params = new HttpParams().set('charity_id', charity_id);
      return this.http.get('disconnect', {params});
    }


    getMappingAttributes(data_type: string, charity_id: any): Observable<any>{
      let params = new HttpParams().set('type', data_type).set('charity_id', charity_id);
      return this.http.get('map_attributes', {params});
    }

    getlistmapping(type:any, charity_id: any): Observable<any>{
       let params = new HttpParams().set('type', type).set('charity_id', charity_id);
       return this.http.get('list_mapping', {params});
    }

    postMappingAttributes(data: any, charity_id: any): Observable<any> {
      let params = new HttpParams().set('charity_id', charity_id);     
      return this.http.post('update_mapping', data, {params});
    }

    getBlackbaudConnection(charity_id: any){
      let params = new HttpParams().set('charity_id', charity_id);
      return this.http.get('already_login_blackbaud', {params});
    }

    syncdata(charityID: any): Observable<any>{
      let params = new HttpParams().set('charity_id', charityID);
      return this.http.get('start_sync', {params});
    }

    syncStatus(sync_id: any, charityId = ''): Observable<any>{
      let params = new HttpParams().set('sync_id', sync_id).set('charity_id', charityId);
      return this.http.get('sync_status', {params});
    }

    isActiveSyncStatus(charityID: any): Observable<any>{
      let params = new HttpParams().set('charity_id', charityID);
      return this.http.get('sync_active_status', {params});
    }

    viewLogStatus(charityID: any): Observable<any> {
      let params = new HttpParams().set('charity_id', charityID);
      return this.http.get('sync_status_logs',{params});
    }

    getsettingmapping(charity_id: any): Observable<any>{
      let params = new HttpParams().set('charity_id', charity_id);
      return this.http.get('show_settings', {params});
    }

    postSettingAttributes(data: any, charity_id:any): Observable<any> {
      return this.http.post('update_settings?charity_id=' + charity_id, data);
    }
   
}
