<div class="continer-fluid">
  <div class="new-dashboard-wrapper">

    <!-- app header -->
    <app-new-header></app-new-header>
    <ng-container *ngIf="alertMsg.msg !== ''">
      <app-alert [message]="alertMsg"></app-alert>
    </ng-container>
    <!-- Side Menu -->
    <app-side-menu></app-side-menu>

    <div class="grid-wrapper">
      <div class="row no-gutters mx-864">
        <div class="col-12">

          <div class="overlay__inner custom-overlay" *ngIf="loading">
            <div class="overlay__content custom">
                <span class="spinner loadImg"></span>
            </div>
        </div>

          <div class="ngo-admin-box">
            <div class="breadcrumbs">
              <ul>
                <li>
                  <a [routerLink]="['/integrations']">Integrations</a>
                </li>
                <li>
                  <a [routerLink]="['/integrations/map-attributes']">Luminate Online</a>
                </li>
                <li class="active">
                  <a href="javascript:void(0)">Sync Settings</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="content-integrations  mt-44">
            <div class="heading_wrapper">
              <div class="page-title">Luminate Online</div>
            </div>

            <form [formGroup]="syncsettingForm">
              <div class="content-box mb-4 pl-4">
                <div class="box syncSettingsData">
                  <label class="custom-container">
                    <input type="checkbox" formControlName="new_cons" [checked]="new_cons" [value]="new_cons"
                      [(ngModel)]="new_cons">
                    <span class="checkmark"></span>
                  </label>
                  <h4 class="box-title p-0">Create new Constituent records</h4>
                  <p class="subHeading">If unable to match a GoodUnited record with a Constituent record then create a
                    new Constituent record</p>
                </div>

                <div class="box syncSettingsData">
                  <label class="custom-container">
                    <input type="checkbox" formControlName="birthday_fundraiser_sync"
                      [checked]="birthday_fundraiser_sync" [value]="birthday_fundraiser_sync"
                      [(ngModel)]="birthday_fundraiser_sync">
                    <span class="checkmark"></span>
                  </label>
                  <h4 class="box-title p-0">Sync Birthday / Direct Facebook fundraiser and donor data</h4>
                  <p class="subHeading">Birthday / Direct fundraisers originate on Facebook</p>
                </div>

                <div class="box syncSettingsData">
                  <label class="custom-container">
                    <input type="checkbox" formControlName="p2p_sync" [checked]="p2p_sync" [value]="p2p_sync"
                      [(ngModel)]="p2p_sync">
                    <span class="checkmark"></span>
                  </label>
                  <h4 class="box-title p-0">Sync P2P / Integrated Facebook fundraiser data</h4>
                  <p class="subHeading">P2P / Integrated fundraisers are first created via TeamRaiser and then deployed
                    to Facebook</p>
                </div>

                <div class="box syncSettingsData">
                  <label class="custom-container">
                    <input type="checkbox" formControlName="sync_24_hours" [checked]="sync_24_hours"
                      [value]="sync_24_hours" [(ngModel)]="sync_24_hours">
                    <span class="checkmark"></span>
                  </label>
                  <h4 class="box-title p-0">Sync data every 24 hours</h4>
                  <p class="subHeading">Automatically push new GoodUnited data to Luminate Online every 24 hours. If
                    left unchecked you must sync data manually</p>
                </div>
              </div>
              <div class="box">
                <button class="custom-btn" (click)="updateSynsetting()">Update</button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
