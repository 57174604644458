import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-report-edit-confirmation',
  templateUrl: './report-edit-confirmation.component.html',
  styleUrls: ['./report-edit-confirmation.component.scss']
})
export class ReportEditConfirmationComponent implements OnInit {

  reportName: string;
  isNewReport: boolean;
  title: string = '';
  reportDescription: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReportEditConfirmationComponent>,
  ) { 
    this.reportName = data.reportName;
    this.isNewReport = data.isNewReport;
  }

  ngOnInit() {
    this.title = this.isNewReport?'New Report ':'Overwrite Report';
    this.reportDescription = this.isNewReport?" Would you like to save the report name "+ '\n':" Would you like to overwrite the previous" + '\n' + "version of the report name ";
  }

  onClose(output: boolean) {
    this.dialogRef.close(output);
  }

}
