import { Component, OnInit, OnDestroy } from '@angular/core';
import { Filter } from '../../../models/Fundraiser';
import { SubscriberListService } from 'src/app/services/subscriber.service';
import { Subscription } from 'rxjs';
import { NgoSharedService } from '../../services/ngo-shared.service';
import { FilterSharedService } from '../../services/filter-shared.service';
import { finalize } from 'rxjs/operators';
import { FormatNumber } from '../../custom/FormatNumber';
import { Router } from '@angular/router';
import { TooltipPosition } from '@angular/material/tooltip';
import { UntypedFormControl } from '@angular/forms';


@Component({
  selector: 'app-active-subscribers-list',
  templateUrl: './active-subscribers-list.component.html',
  styleUrls: ['./active-subscribers-list.component.scss']
})
export class ActiveSubscribersListComponent implements OnInit, OnDestroy {
  public charityId: string;
  public currencyName: string;

  public charityIDSubscription: Subscription;
  public filterDataSubscription: Subscription;
  public subscriberlistData: any;

  public filterData: Filter = {
    type: 'all',
    year: null,
    month: null
  };

  public activeSubscriberLoader  = true;
  public formatNumber = new FormatNumber();

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionLeft = new UntypedFormControl(this.positionOptions[4]);
  positionTop = new UntypedFormControl(this.positionOptions[2]);
  positionBottom = new UntypedFormControl(this.positionOptions[3]);

  constructor(
    private _ngoSharedService: NgoSharedService,
    private _subscriberlist: SubscriberListService,
    private _filterSharedService: FilterSharedService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.getCharityId();
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.currencyName = selectedNGO.currency_name;
              this.charityId = selectedNGO.charityId;
              if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
              this.getDateFilter();
            }
          }
        );
  }

  getDateFilter() {
    this.filterDataSubscription = this._filterSharedService
        .dateFilters$
        .subscribe(
          res => {
            this.filterData = res;
            this.getSubscrbierListData();
          }
        );
  }

  getSubscrbierListData() {
    this.activeSubscriberLoader = true;
    this._subscriberlist
        .getSubscriberList(this.charityId, this.filterData)
        .pipe(finalize(() => this.activeSubscriberLoader = false))
        .subscribe(
          (res: any) => {
            if (typeof res === 'string') return this.subscriberlistData = [];

            this.subscriberlistData = res.map((val) => {
              const newData = Object.assign({}, val);
              newData.fundraiser_amount = this.formatNumber.format(val.fundraiser_amount);
              return newData;
            });
          }
        );
  }

  openSubscriberDetail(_id){  
    this._router.navigate(['/subscribers/detail', _id]);
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
  }

}
