<div class="sidebar" [ngClass]="{'closed-sidebar': status == false, 'open-sidebar':status == true }">
   
    <div class="gu-logo">
        <a [routerLink]="['/']" class="logo-normal">
            <img width="40" src="../../assets/images/slogo.svg" alt="" />
            <p><img src="../../assets/images/logotext.svg" alt="" width="120"/></p>


        </a>   
        <a href="javascript:void(0)" class="closebtn" (click)="closeNav()"><img src="../../assets/images/closeIcon.png" alt=""></a>    
    </div>


    <!-- <h6>Organization</h6>
    <div class="organisation" *ngIf="selectedNgo">

        <div class="btn-group">
            <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <div class="imageIcon">
                    <img [src]="selectedNgo?.profilePicture" class="circle">
                </div>
                <div class="orgName" [title]="selectedNgo?.name">
                    {{ selectedNgo?.name }} 
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>                
            </button>
            <div class="ngo-wrapper dropdown-menu">
                <div class="searchBox">
                    <input type="search" class="search" #searchInput (input)="filter($event)" placeholder="Search">
                    <span class="searchIcon"></span>
                </div>
                <ul id="style-2" *ngIf="selectedNgo">
                    <ng-container *ngFor="let ngo of filteredNgoList$ | async">
                        <li *ngIf="ngo.charityId !== selectedNgo.charityId">
                            <div class="imageIcon">
                                <img [src]="ngo.profilePicture" alt="">
                            </div>
                            <div class="orgName" [title]="ngo.name" (click)="setPage(ngo)">
                                {{ ngo.name }}
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>

    </div> -->

    <nav role="navigation" class="side-nav-menu w-nav-menu">
        <ul class="list-unstyled">
             <li>
                <a (click)="redirect('/home')" class="nav-link" [ngClass]="{'active': (url === '/home') || (url === '/')}">
                    <div class="nav-ic">
                        <img src="../../assets/images/dashboard.svg" alt="" class="ic_default">
                    </div>
                    <div class="nav-ic-hover">
                        <img src="../../assets/images/dashover.svg" alt="" class="ic_default">
                    </div>
                    <div class="text-block-28"> Dashboard</div>
                </a>
            </li> 
            <li>
                <a (click)="redirect('/subscribers')" class="nav-link" [ngClass]="{'active': url === '/subscribers'}">
                    <div class="nav-ic">
                        <img src="../../assets/images/subscribers.svg" alt="" class="ic_default">
                    </div>
                    <div class="nav-ic-hover">
                        <img src="../../assets/images/subscriberhover.svg" alt="" class="ic_default">
                    </div>
                    <div class="text-block-28"> Subscribers</div>
                </a>
            </li>
            <li>
                <a (click)="redirect('/events')" class="nav-link" [ngClass]="{'active': url === '/events'}">
                    <div class="nav-ic">
                        <img src="../../assets/images/events.svg" alt="" class="ic_default">
                    </div>
                    <div class="nav-ic-hover">
                        <img src="../../assets/images/eventhover.svg" alt="" class="ic_default">
                    </div>
                    <div class="text-block-28"> Challenges</div>
                </a>
            </li>      
            <!-- <ng-container *ngIf="showReportMenu">
                <li>
                    <a (click)="redirect('/reports')" class="nav-link" [ngClass]="{'active': url === '/reports'}">
                        <div class="nav-ic">
                            <img src="../../assets/images/reporting.svg" alt="" class="ic_default">
                        </div>
                        <div class="nav-ic-hover">
                            <img src="../../assets/images/reporthover.svg" alt="" class="ic_default">
                        </div>
                        <div class="text-block-28">Reports</div>
                    </a>
                </li>
            </ng-container>     
            
            <ng-container  *ngIf="(userRole == userROLE.SUPER_ADMIN && showReportMenu) || (userRole == userROLE.NGO_ADMIN && showReportMenu && new_reporting)">
                <li>
                    <a (click)="redirect('/newreports')" class="nav-link" [ngClass]="{'active': url === '/newreports'}">
                        <div class="nav-ic">
                            <img src="../../assets/images/reporting.svg" alt="" class="ic_default">
                        </div>
                        <div class="nav-ic-hover">
                            <img src="../../assets/images/reporthover.svg" alt="" class="ic_default">
                        </div>
                        <div class="text-block-28">Reports</div>
                    </a>
                </li>
            </ng-container> -->

            <li class="reports">
                <a href="#reportsSubmenu" [ngClass]="{'active': url === '/newreports', 'active1': url === '/reports'}" data-toggle="collapse" aria-expanded="false"
                    class="dropdown-toggle collapsed">
                    <div class="nav-ic">
                        <img src="../../assets/images/reporting.svg" alt="" class="ic_default">
                    </div>
                    <div class="nav-ic-hover">
                        <img src="../../assets/images/reporthover.svg" alt="" class="ic_default">
                    </div>
                    <div class="text-block-28"> Reports <i class="fa fa-chevron-down" aria-hidden="true"></i></div>
                </a>
                <ul class="list-unstyled collapse" id="reportsSubmenu">
                    <li *ngIf="(userRole == userROLE.SUPER_ADMIN && showReportMenu) || (userRole == userROLE.SUPER_ADMIN && !showReportMenu && new_reporting) || (userRole == userROLE.NGO_ADMIN && showReportMenu && new_reporting) || (userRole == userROLE.NGO_ADMIN && !showReportMenu && new_reporting)">
                        <a class="text-block-28" [routerLink]="['/newreports']">New Reports</a>
                    </li>
                    <li *ngIf="(userRole == userROLE.SUPER_ADMIN && showReportMenu) || (userRole == userROLE.SUPER_ADMIN && !showReportMenu && !new_reporting) || (userRole == userROLE.NGO_ADMIN && showReportMenu && !new_reporting) || (userRole == userROLE.NGO_ADMIN && showReportMenu && new_reporting) || (userRole == userROLE.NGO_ADMIN && !showReportMenu && !new_reporting)">                        
                        <a class="text-block-28" [routerLink] = "['/reports']">Legacy Reports</a>
                    </li>
                </ul>
            </li>

            <li *ngIf="userRole == 'ROLE_SUPER_ADMIN'" class="optimization">
                <a href="#optimizationSubmenu" [ngClass]="{'active': url === '/activation-message/category', 'active1': url === '/activation-message/messages', 'active2': url === '/activation-message/tactics/list', 'active3': url === '/activation-message/current-test'}" data-toggle="collapse" aria-expanded="false"
                    class="dropdown-toggle collapsed">
                    <div class="nav-ic">
                        <img src="../../assets/images/optIcon.svg" alt="" class="ic_default">
                    </div>
                    <div class="nav-ic-hover">
                        <img src="../../assets/images/opthover.svg" alt="" class="ic_default">
                    </div>
                    <div class="text-block-28"> Optimization <i class="fa fa-chevron-down" aria-hidden="true"></i></div>
                </a>
                <ul class="list-unstyled collapse" id="optimizationSubmenu">
                    <li>
                        <a class="text-block-28" [routerLink]="['/activation-message/category']">Category</a>
                    </li>
                    <li>                        
                        <a class="text-block-28" [routerLink] = "['/activation-message/messages']">Messages</a>
                    </li>
                    <li>
                        <a class="text-block-28" [routerLink]="['/activation-message/tactics']">Tactics</a>
                    </li>
                    <li>
                        <a class="text-block-28" [routerLink]="['/activation-message/current-test']">Cohorts</a>
                    </li>
                </ul>
            </li>
            <li class="senetencelLabel">
                <a (click)="redirect('/sentence-level-classifier')" class="nav-link" [ngClass]="{'active': url === '/sentence-level-classifier'}">
                    <div class="nav-ic">
                        <!-- <img src="../../assets/images/reporting.svg" alt="" class="ic_default"> -->
                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    </div>
                    <div class="nav-ic-hover">
                        <!-- <img src="../../assets/images/reporthover.svg" alt="" class="ic_default"> -->
                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    </div>
                    <div class="text-block-28">  Sentence Classifier </div>
                </a>                
            </li>
            <li *ngIf="userRole == 'ROLE_SUPER_ADMIN'" class="Settings nav__dropdown">
                <a href="#SettingsSubmenu" [ngClass]="{'active': url === '/organizations', 'active1': url === '/organizations/manage'}" data-toggle="collapse" aria-expanded="false"
                    class="dropdown-toggle collapsed">
                    <div class="nav-ic">
                        <img style="padding-left: 3px; padding-right: 3px;" src="../../assets/images/settingsIcon.svg" alt="" class="ic_default">
                    </div>
                    <div class="nav-ic-hover">
                        <img src="../../assets/images/ic_settings_hover.svg" alt="" class="ic_default">
                    </div>
                    <div class="text-block-28"> Settings <i class="fa fa-chevron-down" aria-hidden="true"></i></div>
                </a>
                <ul class="list-unstyled collapse" id="SettingsSubmenu">
                    <li>
                        <a href="#organizationSubmenu" class="text-block-28 dropdown-toggle collapsed" data-toggle="collapse" aria-expanded="false">Organizations <i class="fa fa-chevron-down" aria-hidden="true"></i></a>
                        <ul class="list-unstyled collapse" id="organizationSubmenu">
                            <li>                        
                                <a class="text-block-28" [routerLink] = "['/organizations']">Orgs</a>
                            </li>
                            <li>                        
                                <a class="text-block-28" [routerLink] = "['/organizations/manage']">Manage</a>
                            </li>
                        </ul>
                    </li>
                    <li>                        
                        <a class="text-block-28" [routerLink] = "['/users']">User Permissions</a>
                    </li>
                    <li>
                        <a class="text-block-28" [routerLink]="['/integrations']">Integrations</a>
                    </li>                   
                </ul>
            </li>
            <li *ngIf="userRole == 'ROLE_ADMIN'" class="Settings">
                <a href="#SettingsSubmenu" [ngClass]="{'active': url === '/integrations'}" data-toggle="collapse" aria-expanded="false"
                    class="dropdown-toggle collapsed">
                    <div class="nav-ic">
                        <img src="../../assets/images/settingsIcon.svg" alt="" class="ic_default">
                    </div>
                    <div class="nav-ic-hover">
                        <img src="../../assets/images/ic_settings_hover.svg" alt="" class="ic_default">
                    </div>
                    <div class="text-block-28"> Settings <i class="fa fa-chevron-down" aria-hidden="true"></i></div>
                </a>
                <ul class="list-unstyled collapse" id="SettingsSubmenu"> 
                    <li>
                        <a class="text-block-28" [routerLink]="['/integrations']">Integrations</a>
                    </li>                   
                </ul>
            </li>
        </ul>
    </nav>

</div>

