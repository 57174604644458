import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { NgoSharedService } from './shared/services/ngo-shared.service';
import { UserService } from './services/user.service';
import { UserSharedService } from './shared/services/user-shared.service';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { filter, finalize } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { ReportService } from './services/report.service';
import { environment } from 'src/environments/environment';
import { ReportDownloadSharedService } from './shared/services/report-download-shared.service';
import * as LogRocket  from 'logrocket';
import { USER_ROLE, LOG_ROCKET_USER_TYPE } from './constant';
declare let pendo: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public userData;
  public title = 'goodunited';
  public showRouter = false;
  public code: string;

  public currentPath: string;
  public routerSubscription: Subscription;
  public reportSubscription: Subscription;
  protected reportIdSubscription: Subscription;
  protected ngoSubscription: Subscription;

  public alertMsg = { type: '', msg: '', url: ''};
  public reportId: number;
  public charityId: string;

  constructor(
    private router: Router,
    private _userService: UserService,
    private _ngoSharedService: NgoSharedService,
    private _userSharedService: UserSharedService,
    private _commonService: CommonService,
    private ngZone: NgZone,
    private _reportService: ReportService,
    private _reportDownloadSharedService: ReportDownloadSharedService
  ) { }

  ngOnInit() {
    if (environment?.logRocket?.enable) LogRocket.init(environment?.logRocket?.key);

    this.routerSubscription = this.router.events.pipe(
      filter(ev => (ev instanceof NavigationEnd))
    ).subscribe((ev: NavigationEnd) => {
      this.currentPath = ev.url;
      
      // Check if coming from after FB login
      if (!ev.url.includes('fb-callback')) this.checkUserAuth();
      else
      this.showRouter = true;
    });

    this.getReportID();
    this.getActiveNGO();
  }

  ngOnDestroy() {
    this.reportIdSubscription?.unsubscribe();
    this.ngoSubscription?.unsubscribe();
  }

  checkUserAuth() {
    this.routerSubscription.unsubscribe();

    this._userSharedService.userData.subscribe(user => {
      if (user.hasOwnProperty('email')) { 
        this.showRouter = true;
        this.userData = user;
        if (environment?.logRocket?.enable) this.logRocketIdentify(user);
        return;
      }

      if (!(this.currentPath.includes('login') || this.currentPath === '/'))
        sessionStorage.setItem(environment.localKey + 'redirectPath', this.currentPath);

  
      this._userService
        .getUserInfo()
        .subscribe(
          res => {
            this.userData = res;            
            this.ngZone.runOutsideAngular(() => {
              pendo.initialize({
                visitor: {
                    id:    this.userData.fbid,   
                    email: this.userData.email, 
                    name:  this.userData.name, 
                    role:  this.userData.roles[0].role  
                },
                account: {
                    id:  this.userData.fbid, 
                    email: this.userData.email,
                    name:  this.userData.name,
                    role:  this.userData.roles[0].role
                },
                events: {
                  ready: () => {
                    //console.log('pendo initialized')
                  }
                }
              });
            });
             
            this._userSharedService.changeUserData(res);
            if (!(this.userData.selectedPageId)) {
              this.showRouter = true;
              return this.router.navigate(['/organizations']);
            }

            this.setNgoList();
          }, error =>{
            this.showRouter = true;
          }
        );
    });

  }

  setNgoList() {
    this._commonService
        .fundraiserPages()
        .pipe(finalize(() => this.showRouter = true))
        .subscribe(
          (res) => {
            const connectedNgos = res.filter(item => item.connected === true);

            this._ngoSharedService.changeNgoList(connectedNgos);
            this.setSelectedNgo(connectedNgos);
          }
        );
  }

  setSelectedNgo(connectedNgos) {
    const selectedNgo = connectedNgos.find(data => data.pageId === this.userData.selectedPageId);
    this._ngoSharedService.changeSelectedNgo(selectedNgo);
  }

  getReportID() {
    this.reportIdSubscription = this._reportDownloadSharedService
        .reportId
        .subscribe(
          id => {
            if (this.userData?.fbid) {
              if (this.reportSubscription) this.reportSubscription.unsubscribe();

              if (id) {
                this.reportId = id;
                this.startTimer();
              } else {
                this.getRecentReportId();
              }
            }
          }
        )
  }

  getRecentReportId() {    
    this._reportService
        .recentReportId(this.charityId)
        .subscribe(
          res => {
            this.reportId = res.report_id;
            const data = localStorage.getItem(environment.localKey + 'reportId');
            if (!(data && parseInt(data, 10) === this.reportId)) this.startTimer();
          }
        );
  }

  startTimer() {
    //in 5 seconds check report status
    this.reportSubscription = interval(5000).subscribe(x => this.getDownloadReportLink());
  }

  getDownloadReportLink() {
    this._reportService
      .reportDownloadLink(this.charityId, this.reportId.toString())
      .subscribe(
        res => {
          this.alertMsg.msg   = res.message;
          this.alertMsg.type  = (res.task_status as string).toLowerCase();

          if (res.task_status === 'Success') {
            this.alertMsg.url = res.url;
            if (this.reportSubscription) this.reportSubscription.unsubscribe();
          }
        }
      );
  }
  
  downloadCSVClicked(ev) {
    this.reportSubscription.unsubscribe();
    localStorage.setItem(environment.localKey + 'reportId', this.reportId.toString());
  }

  logRocketIdentify(user: any) {
    const { email, name, fbid, roles } = user;
    if (!fbid) return;
    
    const accessType = roles[0]?.role === USER_ROLE.SUPER_ADMIN 
                      ? LOG_ROCKET_USER_TYPE.SUPER_ADMIN
                      : LOG_ROCKET_USER_TYPE.NORMAL_USER;
    LogRocket.identify(fbid, {
      name,
      email,
      accessType,
      env: environment.logRocket?.envType
    });
  }

  getActiveNGO () {
    this.ngoSubscription = this._ngoSharedService.selectedNgo.subscribe(ngo => {
      this.charityId = ngo?.charityId || null;
      this._reportDownloadSharedService.changeReportId(null);
    })
  }

}
