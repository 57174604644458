<div class="userSidebarWrapper">
    <div class="userSidebar" [ngClass]="{'closed-sidebar': status == false, 'open-sidebar':status == true }">
        <div class="logoText">
            <h6>GoodUnited</h6>
            <i class="fa fa-times fa-close" (click)="closeNav()" aria-hidden="true"></i>
        </div>
        <nav role="navigation" class="side-nav-menu w-nav-menu">            
            <ul class="list-unstyled">
                <li *ngIf="userRole == 'ROLE_SUPER_ADMIN'">
                    <a href="#organizationSubmenu" data-toggle="collapse" aria-expanded="false"
                    class="dropdown-toggle collapsed nav-link" [ngClass]="{'active': url === '/organizations', 'active1': url === '/organizations/manage'}">
                        Organizations
                    </a>
                    <ul class="list-unstyled collapse ml-10" id="organizationSubmenu">
                        <li>
                            <a [routerLink] = "['/organizations']" [ngClass]="{'active': url === '/organizations'}" class="nav-link">Organizations</a>
                        </li>
                        <li>
                            <a [routerLink] = "['/organizations/manage']" [ngClass]="{'active': url === '/organizations/manage'}" class="nav-link">Manage</a>
                        </li>                       
                    </ul>
                </li>
                <li *ngIf="userRole == 'ROLE_SUPER_ADMIN'">
                    <a [routerLink] = "['/users']" class="nav-link" [ngClass]="{'active': url === '/users'}">
                        User Permissions
                    </a>
                </li>
                <li>
                    <a [routerLink] = "['/integrations']" class="nav-link" [ngClass]="{'active': url === '/integrations', 'active1': url === '/integrations/map-attributes', 'active2': url === '/integrations/sync-settings'}">
                        Integrations
                    </a>
                </li>
            </ul>
        </nav>   
   </div>        
</div>