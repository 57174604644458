import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-report-download-alert',
  templateUrl: './report-download-alert.component.html',
  styleUrls: ['./report-download-alert.component.scss']
})
export class ReportDownloadAlertComponent implements OnInit {
  @Input() message: any;
  @Output() downloadBtn = new EventEmitter<any>();

  constructor(
    private _userService: UserService,
  ) { }

  ngOnInit() {
  }

  checkUserAuth() {
    this._userService
        .getUserInfo()
        .pipe(finalize(() => this.close()))
        .subscribe(res => window.location.href = this.message.url);
  }

  close() {
    this.message.msg = '';
    this.message.type = '';
    
    this.downloadBtn.emit(true);
  }

}
