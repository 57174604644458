
<div class="row no-gutters mb-3">
    <div class="col-12 col-md-12">
        <div class="searchBox">
            <input  class="searchText form-control" placeholder="Search" type="search">
            <span class="searchIcon"></span>
        </div>
    </div>                                       
</div>
<div class="row no-gutters"> 
    <div class="col-12 col-md-12">

        <!-- <div class="overlay__inner custom-overlay" *ngIf="loader">
            <div class="overlay__content">
                <span class="spinner loadImg"></span>
            </div>
        </div> -->

        <div class="table-responsive">
            <div class="reporting-outer">
                <div class="reporting-inner1">
                   
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th class="hard_left1">
                                            Product
                                        </th>
                                        <th class="next_left1">
                                            Name
                                        </th>
                                        <th class="event cmn">
                                           Challenge
                                        </th>
                                        <th class="series cmn">
                                            Series
                                        </th>
                                        <th class="email cmn">
                                            Email
                                        </th>
                                        <th class="donation_total cmn">
                                            Donation Amount
                                        </th>
                                        <th class="cmn">
                                           Source Name
                                        </th>
                                        <th class="campaign cmn" >
                                            Campaign Id
                                        </th> 
                                        <th class="date cmn" >
                                            Start Date
                                        </th>    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="hard_left1">
                                            <div class="product skyblue">
                                               Direct
                                            </div>
                                        </td>
                                        <td class="next_left1">                                        
                                                <div class="user-profile">
                                                    <div class="widget-content-left">
                                                            <img src="../../../assets/images/noImage.png" alt="">                                                    
                                                    </div>
                                                    <div class="widget-heading">
                                                       Jitendra K
                                                    </div>
                                                </div>
                                        </td>
                                        <td>
                                            Test
                                        </td>
                                        <td>
                                            Test Series
                                        </td>
                                        <td>
                                            deepak@tatrasdata.com
                                        </td>
                                        <td>
                                            600.00
                                        </td>
                                        <td>
                                           test
                                        </td>
                                        <td>
                                            435435
                                        </td>
                                        <td>
                                            2020-11-26
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="hard_left1">
                                            <div class="product skyblue">
                                               Direct
                                            </div>
                                        </td>
                                        <td class="next_left1">                                        
                                                <div class="user-profile">
                                                    <div class="widget-content-left">
                                                            <img src="../../../assets/images/noImage.png" alt="">                                                    
                                                    </div>
                                                    <div class="widget-heading">
                                                       Jitendra K
                                                    </div>
                                                </div>
                                        </td>
                                        <td>
                                            Test
                                        </td>
                                        <td>
                                            Test Series
                                        </td>
                                        <td>
                                            deepak@tatrasdata.com
                                        </td>
                                        <td>
                                            600.00
                                        </td>
                                        <td>
                                           test
                                        </td>
                                        <td>
                                            435435
                                        </td>
                                        <td>
                                            2020-11-26
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="hard_left1">
                                            <div class="product skyblue">
                                               Direct
                                            </div>
                                        </td>
                                        <td class="next_left1">                                        
                                                <div class="user-profile">
                                                    <div class="widget-content-left">
                                                            <img src="../../../assets/images/noImage.png" alt="">                                                    
                                                    </div>
                                                    <div class="widget-heading">
                                                       Jitendra K
                                                    </div>
                                                </div>
                                        </td>
                                        <td>
                                            Test
                                        </td>
                                        <td>
                                            Test Series
                                        </td>
                                        <td>
                                            deepak@tatrasdata.com
                                        </td>
                                        <td>
                                            600.00
                                        </td>
                                        <td>
                                           test
                                        </td>
                                        <td>
                                            435435
                                        </td>
                                        <td>
                                            2020-11-26
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="hard_left1">
                                            <div class="product skyblue">
                                               Direct
                                            </div>
                                        </td>
                                        <td class="next_left1">                                        
                                                <div class="user-profile">
                                                    <div class="widget-content-left">
                                                            <img src="../../../assets/images/noImage.png" alt="">                                                    
                                                    </div>
                                                    <div class="widget-heading">
                                                       Jitendra K
                                                    </div>
                                                </div>
                                        </td>
                                        <td>
                                            Test
                                        </td>
                                        <td>
                                            Test Series
                                        </td>
                                        <td>
                                            deepak@tatrasdata.com
                                        </td>
                                        <td>
                                            600.00
                                        </td>
                                        <td>
                                           test
                                        </td>
                                        <td>
                                            435435
                                        </td>
                                        <td>
                                            2020-11-26
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="hard_left1">
                                            <div class="product skyblue">
                                               Direct
                                            </div>
                                        </td>
                                        <td class="next_left1">                                        
                                                <div class="user-profile">
                                                    <div class="widget-content-left">
                                                            <img src="../../../assets/images/noImage.png" alt="">                                                    
                                                    </div>
                                                    <div class="widget-heading">
                                                       Jitendra K
                                                    </div>
                                                </div>
                                        </td>
                                        <td>
                                            Test
                                        </td>
                                        <td>
                                            Test Series
                                        </td>
                                        <td>
                                            deepak@tatrasdata.com
                                        </td>
                                        <td>
                                            600.00
                                        </td>
                                        <td>
                                           test
                                        </td>
                                        <td>
                                            435435
                                        </td>
                                        <td>
                                            2020-11-26
                                        </td>
                                    </tr>
                                                                                                                                                                                                                                        
                                </tbody>
                            </table>
                       
                    
                    <!-- <ng-template #NotFoundData>
                        <div class="NotFound text-center">
                            <span>No Data Found</span>
                        </div>
                    </ng-template> -->
        
                </div>
            </div>
        </div>
        
        <div class="guPaginationv-1 text-center">
            <ul>               
                <li><a href="javascript:void(0)">Previous</a></li>
                <li><a href="javascript:void(0)">Next</a></li>
            </ul>
        </div>
        <!-- <div class="newGuPagination" *ngIf="reportsData?.length>0">
            <pagination-controls [directionLinks]="false"
              (pageChange)="pageChanged($event)">
            </pagination-controls>
            <span class="countNumber">{{currentPageCount}}/{{totalRecords}}</span>
        </div> -->

    </div>    
    <!-- <div class="col-12 col-md-12 text-right mt-3">
        <a href="#" class="delete">Delete</a>                           
        <button type="button" class="custom-btn mr-2" style="background: #028795;">Export </button>
        <button type="button" class="custom-btn">Save</button>
    </div> -->
</div>