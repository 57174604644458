<div class="continer-fluid">
  <div class="new-dashboard-wrapper">

    <!-- app header -->
    <app-new-header></app-new-header>
    <!-- Side Menu -->
    <app-side-menu (ngoChanged)="ngoChanged($event)"></app-side-menu>

    <div class="grid-wrapper">
      <div class="row no-gutters">
        <div class="col-12">

          <div class="heading_wrapper">
            <div class="page-title">Activation Message Categories</div>
            <div class="common-box-wrapper">
                <div class="btnWrapper">
                    <button data-toggle="modal" (click)="displayAllMessage()" class="custom-btn" type="button">View Message Performance</button>
                </div> 
            </div>
          </div>

          <div class="content-box">
            <div class="overlay__inner custom-overlay" *ngIf="loading">
              <div class="overlay__content">
                <span class="spinner loadImg"></span>
              </div>
            </div>

            <div class="table-responsive">
              <div class="reporting-outer">
                <div class="reporting-inner">
                  <table class="table table-borderless mb-0">
                    <thead>
                      <tr>
                        <th class="serial">ID</th>
                        <th>Category</th>
                        <th>Messages Posted (all orgs)</th>
                        <th>Opt-ins (all orgs)</th>
                        <th>Opt-in % (all orgs)</th>
                        <th>Messages Posted</th>
                        <th>Opt-in</th>
                        <th>Opt-in %</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="overallList?.length > 0; else NotFound">
                        <tr *ngFor="let data of overallList">
                          <td class="serial">{{data.message_category_id}}</td>
                          <td><span class="name">{{data.message_category_name}}</span></td>
                          <td><span class="count">{{data.total_messages_posted | number:'1.0-0'}}</span></td>
                          <td><span class="count">{{data.total_optins | number:'1.0-0'}}</span></td>
                          <td>
                            <span class="count">{{data.optins_percentage | number : '1.0-2'}}%</span>
                          </td>
                          <td>
                            <span class="count">{{data.messages_posted_for_org | number:'1.0-0'}}</span>
                          </td>
                          <td><span class="count">{{data.org_optins | number:'1.0-0'}}</span></td>
                          <td><span class="count">{{data.org_optins_percentage | number : '1.0-2'}}%</span></td>
                        </tr>       
                      </ng-container>
                      <ng-template #NotFound>
                        <tr>
                          <td colspan="8" style="text-align: center;"> No Data Found </td>
                        </tr>
                      </ng-template>                
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        
        </div>
      </div>
    </div>
  </div>
</div>
<div class="shape-bg">
  <div class="c-shape-bg is--full rt-25">
  </div>
</div>

