import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Filter } from '../models/Fundraiser';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventDetail} from '../models/Event';
import { DateFilter } from '../shared/custom/DateFilter';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  public dateFilter = new DateFilter();

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  activeEventGraphData(charityId: string, data: Filter): Observable<any> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;
    
    const path = 'get_event_fundraiser_data';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  eventList(charityId: string, data: Filter): Observable<any> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;
    
    const path = 'get_all_events_graph_listing_fundraiser_data';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  onlyEventList(charityId: string, data: Filter): Observable<any> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;
    
    const path = 'get_series_details_listing';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  eventSeries(charityId: string, data: Filter, seriesId: string): Observable<any> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;
    params['series_id'] = seriesId;
    
    const path = 'series_graph_data';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  onlyEventSeriesList(charityId: string, data: Filter, seriesId: string): Observable<any> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;
    params['series_id'] = seriesId;
    
    const path = 'get_event_details_listing';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  getEventDetail(charityId: string, event_id: any): Observable<any> {
    const params = {
      charity_id: charityId,
      event_id
    };
    const path = 'get_event_details';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  eventDetailsSubscirberList(charityId: string, event_id: any): Observable<any> {
    const params = {
      charity_id: charityId,
      event_id
    };
    const path = 'get_event_top_subscribers';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  eventSubscriberList(charityId: string, data: Filter): Observable<any> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;
    
    const path = 'get_event_top_subscribers';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  getEventDetailById(charityId: string, eventId: string): Observable<EventDetail> {
    const params = {
      charity_id: charityId,
      event_id: eventId,
    };
    const path = 'get_event';

    return this.http.get<EventDetail>(path, { params });
  }

  createEvent(data, charityId: string): Observable<any> {
    const params = {
      charity_id: charityId,
    };

    return this.http.post('createEvent', data, { params });
  }

  editEvent(data, charityId: string): Observable<any> {
    const params = {
      charity_id: charityId,
    };
    return this.http.put('updateEvent', data, { params });
  }

  eventListSeriesActivities(charityId: string): Observable<any> {
    const params = {
      charity_id: charityId,
    };
    const path = 'listSeriesActivities';

    return this.http.get(path, { params });
  }

  eventSeriesSubscirberList(charityId: string, data: Filter, series_id: any): Observable<any> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;
    params['series_id'] = series_id;
    
    const path = 'get_event_top_subscribers';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  eventSeriesGraphData(charityId: string, data: Filter, seriesId = null): Observable<any> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;
    
    const path = 'series_graph_data';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  eventSeriesFundraiserGraphData(charityId: string, data: Filter): Observable<any> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;

    const path = 'get_series_fundraiser_data';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  eventSeriesSearch(charityId: string, data: Filter, seriesId: string, event_search: string): Observable<any> {
    let params = this.dateFilter.getParams(data);
    params['charity_id'] = charityId;
    params['series_id'] = seriesId;
    params['event_search'] = event_search;

    const path = 'get_event_details_listing';

    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }

  createSeries(data, charityId: string): Observable<any> {
    const params = {
      charity_id: charityId,
    };

    return this.http.post('add_series', data, { params });
  }

  createActivityLabel(data, charityId: string): Observable<any> {
    const params = {
      charity_id: charityId,
    };

    return this.http.post('add_activity', data, { params });
  }

  redirect(err) {
    if (err.status === 401) this.router.navigate(['/login']);
    return throwError(err);
  }

  getNewEventList(charityId: string, data: Filter, seriesId: string = null,series?) {
    let params = {};
    if(series == 'seriesOne'){
      params['filter'] = 'all';
    }else{
      params = this.dateFilter.getParams(data);
    }
    
    params['charity_id'] = charityId;
    
    if (seriesId != null) {
      params['series_id'] = seriesId;
    }

    const path = 'get_series_details_listing';
    return this.http.get(path, { params }).pipe(map((res: any) => res.message));
  }


}